<template>
    <div
        v-if="!isError"
        class="nauryz-challenge-banner"
        :class="{ started: isGameStarted }"
        @click="handleClickBanner"
    >
        <h4>Nauryz Challenge</h4>
        <span class="sub">
            {{ $t('nauryz_challenge_subtitle') }}
        </span>
    </div>

    <ActivateBonusesModal
        v-if="showActivateBonusesModal"
        :show="showActivateBonusesModal"
        @continue="handleClickBanner"
        @cancel="showActivateBonusesModal = false"
    />

    <TModal v-model="showDebtWarningModal">
        <ModalRequestFailure :title="$t('pay_off_debt_to_participate_quiz')">
            <template #actions>
                <TButton @click="handleClickPayOff">
                    {{ $t('pay_off') }}
                </TButton>
            </template>
        </ModalRequestFailure>
    </TModal>

    <TModal v-model="showPaymentModal">
        <ModalPayment />
    </TModal>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, type Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import { TButton, TModal } from '@telecom/t-components'
import ActivateBonusesModal from '../BonusesCard/ActivateBonusesModal.vue'
import ModalPayment from '@/shared/components/Modal/ModalPayment.vue'
import ModalRequestFailure from '@/shared/components/Modal/ModalRequestFailure.vue'
import { useBonusesStore } from '@/shared/modules/bonuses/store'

const route = useRoute()
const router = useRouter()
const { isError } = storeToRefs(useBonusesStore())

const isGameStarted: Ref<boolean> = ref(true)

const showActivateBonusesModal: Ref<boolean> = ref(
    route.query.showLoyaltyModal === 'true'
)
const showDebtWarningModal: Ref<boolean> = ref(
    route.query.showPaymentModal === 'true'
)
const showPaymentModal: Ref<boolean> = ref(false)

const handleClickBanner = (): void => {
    router.push({ name: 'bonuses.game' })
}

const handleClickPayOff = (): void => {
    showDebtWarningModal.value = false
    showPaymentModal.value = true
}

let interval: NodeJS.Timeout

onMounted(() => {
    router.replace({ query: {} })
    const startDate = new Date('2024-09-16T00:00:00')

    const checkForGameStarted = () => {
        const nowDate = new Date()
        isGameStarted.value = nowDate > startDate
    }

    checkForGameStarted()

    interval = setInterval(checkForGameStarted, 5000)
})

onUnmounted(() => {
    clearInterval(interval)
})
</script>

<style lang="scss">
.nauryz-challenge-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4px;
    background-image: url('@/assets/images/bonuses/nauryz-challenge-banner.png');
    background-repeat: no-repeat;
    background-position: 100%;
    height: 86px;
    padding: 18px 20px;
    box-shadow: 0px -2px 20px rgba(1, 88, 139, 0.24);
    border-radius: 24px 21px 0px 0px;
    font-weight: 500;
    font-size: text(p4);
    line-height: 23px;
    color: color(dark);
    z-index: 10;
    background-color: color(white);

    h4 {
        font-size: text(h6);
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.03em;
    }

    img {
        height: 20px;
    }

    .sub {
        color: color(dark);
        font-size: text(p6);
        font-weight: 400;
        line-height: 15px;
        max-width: 180px;
    }
}

*:has(+ .nauryz-challenge-banner) {
    margin-bottom: 30px;
}
</style>
