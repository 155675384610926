<template>
    <div :class="['card', borderClass]">
        <slot />
    </div>
</template>

<script setup lang="ts">
import { computed, type ComputedRef } from 'vue'

const props = withDefaults(
    defineProps<{
        isBorder?: boolean
    }>(),
    {
        isBorder: false
    }
)

const borderClass: ComputedRef<string> = computed(() => {
    return props.isBorder ? 'border' : ''
})
</script>

<style scoped lang="scss">
.card {
    background-color: color(white);
    padding: 24px 16px;
    border-radius: rounded(xl);

    &:deep(.card__table) {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &:deep(.card__table-tr) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &:deep(.card__table-td) {
        color: color(dark);
        font-size: text(p4);
        font-weight: 400;
        line-height: 125%;
    }

    &:deep(.card__table-td:first-child) {
        color: color(gray);
        font-size: text(p5);
        font-weight: 400;
        line-height: 125%;
    }

    &.border {
        border: 1px solid color(divider-mobile);
    }

    @include media(min, md) {
        padding: 24px;
    }
}
</style>
