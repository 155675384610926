<template>
    <Wrapper>
        <SettingsWrapper />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const SettingsWrapper = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/settings/SettingsWrapper.vue')
})
</script>

<style scoped lang="scss"></style>
