import type { Router } from 'vue-router'
import * as Sentry from '@sentry/vue'

type AcceptedEnvironmentType = 'development' | 'staging' | 'production'
const allowedEnvs: AcceptedEnvironmentType[] = ['production']

export class SentryService {
    static init(app: any, router: Router): void {
        if (
            !allowedEnvs.includes(
                import.meta.env.VITE_NODE_ENV as AcceptedEnvironmentType
            )
        ) {
            console.warn(
                `Method "SentryService.init" is not executed because it's not in an allowed environment.`
            )
            return
        }

        Sentry.init({
            app,
            dsn: 'https://f3e6a3c9ab5905d648ee5ad286352a47@sentry.telecom.kz/22',
            integrations: [Sentry.browserTracingIntegration({ router })],
            environment: import.meta.env.VITE_NODE_ENV,
            release: `customer-front@${import.meta.env.VITE_RELEASE_NAME}`
        })
    }

    static setUser(user: Sentry.User): void {
        Sentry.setUser(user)
    }

    static sendEvent(event: Sentry.Event): void {
        Sentry.captureEvent({
            level: 'error',
            ...event
        })
    }

    static sendException(exception: any): void {
        Sentry.captureException(exception)
    }
}
