<template>
    <AutoPaymentUpdate />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const AutoPaymentUpdate = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import(
            '@/components/payments/AutoPayment/components/AutoPaymentUpdate.vue'
        )
})

const { setTitle } = useTitle()

setTitle('auto_payment_settings')
</script>

<style scoped lang="scss"></style>
