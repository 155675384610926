export class Utils {
    static redirectLinkByUrl(redirectLink: string): void {
        const link = document.createElement('a')

        link.href = redirectLink
        link.click()
    }

    static getValueByQueryParam(queryParam: string): string | null {
        const urlObject = new URL(window.location.href)

        return urlObject.searchParams.get(queryParam)
    }

    static parseDataByKeys = (
        obj: any,
        rules: { [key: string]: string }
    ): any => {
        const parsedObj: any = {}

        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (key in rules) {
                    parsedObj[rules[key]] = obj[key]
                } else {
                    parsedObj[key] = obj[key]
                }
            }
        }

        return parsedObj
    }

    static getMobileOS(): 'Android' | 'iOS' | 'Other' {
        const ua = navigator.userAgent

        if (/android/i.test(ua)) {
            return 'Android'
        } else if (/iPad|iPhone|iPod/.test(ua)) {
            return 'iOS'
        }

        return 'Other'
    }

    static getIosVersion(): string {
        if (this.getMobileOS() !== 'iOS') {
            return 'incorrect-version'
        }

        const ua = navigator.userAgent
        const iosVersionMatch = ua.match(/TelecomKz\/([\d.]+)/)

        console.log(`USER AGENT ${ua}`)
        console.log(`USER AGENT IOS VERSION ${iosVersionMatch}`)

        if (iosVersionMatch) {
            console.log(`USER AGENT IOS VERSION MATCH ${iosVersionMatch[1]}`)
            return iosVersionMatch[1]
        }

        return ua
    }

    static compareVersions(version1: string, version2: string): number {
        console.log(`VERSION 1 ${version1}`)
        console.log(`VERSION 2 ${version2}`)
        const v1Parts = version1.split('.').map(Number)
        const v2Parts = version2.split('.').map(Number)

        console.log(`VERSION 1 PARTS ${version1}`)
        console.log(`VERSION 2 PARTS ${version2}`)

        if (
            v1Parts.some((el) => isNaN(el)) ||
            v2Parts.some((el) => isNaN(el))
        ) {
            console.log(`IF VERSION INCORRECT`)
            return -2 // if incorrect data
        }

        for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
            const v1 = v1Parts[i] || 0
            const v2 = v2Parts[i] || 0

            if (v1 > v2) {
                console.log(`IF VERSION 1 MORE THAN VERSION 2`)
                return 1 // if v1 more than v2
            }
            if (v1 < v2) {
                console.log(`IF VERSION 1 LESS THAN VERSION 2`)
                return -1 // if v1 less than v2
            }
        }

        console.log(`IF VERSION EQUAL`)
        return 0 // if v1 equal v2
    }
}
