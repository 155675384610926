<template>
    <Wrapper>
        <SalesWrapper />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import PageError from '@/shared/components/UI/PageError.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import Preloader from '@/shared/components/UI/Preloader.vue'

const SalesWrapper = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import('@/components/bonuses/components/Sales/SalesWrapper.vue')
})

const { setTitle } = useTitle()

setTitle('bonuses_sales')
</script>
