import type { AxiosResponse } from 'axios'

import type {
    AuthResponse,
    AuthenticateAituParams,
    AuthenticateAituResponse,
    AuthenticateRequestBody,
    CheckAituCodeRequestBody,
    CheckAituCodeResponse,
    CheckSmsRequestBody,
    CheckSmsResponse,
    RateAituVerificationRequestBody,
    RefreshRequestBody,
    ResetPasswordRequestBody,
    SendSmsRequestBody,
    OtpVerificationPayload,
    OtpVerificationResponse
} from './types'
import axios from '@/shared/services/api/axios'

export class AuthApi {
    static authenticate(
        payload: AuthenticateRequestBody
    ): Promise<AxiosResponse<AuthResponse>> {
        return axios.post('/api/v2.0/customer/oauth/token', payload)
    }

    static refresh(
        payload: RefreshRequestBody
    ): Promise<AxiosResponse<AuthResponse>> {
        return axios.post('/api/v2.0/customer/oauth/refresh', payload)
    }

    static sendSms(payload: SendSmsRequestBody): Promise<AxiosResponse> {
        return axios.post('/api/v2.0/customer/password/sms', payload)
    }

    static checkSms(
        payload: CheckSmsRequestBody
    ): Promise<AxiosResponse<CheckSmsResponse>> {
        return axios.post('/api/v2.0/customer/password/check', payload)
    }

    static resetPassword(
        payload: ResetPasswordRequestBody
    ): Promise<AxiosResponse<AuthResponse>> {
        return axios.post('/api/v2.0/customer/password/change', payload)
    }

    static authenticateAitu(
        params: AuthenticateAituParams
    ): Promise<AxiosResponse<AuthenticateAituResponse>> {
        return axios.get('/api/v3.0/customer/aitu/link', {
            params
        })
    }

    static checkAituCode(
        payload: CheckAituCodeRequestBody
    ): Promise<AxiosResponse<CheckAituCodeResponse>> {
        return axios.post('/api/v3.0/customer/aitu/check-aitu-code', payload)
    }

    static rateAituVerification(
        payload: RateAituVerificationRequestBody
    ): Promise<AxiosResponse> {
        return axios.post('/api/v3.0/customer/aitu/ratings', payload)
    }

    static otpVerification(
        customerId: number,
        payload: OtpVerificationPayload
    ): Promise<AxiosResponse<OtpVerificationResponse>> {
        return axios.post(
            `/api/v1.0/customer/${customerId}/verifyplusproxy`,
            payload
        )
    }
}
