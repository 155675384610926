<template>
    <ReregistrApplications />
</template>

<script setup lang="ts">
import { defineAsyncComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import Preloader from '@/shared/components/UI/Preloader.vue'
import PageError from '@/shared/components/UI/PageError.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import { AituService } from '@/shared/services/aitu/aitu-service'
import { useHeaderIcon } from '@/shared/hooks/useHeaderIcon'

const { setTitle } = useTitle()
const router = useRouter()
const { disableHeaderIcon } = useHeaderIcon()

setTitle('re_registration_pa')

const ReregistrApplications = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import(
            '@/components/re-registration/Reregistration/components/ReregistrationApplications.vue'
        )
})

const backHandler = (): void => {
    router.push({ name: 'extra-services' })
}

onMounted(() => {
    AituService.enableBackArrow()
    AituService.showBackArrow()
    AituService.setBackArrowHandler(() => backHandler())
    disableHeaderIcon()
})
</script>

<style scoped lang="scss"></style>
