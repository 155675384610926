import amplitude from 'amplitude-js'
import { AmplitudeCounters, YandexCounters } from './enum'

export class MetricService {
    private static initializeYandexMetric(id: string | number) {
        window.ym = ((...args: any[]) => {
            ;(window.ym.a = window.ym.a || []).push(args)
        }) as any

        window.ym.a = []
        window.ym.l = new Date().getTime()

        const script = document.createElement('script')
        script.async = true
        script.src = 'https://mc.yandex.ru/metrika/tag.js'
        document.head.appendChild(script)

        script.onload = () => {
            window.ym!(id, 'init', {
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: false,
                trackHash: false
            })
        }
    }

    private static initializeAmplitudeMetric(name: string, id: string) {
        amplitude.getInstance(name).init(id)
    }

    static initializeAdditionalMetrics = () => {
        const additionalYandexMetrics: YandexCounters[] = [
            YandexCounters.YandexTwo,
            YandexCounters.YandexThree
        ]
        const additionalAmplitudeMetrics: Record<string, AmplitudeCounters> = {
            second: AmplitudeCounters.AmplitudeSecond
        }

        additionalYandexMetrics.forEach((metricId) => {
            if (metricId) {
                this.initializeYandexMetric(metricId)
            }
        })

        Object.entries(additionalAmplitudeMetrics).forEach(
            ([name, metricId]) => {
                if (metricId) {
                    this.initializeAmplitudeMetric(name, String(metricId))
                }
            }
        )
    }
}
