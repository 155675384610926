import { useRouter, type RouteLocationNormalized } from 'vue-router'

import { AituService } from '../../services/aitu/aitu-service'
import { HeaderMenuIcon } from '../../types/aitu'
import { useHeaderIcon } from '../useHeaderIcon'
import { useAppRedirect } from '@/components/auth/hooks/useAppRedirect'
import { useMainStore } from '@/components/main/store'
import { useAuthStore } from '@/components/auth/store'

export const useRedirectPushRoute = () => {
    const router = useRouter()
    const mainStore = useMainStore()
    const authStore = useAuthStore()
    const {
        setRedirect,
        setRedirectPushProcessEnabled,
        redirectPushProcessEnabled
    } = useAppRedirect()

    const redirectToHome = (): void => {
        disableHeaderIcon()
        setRedirect('redirectPush', null)
        setRedirectPushProcessEnabled(false)

        router.push({ name: 'home' })
    }

    const { enableHeaderIcon, disableHeaderIcon } = useHeaderIcon(
        'redirect-close',
        HeaderMenuIcon.Close,
        redirectToHome
    )

    const handleRedirectPush = (
        to: RouteLocationNormalized,
        _from: RouteLocationNormalized
    ) => {
        if (to.query.redirectPush) {
            if (!authStore.isAuthenticated) {
                mainStore.openAskAuthModal()

                return
            }
        }

        if (redirectPushProcessEnabled.value) {
            AituService.enableBackArrow(false)
            enableHeaderIcon()
        }

        if (to.query.rPush) {
            setRedirectPushProcessEnabled(true)
            AituService.disableBackArrow()
            enableHeaderIcon()
        }
    }

    return {
        handleRedirectPush
    }
}
