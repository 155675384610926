import type { AxiosResponse } from 'axios'
import { storeToRefs } from 'pinia'
import { useCustomerStore } from '../customer/store'
import type {
    AddBalanceRequestBody,
    CheckPromocodeResponse,
    GetBonusCardsResponse,
    GetBonusesHistoryResponse,
    GetCustomerBonusesResponse,
    GetCustomerBonusesStatus,
    GetPartnersPromotionsResponse,
    GetBonusWelcomeResponse,
    GetBonusWelcomePayload
} from './types'
import axios from '@/shared/services/api/bonuses'

export class BonusesApi {
    private static getCustomerIin(): string | undefined {
        const { metadata } = storeToRefs(useCustomerStore())

        return metadata.value?.individual.iin
    }

    static getCustomerBonuses(): Promise<
        AxiosResponse<GetCustomerBonusesResponse>
    > {
        const customerIin: string | undefined = this.getCustomerIin()

        if (!customerIin) {
            console.error(
                `customer iin doesnt exist, couldn't execute /api/customer/{customer_iin}/bonuses`
            )
            return new Promise((_resolve, reject) =>
                reject(new Error('customer iin doesnt exist'))
            )
        }

        return axios.get(`/api/customer/${customerIin}/bonuses`)
    }

    static activateCustomerBonuses(): Promise<AxiosResponse<unknown>> {
        return axios.post(`/api/customer/${this.getCustomerIin()}/activate`)
    }

    static getCustomerBonusesStatus(): Promise<
        AxiosResponse<GetCustomerBonusesStatus>
    > {
        return axios.get(
            `/api/customer/${this.getCustomerIin()}/referral_bonuses_status`
        )
    }

    static getDetailsHistory(): Promise<
        AxiosResponse<GetBonusesHistoryResponse>
    > {
        return axios.get(`/api/customer/${this.getCustomerIin()}/transactions`)
    }

    static getPartnersPromotions(): Promise<
        AxiosResponse<GetPartnersPromotionsResponse>
    > {
        const { currentAccount } = storeToRefs(useCustomerStore())

        return axios.get(
            `/api/customer2/${this.getCustomerIin()}/partners/promotions`,
            {
                params: {
                    filial_id: currentAccount.value?.filial_id,
                    size: 100000
                }
            }
        )
    }

    static getPartnersPromoсode(partnerId: number): Promise<
        AxiosResponse<{
            data: {
                instance: string
            }
        }>
    > {
        return axios.get(
            `/api/customer2/${this.getCustomerIin()}/partners/promotions/${partnerId}/promocode`
        )
    }

    static getBonusCards(): Promise<AxiosResponse<GetBonusCardsResponse>> {
        return axios.get(`/api/customer/${this.getCustomerIin()}/cards/grouped`)
    }

    static getBonusWelcome(
        payload: GetBonusWelcomePayload
    ): Promise<AxiosResponse<GetBonusWelcomeResponse>> {
        return axios.post(
            `/api/customer/${this.getCustomerIin()}/transactions`,
            payload
        )
    }

    static addBalance(
        data: AddBalanceRequestBody
    ): Promise<AxiosResponse<{ status: boolean }>> {
        return axios.post(
            `/api/customer/${this.getCustomerIin()}/transactions`,
            data
        )
    }

    static checkPromocode(
        promocode: string
    ): Promise<AxiosResponse<CheckPromocodeResponse>> {
        return axios.get(`/api/customer/${promocode}/check_referral_promocode`)
    }
}
