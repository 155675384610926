<template>
    <div class="gradient-border">
        <Card class="tariffs-card" @click="handleClickTariffs">
            <TSpace>
                <TIcon class="tariff-icon" stroke="var(--color-primary)">
                    <ShoppingBag02Icon />
                </TIcon>

                <TSpace
                    justify="space-between"
                    align="center"
                    style="width: 100%"
                >
                    <TSpace vertical y-gap="4">
                        <strong>
                            {{ $t('tariffs') }}
                        </strong>

                        <small>
                            {{ $t('see_new_tariffs') }}
                        </small>
                    </TSpace>

                    <TButton
                        type="gray"
                        rounded
                        no-padding
                        style="padding: 4px"
                    >
                        <TIcon stroke="var(--color-primary)">
                            <ArrowNarrowRightIcon />
                        </TIcon>
                    </TButton>
                </TSpace>
            </TSpace>
        </Card>
    </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import {
    ArrowNarrowRightIcon,
    ShoppingBag02Icon,
    TButton,
    TIcon,
    TSpace
} from '@telecom/t-components'
import Card from '@/shared/components/Card/Card.vue'

const router = useRouter()

const handleClickTariffs = (): void => {
    router.push({
        name: 'main'
    })
}
</script>

<style scoped lang="scss"></style>
