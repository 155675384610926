<template>
    <AutoPaymentStatus />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const AutoPaymentStatus = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import(
            '@/components/payments/AutoPayment/components/AutoPaymentStatus.vue'
        )
})
</script>

<style scoped lang="scss"></style>
