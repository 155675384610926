<template>
    <Wrapper>
        <UserFoundByIin />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const UserFoundByIin = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/auth/components/UserFoundByIin.vue')
})

const { setTitle } = useTitle()

setTitle('gbdfl_user_found')
</script>
