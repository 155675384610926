import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { Language } from './types'
import { TranslationsService } from './translationsService'

export const useTranslationsStore = defineStore('translations', () => {
    const language: Ref<Language> = ref('ru')
    const { locale } = useI18n()

    const setLanguage = (lang: Language) => {
        locale.value = TranslationsService.isExistedLanguage(lang) ? lang : 'ru'
        language.value = TranslationsService.isExistedLanguage(lang)
            ? lang
            : 'ru'
    }

    return {
        language,
        setLanguage
    }
})
