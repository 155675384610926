<template>
    <Wrapper>
        <SapaRouterStart />
    </Wrapper>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, onBeforeMount } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'
import { AituService } from '@/shared/services/aitu/aitu-service'
import { useHeaderIcon } from '@/shared/hooks/useHeaderIcon'

const SapaRouterStart = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/sapa/components/SapaRouterStart.vue')
})

const { disableHeaderIcon } = useHeaderIcon()
const { setTitle } = useTitle()

onBeforeMount(() => {
    AituService.disableBackArrow()
    disableHeaderIcon()
})

setTitle('sapa_get_router')
</script>
