export class PersistanceLocalStorage {
    static getItem(key: string): any {
        try {
            return JSON.parse(localStorage.getItem(key) as string)
        } catch (e) {
            console.error('Error in getting data from localStorage', e)
            return null
        }
    }

    static setItem(key: string, data: any): void {
        try {
            localStorage.setItem(key, JSON.stringify(data))
        } catch (e) {
            console.error('Error in setting data to localStorage', e)
        }
    }

    static deleteItem(key: string): void {
        try {
            localStorage.removeItem(key)
        } catch (e) {
            console.error('Error in deleting data in localStorage', e)
        }
    }
}
