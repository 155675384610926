<template>
    <Wrapper>
        <ModalRefused />
    </Wrapper>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const ModalRefused = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import('@/components/digital-document/components/ModalRefused.vue')
})

const { setTitle } = useTitle()

setTitle('digital_document')
</script>

<style scoped lang="scss"></style>
