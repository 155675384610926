import { ref, type Ref, type ComputedRef, computed } from 'vue'
import { defineStore } from 'pinia'
import type { AxiosError } from 'axios'
import type { SelectOption } from '@telecom/t-components/dist/components/TSelect/types'
import { TransferAddressService } from './service'
import { ServiceTransferApi } from './api'
import {
    TransferAddressStatus,
    type CreateAutoblankForm,
    type TransferRelocationData,
    type TransferCreateRequest
} from './types'
import router from '@/router'

import { useCustomerStore } from '@/shared/modules/customer/store'
import { TelecomApi } from '@/shared/services/api/telecom'
import { useServicesStore } from '@/components/services/ServiceList/store'
import { OnboardingApi } from '@/shared/modules/onboarding/api'
import { AppealsMobileApi } from '@/components/appeals/api'
import type { CreateAutoblankPayload } from '@/shared/modules/onboarding/types'

export const useServiceTransferStore = defineStore('service-transfer', () => {
    const isLoading: Ref<boolean> = ref(false)
    const requestId: Ref<number> = ref(0)
    const result: Ref<boolean> = ref(false)
    const townStateId: Ref<number> = ref(0)
    const townState: Ref<any> = ref(null)
    const townNew: Ref<string> = ref('')
    const houseNew: Ref<number> = ref(0)
    const streetNew: Ref<string> = ref('')
    const flatNum: Ref<number> = ref(0)
    const currentStatus: Ref<TransferAddressStatus> = ref(
        TransferAddressStatus.Created
    )
    const streets: Ref<SelectOption[] | null> = ref(null)
    const customerStore = useCustomerStore()
    const transferAddressDoc: Ref<string> = ref('')
    const servicesStore = useServicesStore()

    const autoblankForm: ComputedRef<CreateAutoblankForm> = computed(() => {
        const {
            iin,
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName
        } = customerStore.getIndividual!

        const {
            doc_number: docNumber,
            doc_date_from: docDateFrom,
            doc_issued_by: docIssuedBy
        } = customerStore.getCustomerDoc!

        const annualContractId = '2'
        const adrStreet =
            `${customerStore.currentAccount?.address.selectedStreet.text} ${customerStore.currentAccount?.address.house}` ||
            ''
        const townStateName =
            customerStore.currentAccount?.address.selectedTown.text || ''
        const streetName =
            customerStore.currentAccount?.address.selectedStreet.text || ''
        const house = customerStore.currentAccount?.address.house || 0
        const CID = requestId.value || 0
        const subHouse = 0
        const flat = customerStore.currentAccount?.address.flat || 0
        const paymentFormID = customerStore.getCustomerAccountType || 1
        const firstNameInitial = firstName ? `${firstName[0]}.` : ''
        const middleNameInitial = middleName ? `${middleName[0]}.` : ''
        const productOffer = TransferAddressService.filterService(
            servicesStore.services
        )
        const blankAbonentName =
            `${lastName} ${firstNameInitial}${middleNameInitial}`.trim()
        const townStateNameNew = townNew.value
        const streetNameNew = streetNew.value
        const newHouse = houseNew.value
        const flatNew = flatNum.value
        const email = customerStore.getPrimaryEmail || ''
        const adrStrNew = `${townState.value.text} ${streetNew.value} ${houseNew.value}`

        const form = {
            BlankID: 18,
            CID,
            DocumentParams: {
                mobnum: Number(customerStore.getPrimaryMobilePhone),
                user: 'MobApp',
                code: 1,
                isRent: 1,
                isArenda: 0
            },
            dataJson: {
                localID: customerStore.getLocalId || 0,
                isDemandInstaller: 1,
                IIN: iin,
                firstName,
                middleName,
                lastName,
                annualContractId,
                blankAbonentName,
                newProductOffer: productOffer,
                docNumber,
                docDateFrom,
                docIssuedBy,
                email,
                townStateName,
                streetName,
                house,
                paymentFormID,
                subHouse,
                flat,
                adrStreet,
                townStateNameNew,
                streetNameNew,
                houseNew: newHouse,
                subHouseNew: null,
                flatNew,
                adrStrNew,
                oldProductOffer: productOffer
            }
        }

        return form
    })

    const checkTransferAddress = async (
        requestBody: TransferCreateRequest
    ): Promise<void> => {
        if (requestId.value) {
            return
        }

        isLoading.value = true

        ServiceTransferApi.createTransferRequests(requestBody)
            .then((createResponse) => {
                if (createResponse?.data?.requestId) {
                    requestId.value = createResponse.data.requestId

                    return ServiceTransferApi.checkTransfer(requestId.value)
                        .then((response) => {
                            result.value = response?.data?.result
                            townStateId.value = response?.data?.townStateId

                            return result.value
                        })
                        .catch((error) => {
                            console.log(error)
                            router.push({ name: 'transfer-refused' })
                            throw new Error(
                                'No requestId received from createTransfer.'
                            )
                        })
                        .finally(() => (isLoading.value = false))
                }
            })
            .catch((error) => {
                console.error('Error during transfer process:', error)
                router.push({ name: 'transfer-refused' })
            })
            .finally(() => (isLoading.value = false))
    }

    const transferRelocation = async (
        status: TransferAddressStatus
    ): Promise<void> => {
        const relocationData: TransferRelocationData = {
            status
        }

        try {
            await ServiceTransferApi.transferRelocation(
                requestId.value,
                relocationData
            )
        } catch (error) {
            console.log(error)
        }
    }

    const getAcquaintedTransfer = async (
        status: TransferAddressStatus
    ): Promise<void> => {
        isLoading.value = true

        try {
            await transferRelocation(status)

            const { data } = await AppealsMobileApi.getOrders()

            currentStatus.value =
                await TransferAddressService.checkOrdersStatus(data)
            await changeCurrentStatus(currentStatus.value)

            if (
                (currentStatus.value = TransferAddressStatus.OpenOrderChecked)
            ) {
                router.push({
                    name: 'transfer-address-form'
                })
            } else {
                router.push({ name: 'transfer-open-order' })
            }
        } catch (err) {
            const error = err as AxiosError

            if (error?.response?.status === 500) {
                router.push({
                    name: 'transfer-unavailable'
                })
            }
        } finally {
            isLoading.value = false
        }
    }

    const changeCurrentStatus = async (
        status: TransferAddressStatus
    ): Promise<void> => {
        const relocationData = {
            status
        }

        isLoading.value = true

        try {
            const { data } = await ServiceTransferApi.transferRelocation(
                requestId.value,
                relocationData
            )
        } catch (error) {
            console.log(error)
        } finally {
            isLoading.value = false
        }
    }

    const setTownState = (town: string): void => {
        townNew.value = town
    }

    const setStreet = (street: string): void => {
        streetNew.value = street
    }

    const setHouse = (houseId: number): void => {
        houseNew.value = houseId
    }

    const setFlat = (flat: number): void => {
        flatNum.value = flat
    }

    const getTownById = async (): Promise<void> => {
        isLoading.value = true

        try {
            const { data } = await TelecomApi.getRegionsIsb()

            townState.value = TransferAddressService.findTownById(
                data,
                townStateId.value
            )
        } catch (error) {
            console.log(error)
        } finally {
            isLoading.value = false
        }
    }

    const resetTransferRequest = (): void => {
        requestId.value = 0
        townStateId.value = 0
    }

    const resetTransferRequestId = (): void => {
        requestId.value = 0
    }

    const getStreets = async (): Promise<void> => {
        isLoading.value = true

        try {
            const { data } = await TelecomApi.getStreets(townStateId.value)

            streets.value = TransferAddressService.modifyStreets(data)
        } catch (error) {
            console.log(error)
        } finally {
            isLoading.value = false
        }
    }

    const createAutoblank = async (
        orderId?: number,
        otpCode?: string
    ): Promise<void> => {
        isLoading.value = true

        const otpCodeNumber = Number(otpCode)

        try {
            const params: any = { ...autoblankForm.value }

            if (orderId) {
                params.CID = orderId
            }

            if (otpCode) {
                params.DocumentParams.code = otpCodeNumber
            }

            const { data } = await OnboardingApi.createAutoblank(params)

            transferAddressDoc.value = data.data
        } catch (error) {
            console.log(error)
        } finally {
            isLoading.value = false
        }
    }

    return {
        isLoading,
        checkTransferAddress,
        resetTransferRequest,
        getAcquaintedTransfer,
        result,
        getTownById,
        townState,
        getStreets,
        currentStatus,
        streets,
        requestId,
        transferAddressDoc,
        createAutoblank,
        setTownState,
        setHouse,
        resetTransferRequestId,
        setStreet,
        setFlat,
        changeCurrentStatus,
        transferRelocation,
        townNew,
        streetNew,
        houseNew,
        flatNum,
        townStateId,
        autoblankForm
    }
})
