<template>
    <Wrapper>
        <MegaSapaScanGuide />
    </Wrapper>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, onBeforeMount } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'
import { useHeaderIcon } from '@/shared/hooks/useHeaderIcon'

const MegaSapaScanGuide = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import('@/components/sapa/components/megaline/MegaSapaScanGuide.vue')
})

const { disableHeaderIcon } = useHeaderIcon()
const { setTitle } = useTitle()

onBeforeMount(() => {
    disableHeaderIcon()
})

setTitle('sapa_information')
</script>
