import type { AxiosResponse } from 'axios'

import axios from '@/shared/services/api/axios'
import type {
    CreateAutoblankNewForm,
    CreateAutoblankPayload,
    OnboardingCustomerForm,
    OnboardingCustomerResponse,
    OnboardingOrderForm,
    PhoneOtpForm
} from '@/shared/modules/onboarding/types'
import type { CreateAutoblankForm } from '@/components/services/ServiceTransfer/types'

export class OnboardingApi {
    static sendOtp(data: PhoneOtpForm): Promise<AxiosResponse<void>> {
        return axios.post('/api/v1.0/open/otp/sms/send', data)
    }

    static createAutoblank(
        data: CreateAutoblankPayload | CreateAutoblankNewForm
    ): Promise<AxiosResponse<any>> {
        return axios.post('/api/v1.0/document/autoblank/create', data)
    }

    static onboardingCustomer(
        payload: OnboardingCustomerForm
    ): Promise<AxiosResponse<{ data: OnboardingCustomerResponse }>> {
        return axios.post('/api/v1.0/open/onboarding/customer', payload)
    }

    static onboardingOrder(
        data: OnboardingOrderForm
    ): Promise<AxiosResponse<void>> {
        return axios.post('/api/v2.0/open/onboarding/order', data)
    }
}
