<template>
    <TModal v-model="mainStore.isAskAuthModal">
        <ModalRequestFailure
            :title="$t('auth_login')"
            :text="$t('auth_next_required')"
        >
            <template #actions>
                <TButton full size="large" @click="askAuthHandler">
                    {{ $t('auth_do') }}
                </TButton>
            </template>
        </ModalRequestFailure>
    </TModal>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'

import { TModal, TButton } from '@telecom/t-components'
import { useAuthStore } from '../../store'
import { useMainStore } from '@/components/main/store'
import ModalRequestFailure from '@/shared/components/Modal/ModalRequestFailure.vue'

const mainStore = useMainStore()
const authStore = useAuthStore()
const router = useRouter()

const askAuthHandler = (): void => {
    mainStore.closeAskAuthModal()

    authStore.logout().then(() => {
        router.push({ name: 'login' })
    })
}
</script>
