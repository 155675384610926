<template>
    <Wrapper>
        <AituVerification />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent, onBeforeMount, onUnmounted } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import { AituService } from '@/shared/services/aitu/aitu-service'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const AituVerification = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/auth/components/AituVerification.vue')
})

const { setTitle } = useTitle()

setTitle('verification')

onBeforeMount(() => {
    AituService.enableBackArrow()
    AituService.showBackArrow(false)
})

onUnmounted(() => {
    AituService.showBackArrow()
    AituService.enableBackArrow(false)
})
</script>
