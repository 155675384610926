<template>
    <TModal
        v-model="modalState.exit"
        :close-icon="false"
        class="reregistration-close-modal"
    >
        <ModalSimple
            :title="$t('appeals_register_ls_confirm_action')"
            :text="$t('return_to_beginning_progress_lost')"
        >
            <template #actions>
                <TSpace x-gap="12">
                    <TButton full @click="toggleModal('exit')">
                        {{ $t('cancel') }}
                    </TButton>
                    <TButton full type="gray" @click="onExit">
                        {{ $t('yes') }}
                    </TButton>
                </TSpace>
            </template>
        </ModalSimple>
    </TModal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { TButton, TModal, TSpace } from '@telecom/t-components'

import { useModal } from '@/shared/hooks/useModal'
import ModalSimple from '@/shared/components/Modal/ModalSimple.vue'
import type { ModalStateExit } from '@/components/re-registration/Reregistration/types'
import { useReregistrationStore } from '@/components/re-registration/Reregistration/store'
import { ReregistrationApi } from '@/components/re-registration/Reregistration/api'

const router = useRouter()
const route = useRoute()
const reregistrationStore = useReregistrationStore()
const { flow, applicationSelected } = storeToRefs(reregistrationStore)

const modalState: ModalStateExit = reactive({
    exit: false
})
const { toggleModal } = useModal<ModalStateExit>(modalState)

const onExit = (): void => {
    const cancelRoutes: string[] = [
        're-registration-appeal.tariff-plan',
        're-registration-appeal.sign',
        're-registration-appeal.verification',
        're-registration-appeal.otp',
        're-registration-appeal.equipment'
    ]

    if (route.name && cancelRoutes.includes(<string>route.name) && flow.value === 'ex-new') {
        ReregistrationApi.cancelReissue({ request_id: applicationSelected.value!.id })
    }

    router.push({ name: 'home' })
}

defineExpose({ toggleModal })
</script>

<style lang="scss">
.reregistration-close-modal {
    .modal__title {
        margin-bottom: -20px;
    }
}
</style>
