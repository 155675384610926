<template>
    <TGrid cols="3" x-gap="12" y-gap="12">
        <TGridItem v-for="item in menuItems" :key="item.name">
            <button
                v-if="item.name"
                class="menu-list-item"
                @click="onNavigateWithMetric(item)"
            >
                <TIcon v-if="item.icon" stroke="#267CCC" width="28" height="28">
                    <Component :is="item.icon" />
                </TIcon>

                <img v-if="item.image" :src="item.image" :alt="item.name" />

                <h3 class="menu-list-item__title">
                    {{ $t(item.title) }}
                </h3>

                <span v-if="item.badge" class="menu-list-item__badge">
                    {{ $t(item.badge) }}
                </span>
            </button>

            <button v-else id="btn-chat-bot" class="menu-list-item">
                <TIcon stroke="#267CCC" width="28" height="28">
                    <Component :is="item.icon" />
                </TIcon>

                <h3 class="menu-list-item__title">
                    {{ $t(item.title) }}
                </h3>
            </button>
        </TGridItem>
    </TGrid>
</template>

<script lang="ts" setup>
import { onMounted, ref, type Ref } from 'vue'
import { useRouter } from 'vue-router'

import {
    TIcon,
    MessageChatCircleIcon,
    Edit05Icon,
    CreditCard02Icon,
    TGrid,
    TGridItem
} from '@telecom/t-components'
import { useMainStore } from '../store'
import type { NavbarItem } from '@/components/main/MenuList/types'
import ServicesIcon from '@/assets/images/services-icon.svg'
import DetalizationIcon from '@/assets/images/main-calendar-icon.svg'
import AppsIcon from '@/assets/images/apps-icon.svg'
import { useMetric } from '@/shared/hooks/useMetric'
import { YandexCounters } from '@/shared/services/metric/enum'
import { useAuthStore } from '@/components/auth/store'

const menuItems: NavbarItem[] = [
    {
        title: 'navbar_services',
        name: 'services',
        metricGoal: 'HOMETAPMYSERVICES',
        noAuthMetricGoal: 'myservices',
        image: ServicesIcon,
        isAuth: true
    },
    {
        title: 'navbar_detalization',
        name: 'detalization',
        metricGoal: 'HOMEDETALIZATION',
        noAuthMetricGoal: 'detalization',
        image: DetalizationIcon,
        isAuth: true
    },
    {
        title: 'payments_payments',
        name: 'payments',
        icon: CreditCard02Icon,
        metricGoal: 'HOMEPAGEPAYMENTS',
        noAuthMetricGoal: 'payments',
        isAuth: true
    },
    {
        title: 'navbar_applies',
        name: 'appeals',
        metricGoal: 'HOMETAPORDERS',
        noAuthMetricGoal: 'application',
        icon: Edit05Icon,
        isAuth: true
    },
    {
        name: 'faq',
        title: 'online_consultant',
        metricGoal: '',
        icon: MessageChatCircleIcon
    },
    {
        title: 'navbar_extra_services',
        name: 'extra-services',
        image: AppsIcon,
        isAuth: true,
        badge: 'new'
    }
]

const { sendMetric } = useMetric()
const router = useRouter()
const authStore = useAuthStore()
const mainStore = useMainStore()

const interval: Ref<number | undefined> = ref(undefined)

onMounted(() => {
    // interval.value = setInterval(() => {
    //     replaceOpenChatEvent()
    // }, 100) as unknown as number
})

const replaceOpenChatEvent = (): void => {
    let currentBtn = document.getElementById(
        'ib-button-messaging'
    ) as HTMLElement

    if (!currentBtn) {
        currentBtn = document.querySelector('.ib-widget-button') as HTMLElement
    }

    const customBtn = document.getElementById('btn-chat-bot')

    if (currentBtn && customBtn) {
        customBtn.addEventListener('click', (e) => {
            e.preventDefault()
            currentBtn.click()
        })

        clearInterval(interval.value)
    }
}

const onNavigateWithMetric = (item: NavbarItem): void => {
    if (!authStore.isAuthenticated && item.isAuth) {
        mainStore.openAskAuthModal()

        sendMetric(
            'notautho_main',
            {
                clicked: item.noAuthMetricGoal
            },
            YandexCounters.YandexTwo
        )

        return
    }

    router
        .push({ name: item.name })
        .then(() => {
            if (item.metricGoal) {
                sendMetric(item.metricGoal)

                if (!authStore.isAuthenticated && item.noAuthMetricGoal) {
                    sendMetric(
                        'notautho_main',
                        {
                            clicked: item.noAuthMetricGoal
                        },
                        YandexCounters.YandexTwo
                    )
                }
            }
        })
        .catch((err) => {
            console.error(err)
        })
}
</script>

<style scoped lang="scss">
.menu-list-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    text-decoration: none;
    text-align: center;
    background-color: color(white);
    border-radius: rounded(md);
    padding: 12px 8px;
    //min-height: 96px;
    border: 0;
    width: 100%;
    position: relative;

    &__title {
        color: color(black);
        font-weight: 400;
        font-size: text(p5);
        line-height: 112.5%;
    }

    &__badge {
        position: absolute;
        top: -6px;
        left: -8px;
        background-color: color(primary);
        color: color(white);
        border-radius: rounded(md);
        padding: 0 6px;
        font-size: text(p6);
    }
}
</style>
