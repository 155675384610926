import '@/assets/styles/global.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import YmapPlugin from 'vue-yandex-maps'
import { createI18n } from 'vue-i18n'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { install } from 'vue3-recaptcha-v2'
import { initYandexMetrika } from 'yandex-metrika-vue3'
import amplitude from 'amplitude-js'

import App from './App.vue'
import router from './router'
import { TranslationsService } from './shared/modules/translations/translationsService'
import type { TranslationGroup } from './shared/modules/translations/types'
import { SentryService } from './shared/services/sentry/sentry-service'
import { MetricService } from '@/shared/services/metric/metricService'
import { recaptchaConfig } from '@/shared/config/recaptcha'

const settings = {
    apiKey: '03496f65-7f15-4154-8fee-49d55b393dc2' // Индивидуальный ключ API
}

amplitude.getInstance().init(import.meta.env.VITE_AMPLITUDE_API_KEY)

const translations: TranslationGroup =
    await TranslationsService.getTranslations()

const i18n = createI18n({
    legacy: false,
    locale: 'ru',
    fallbackLocale: 'ru',
    pluralRules: {
        ru: TranslationsService.russianPluralizationRule
    },
    messages: translations
})

const app = createApp(App)
const pinia = createPinia()

pinia.use(piniaPluginPersistedstate)

SentryService.init(app, router)

app.use(pinia)
app.use(router)
app.use(i18n)
app.use(YmapPlugin, settings)
app.use(install, {
    sitekey: recaptchaConfig.invisibleSiteKey
})
app.use(initYandexMetrika, {
    id: import.meta.env.VITE_YANDEX_METRIC_FIRST_ID,
    env:
        import.meta.env.VITE_NODE_ENV === 'production' ||
        import.meta.env.VITE_NODE_ENV === 'staging'
            ? 'production'
            : import.meta.env.VITE_NODE_ENV,
    scriptSrc: 'https://mc.yandex.ru/metrika/tag.js',
    debug: false
})
MetricService.initializeAdditionalMetrics()

app.mount('#app')
