import type { AxiosResponse } from 'axios'

import axios from '@/shared/services/api/axios'
import type {
    AutoPaymentResponse,
    DebitCardListResponse,
    AddDebitCardPayload,
    AddDebitCardResponse,
    RemoveDebitCardPayload,
    DefaultResponse,
    AutoPaymentPayload,
    DeleteAutoPaymentPayload
} from '@/components/payments/AutoPayment/types'

export class AutoPaymentApi {
    static getInfo(
        customerAccountLocalId: number
    ): Promise<AxiosResponse<AutoPaymentResponse>> {
        return axios.get(
            `/api/v2.0/customer/recurrent-payment/info/${customerAccountLocalId}`
        )
    }

    static getCardList(
        customerAccountLocalId: number
    ): Promise<AxiosResponse<DebitCardListResponse>> {
        return axios.get(
            `/api/v2.0/customer/recurrent-payment/cardstoragelist/${customerAccountLocalId}`
        )
    }

    static addCard(
        data: AddDebitCardPayload
    ): Promise<AxiosResponse<AddDebitCardResponse>> {
        return axios.post('/api/v2.0/customer/recurrent-payment/add_card', {
            ...data,
            redesign: 1
        })
    }

    static removeCard(
        data: RemoveDebitCardPayload
    ): Promise<AxiosResponse<DefaultResponse>> {
        return axios.post(
            '/api/v2.0/customer/recurrent-payment/delete_card',
            data
        )
    }

    static createAutoPayment(
        data: AutoPaymentPayload
    ): Promise<AxiosResponse<DefaultResponse>> {
        return axios.post(
            '/api/v2.0/customer/recurrent-payment/create_autopayment',
            data
        )
    }

    static updateAutoPayment(
        data: AutoPaymentPayload
    ): Promise<AxiosResponse<DefaultResponse>> {
        return axios.post('/api/v2.0/customer/recurrent-payment/update', data)
    }

    static deleteAutoPayment(
        data: DeleteAutoPaymentPayload
    ): Promise<AxiosResponse<DefaultResponse>> {
        return axios.post(
            '/api/v2.0/customer/recurrent-payment/delete_autopayment',
            data
        )
    }
}
