import { AituService } from '@/shared/services/aitu/aitu-service'

const ACTIVITY_TIMEOUT = 300
const TOKEN_TIMEOUT_TO_UPDATE = 60

export class AuthService {
    static getExpiresAt(expiresIn: number): number {
        const currentTimestamp = AuthService.getCurrentTimestamp()

        return currentTimestamp + expiresIn
    }

    static isUserActive(
        lastActivityTimestamp: number,
        activityTimeout: number = ACTIVITY_TIMEOUT
    ): boolean {
        const currentTimestamp = AuthService.getCurrentTimestamp()
        const timeout = currentTimestamp - lastActivityTimestamp

        return timeout <= activityTimeout
    }

    static isTokenNeedToBeUpdated(
        expiresAtTimestamp: number,
        timeoutToUpdate: number = TOKEN_TIMEOUT_TO_UPDATE
    ): boolean {
        const currentTimestamp = AuthService.getCurrentTimestamp()
        const secToExpire = expiresAtTimestamp - currentTimestamp

        return secToExpire <= timeoutToUpdate
    }

    static isTokenActive(expiresAtTimestamp: number): boolean {
        return expiresAtTimestamp > AuthService.getCurrentTimestamp()
    }

    private static getCurrentTimestamp(): number {
        return Math.round(new Date().valueOf() / 1000)
    }

    static async getBiometryCancel(): Promise<boolean> {
        try {
            const result = await AituService.getItem('auth/biometryCancel')

            if (!result) {
                return false
            }

            return true
        } catch (error) {
            console.log(
                'error in `getItem` with auth/biometryCancel ==> ',
                error
            )
            return false
        }
    }

    static enableBiometryCancel(): void {
        AituService.setItem('auth/biometryCancel', true)
    }

    static disableBiometryCancel(): void {
        AituService.setItem('auth/biometryCancel', false)
    }
}
