import { ref, type Ref } from 'vue'
import { defineStore } from 'pinia'

import { SlidersApi } from './api'

import type { Slide } from './types'

export const useSlidersStore = defineStore('sliders-store', () => {
    const mainSlider: Ref<Slide[]> = ref([])

    const getMainSlider = async (slugs: string[]): Promise<void> => {
        if (mainSlider.value.length > 0) {
            return
        }

        const { data } = await SlidersApi.getMainSlider(slugs)

        slugs.forEach((slug: string) => {
            mainSlider.value = [
                ...mainSlider.value,
                ...(data.data[slug]?.services || [])
            ]
        })
    }

    return {
        mainSlider,
        getMainSlider
    }
})
