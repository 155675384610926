import { ref, type Ref } from 'vue'

export const useMonth = () => {
    const getMonth = (month: number): { name: string; declension: string } => {
        const months: Ref<{ name: string; declension: string }[]> = ref([
            {
                name: 'january',
                declension: 'january_s'
            },
            {
                name: 'february',
                declension: 'february_s'
            },
            {
                name: 'march',
                declension: 'march_s'
            },
            {
                name: 'april',
                declension: 'april_s'
            },
            {
                name: 'may',
                declension: 'may_s'
            },
            {
                name: 'june',
                declension: 'june_s'
            },
            {
                name: 'july',
                declension: 'july_s'
            },
            {
                name: 'august',
                declension: 'august_s'
            },
            {
                name: 'september',
                declension: 'september_s'
            },
            {
                name: 'october',
                declension: 'october_s'
            },
            {
                name: 'november',
                declension: 'november_s'
            },
            {
                name: 'december',
                declension: 'december_s'
            }
        ])

        return months.value[month]
    }

    const currentMonth = (): number => {
        const currentDate = new Date()

        return currentDate.getMonth()
    }

    const nextMonth = (): number => {
        const month = currentMonth()

        if (month + 1 === 12) {
            return 0
        }

        return month + 1
    }

    const prevMonth = (): number => {
        const month = currentMonth()

        if (month - 1 === -1) {
            return 11
        }

        return month - 1
    }

    return {
        getMonth,
        currentMonth,
        nextMonth,
        prevMonth
    }
}
