import { ref, type Ref } from 'vue'
import { storeToRefs } from 'pinia'

import { ApiClient } from '@nplus.tech/ktwidget-client'
import { KTPayApi } from '@/shared/modules/kt-pay/api'
import type { OrderParams } from '@/shared/modules/kt-pay/types'
import { useCustomerStore } from '@/shared/modules/customer/store'
import { ktPayConfig } from '@/shared/config/kt-pay'
import { PersistanceLocalStorage } from '@/helpers/persistanceStorage'

export const useKTPay = () => {
    const orderInfo: Ref<any> = ref(null)
    const errorLess100: Ref<any> = ref(null)
    const transactionHash: Ref<string> = ref('')
    const customerStore = useCustomerStore()
    const { currentAccount } = storeToRefs(customerStore)
    const { setItem } = PersistanceLocalStorage

    const createOrder = async (params: OrderParams): Promise<void> => {
        try {
            const { data } = await KTPayApi.createOrder(params)

            orderInfo.value = data
        } catch (e: any) {
            errorLess100.value = e.response?.data.errors.amount[0]
            console.error(e)
        }
    }

    const createTransaction = (order: any): Promise<any> => {
        const transaction = new ApiClient(
            ktPayConfig.app_id,
            ktPayConfig.app_key,
            ktPayConfig.test_mode
        )

        return new Promise((resolve, reject) => {
            const iframe = transaction.createIframe()
            const encrypted = transaction.encrypted({ order })
            const headers = {
                'Content-Type': 'text/plain',
                'Auth-Identifier': transaction.auth
            }
            fetch(
                `${transaction.getUrl(
                    'fintech'
                )}/api/v1/merchant-widget/payment`,
                {
                    method: 'post',
                    headers,
                    body: encrypted
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    resolve(data)
                    iframe.src =
                        `${transaction.getUrl('widget')}/payment-card?hash=` +
                        data.response.data.transaction_hash +
                        '&show_close_button=false'
                })
                .catch(reject)
        })
    }

    const openWidget = async (successLink?: string, failLink?: string) => {
        const { response } = await createTransaction({
            order_id: orderInfo.value.order_id.toString(),
            amount: orderInfo.value.amount,
            type: 'service',
            service: {
                service_id: 1,
                contract_value: `${orderInfo.value.server_id}-${orderInfo.value?.abonent_id}`
            },
            description: 'payment from telecom-aitu',
            show_close_button: false,
            callback_force_success_url: successLink,
            callback_force_failed_url: failLink
        })
        transactionHash.value = response.data.transaction_hash
        setItem('transactionHash', transactionHash.value)
    }

    return {
        createOrder,
        openWidget,
        errorLess100,
        transactionHash
    }
}
