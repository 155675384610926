import type { AxiosResponse } from 'axios'

import axios from '@/shared/services/api/axios'
import type {
    Booster,
    Service,
    AddBoosterParams,
    DisableServiceParams
} from '@/components/services/ServiceList/types'

export class ServicesMobileApi {
    static getServices(): Promise<AxiosResponse<Service[]>> {
        return axios.get('/api/v1.0/customer/services')
    }

    static disableService(
        id: number,
        params: DisableServiceParams
    ): Promise<AxiosResponse<any>> {
        return axios.delete(`/api/v1.0/customer/services/${id}`, { params })
    }

    static getBoosters(serviceType: string): Promise<AxiosResponse<Booster[]>> {
        return axios.get(`/api/v2.0/customer/boosters/${serviceType}`)
    }

    static addBoostTraffic(
        serviceType: string,
        params: AddBoosterParams
    ): Promise<AxiosResponse<any>> {
        return axios.post(
            `/api/v2.0/customer/boosters_new/${serviceType}`,
            params
        )
    }
}
