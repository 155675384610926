import { HeaderMenuIcon } from '@/shared/types/aitu'
import { useHeaderIcon } from '@/shared/hooks/useHeaderIcon'


export const useHeaderClose = (callback: () => void) => {
    const closeHandler = () => {
        callback()
    }

    const { enableHeaderIcon } = useHeaderIcon(
        'online-booking-close',
        HeaderMenuIcon.Close,
        closeHandler
    )

    const enableHeaderClose = (): void => {
        enableHeaderIcon()
    }

    return {
        enableHeaderClose
    }
}
