<template>
    <TInput
        ref="inputRef"
        v-model="amount"
        :label="label"
        inputmode="decimal"
        @focus="focused = true"
        @blur="focused = false"
    >
        <template #suffix>
            <slot name="suffix">
                <TButton type="text" no-padding @click="focusInput">
                    <TIcon>
                        <Pencil02Icon />
                    </TIcon>
                </TButton>
            </slot>
        </template>
    </TInput>
</template>

<script setup lang="ts">
import { type Ref, ref, computed, type WritableComputedRef } from 'vue'
import { TInput, TButton, TIcon, Pencil02Icon } from '@telecom/t-components'
import { TelecomService } from '@/shared/services/telecom/telecom-service'

const props = withDefaults(
    defineProps<{
        modelValue: number
        currency?: string
        label?: string
        max?: number
    }>(),
    {
        currency: '₸',
        label: undefined,
        max: undefined
    }
)

const emit = defineEmits<{
    (event: 'update:modelValue', payload: number): void
}>()

const inputRef: Ref<InstanceType<typeof TInput> | null> = ref(null)
const focused: Ref<boolean> = ref(false)

const amount: WritableComputedRef<string> = computed({
    get() {
        if (focused.value) {
            return String(props.modelValue)
        }

        if (props.currency === '₸') {
            return TelecomService.formatAmountRound(props.modelValue)
        }

        return TelecomService.formatAmountRound(props.modelValue).replace(
            '₸',
            props.currency
        )
    },
    set(value) {
        const validValue = value.replace(',', '.').replace('..', '.')
        const parsed = Number(Number(validValue).toFixed(2))

        if (props.max) {
            emit(
                'update:modelValue',
                parsed > props.max ? Number(amount.value) : parsed
            )

            // resetting input value for controlling what it shows
            const inputElement: HTMLInputElement =
                inputRef.value?.$el.querySelector('input')
            inputElement.value =
                validValue.at(-1) === '.' ||
                (validValue.at(-2) === '.' && validValue.at(-1) === '0')
                    ? validValue
                    : String(parsed)

            return
        }

        emit('update:modelValue', parsed)
    }
})

const focusInput = (): void => {
    const inputElement: HTMLInputElement =
        inputRef.value?.$el.querySelector('input')
    inputElement && inputElement.focus()
}
</script>
