import aituBridge from '@btsd/aitu-bridge'

import type {
    AituResponseType,
    CopyToClipboardResponse,
    GetMeResponse,
    GetPhoneResponse,
    HeaderMenuItem,
    VibrateOptions,
    ShareFileResponse,
    GetContactsResponse,
    GetGeoResponse,
    SelectContactResponse,
    GetUserProfileResponse,
    ShareFileParams,
    ShareImgParams,
    AituResponseModifiedType,
    NavigationItemMode
} from '@/shared/types/aitu'
import { Utils } from '@/helpers/utils'

export class AituService {
    static isInApp(): boolean {
        if (aituBridge.isSupported()) {
            return true
        } else {
            const isDebug = import.meta.env.VITE_APP_DEBUG

            if (typeof isDebug !== 'undefined') {
                return true
            }
        }

        return false
    }

    static isSupported(method?: string): boolean {
        if (method) {
            return aituBridge.supports(method)
        }

        return aituBridge.isSupported()
    }

    static async setItem(key: string, value: any): Promise<void> {
        if (!AituService.isSupported()) {
            return
        }

        await aituBridge.storage.setItem(key, JSON.stringify(value))
    }

    static async getItem(key: string): Promise<any> {
        if (!AituService.isSupported()) {
            return null
        }

        const result = await aituBridge.storage.getItem(key)

        if (!result) {
            return null
        }

        return JSON.parse(result)
    }

    static async clear(): Promise<void> {
        await aituBridge.storage.clear()
    }

    static getMe(): Promise<GetMeResponse> {
        return aituBridge.getMe()
    }

    static getPhone(): Promise<GetPhoneResponse | null> {
        if (!AituService.isSupported()) {
            return new Promise((resolve) => resolve(null))
        }

        return aituBridge.getPhone()
    }

    static getContacts(): Promise<GetContactsResponse> {
        return aituBridge.getContacts()
    }

    static getGeo(): Promise<GetGeoResponse> {
        return aituBridge.getGeo()
    }

    static selectContact(): Promise<SelectContactResponse> {
        return aituBridge.selectContact()
    }

    static getQr(): Promise<string> {
        return aituBridge.getQr()
    }

    static openSettings(): Promise<AituResponseType> {
        return aituBridge.openSettings()
    }

    static vibrate(options: VibrateOptions): void {
        aituBridge.vibrate(options)
    }

    static async enableBackArrow(state = true): Promise<void> {
        try {
            await aituBridge.setCustomBackArrowMode(state)
        } catch (error) {
            console.error('Error with `setCustomBackArrowMode` method', error)
        }
    }

    static async showBackArrow(state = true): Promise<void> {
        if (!state) {
            this.disableSwipeBack()
        } else {
            this.enableSwipeBack()
        }

        try {
            await aituBridge.setCustomBackArrowVisible(state)
        } catch (error) {
            console.error(
                'Error with `setCustomBackArrowVisible` method',
                error
            )
        }
    }

    static setBackArrowHandler(handler: any): void {
        try {
            aituBridge.setCustomBackArrowOnClickHandler(handler)
        } catch (error) {
            console.error(
                'Error with `setCustomBackArrowOnClickHandler` method',
                error
            )
        }
    }

    static disableBackArrow(): void {
        AituService.enableBackArrow()
        AituService.showBackArrow(false)
    }

    static setHeaderMenuItems(items: HeaderMenuItem[]): void {
        try {
            aituBridge.setHeaderMenuItems(items)
        } catch (error) {
            console.error('Error with `setHeaderMenuItems` method', error)
        }
    }

    static setHeaderTitleHandler(handler: any): void {
        try {
            aituBridge.setHeaderMenuItemClickHandler(handler)
        } catch (error) {
            console.error(
                'Error with `setHeaderMenuItemClickHandler` method',
                error
            )
        }
    }

    static setTitle(title: string): Promise<AituResponseType> {
        if (!AituService.isSupported()) {
            return new Promise((resolve) => resolve('failed'))
        }

        return aituBridge.setTitle(title)
    }

    static async copyToClipboard(
        text: string
    ): Promise<CopyToClipboardResponse> {
        try {
            if (!AituService.isSupported()) {
                await navigator.clipboard.writeText(text)
                return 'success'
            }

            return await aituBridge.copyToClipboard(text)
        } catch (error) {
            console.error('Error with `copyToClipboard` method', error)
            return 'failed'
        }
    }

    static async enableNotifications(): Promise<void> {
        await aituBridge.enableNotifications()
    }

    static async disableNotifications(): Promise<void> {
        await aituBridge.disableNotifications()
    }

    static enablePrivateMessaging(
        appId = '7ad6a682-515f-11ee-9e20-46ef8c9eb03c'
    ): Promise<string> {
        return aituBridge.enablePrivateMessaging(appId)
    }

    static disablePrivateMessaging(
        appId = '7ad6a682-515f-11ee-9e20-46ef8c9eb03c'
    ): Promise<string> {
        return aituBridge.disablePrivateMessaging(appId)
    }

    static getSMSCode(): Promise<string> {
        return aituBridge.getSMSCode()
    }

    static getUserProfile(userId: string): Promise<GetUserProfileResponse> {
        return aituBridge.getUserProfile(userId)
    }

    static share(text: string): Promise<AituResponseType> {
        return aituBridge.share(text)
    }

    static async shareFile({
        text = '',
        fileName,
        base64Data
    }: ShareFileParams): Promise<ShareFileResponse> {
        try {
            await aituBridge.shareFile(text, fileName, base64Data)

            return 'success'
        } catch (error) {
            console.error('Error with `shareFile` method', error)
            return 'failed'
        }
    }

    static async shareImage({
        text = '',
        base64Data
    }: ShareImgParams): Promise<ShareFileResponse> {
        try {
            await aituBridge.shareImage(text, base64Data)

            return 'success'
        } catch (error) {
            console.error('Error with `shareFile` method', error)
            return 'failed'
        }
    }

    static openExternalUrl(url: string): Promise<AituResponseType> {
        return aituBridge.openExternalUrl(url)
    }

    // @todo: remove AituResponseModifiedType type
    static checkBiometry(): Promise<
        AituResponseType | AituResponseModifiedType
    > {
        return aituBridge.checkBiometry()
    }

    static closeApplication(): Promise<AituResponseType> {
        return aituBridge.closeApplication()
    }

    static enableSwipeBack(): Promise<AituResponseType> {
        const currentPlatform = Utils.getMobileOS()

        if (currentPlatform !== 'iOS') {
            console.warn('`enableSwipeBack` method requires iOS platform')
            return new Promise((resolve) => resolve('failed'))
        }

        return aituBridge.enableSwipeBack()
    }

    static disableSwipeBack(): Promise<AituResponseType> {
        const currentPlatform = Utils.getMobileOS()

        if (currentPlatform !== 'iOS') {
            console.warn('`disableSwipeBack` method requires iOS platform')
            return new Promise((resolve) => resolve('failed'))
        }

        return aituBridge.disableSwipeBack()
    }

    static setNavigationItemMode(mode: NavigationItemMode): void {
        aituBridge.setNavigationItemMode(mode)
    }

    static getNavigationItemMode(): Promise<NavigationItemMode> {
        return aituBridge.getNavigationItemMode()
    }
}
