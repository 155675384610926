import { useRouter } from 'vue-router'
import { ref, type Ref } from 'vue'

import {
    AppRedirectUrls,
    type AppQueryRedirectMap,
    type AppQueryRedirectHandlers,
    type RedirectPushParamsHandlers
} from '../types'
import { Utils } from '@/helpers/utils'
import { CustomerService } from '@/shared/modules/customer/customerService'
import { SapaService } from '@/components/sapa/service'
import { DetalizationService } from '@/components/detalization/services'

const queryRedirectMap: Ref<AppQueryRedirectMap> = ref({
    redirect: null,
    redirectPush: null
})
const redirectPushProcessEnabled: Ref<boolean> = ref(false)

export const useAppRedirect = () => {
    const router = useRouter()

    const paymentKTPaySuccessHandler = (): void => {
        router.push({
            name: AppRedirectUrls.PaymentKtPaySuccess
        })
    }

    const paymentKTPayFailureHandler = (): void => {
        router.push({
            name: AppRedirectUrls.PaymentKtPayFailure
        })
    }

    const paymentReregistrationHandler = (): void => {
        router.push({
            name: AppRedirectUrls.PaymentReregistrationKtPay
        })
    }

    const appRedirectValuesHandlers: { [key: string]: () => void } = {
        [AppRedirectUrls.PaymentKtPaySuccess]: paymentKTPaySuccessHandler,
        [AppRedirectUrls.PaymentKtPayFailure]: paymentKTPayFailureHandler,
        [AppRedirectUrls.PaymentReregistrationKtPay]:
            paymentReregistrationHandler
    }

    const redirectHandler = (redirectValue: string | null): boolean => {
        if (redirectValue && redirectValue in appRedirectValuesHandlers) {
            appRedirectValuesHandlers[redirectValue]()

            return true
        }

        return false
    }

    const redirectPushHandler = (redirectValue: string | null): boolean => {
        if (!redirectValue) {
            return false
        }

        const paramsHandlers: RedirectPushParamsHandlers = {
            account_local_id: CustomerService.redirectPushSetCurrentAccount,
            request_id: SapaService.redirectPushSetRequestId,
            code_verify: SapaService.redirectPushSetCodeVerify,
            entityAuthorizationId:
                SapaService.redirectPushSetEntityAuthorizationId,
            detalization_mobile_service_type:
                DetalizationService.pushRedirectDetalizationMobile,
            detalization_mobile_phone_number:
                DetalizationService.pushRedirectDetalizationWithPhone
        }

        const [redirect, ...params]: string[] = redirectValue.split('$')
        console.log('redirect params from aitu push ==> ', params)

        if (params.length > 0) {
            for (const [key, callback] of Object.entries(paramsHandlers)) {
                for (const param of params) {
                    if (param.includes(key)) {
                        const [key, value]: string[] = param.split('=')

                        if (callback && value) {
                            callback(value)
                            console.log(`executed with "${key}"`)
                        }
                    }
                }
            }
        }

        router.push({
            path: redirect,
            query: {
                rPush: 1
            }
        })

        return true
    }

    const appRedirectHandler = (
        queryRedirect: keyof AppQueryRedirectMap
    ): boolean => {
        const queryRedirectHandlers: AppQueryRedirectHandlers = {
            redirect: redirectHandler,
            redirectPush: redirectPushHandler
        }

        if (queryRedirect in queryRedirectHandlers) {
            return queryRedirectHandlers[queryRedirect](
                queryRedirectMap.value[queryRedirect]
            )
        }

        return false
    }

    const setRedirect = (
        key?: keyof AppQueryRedirectMap | undefined,
        value: string | null = null
    ): void => {
        if (key && key in queryRedirectMap.value) {
            queryRedirectMap.value[key as keyof AppQueryRedirectMap] = value

            return
        }

        for (const key in queryRedirectMap.value) {
            if (
                Object.prototype.hasOwnProperty.call(
                    queryRedirectMap.value,
                    key
                )
            ) {
                queryRedirectMap.value[key as keyof AppQueryRedirectMap] =
                    Utils.getValueByQueryParam(key)
            }
        }
    }

    const setRedirectPushProcessEnabled = (state: boolean): void => {
        redirectPushProcessEnabled.value = state
    }

    return {
        setRedirect,
        appRedirectHandler,
        queryRedirectMap,
        redirectPushProcessEnabled,
        setRedirectPushProcessEnabled
    }
}
