<template>
    <div
        :class="[
            'accordion',
            { minPadding, bordered, noPadding, hideTitleLocal, full }
        ]"
    >
        <div class="accordion__header" @click="onToggle">
            <div class="accordion__title">
                <slot name="title">
                    <span>Заголовок</span>
                </slot>
            </div>
            <div
                v-if="plusTrigger"
                :class="['accordion__trigger plus', { isOpen }]"
            >
                <TButton type="text" size="small" no-padding>
                    <TIcon stroke="#267CCC">
                        <PlusIcon />
                    </TIcon>
                </TButton>
            </div>
            <div v-else :class="['accordion__trigger', { isOpen }]">
                <TButton type="text" size="small" no-padding>
                    <TIcon :stroke="arrowColor" width="28" height="28">
                        <ChevronDownIcon />
                    </TIcon>
                </TButton>
            </div>
        </div>
        <div v-if="isOpen" :class="['accordion__content', { hasTitleIcon }]">
            <slot name="content">Контент</slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    TButton,
    TIcon,
    ChevronDownIcon,
    PlusIcon
} from '@telecom/t-components'
import { type Ref, ref, onMounted } from 'vue'

const props = withDefaults(
    defineProps<{
        hasTitleIcon?: boolean
        minPadding?: boolean
        plusTrigger?: boolean
        bordered?: boolean
        noPadding?: boolean
        full?: boolean
        hideTitle?: boolean
        arrowColor?: string
    }>(),
    {
        hasTitleIcon: false,
        minPadding: false,
        plusTrigger: false,
        bordered: false,
        noPadding: false,
        full: false,
        hideTitle: false,
        arrowColor: 'black'
    }
)

const isOpen: Ref<boolean> = ref(false)
const hideTitleLocal: Ref<boolean> = ref(false)

const emits = defineEmits<{
    (event: 'toggle', value: boolean): void
}>()

const onToggle = (): void => {
    isOpen.value = !isOpen.value
    emits('toggle', isOpen.value)

    if (props.hideTitle) {
        hideTitleLocal.value = isOpen.value
    }
}

onMounted(() => {
    if (props.hideTitle) {
        hideTitleLocal.value = isOpen.value
    }
})

defineExpose({ onToggle })
</script>

<style scoped lang="scss">
.accordion {
    background-color: color(white);
    padding: 16px;
    border-radius: rounded(md);

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        cursor: pointer;
        position: relative;

        &::selection {
            background-color: transparent;
        }
    }

    &__title {
        font-weight: 500;
        font-size: text(p4);
        line-height: 127.7%;
        display: flex;
        align-items: center;
        gap: 10px;

        &:deep(img) {
            width: 28px;
            height: 28px;
        }
    }

    &__trigger {
        .t-icon {
            transition: all 0.3s;
        }

        &.isOpen {
            .t-icon {
                transform: rotate(180deg);
            }
        }

        &.plus {
            &.isOpen {
                .t-icon {
                    transform: rotate(45deg);
                }
            }
        }

        .t-button {
            background-color: transparent;
        }
    }

    &__content {
        overflow: hidden;
        overflow-x: scroll;
        padding: 8px 0 0;

        span,
        p,
        img,
        iframe {
            max-width: 100%;
        }

        &.hasTitleIcon {
            padding-left: 38px;
        }

        &::selection {
            background-color: transparent;
        }
    }

    &.minPadding {
        padding: 12px;

        @include media(min, md) {
            padding: 12px 24px;
        }
    }

    &.bordered {
        background-color: color(white);
        border: 1px solid color(divider-mobile);
    }

    &.noPadding {
        padding: 0;
    }

    &.hideTitleLocal {
        .accordion__title {
            opacity: 0;
        }
    }

    &.full {
        width: 100%;
    }

    @include media(min, md) {
        background-color: color(bg-blue);
    }
}
</style>
