<template>
    <Wrapper>
        <SapaRating />
    </Wrapper>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, onBeforeMount } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'
import { useHeaderIcon } from '@/shared/hooks/useHeaderIcon'
import { AituService } from '@/shared/services/aitu/aitu-service'

const SapaRating = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/sapa/components/SapaRating.vue')
})

const { disableHeaderIcon } = useHeaderIcon()
const { setTitle } = useTitle()

onBeforeMount(() => {
    AituService.disableBackArrow()
    disableHeaderIcon()
})

setTitle('')
</script>
