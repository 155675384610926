<template>
    <div :class="['alert', type]">
        <slot name="icon">
            <TIcon v-if="type === 'error'" stroke="#FF5353">
                <AlertTriangleIcon />
            </TIcon>
            <TIcon v-else-if="type === 'success'" stroke="#44be7c">
                <CheckIcon />
            </TIcon>
        </slot>
        <div class="alert__text">
            <slot name="text">Место для текста</slot>
        </div>
        <TButton
            v-if="clickable"
            type="text"
            no-padding
            @click="emit('onClick')"
        >
            <slot name="action">
                <TIcon stroke="#667a8b">
                    <InfoCircleIcon />
                </TIcon>
            </slot>
        </TButton>
    </div>
</template>

<script setup lang="ts">
import {
    TIcon,
    TButton,
    AlertTriangleIcon,
    CheckIcon,
    InfoCircleIcon
} from '@telecom/t-components'

type AlertType = 'warning' | 'error' | 'success' | 'info' | 'gray'

withDefaults(
    defineProps<{
        type?: AlertType
        clickable?: boolean
    }>(),
    {
        type: 'info',
        clickable: false
    }
)

const emit = defineEmits<{
    (event: 'onClick'): void
}>()
</script>

<style scoped lang="scss">
.alert {
    padding: 12px;
    border-radius: rounded(md);
    color: color(dark);
    display: flex;
    //align-items: center;
    //justify-content: space-between;
    gap: 12px;

    &__text {
        font-size: text(p6);
        line-height: 128%;
    }

    &.info {
        background-color: color(primary);
    }

    &.warning {
        background-color: color(secondary-background);
    }

    &.error {
        background-color: rgba(255, 83, 83, 0.12);
    }
    &.success {
        background-color: rgba(68, 190, 124, 0.2);
    }
    &.gray {
        background-color: color(divider);
    }

    .t-button {
        align-items: flex-start;
    }
}
</style>
