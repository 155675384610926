import axios from 'axios'
import { useRouter } from 'vue-router'

import { useAuthStore } from '@/components/auth/store'
import { useCustomerStore } from '@/shared/modules/customer/store'
import { useTranslationsStore } from '@/shared/modules/translations/store'

const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_GATEWAY_URL
})

export const defineGatewayAxiosDefaults = () => {
    const authStore = useAuthStore()
    const customerStore = useCustomerStore()
    const router = useRouter()
    const translationsStore = useTranslationsStore()

    axiosInstance.interceptors.request.use((request) => {
        request.headers['Accept-Language'] = translationsStore.language

        if (authStore.isAuthenticated) {
            request.headers.Authorization = 'Bearer ' + authStore.getAccessToken

            if (customerStore.currentAccount) {
                const params =
                    typeof request.params === 'object' ? request.params : {}
                params['filial_id'] =
                    params['filial_id'] ||
                    customerStore.currentAccount.filial_id
                params['account_id'] =
                    params['account_id'] || customerStore.currentAccount.id
                params['account_local_id'] =
                    params['account_local_id'] ||
                    customerStore.currentAccount.local_id

                request.params = params
            }
        }

        return request
    })

    axiosInstance.interceptors.response.use(null, (error) => {
        if (error.response.status === 401) {
            authStore.logout().then(() => {
                router.push({ name: 'login' })
            })
        }

        throw error
    })
}

export default axiosInstance
