import type {
    AutoPaymentResponse,
    AutoPaymentResponseData,
    DebitCard
} from '@/components/payments/AutoPayment/types'
import { TelecomService } from '@/shared/services/telecom/telecom-service'
import cardIcon4 from '@/assets/images/cards/Visa.svg'
import cardIcon2 from '@/assets/images/cards/Mir.svg'
import cardIcon5 from '@/assets/images/cards/MasterCart.svg'

export class AutoPaymentService {
    static cardIcon: { [key: number]: string } = {
        2: cardIcon2,
        4: cardIcon4,
        5: cardIcon5
    }

    static setAutoPayment(
        data: AutoPaymentResponse
    ): AutoPaymentResponse | null {
        if (!data) return null

        const info: AutoPaymentResponse['data'] = data?.data

        if (info && info.card_mask && info.autopayment_amount) {
            info.card_mask_full = info.card_mask
            info.card_ico =
                AutoPaymentService.cardIcon[Number(info.card_mask[0])]
            info.card_mask = `****${info.card_mask.slice(-4)}`
            info.autopayment_amount_format = TelecomService.formatAmount(
                info.autopayment_amount
            )
        } else {
            data.data = {}
        }

        return data
    }

    static setCardList(
        data: DebitCard[],
        payment: AutoPaymentResponseData
    ): DebitCard[] {
        return data.map((card) => ({
            ...card,
            is_active: card['card_mask'] === payment?.card_mask_full,
            card_format_mask: `****${card['card_mask'].slice(-4)}`,
            card_ico: AutoPaymentService.cardIcon[+card['card_mask'][0]]
        }))
    }
}
