export interface FDBanner {
    description: string | null
    desktop: string | null
    link: string | null
    mobile: string | null | undefined
    name: string | null
    path: string | null
    slide_name: string | null
    text_3: string | null
}

export interface ServiceCinemaRoom {
    name?: string
    desc?: string
    icon?: string
}

export interface ChannelsCinema {
    [key: number]: {
        tv?: string[]
        hd?: string[]
        cinemaRoom?: ServiceCinemaRoom[]
    }
}

export interface ModalState {
    [key: string]: boolean
}

export interface FDTab {
    id: string | number
    label: string
    component: any
    props?: any
    title?: string
}

export enum FDTabIds {
    wired = '1',
    wireless = 'wireless',
    additionalServices = '3'
}

export enum FDSubTabIds {
    tvPlus = 'tvplus',
    otherServices = 'other'
}

export interface ServiceFilterPayload {
    town_type_id: number
    resource_specification_id: number
    annual_contract_id: number
    filial_id: number
    language: string | number
    po_group_id: number
    mobile_product_id: number
    mobile_min_count: number
    is_idtv: number
    is_stb: number
    speed: number
    is_packet_offer: number
    is_ota: number
    is_offer_for_sale: number
    is_tvfms: number
    is_flush_cache_needed?: number
}

export interface SelectedPackageServiceDetail {
    id: number
    slug: string
    icon: string
    text_1: string
    text_2: string
    text_3: string
    has_special_tooltip: number
    not_included_to_rate: number
    range: number
    toggle: number
    active: number
    show_sim: number
    show_telephony: number
    text_telephony: null | string
    text_sim_rows: null | string
    text_sim_one: null | string
    text_sim_two: null | string
    show_modal: number
    text_modal: string
    show_modal_2: number
    text_modal_2: null | string
    header_modal: null | string
    header_modal_2: null | string
    title_modal_2: null | string
}

export interface ServiceComponentStruct {
    struct_id: string
    struct_name: string
    struct_short_description: string
}

export interface ServiceComponent {
    component_id: string
    component_name: string
    price: null | number
    component_client_name: string
    product_id: string
    min_count: number
    max_count: number
    structs: ServiceComponentStruct[]
    poCharUse: null | any
}

export interface ServiceFilter {
    product_offer_id: string
    product_offer_name: string
    product_offer_client_name: string
    product_offer_client_name2: string
    price: string
    ont_rent: string
    stb_rent: null | string
    annual_contract_id: string
    components: ServiceComponent[]
}

export interface PackageService {
    id: number
    main_service_type_id: number
    slug: string
    icon: string
    enabled: boolean
    calculable?: boolean
    rate_color?: string
    show_in_package_block?: boolean
    price?: number
    block_title?: string
    block_description?: string
    page_title?: string
    page_description?: string
    subservice_texts?: any[]
    subservice_selects?: any[]
    period_description?: string
    rate_details?: SelectedPackageServiceDetail[]
    tab?: string
    tooltip?: string
    contract?: string
    background?: string
    filter?: ServiceFilter
}

interface Advantage {
    id: number
    slug: string
    text: string
}

interface BlockType {
    id: number
    name: string
    slug: string
    order: number
}

interface ServiceType {
    id: number
    name: string
    code: number
}

export interface SingleService {
    id: number
    slug: string
    enabled: true
    name: null | string
    banner_name: string
    short_description: null | string
    description: null | string
    desktop: string
    mobile: string
    seo_description: string
    seo_keywords: string
    seo_title: string
    service_types: ServiceType[]
    simple_text: string
    text_one: string
    text_two: null | string
    advantages: Advantage[]
    block_types: BlockType[]
    blocks: any[]
    main_services: PackageService[]
}

export interface SingleServiceResponse {
    data: SingleService
}

export interface ServiceFilterResponse {
    data: ServiceFilter
}

export interface TerminalCheckPayload {
    townStateId: number
    specificationId: number
}

export interface TerminalCheckStocks {
    divisionId: number
    resourceSpecificationId: number
    stockId: number | string
    stockName: string
    vendorId: number
    termEquipmentCount: number
}

export interface TerminalCheckResponse {
    stocks: TerminalCheckStocks[]
}

export interface ModalStateConnectService {
    failure: boolean
    notAvailable: boolean
    notTermEquipmentCount: boolean
}

export interface TypeIdentify {
    name: string
    value: string
    onSelect: () => void
}

export interface TownStatesResponse {
    id: number
    text: string
    filial_id: number
    town_id: number
    code: string
}

export interface ReservePayload {
    town_id: number | string | undefined
    phone: number | string
    recaptchaToken: string
    city: {
        reserve: boolean
    }
    mobile: {
        reserve: boolean
    }
}

export interface ReserveResponse {
    success: boolean
    data: {
        order_id: number
        mobile: any
        city: any
    }
}

export enum FDProduct {
    internet = 2000022,
    tv = 2000202,
    tv_new = 2000201,
    homePhone = 2000000,
    mobileTv = 2000202,
    mobileTvComponentId = 1035761,
    mobileInternet = 2000097
}

export enum FDService {
    bereket = 372,
    keremetTv = 371,
    keremetMobile = 373,
    shanyrakPlus = 386,
    shanyrakMobile = 387,
    shanyrakTv = 385,
    alemPlus = 378,
    alemMobile = 379,
    alemTv = 380,
    alem = 381
}

export enum FDServiceItem {
    full = 397
}

export enum FDServiceItemStage {
    full = 412
}

export enum CommunicationType {
    VerifyId = 'treatmentMobileVerify',
    FullDigitalId = 'opportunity'
}

export interface FDModalStateExit {
    exit: boolean
}

export interface FDCloseModalInterface {
    toggleModal: (action: 'open' | 'exit') => void
}

export enum FDMobileSim {
    ALTEL = 2000097,
    KCELL = 2000170
}

export interface FDServiceModalTabLabels {
    cinemas: string
    tv: string
    hd: string
}

export interface TechnicalCheckRequest {
    town_state_id: number | string | undefined
    street_id: number | string | undefined
    house: string
    flat?: string | undefined
    sub_house: string | undefined
    sub_flat?: string | undefined
    filial_id?: number
    account_id?: number
    account_local_id?: number
    technology?: string
}

export enum TechnologyService {
    ftth = 'ftth'
}

export enum CheckTerminalSpecification {
    id = 1005046
}

export interface TechnicalCheckNotExistTVResponse {
    message: string
    params: {
        filial_id: number
        town_state_id: number
        street_id: number
        house: number
        base_resource_spec_id: number
    }
    available: boolean
}

export interface TechnicalCheckExistTVResponse {
    tech_available_result_type_id: number
    vendor_id: number
    terminal_enclosure_id: number
    terminal_enclosure_code: string
    available: boolean
}

export interface BonusesState {
    promocode: string
    isPromocodeInvalid: boolean
    bonusesAmount: number
}
