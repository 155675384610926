import type { AxiosResponse } from 'axios'

import type { RegionIsb, RegionsResponse, Street } from '@/shared/types/telecom'
import axios from '@/shared/services/api/axios'
import type {
    TechnicalCheckExistTVResponse,
    TechnicalCheckNotExistTVResponse,
    TechnicalCheckRequest
} from '@/components/full-digital/types'

export class TelecomApi {
    static getRegions(): Promise<AxiosResponse<RegionsResponse>> {
        return axios.get('/api/v1.0/open/regions')
    }

    static getRegionsIsb(): Promise<AxiosResponse<RegionIsb[]>> {
        return axios.get('/api/v1.0/open/isb/town-states')
    }

    static getStreets(
        isbTownStateId: number | string
    ): Promise<AxiosResponse<Street[]>> {
        return axios.get(
            `/api/v1.0/open/isb/town-states/${isbTownStateId}/streets`
        )
    }

    static technicalCheck(
        body: TechnicalCheckRequest
    ): Promise<
        AxiosResponse<
            TechnicalCheckExistTVResponse | TechnicalCheckNotExistTVResponse
        >
    > {
        return axios.get(`/api/v1.0/open/technical/${body.technology}`, {
            params: {
                town_state_id: body.town_state_id,
                street_id: body.street_id,
                house: body.house,
                sub_house: body.sub_house
            }
        })
    }
}
