export interface GetMeResponse {
    name: string
    lastname: string
    id: string
    avatar?: string
    avatarThumb?: string
    notifications_allowed: boolean
    private_messaging_enabled: boolean
    sign: string
}

export interface GetPhoneResponse {
    phone: string
    sign: string
}

export interface GetGeoResponse {
    latitude: number
    longitude: number
}

export interface GetContactsResponse {
    contacts: Array<{
        first_name: string
        last_name: string
        phone: string
    }>
    sign: string
}

export interface SelectContactResponse {
    phone: string
    name: string
    lastname: string
}

export interface GetUserProfileResponse {
    name: string
    lastname?: string
    phone?: string
    avatar?: string
    avatarThumb?: string
}

export interface ShareFileParams {
    text?: string
    fileName: string
    base64Data: string
}

export interface ShareImgParams {
    text?: string
    base64Data: string
}

/**
 * Example: [100, 200, 300, 400, 500]
 * Vibrate 100ms, pause 200ms, vibrate 300ms, pause 400ms, vibrate 500ms
 */
export type VibrateOptions = number[]

export type AituResponseType = 'success' | 'failed'
export type AituResponseModifiedType = 'true' | 'false'

export interface SendPinPayload {
    type: 'current' | 'new'
    pin: string
}

export enum HeaderMenuIcon {
    Search = 'Search',
    ShoppingCart = 'ShoppingCart',
    Menu = 'Menu',
    Share = 'Share',
    Notifications = 'Notifications',
    Help = 'Help',
    Error = 'Error',
    Person = 'Person',
    Sort = 'Sort',
    Filter = 'Filter',
    Close = 'Close'
}

export interface HeaderMenuItem {
    id: string
    icon: HeaderMenuIcon
    badge?: string
}

export type CopyToClipboardResponse = 'success' | 'failed'

export type ShareFileResponse = 'success' | 'failed'

export enum NavigationItemMode {
    SystemBackArrow = 'SystemBackArrow',
    CustomBackArrow = 'CustomBackArrow',
    NoItem = 'NoItem',
    UserProfile = 'UserProfile'
}
