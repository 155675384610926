import type { AxiosResponse } from 'axios'

import axios from '@/shared/services/api/axios'
import type {
    BalanceDetailParams,
    BalanceDetailResponseApi
} from '@/components/detalization/DetailsBalance/types'

export class DetailsBalanceApi {
    static getBalanceDetails(
        params: BalanceDetailParams
    ): Promise<AxiosResponse<BalanceDetailResponseApi>> {
        return axios.get(
            `/api/v2.0/customer/billing/detailedBalance/${params.filialId}/${params.customerAccountId}/${params.customerAccountType}/${params.accountId}`
        )
    }
}
