<template>
    <Wrapper>
        <FAQ />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import { AituService } from '@/shared/services/aitu/aitu-service'

const router = useRouter()

const FAQ = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/full-digital/components/FDFaq.vue')
})

onMounted((): void => {
    AituService.enableBackArrow()
    AituService.showBackArrow()
    AituService.setBackArrowHandler(() => backHandler())
})

const backHandler = (): void => {
    router.go(-1)
}

const { setTitle } = useTitle()

setTitle('faq')
</script>
