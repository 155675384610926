import { defineStore } from 'pinia'
import { reactive, ref, type ComputedRef, type Ref, computed } from 'vue'
import amplitude from 'amplitude-js'
import type { AxiosError } from 'axios'

import type {
    CustomerState,
    GetCustomerResponse,
    CustomerMetadata,
    CustomerAccount,
    CustomerIndividual,
    CustomerDocument,
    ParsedCustomerAccount,
    CustomerDeliverySelected
} from './types'
import { CustomerApi } from './api'
import { CustomerService } from './customerService'
import { useAutoPaymentStore } from '@/components/payments/AutoPayment/store'
import { useChangeArchiveStore } from '@/components/change-archive/store'
import { AituService } from '@/shared/services/aitu/aitu-service'
import { useAuthStore } from '@/components/auth/store'
import { SentryService } from '@/shared/services/sentry/sentry-service'
import { useAppealsStore } from '@/components/appeals/store'

export const useCustomerStore = defineStore(
    'customer',
    () => {
        const authStore = useAuthStore()
        const autoPaymentStore = useAutoPaymentStore()
        const changeArchiveStore = useChangeArchiveStore()
        const appealStore = useAppealsStore()
        const customer: CustomerState = reactive({
            id: null,
            name: null,
            survey: null,
            aitu_customer_created_at: null
        })
        const metadata: Ref<CustomerMetadata | null> = ref(null)
        const currentAccount: Ref<CustomerAccount | null> = ref(null)

        const setCustomer = (data: GetCustomerResponse) => {
            customer.id = data.user.id
            customer.name = data.user.name
            customer.survey = data.survey
            customer.aitu_customer_created_at =
                data.metadata.aitu_customer_created_at
            metadata.value = data.metadata

            SentryService.setUser({
                id: data.metadata.customer_id,
                customer_id: data.metadata.customer_id,
                username: metadata.value.name,
                email: metadata.value.primary_email,
                phone: metadata.value.primary_mobile_phone,
                aitu_user_id: metadata.value.aitu?.aituUserId
            })

            amplitude.getInstance().setUserId(data.metadata.customer_id)
        }

        const dropCustomer = () => {
            customer.id = null
            customer.name = null
            customer.survey = null
            customer.aitu_customer_created_at = null
        }

        const unsetCustomer = () => {
            customer.id = null
            metadata.value = null
            currentAccount.value = null
        }

        const getPortalCustomer = (): Promise<GetCustomerResponse> => {
            return new Promise((resolve, reject) => {
                if (customer.id) {
                    return
                }

                CustomerApi.getCurrentCustomer()
                    .then(({ data }) => {
                        setCustomer(data)

                        initCurrentAccount(data.metadata)
                        resolve(data)
                    })
                    .catch((error) => {
                        console.log(
                            'error in `getCurrentCustomer` method => ',
                            error
                        )
                        reject(error)
                    })
            })
        }

        const setEmail = (email: string) => {
            if (metadata.value) {
                metadata.value.primary_email = email
            }
        }

        const setCurrentAccount = (data: CustomerAccount) => {
            currentAccount.value = {
                ...currentAccount.value,
                ...data
            }

            autoPaymentStore.clearAutoPayment()
            changeArchiveStore.clearRecommendation()
            appealStore.resetOrders()
            appealStore.resetAppeals()
        }

        const initCurrentAccount = (data: CustomerMetadata | null) => {
            if (data?.agreement) {
                const defaultAccount: CustomerAccount =
                    CustomerService.getDefaultAccount(data.agreement)

                setCurrentAccount(defaultAccount)
            }
        }

        const updateUserAitu = async (): Promise<void> => {
            const { id } = await AituService.getMe()

            if (!id) {
                console.log('client id doesnt exist')
                return
            }

            try {
                await CustomerApi.updateUserAitu({
                    client_id: id,
                    phone: authStore.getAituPhone,
                    ...(getCustomerId.value && {
                        customer_id: getCustomerId.value
                    })
                })
            } catch (error) {
                console.log('method `updateUserAitu` failed => ', error)
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message: 'AUTH | error with `updateUserAitu` method',
                    tags: {
                        flow: 'auth'
                    },
                    extra: {
                        request: {
                            client_id: id,
                            phone: authStore.getAituPhone,
                            ...(getCustomerId.value && {
                                customer_id: getCustomerId.value
                            })
                        },
                        response: errorData
                    }
                })
            }
        }

        const getCustomerId: ComputedRef<number | undefined> = computed(
            () => metadata.value?.customer_id
        )

        const getIndividual: ComputedRef<CustomerIndividual | undefined> =
            computed(() => metadata.value?.individual)

        const getIndividualIIn: ComputedRef<string | undefined> = computed(
            () => metadata.value?.individual?.iin
        )

        const getCustomerDoc: ComputedRef<CustomerDocument | undefined> =
            computed(() => metadata.value?.individual.customer_doc)

        const getMetadata: ComputedRef<CustomerMetadata | null> = computed(
            () => metadata.value
        )

        const getCustomerName: ComputedRef<string> = computed(() => {
            if (!metadata.value) {
                return ''
            }

            return metadata.value.name
        })

        const getPrimaryEmail: ComputedRef<string | undefined> = computed(
            () => metadata.value?.primary_email
        )

        const getPrimaryMobilePhone: ComputedRef<string> = computed(() => {
            if (!metadata.value?.primary_mobile_phone) {
                return ''
            }

            return metadata.value?.primary_mobile_phone
        })

        const getFilialId: ComputedRef<number | undefined> = computed(
            () => currentAccount.value?.filial_id
        )

        const getLocalId: ComputedRef<number | undefined> = computed(
            () => currentAccount.value?.local_id
        )

        const getId: ComputedRef<number | undefined> = computed(
            () => currentAccount.value?.id
        )

        const getCustomerAccountType: ComputedRef<number | undefined> =
            computed(() => currentAccount.value?.customer_account_type_id)

        const customerAccounts: ComputedRef<ParsedCustomerAccount[]> = computed(
            () => {
                const agreement = metadata.value?.agreement

                if (!agreement) {
                    return []
                }

                return CustomerService.getParsedCustomerAccountsList(agreement)
            }
        )

        const customerDeliveryType: ComputedRef<CustomerDeliverySelected | null> =
            computed(() => {
                const agreement = metadata.value?.agreement

                if (!agreement) {
                    return null
                }

                if (!getLocalId.value) {
                    return null
                }

                const data = CustomerService.getCustomerDeliveryType(
                    agreement,
                    getLocalId.value
                )

                return data
            })

        return {
            customer,
            metadata,
            currentAccount,
            getIndividual,
            getCustomerDoc,
            getMetadata,
            getPrimaryEmail,
            getPrimaryMobilePhone,
            setCustomer,
            setCurrentAccount,
            setEmail,
            getPortalCustomer,
            unsetCustomer,
            updateUserAitu,
            getCustomerName,
            getFilialId,
            getLocalId,
            getId,
            getCustomerAccountType,
            getCustomerId,
            customerAccounts,
            customerDeliveryType,
            dropCustomer,
            getIndividualIIn
        }
    },
    {
        persist: true
    }
)
