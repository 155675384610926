import type { SelectOption } from '@telecom/t-components/dist/components/TSelect/types'
import type {
    AppealsCart,
    Communications,
    Orders,
    FilteredService,
    OrdersCart,
    AppealReasons,
    Appeal,
    TimeSlot,
    WFMData,
    WFMAppeals,
    WFMOrders
} from './types'

import type { Service } from '@/components/services/ServiceList/types'
import { TelecomService } from '@/shared/services/telecom/telecom-service'

export class AppealService {
    static modifiedAppeals(appealItem: Communications[]): AppealsCart[] {
        if (!appealItem) {
            return []
        }

        const data: AppealsCart[] = appealItem.map((item: Communications) => ({
            id: item.ID,
            name: item.COMMUNICATION_TYPE,
            create_date: item.CREATE_DATE,
            status: item.COMMUNICATION_STATE,
            status_id: item.COMMUNICATION_STATE_ID,
            type: item.DESCRIPTION,
            cart_type: 1
        }))

        return data
    }

    static modifiedOrders(orderItem: Orders[]): AppealsCart[] {
        if (!orderItem) {
            return []
        }

        const data: AppealsCart[] = orderItem.map((item: Orders) => ({
            id: item.id,
            name: item.name,
            create_date: item.insert_date,
            status: item.status,
            status_id: item.osm_order_status_id,
            type: item.type,
            cart_type: 2
        }))

        return data
    }

    static modifiedWFMTimeSlot(timeSlots: TimeSlot[]): {
        timeSlotId: number
        date: string
        time: string
    } {
        const [timeSlot] = timeSlots
        const date = TelecomService.formatDateToDDMMYYYY(timeSlot.date)
        const hour = Number(timeSlot.startTime.slice(0, 2))
        let hourText = ''

        if (hour < 13) {
            hourText = 'AM'
        } else {
            hourText = 'PM'
        }

        return {
            timeSlotId: timeSlot.id,
            date,
            time: hourText
        }
    }

    static modifiedWFMAppelItem(
        appealItem: Communications[],
        data: WFMData
    ): WFMAppeals {
        const { stateData } = data

        const defaultData = {
            isDYM: false,
            id: 0,
            status: stateData?.name || ''
        }

        if (!appealItem) {
            return defaultData
        }

        const [appeal] = appealItem

        if (appeal.SINGLE_DAMAGE_ID !== data.entityId) {
            return defaultData
        }

        if (stateData.id === 1 || stateData.id === 3) {
            if (data?.timeSlots.length) {
                const timeSlotData = this.modifiedWFMTimeSlot(data.timeSlots)

                return {
                    isDYM: true,
                    id: appeal.SINGLE_DAMAGE_ID,
                    date: timeSlotData.date,
                    time: timeSlotData.time,
                    timeSlotId: timeSlotData.timeSlotId,
                    DYMType: stateData.id,
                    status: stateData.name
                }
            }

            return {
                isDYM: true,
                id: appeal.SINGLE_DAMAGE_ID,
                DYMType: stateData.id,
                status: stateData.name
            }
        }

        return defaultData
    }

    static modifiedWFMOrderItem(id: string, data: WFMData): WFMOrders {
        const { stateData } = data

        const defaultData = {
            isDYI: false,
            id: 0,
            status: stateData?.name || ''
        }

        if (stateData.id === 1 || stateData.id === 3) {
            if (data?.timeSlots.length) {
                const timeSlotData = this.modifiedWFMTimeSlot(data.timeSlots)

                return {
                    isDYI: true,
                    id,
                    date: timeSlotData.date,
                    time: timeSlotData.time,
                    timeSlotId: timeSlotData.timeSlotId,
                    DYIType: stateData.id,
                    status: stateData.name
                }
            }

            return {
                isDYI: true,
                id,
                DYIType: stateData.id,
                status: stateData.name
            }
        }

        return defaultData
    }

    static modifiedAppealItem(appealItem: Communications[]): Appeal[] {
        if (!appealItem) {
            return []
        }

        const data: Appeal[] = appealItem.map((item: Communications) => ({
            id: item.ID,
            type: item.COMMUNICATION_TYPE,
            create_date: item.CREATE_DATE,
            update_date: item.UPDATE_DATE,
            close_date: item.CLOSE_DATE,
            full_name: item.CURRENT_USER_NAME,
            status: item.COMMUNICATION_STATE,
            comment: item.COMMENT_ON_CLOSE,
            attachments: item.ATTACHMENTS
        }))

        return data
    }

    static modifiedOrderItem(orderItem: object | any): OrdersCart | null {
        if (typeof orderItem === 'object' && orderItem !== null) {
            const orderDetailedItem = {
                account: orderItem.data.customerAccountId,
                order_number: orderItem.data.custOrderId,
                status: orderItem.order.cfvCustOrderStatusName,
                update_date: orderItem.order.updateDate,
                insert_date: orderItem.order.insertDate,
                product_name: orderItem.order.productOfferName,
                comment: orderItem.order.comment,
                fullName: orderItem.fullName,
                order: orderItem?.order
            }
            return orderDetailedItem
        }

        return null
    }

    static extractNamesFromFilteredServices = (
        filteredServices: Service[]
    ): FilteredService[] => {
        if (!filteredServices) {
            return []
        }

        const extractedNames: FilteredService[] = []

        for (const service of filteredServices) {
            if (['RENT', 'VAS', 'CC_TV'].includes(service.SERVICE_TYPE)) {
                continue
            }

            if (service.PRODUCTS.length) {
                const packageNames = service.PRODUCTS.map((product) => ({
                    id: Number(product.PRODUCT_OFFER_STRUCT_ID),
                    prod_id: product.PRODUCT_ID,
                    history_id:
                        Number(product.HISTORY_ID) || service.HISTORY_ID,
                    label: product.NAME || service.NAME,
                    prod_num: product.PRODUCT_NUM,
                    subscription_id: service.CA_SUBSCRIPTION?.ID
                }))
                extractedNames.push(...packageNames)
            } else {
                extractedNames.push({
                    id: service.CA_PRODUCT_INSTANCE_ID,
                    prod_id: 2000018,
                    label: service.NAME as string,
                    history_id: null,
                    subscription_id: service.CA_SUBSCRIPTION?.ID
                })
            }
        }

        return extractedNames
    }

    static modifyServiceNameSubscribtion = (
        filteredService: Service[]
    ): SelectOption[] => {
        if (!filteredService.values) {
            return []
        }

        const data: SelectOption[] = filteredService.map(
            (service: Service) => ({
                id: service.CA_SUBSCRIPTION?.ID as string,
                label: service.NAME as string
            })
        )

        return data
    }

    static modifiedReasons(reasons: AppealReasons[]): SelectOption[] {
        if (!reasons) {
            return []
        }

        const data: SelectOption[] = reasons.map((item: AppealReasons) => ({
            id: item.value,
            label: item.text
        }))

        return data
    }

    static concatenateCommentWithReason(
        consultReason: SelectOption[] | null,
        currentSelectedConultation: SelectOption
    ): string {
        if (!consultReason) {
            return ''
        }

        const selectedReason = consultReason.find(
            (reason: SelectOption) =>
                reason.id === currentSelectedConultation.id
        )

        if (!selectedReason) {
            return 'appeals_consultation'
        }

        const reasonTranslations: any = {
            1: 'appeals_consultation_payment',
            2: 'appeals_consultation_service',
            3: 'appeals_consultation_return_payment',
            4: 'appeals_consultation_restore_sim',
            5: 'appeals_consultation_change_address',
            6: 'appeals_consultation_re-registration',
            7: 'appeals_consultation_change_package'
        }

        const translationKey =
            reasonTranslations[selectedReason.id] || 'appeals_consultation'

        return translationKey
    }
}
