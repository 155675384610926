<template>
    <Wrapper>
        <AppealsWrapper />
    </Wrapper>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'
import { AituService } from '@/shared/services/aitu/aitu-service'

const AppealsWrapper = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/appeals/Wrapper/AppealsWrapper.vue')
})

const { setTitle } = useTitle()
const router = useRouter()

setTitle('appeals_appeals')

const backHandler = (): void => {
    router.push({ name: 'home' })
}

onMounted(() => {
    AituService.enableBackArrow()
    AituService.showBackArrow()
    AituService.setBackArrowHandler(() => backHandler())
})
</script>

<style scoped lang="scss"></style>
