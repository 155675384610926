import { AmplitudeCounters } from './enum'
import { MetricBase } from './metricBase'

type YesOrNo = 'yes' | 'no'

interface FullDigitalsMetricsMap {
    all_packages_opened: {
        connect_package:
            | 'bereket'
            | 'keremet_mobile'
            | 'keremet_tv'
            | 'alaman'
            | 'internet500'
            | 'internet200'
            | 'shanyrak_plus'
            | 'shanyrak_mobile'
            | 'shanyrak_tv'
            | 'home'
            | 'social'
            | 'alem_plus'
            | 'alem_mobile'
            | 'alem_tv'
            | 'alem_simple'
            | 'TV+'
    }
    constructor_opened: {
        period: string
        home_phone: YesOrNo
        rent_equipment: YesOrNo
        promocode: YesOrNo
        constructor_connect_clicked: true
    }
    potential_constructor_opened: {
        oblast_choiced: string
        potential_constructor_continue_clicked: true
        potential_constructor_main_clicked: true
    }
    potential_success_opened: {
        clicked: 'main'
    }
    potential_error_opened: {
        clicked: 'ok'
    }
    square_opened: {
        square_choiced: string
        continue: true
    }
    square_exit: {
        choiced: 'exit' | 'cancel'
    }
    user_found_opened: {
        user_found_login_clicked: true
        user_found_application_clicked: true
    }
    user_found_exit: {
        choiced: 'exit' | 'cancel'
    }
    technical_capability_opened: {
        city_choiced: string
        technical_capability_no_address_clicked: true
        household_choiced: 'flat' | 'private_home'
        owner_choiced: YesOrNo
        continue: true
    }
    capability_exit: {
        choised: 'exit' | 'cancel'
    }
    technical_capability_error_opened: {
        technical_capability_error_again_clicked: true
        technical_capability_error_main_clicked: true
    }
    technical_capability_fail_opened: {
        technical_capability_fail_help_clicked: true
        technical_capability_fail_main_clicked: true
    }
    technical_capability_verify_modal: {
        technical_capability_verify_sendsms: true
    }
    equipment_service_error_opened: {
        equipment_service_error_main_clicked: true
        equipment_service_error_help_clicked: true
    }
    equipment_no_availability_opened: {
        equipment_no_availability_main_clicked: true
        equipment_no_availability_help_clicked: true
    }
    id_verification_opened: {
        type: string
        clicked: 'continue' | 'main' | 'back'
    }
    aitu_verification_opened: {
        continue: YesOrNo
        typed: YesOrNo
    }
    aitu_verification_camera: {
        turn: 'on' | 'off'
    }
    aitu_verification_exit: {
        choiced: 'exit' | 'cancel'
    }
    aitu_verification_camera_screen: {
        ready: 'yes' | 'exit'
    }
    aitu_verification_camera_screen_exit: {
        choiced: 'exit' | 'cancel'
    }
    aitu_verification_camera_screen_download: string
    aitu_verification_problem_opened: {
        clicked: 'again' | 'main'
    }
    aitu_verification_add_documents: {
        upload: '1side' | '2side'
        clicked: 'cancel' | 'user_agreement_opened'
    }
    aitu_verification_add_documents_exit: {
        choiced: 'exit' | 'cancel'
    }
    aitu_verification_check_documents: {
        continue: 'yes' | 'again' | 'cancel'
    }
    gbdfl_verification_opened: {
        clicked: 'typed_ii' | 'continue' | 'back' | 'exit'
    }
    gbdfl_verification_opened_error: {
        clicked: 'again' | 'main' | 'exit'
    }
    gbdfl_verification_user_found: {
        clicked: 'cabinet' | 'other_iin'
    }
    gbdfl_verification_response_waiting: {
        clicked: 'back' | 'close'
    }
    gbdfl_verification_response_waiting_exit: {
        clicked: 'exit' | 'cancel'
    }
    gbdfl_verification_response_waiting_error: {
        clicked: 'change_method' | 'help' | 'main' | 'back'
    }
    id_verification_opened_2variants: {
        choised: 'aitu' | 'dd'
        clicked: 'continue' | 'main' | 'back'
    }
    gbdfl_verification_response_waiting_512: {
        clicked: 'change_method' | 'help' | 'main' | 'back' | 'exit'
    }
    dd_verification_opened: {
        clicked: 'help' | 'verification_iin'
        typed: 'iin'
    }
    dd_verification_error_opened: {
        clicked: 'again' | 'another' | 'help'
    }
    dd_verification_exit: {
        choised: 'exit' | 'cancel'
    }
    blank_agreement_sign: {
        clicked:
            | 'blank_agreement_declaration_clicked'
            | 'checkbox_clicked'
            | 'continue'
    }
    blank_agreement_viewed: {
        clicked: 'share' | 'close'
    }
    blank_agreement_sign_sms: {
        clicked: 'again' | 'back'
        error: string
    }
    blank_agreement_sign_sms_unavailable: {
        clicked: 'manager_help' | 'main'
    }
    order_creation_opened: {
        clicked: 'continue'
    }
    wifi_opened: {
        clicked: 'buy' | 'main' | 'login'
    }
    modal_take_bonus_opened: {
        clicked: 'promocode_inserted'
    }
}

export class FullDigitalMetrics extends MetricBase<FullDigitalsMetricsMap> {
    constructor() {
        super([AmplitudeCounters.AmplitudeSecond])
    }
}
