import { ref, watch, type Ref } from 'vue'
import { useRoute } from 'vue-router'

const layout: Ref<string> = ref('')

export const useLayout = () => {
    const route = useRoute()

    const setLayout = (layoutStyle: string) => {
        layout.value = layoutStyle
    }

    watch(
        () => route.meta.layout,
        () => {
            layout.value = route.meta.layout as string
        }
    )

    return {
        layout,
        setLayout
    }
}
