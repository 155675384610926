import type { AxiosResponse } from 'axios'

import axios from '@/shared/services/api/axios'

export class SlidersApi {
    static getMainSlider(slugs: string[]): Promise<AxiosResponse<any>> {
        return axios.get('/api/v1.0/main/slider', {
            params: {
                'slug[]': slugs
            }
        })
    }
}
