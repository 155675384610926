<template>
    <Wrapper>
        <template v-if="isAuthenticated">
            <UserInfoCard />
            <BannersSlider />
            <UserBalanceCard />
            <IdentityCardDoc />
            <MenuList />

            <TariffsCard />

            <BonusesCard />
            <AdditionalServices />
            <ShopTelecomCard @click="shopTelecomHandler" />
            <!-- <MiniBottomBanner
                v-if="isShowMiniBanner"
                custom-class="action_loyal"
                background="#3a75aa"
                title="mini_banner_loyal_title"
                description="mini_banner_loyal_description"
            /> -->
            <NauryzChallenge v-if="isShowMiniBanner" />
        </template>

        <template v-else>
            <TButton
                class="enter-cabinet-btn"
                type="outlined"
                size="large"
                full
                @click="loginHandler"
            >
                {{ $t('login') }}
            </TButton>
            <BannersSlider />
            <MenuList />
            <BonusesCardStatic @on-click="bonusesStaticHandler" />
            <TariffPackages />
            <TvPlusSectionStatic />
            <ShopTelecomCard @click="shopTelecomHandler" />
        </template>
    </Wrapper>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

import { TButton } from '@telecom/t-components'
import { useAuthStore } from '../auth/store'
import { useMainStore } from './store'
import TariffPackages from './TariffPackages/TariffPackages.vue'
import TariffsCard from './TariffPackages/TariffsCard.vue'
import BonusesCardStatic from './BonusesCard/BonusesCardStatic.vue'
import TvPlusSectionStatic from './AdditionalServices/TvPlusSectionStatic.vue'
import ShopTelecomCard from './AdditionalServices/ShopTelecomCard.vue'
import NauryzChallenge from './NauryzChallenge/NauryzChallenge.vue'
import { useBonusesStore } from '@/shared/modules/bonuses/store'
import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import AdditionalServices from '@/components/main/AdditionalServices/AdditionalServices.vue'
import MenuList from '@/components/main/MenuList/MenuList.vue'
import UserBalanceCard from '@/components/main/UserCard/UserBalanceCard.vue'
import UserInfoCard from '@/components/main/UserCard/UserInfoCard.vue'
import BonusesCard from '@/components/main/BonusesCard/BonusesCard.vue'
import IdentityCardDoc from '@/components/main/IdCard/IdentityCardDoc.vue'
import BannersSlider from '@/components/main/BannersSlider/BannersSlider.vue'
// import MiniBottomBanner from '@/shared/components/MiniBottomBanner/MiniBottomBanner.vue'
import { useCustomerStore } from '@/shared/modules/customer/store'
import { useMetric } from '@/shared/hooks/useMetric'
import { YandexCounters } from '@/shared/services/metric/enum'
import { AituService } from '@/shared/services/aitu/aitu-service'
import { SentryService } from '@/shared/services/sentry/sentry-service'
import type { GetMeResponse, GetPhoneResponse } from '@/shared/types/aitu'

const customerStore = useCustomerStore()
const mainStore = useMainStore()
const authStore = useAuthStore()
const bonusesStore = useBonusesStore()
const { isAuthenticated } = storeToRefs(authStore)
const { isShowMiniBanner } = storeToRefs(bonusesStore)
const { sendMetric } = useMetric()
const router = useRouter()

onMounted(() => {
    updateUserAitu()
    setSentryUser()
})

const setSentryUser = async (): Promise<void> => {
    const { id, name, lastname }: GetMeResponse = await AituService.getMe()
    const phoneResult: GetPhoneResponse | null = await AituService.getPhone()

    SentryService.setUser({
        username: `${name}, ${lastname}`,
        phone: phoneResult?.phone,
        aitu_user_id: id
    })
}

const openAskAuthModal = (): void => {
    mainStore.openAskAuthModal()
}

const loginHandler = (): void => {
    authStore.logout().then(() => {
        router.push({ name: 'login' })
    })

    sendMetric(
        'notautho_main',
        {
            clicked: 'authorization'
        },
        YandexCounters.YandexTwo
    )
}

const bonusesStaticHandler = (): void => {
    openAskAuthModal()

    sendMetric(
        'notautho_main',
        {
            clicked: 'bonus'
        },
        YandexCounters.YandexTwo
    )
}

const shopTelecomHandler = (): void => {
    AituService.openExternalUrl('https://shop.telecom.kz/')

    sendMetric('OPENINGWINDOWTELECOMSHOP', {}, YandexCounters.YandexTwo)
}

const updateUserAitu = (): void => {
    if (!authStore.isAuthenticated) {
        customerStore.updateUserAitu()
    }
}
</script>

<style scoped lang="scss">
.enter-cabinet-btn {
    background-color: color(white) !important;
    color: color(primary) !important;
}
</style>
