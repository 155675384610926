<template>
    <Card :class="['mobile-navigation', { show: isShowMenu }]">
        <TSpace vertical y-gap="16">
            <TSpace vertical y-gap="0">
                <TSpace
                    v-for="link in links"
                    :key="link.name"
                    x-gap="16"
                    justify="space-between"
                    align="center"
                    class="nav-link"
                    @click="onRouteChange(link)"
                >
                    <TSpace align="center" x-gap="16">
                        <TIcon stroke="#267ccc" width="24" height="24">
                            <Component :is="link.icon" />
                        </TIcon>

                        <div class="nav-link__title">
                            {{ $t(link.title) }}
                        </div>

                        <span v-if="link.badge" class="nav-link__badge">
                            {{ $t(link.badge) }}
                        </span>
                    </TSpace>

                    <TIcon width="24" height="24">
                        <ChevronRightIcon />
                    </TIcon>
                </TSpace>
            </TSpace>
        </TSpace>
    </Card>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'

import {
    TSpace,
    TIcon,
    User01Icon,
    ChevronRightIcon,
    InfoCircleIcon,
    MessageQuestionSquareIcon,
    Edit05Icon,
    CalendarIcon,
    ArchiveIcon,
    CreditCard02Icon,
    TransferIcon,
    UserEditIcon,
    CalendarCheck02Icon
} from '@telecom/t-components'
import type { NavbarItem } from '@/components/main/MenuList/types'
import Card from '@/shared/components/Card/Card.vue'
import { useMetric } from '@/shared/hooks/useMetric'
import { useMainStore } from '@/components/main/store'
import { useAuthStore } from '@/components/auth/store'
import { YandexCounters } from '@/shared/services/metric/enum'

const router = useRouter()
const { sendMetric } = useMetric()

const authStore = useAuthStore()
const mainStore = useMainStore()

withDefaults(
    defineProps<{
        isShowMenu?: boolean
    }>(),
    {
        isShowMenu: false
    }
)

const emit = defineEmits<{
    (event: 'on-route'): void
}>()

const links: NavbarItem[] = [
    {
        title: 'navbar_profile',
        name: 'settings',
        icon: User01Icon,
        metricGoal: 'PROFILETELECOM',
        isAuth: true
    },
    {
        title: 'payments_payments',
        name: 'payments',
        icon: CreditCard02Icon,
        metricGoal: 'PAYMENTS',
        noAuthMetricGoal: 'payments',
        isAuth: true
    },
    {
        title: 'navbar_services',
        name: 'services',
        icon: ArchiveIcon,
        metricGoal: 'MYSERVICES',
        noAuthMetricGoal: 'myservices',
        isAuth: true
    },
    {
        title: 'navbar_detalization',
        name: 'detalization',
        icon: CalendarIcon,
        metricGoal: 'DETALIZATION',
        noAuthMetricGoal: 'detalization',
        isAuth: true
    },
    {
        title: 'navbar_applies',
        name: 'appeals',
        icon: Edit05Icon,
        metricGoal: 'ORDERS',
        noAuthMetricGoal: 'application',
        isAuth: true
    },
    {
        title: 'help',
        name: 'help',
        icon: MessageQuestionSquareIcon,
        metricGoal: 'HELP',
        noAuthMetricGoal: 'help',
        isAuth: false
    },
    {
        title: 'navbar_important_docs',
        name: 'important-docs',
        icon: InfoCircleIcon,
        metricGoal: 'IMPORTANTDOCUMENTS',
        isAuth: false
    }
]

const onRouteChange = (item: NavbarItem): void => {
    if (!authStore.isAuthenticated && item.isAuth) {
        mainStore.openAskAuthModal()

        sendMetric(
            'notautho_main',
            {
                clicked: item.noAuthMetricGoal
            },
            YandexCounters.YandexTwo
        )

        return
    }

    router.push({ name: item.name }).then(() => {
        emit('on-route')
    })

    if (item.metricGoal) {
        if (item.metricCounter) {
            sendMetric(item.metricGoal, {}, item.metricCounter)

            return
        }

        sendMetric(item.metricGoal)
    }

    if (!authStore.isAuthenticated && item.noAuthMetricGoal) {
        sendMetric(
            'notautho_main',
            {
                clicked: item.noAuthMetricGoal
            },
            YandexCounters.YandexTwo
        )
    }
}
</script>

<style scoped lang="scss">
.mobile-navigation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: 0.3s;
    padding: 10px 16px;
    background: color(white);
    height: 100vh;
    width: 100vw;
    opacity: 0;
    transform: translateX(100vh);
    border-radius: 0;

    .close {
        padding: 0;
    }

    &.show {
        opacity: 1;
        transform: translateX(0);
    }

    .nav-link {
        padding: 14px 16px;

        &__title {
            font-size: text(p3);
            line-height: 120%;
            font-weight: 400;
            color: color(dark);
            letter-spacing: 0.4px;
        }

        &__badge {
            font-size: text(p5);
            line-height: 125%;
            color: color(white);
            background-color: color(primary);
            padding: 8px 12px;
            border-radius: rounded(xxl);
        }
    }
}
</style>
