import { defineStore } from 'pinia'
import { reactive, ref, type Ref, toRefs } from 'vue'

import { BonusesApi } from './api'
import type {
    AddBalanceRequestBody,
    BonusesState,
    GetBonusWelcomePayload,
    GetBonusWelcomeResponse,
    GetCustomerBonusesStatus
} from './types'
import { useDetailsBalanceStore } from '@/components/detalization/DetailsBalance/store'

export const useBonusesStore = defineStore('bonuses', () => {
    const isShowMiniBanner: Ref<boolean> = ref(true)

    const balanceStore = useDetailsBalanceStore()

    const state: BonusesState = reactive({
        isLoaded: false,
        isError: false,
        balance: 0,
        isActive: false,
        isLoading: false,
        validities: [],
        referral: {
            id: '',
            link: '',
            promocode: ''
        },
        detailsHistory: [],
        promotions: [],
        bonusCards: []
    })

    const getCustomerBonuses = async (): Promise<void> => {
        if (state.isLoaded) {
            return
        }

        state.isLoading = true

        try {
            const { data } = await BonusesApi.getCustomerBonuses()
            state.balance = data.bonus_account_balance
            state.validities = data.bonuses.map((bonus) => ({
                amount: bonus.amount,
                status: bonus.bonus_status,
                createdAt: new Date(bonus.created_at),
                dateEnd: new Date(bonus.date_end)
            }))
            state.isActive = data.bonus_status
            state.referral = data.referral
            state.isLoaded = true
        } catch (e) {
            state.isError = true
        } finally {
            state.isLoading = false
        }
    }

    const getBonusWelcome = async (
        payload: GetBonusWelcomePayload
    ): Promise<GetBonusWelcomeResponse> => {
        const { data } = await BonusesApi.getBonusWelcome(payload)

        return data
    }

    const activateBonuses = async (): Promise<
        GetCustomerBonusesStatus | undefined
    > => {
        state.isLoading = true

        if (state.isActive) {
            state.isLoading = false
            return
        }

        try {
            await BonusesApi.activateCustomerBonuses()
            state.isLoaded = false
            await getCustomerBonuses()
            const { data } = await BonusesApi.getCustomerBonusesStatus()

            return data
        } catch (e) {
            state.isError = true
        } finally {
            state.isLoading = false
        }
    }

    const getDetailsHistory = async (): Promise<void> => {
        state.isLoading = true

        try {
            const { data } = await BonusesApi.getDetailsHistory()
            state.detailsHistory = data.items
        } finally {
            state.isLoading = false
        }
    }

    const getPromotions = async (): Promise<void> => {
        state.isLoading = true

        try {
            const { data } = await BonusesApi.getPartnersPromotions()
            state.promotions = data.items
        } finally {
            state.isLoading = false
        }
    }

    const getBonusCards = async (retry: boolean = false): Promise<void> => {
        if (state.bonusCards.length && !retry) {
            return
        }

        state.isLoading = true

        try {
            const { data } = await BonusesApi.getBonusCards()
            const values = Object.values(data)
            state.bonusCards = values.map((value) => ({
                banner: value.banner,
                ...value.items[0]
            }))
        } finally {
            state.isLoading = false
        }
    }

    const addBalance = async (
        body: AddBalanceRequestBody
    ): Promise<boolean> => {
        state.isLoading = true

        try {
            const { data } = await BonusesApi.addBalance(body)

            state.isLoaded = false

            getCustomerBonuses()
            balanceStore.getBalanceDetails()

            return data.status
        } finally {
            state.isLoading = false
        }
    }

    const resetIsLoaded = (): void => {
        state.isLoaded = false
    }

    const toggleMiniBanner = (): void => {
        isShowMiniBanner.value = !isShowMiniBanner.value
    }

    const setStatusMiniBanner = (status: boolean): void => {
        isShowMiniBanner.value = status
    }

    const getAssetUrl = (url: string): string => {
        if (url.includes('/uploads')) {
            return `${import.meta.env.VITE_API_BFF_URL}${url}`
        }

        return `${
            import.meta.env.VITE_API_LOYALTY_URL
        }/api/v1/cms-loyalty/api/bonuses/packages/file/${url}`
    }

    return {
        storageUrl: import.meta.env.VITE_API_BFF_URL,
        isShowMiniBanner,
        ...toRefs(state),
        getCustomerBonuses,
        activateBonuses,
        getDetailsHistory,
        getPromotions,
        getBonusCards,
        getBonusWelcome,
        addBalance,
        resetIsLoaded,
        toggleMiniBanner,
        setStatusMiniBanner,
        getAssetUrl
    }
})
