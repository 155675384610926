import amediatekaIcon from '@/assets/images/amediateka-item.svg'
import qazaqshaIcon from '@/assets/images/qazaqsha-icon.svg'
import salemIcon from '@/assets/images/salem-item.svg'
import madeInKzIcon from '@/assets/images/made-in-kz-item.svg'
import winkIcon from '@/assets/images/wink-logo.svg'
import megogoIcon from '@/assets/images/megogo-item.svg'
import kinoroomIcon from '@/assets/images/logo_kinoroom.svg'
import startIcon from '@/assets/images/start-item.svg'
import premierIcon from '@/assets/images/premier-item.svg'
import vijuIcon from '@/assets/images/viju-item-circle-icon.svg'

import type { ChannelsCinema } from '@/components/full-digital/types'

const cardsProd = {
    BEREKET: {
        ID: 372,
        MOBILE_CONNECTION_ID: 405,
        TV_CINEMA_ID: 408,
        TV_ID: 409,
        CINEMA_ID: 410,
        BONUS: 401
    },
    KEREMET_TV: {
        ID: 371,
        TV_ID: 409,
        CINEMA_ID: 410,
        BONUS: 401
    },
    KEREMET_MOBILE: {
        ID: 373,
        MOBILE_CONNECTION_ID: 406,
        TV_CINEMA_ID: 407,
        BONUS: 401
    },
    SHANYRAQ_TV: {
        ID: 385,
        CITY_CONNECTION_ID: 413,
        TV_ID: 409,
        CINEMA_ID: 411
    },
    SHANYRAQ_PLUS: {
        ID: 386,
        MOBILE_CONNECTION_ID: 405,
        TV_CINEMA_ID: 408,
        TV_ID: 409,
        CINEMA_ID: 410,
        CITY_CONNECTION_ID: 412
    },
    SHANYRAQ_MOBILE: {
        ID: 387,
        MOBILE_CONNECTION_ID: 406,
        TV_CINEMA_ID: 407,
        CITY_CONNECTION_ID: 412
    },
    ALEM_PLUS: {
        ID: 378,
        TV_ID: 384,
        CINEMA_ID: 393
    },
    ALEM_MOBILE: {
        ID: 379,
        MOBILE_CONNECTION_ID: 383
    },
    ALEM_TV: {
        ID: 380,
        TV_ID: 384,
        CINEMA_ID: 393
    },
    ALAMAN: {
        ID: 335,
        MOBILE_CONNECTION_ID: 183
    },
    INTERNET_500: {
        ID: 352
    },
    INTERNET_200: {
        ID: 348
    }
}

const cardsStage = {
    BEREKET: {
        ID: 372,
        MOBILE_CONNECTION_ID: 409,
        TV_CINEMA_ID: 412,
        TV_ID: 413,
        CINEMA_ID: 414,
        BONUS: 403
    },
    KEREMET_TV: {
        ID: 371,
        TV_ID: 413,
        CINEMA_ID: 414,
        BONUS: 403
    },
    KEREMET_MOBILE: {
        ID: 373,
        MOBILE_CONNECTION_ID: 410,
        TV_CINEMA_ID: 411,
        BONUS: 403
    },
    SHANYRAQ_TV: {
        ID: 382,
        CITY_CONNECTION_ID: 417,
        TV_ID: 413,
        CINEMA_ID: 415
    },
    SHANYRAQ_PLUS: {
        ID: 383,
        MOBILE_CONNECTION_ID: 409,
        TV_CINEMA_ID: 412,
        TV_ID: 413,
        CINEMA_ID: 414,
        CITY_CONNECTION_ID: 416
    },
    SHANYRAQ_MOBILE: {
        ID: 384,
        MOBILE_CONNECTION_ID: 410,
        TV_CINEMA_ID: 411,
        CITY_CONNECTION_ID: 416
    },
    ALEM_PLUS: {
        ID: 378,
        TV_ID: 385,
        CINEMA_ID: 394
    },
    ALEM_MOBILE: {
        ID: 379,
        MOBILE_CONNECTION_ID: 382
    },
    ALEM_TV: {
        ID: 381,
        TV_ID: 383,
        CINEMA_ID: 394
    },
    ALAMAN: {
        ID: 283,
        MOBILE_CONNECTION_ID: 189
    },
    INTERNET_500: {
        ID: 352
    },
    INTERNET_200: {
        ID: 348
    }
}

export const CARDS =
    import.meta.env.VITE_NODE_ENV === 'production' ? cardsProd : cardsStage

const channelsTvCinema: ChannelsCinema = {
    [CARDS.BEREKET.ID]: {
        tv: [
            'Balapan',
            'Abai TV',
            'МИР',
            'ASTANA TV',
            'Almaty',
            'Первый канал. Евразия',
            '31 канал',
            'Седьмой канал',
            'El arna',
            'НТК',
            'Turkistan',
            'Той Думан',
            'MUNARA TV',
            'Hit TV',
            'Көктем SD',
            'СТС',
            'Большая пятница',
            'ТНТ4',
            'Время',
            'Россия К',
            'Родное кино',
            'Дом кино',
            'Hollywood',
            'НСТВ',
            'Индийское кино',
            'Синема',
            'Amedia 2',
            'Da Vinci',
            'Ti Ji',
            'Gulli Girl',
            'О!',
            'Карусель',
            'Nick Jr',
            'SALEM ALEM',
            'Победа',
            'HDL',
            'Техно 24',
            'Инфоканал',
            'Поехали!',
            'Туран (тест)',
            'Телекафе',
            'Бобёр',
            'Лапки Live',
            'Бокс ТВ',
            'Матч! Планета',
            'KHL',
            'viju+ Sport',
            'Музыка Первого',
            'ТНТ Music',
            'Шансон',
            'Mezzo',
            'Мир 24',
            'BBC News',
            'Euronews',
            'РБК',
            'Россия 24',
            'Первый канал. Всемирная Сеть',
            'Россия РТР',
            'НТВ Мир',
            'РЕН ТВ',
            'ТНВ - Планета',
            'AQTOBE',
            'MANGYSTAY',
            'AQJAIYQ',
            'ATYRAY',
            'РИКА-ТВ',
            'Caspian News',
            'ALTAI',
            'ERTIS',
            'Ирбис ТВ',
            'SEMEI',
            'QYZYLJAR',
            'KÓKSHE',
            'QOSTANAI',
            'SARYARQA',
            'Алау ТВ',
            'Первый Северный',
            '5 канал',
            'Ulytau',
            'Aqsham TV',
            'Твоё ТВ',
            'JAMBYL',
            'QYZYLORDA',
            'Отырар',
            'ONTUSTIK',
            'Айғак',
            'Тұран ТВ',
            'Ассамблея ТВ',
            'QADAM TV',
            'HAN-TV',
            'HOME 4K',
            'Museum 4K',
            'MyZen 4K',
            'Инсайт ТВ 4K',
            'Fashion&Style 4K',
            'QUADRO 4К',
            'Travelxp 4K'
        ],
        hd: [
            'Qazaqstan',
            'Хабар',
            '24KZ',
            'Qazsport',
            'Jibek Joly',
            'КТК',
            'Atameken Business',
            'Sport Plus Qazaqstan',
            'Новое телевидение',
            'SHOW KZ',
            'Ключ',
            'Камеди Казахстан',
            'Наше новое кино',
            'Русский Иллюзион',
            'Иллюзион+',
            'Дом кино Премиум',
            'viju TV1000 русское',
            'Bollywood',
            'Кино Сериал',
            'Кино Драма',
            'Киносемья',
            'Кинокомедия',
            'Киномикс',
            'Кинохит',
            'Кинопремьера',
            'Мужское кино',
            'Киносвидание',
            'Киноужас',
            'Киносерия',
            'viju TV1000',
            'viju TV1000 аction',
            'FOX',
            'Flix Snip',
            'Мосфильм. Золотая коллекция',
            'Plan B',
            'КиноБоевик',
            'Премиум Кино',
            'Мировое Кино',
            'Семейное Кино',
            'Amedia 1',
            'Dorama',
            'Amedia Premium',
            'Amedia hit',
            'Start Air',
            'Start World',
            'NickToons',
            'Мульт',
            'Nickelodeon',
            'Ducktv',
            'Мультиландия',
            'History',
            'viju History',
            'viju Explore',
            'Авто 24',
            'Curiosity Stream',
            'National Geographic',
            'Nat Geo WILD',
            'viju Nature',
            'Моя планета',
            'Рыбалка и охота',
            'Кухня',
            'Загородная жизнь',
            'Мужской',
            'Epik!',
            'Travelxp',
            'ЖИВИ!',
            'MMA-TV.COM',
            'Q Arena',
            'Extreme',
            'KHL Prime',
            'Q League',
            'Setanta Qazaqstan',
            'Setanta Sports 1',
            'Setanta Sports 2',
            'INSPORT',
            'Q Football',
            'Mezzo Live',
            '1',
            'Fashion&LifeStyle',
            'TRT World',
            'DW',
            'France 24',
            'Беларусь 24',
            'TRT AVAZ',
            'Жетысу'
        ],
        cinemaRoom: [
            {
                name: 'Salem',
                desc: 'tv_plus_serials',
                icon: salemIcon
            },
            {
                name: 'KINOROOM',
                desc: 'tv_plus_films',
                icon: madeInKzIcon
            },
            {
                name: 'Amediateka',
                desc: 'tv_plus_serials',
                icon: amediatekaIcon
            },
            {
                name: 'Megogo',
                desc: 'movies-and-tv-shows',
                icon: megogoIcon
            },
            {
                name: 'WINK',
                desc: 'movies-and-tv-shows',
                icon: winkIcon
            },
            {
                name: 'Premier',
                desc: 'movies-and-tv-shows',
                icon: premierIcon
            },
            {
                name: 'Qazaqsha',
                desc: 'tv_plus_films',
                icon: qazaqshaIcon
            },
            {
                name: 'Start',
                desc: 'movies-and-tv-shows',
                icon: startIcon
            },
            {
                name: 'Viju',
                desc: 'movies-and-tv-shows',
                icon: vijuIcon
            }
        ]
    },

    [CARDS.KEREMET_TV.ID]: {
        cinemaRoom: []
    },

    [CARDS.KEREMET_MOBILE.ID]: {
        tv: [
            'МИР',
            'ASTANA TV',
            'Almaty',
            'Первый канал. Евразия',
            'Седьмой канал',
            'El arna',
            'НТК (HLS версия)',
            'Gakku',
            'Turkistan',
            'Той Думан',
            'MUNARA TV',
            'Мир 24',
            'BBC World News',
            'Euronews',
            'РБК',
            'Россия 24',
            'КВН ТВ',
            'Hit TV',
            'СТС',
            'Большая пятница',
            'ТНТ4',
            'Россия РТР',
            'НТВ Мир',
            'РЕН ТВ',
            'Россия К',
            'Родное кино',
            'Hollywood',
            'НСТВ',
            'Индийское кино',
            'Синема',
            'A2',
            'Da Vinci',
            'Ti Ji',
            'Gulli Girl',
            'Nick Jr',
            'Техно 24',
            'Discovery Channel',
            'TLC',
            'Бокс ТВ',
            'Матч! Планета',
            'KHL',
            'viju+ Sport',
            'ТНТ Music',
            'Муз ТВ',
            'Шансон',
            'Mezzo',
            '1HD',
            'ТНВ - Планета',
            'AQTOBE',
            'MANGYSTAY',
            'AQJAIYQ',
            'ATYRAY',
            'РИКА ТВ',
            'Caspian News',
            'ALTAI',
            'ERTIS',
            'Ирбис ТВ',
            'SEMEI',
            'QYZYLJAR',
            'KÓKSHE',
            'QOSTANAI',
            'SARYARQA',
            'Алау ТВ',
            'Первый Северный',
            '5 канал',
            'Ulytau',
            'Aqsham TV',
            'Твое ТВ',
            'JAMBYL',
            'QYZYLORDA',
            'Отырар',
            'ONTUSTIK',
            'Айғак',
            'Тұран ТВ',
            'Ассамблея ТВ',
            'QADAM TV',
            'HAN-TV'
        ],
        hd: [
            'Qazaqstan International',
            'Хабар',
            'Хабар 24',
            'Balapan International',
            'Jibek Joly',
            'КТК',
            'Atameken Business',
            'TRT World',
            'DW',
            'France24',
            'Новое Телевидение',
            '360°',
            'Камеди Казахстан',
            'Наше новое кино',
            'Русский Иллюзион',
            'Иллюзион+',
            'viju TV1000 русское',
            'Bollywood',
            'Alem 2',
            'Alem 1',
            'Киносемья',
            'Кинокомедия',
            'Киномикс',
            'Кинохит',
            'Кинопремьера',
            'Мужское кино',
            'Киносвидание',
            'Киноужас',
            'Киносерия',
            'viju TV1000',
            'viju TV1000 аction',
            'FOX',
            'Flix Snip',
            'Мосфильм. Золотая коллекция',
            'Plan B',
            'A1',
            'Dorama',
            'Amedia Premium',
            'Amedia hit',
            'NickToons',
            'Мульт',
            'Nickelodeon',
            'Ducktv',
            'Мультиландия',
            'History',
            'viju History',
            'viju Explore',
            'HDL',
            'Авто 24',
            'National Geographic',
            'Nat Geo WILD',
            'viju Nature',
            'Моя Планета',
            'Рыбалка и охота',
            'Кухня',
            'Загородная жизнь',
            'Мужской',
            'Travelxp',
            'ЖИВИ!',
            'MMA-TV.COM',
            'Q Arena',
            'EuroSport',
            'EuroSport2',
            'KHL Prime',
            'Q League',
            'Setanta Qazaqstan',
            'Setanta Sports 1',
            'Setanta Sports 2',
            'INSPORT',
            'Sport Plus Qazaqstan',
            'Q Football',
            'Mezzo Live',
            'Беларусь 24',
            'TRT AVAZ',
            'Fashion&LifeStyle',
            'Жетысу'
        ],
        cinemaRoom: [
            {
                name: 'Amediateka',
                desc: 'tv_plus_serials',
                icon: amediatekaIcon
            },
            {
                name: 'Qazaqsha',
                desc: 'tv_plus_films',
                icon: qazaqshaIcon
            },
            {
                name: 'Salem',
                desc: 'movies-and-tv-shows',
                icon: salemIcon
            },
            {
                name: 'KINOROOM',
                desc: 'movies-and-tv-shows',
                icon: kinoroomIcon
            },
            {
                name: 'WINK',
                desc: 'movies-and-tv-shows',
                icon: winkIcon
            },
            {
                name: 'Megogo',
                desc: 'movies-and-tv-shows',
                icon: megogoIcon
            }
        ]
    },

    [CARDS.SHANYRAQ_TV.ID]: {
        cinemaRoom: [
            {
                name: 'Амедиатека',
                desc: 'tv_plus_serials',
                icon: amediatekaIcon
            },
            {
                name: 'Qazaqsha',
                desc: 'tv_plus_films',
                icon: qazaqshaIcon
            },
            {
                name: 'Salem',
                desc: 'tv_plus_serials',
                icon: salemIcon
            },
            {
                name: 'KINOROOM',
                desc: 'tv_plus_films',
                icon: kinoroomIcon
            },
            {
                name: 'WINK',
                desc: 'movies-and-tv-shows',
                icon: winkIcon
            }
        ]
    },

    [CARDS.SHANYRAQ_PLUS.ID]: {},
    [CARDS.SHANYRAQ_MOBILE.ID]: {
        tv: [
            'МИР',
            'ASTANA TV',
            'Almaty',
            'Первый канал. Евразия',
            'Седьмой канал',
            'El arna',
            'НТК (HLS версия)',
            'Gakku',
            'Turkistan',
            'Той Думан',
            'MUNARA TV',
            'Мир 24',
            'BBC World News',
            'Euronews',
            'РБК',
            'Россия 24',
            'КВН ТВ',
            'Hit TV',
            'СТС',
            'Большая пятница',
            'ТНТ4',
            'Россия РТР',
            'НТВ Мир',
            'РЕН ТВ',
            'Россия К',
            'Родное кино',
            'Hollywood',
            'НСТВ',
            'Индийское кино',
            'Синема',
            'A2',
            'Da Vinci',
            'Ti Ji',
            'Gulli Girl',
            'Nick Jr',
            'Техно 24',
            'Discovery Channel',
            'TLC',
            'Бокс ТВ',
            'Матч! Планета',
            'KHL',
            'viju+ Sport',
            'ТНТ Music',
            'Муз ТВ',
            'Шансон',
            'Mezzo',
            '1HD',
            'ТНВ - Планета',
            'AQTOBE',
            'MANGYSTAY',
            'AQJAIYQ',
            'ATYRAY',
            'РИКА ТВ',
            'Caspian News',
            'ALTAI',
            'ERTIS',
            'Ирбис ТВ',
            'SEMEI',
            'QYZYLJAR',
            'KÓKSHE',
            'QOSTANAI',
            'SARYARQA',
            'Алау ТВ',
            'Первый Северный',
            '5 канал',
            'Ulytau',
            'Aqsham TV',
            'Твое ТВ',
            'JAMBYL',
            'QYZYLORDA',
            'Отырар',
            'ONTUSTIK',
            'Айғак',
            'Тұран ТВ',
            'Ассамблея ТВ',
            'QADAM TV',
            'HAN-TV'
        ],
        hd: [
            'Qazaqstan International',
            'Хабар',
            'Хабар 24',
            'Balapan International',
            'Jibek Joly',
            'КТК',
            'Atameken Business',
            'TRT World',
            'DW',
            'France24',
            'Новое Телевидение',
            '360°',
            'Камеди Казахстан',
            'Наше новое кино',
            'Русский Иллюзион',
            'Иллюзион+',
            'viju TV1000 русское',
            'Bollywood',
            'Alem 2',
            'Alem 1',
            'Киносемья',
            'Кинокомедия',
            'Киномикс',
            'Кинохит',
            'Кинопремьера',
            'Мужское кино',
            'Киносвидание',
            'Киноужас',
            'Киносерия',
            'viju TV1000',
            'viju TV1000 аction',
            'FOX',
            'Flix Snip',
            'Мосфильм. Золотая коллекция',
            'Plan B',
            'A1',
            'Dorama',
            'Amedia Premium',
            'Amedia hit',
            'NickToons',
            'Мульт',
            'Nickelodeon',
            'Ducktv',
            'Мультиландия',
            'History',
            'viju History',
            'viju Explore',
            'HDL',
            'Авто 24',
            'National Geographic',
            'Nat Geo WILD',
            'viju Nature',
            'Моя Планета',
            'Рыбалка и охота',
            'Кухня',
            'Загородная жизнь',
            'Мужской',
            'Travelxp',
            'ЖИВИ!',
            'MMA-TV.COM',
            'Q Arena',
            'EuroSport',
            'EuroSport2',
            'KHL Prime',
            'Q League',
            'Setanta Qazaqstan',
            'Setanta Sports 1',
            'Setanta Sports 2',
            'INSPORT',
            'Sport Plus Qazaqstan',
            'Q Football',
            'Mezzo Live',
            'Беларусь 24',
            'TRT AVAZ',
            'Fashion&LifeStyle',
            'Жетысу'
        ],
        cinemaRoom: [
            {
                name: 'Амедиатека',
                desc: 'tv_plus_serials',
                icon: amediatekaIcon
            },
            {
                name: 'Qazaqsha',
                desc: 'tv_plus_films',
                icon: qazaqshaIcon
            },
            {
                name: 'Salem',
                desc: 'tv_plus_serials',
                icon: salemIcon
            },
            {
                name: 'KINOROOM',
                desc: 'tv_plus_films',
                icon: kinoroomIcon
            },
            {
                name: 'WINK',
                desc: 'movies-and-tv-shows',
                icon: winkIcon
            },
            {
                name: 'Megogo',
                desc: 'movies-and-tv-shows',
                icon: megogoIcon
            }
        ]
    },

    [CARDS.ALEM_PLUS.ID]: {
        tv: [],
        hd: [],
        cinemaRoom: []
    },

    [CARDS.ALEM_TV.ID]: {
        cinemaRoom: []
    }
}
channelsTvCinema[CARDS.KEREMET_TV.ID] = channelsTvCinema[CARDS.BEREKET.ID]
channelsTvCinema[CARDS.SHANYRAQ_PLUS.ID] = channelsTvCinema[CARDS.BEREKET.ID]
channelsTvCinema[CARDS.SHANYRAQ_TV.ID] = channelsTvCinema[CARDS.KEREMET_TV.ID]
channelsTvCinema[CARDS.ALEM_TV.ID] = channelsTvCinema[CARDS.KEREMET_TV.ID]
channelsTvCinema[CARDS.ALEM_PLUS.ID] = channelsTvCinema[CARDS.KEREMET_TV.ID]
export { channelsTvCinema }

const channelsTv: ChannelsCinema = {
    [CARDS.BEREKET.ID]: {
        tv: [
            'Balapan',
            'Abai TV',
            'МИР',
            'ASTANA TV',
            'Almaty',
            'Первый канал. Евразия',
            '31 канал',
            'Седьмой канал',
            'El arna',
            'НТК',
            'Turkistan',
            'Той Думан',
            'MUNARA TV',
            'Hit TV',
            'Көктем SD',
            'СТС',
            'Большая пятница',
            'ТНТ4',
            'Время',
            'Россия К',
            'Родное кино',
            'Дом кино',
            'Hollywood',
            'НСТВ',
            'Индийское кино',
            'Синема',
            'Amedia 2',
            'Da Vinci',
            'Ti Ji',
            'Gulli Girl',
            'О!',
            'Карусель',
            'Nick Jr',
            'SALEM ALEM',
            'Победа',
            'HDL',
            'Техно 24',
            'Инфоканал',
            'Поехали!',
            'Туран (тест)',
            'Телекафе',
            'Бобёр',
            'Лапки Live',
            'Бокс ТВ',
            'Матч! Планета',
            'KHL',
            'viju+ Sport',
            'Музыка Первого',
            'ТНТ Music',
            'Шансон',
            'Mezzo',
            'Мир 24',
            'BBC News',
            'Euronews',
            'РБК',
            'Россия 24',
            'Первый канал. Всемирная Сеть',
            'Россия РТР',
            'НТВ Мир',
            'РЕН ТВ',
            'ТНВ - Планета',
            'AQTOBE',
            'MANGYSTAY',
            'AQJAIYQ',
            'ATYRAY',
            'РИКА-ТВ',
            'Caspian News',
            'ALTAI',
            'ERTIS',
            'Ирбис ТВ',
            'SEMEI',
            'QYZYLJAR',
            'KÓKSHE',
            'QOSTANAI',
            'SARYARQA',
            'Алау ТВ',
            'Первый Северный',
            '5 канал',
            'Ulytau',
            'Aqsham TV',
            'Твоё ТВ',
            'JAMBYL',
            'QYZYLORDA',
            'Отырар',
            'ONTUSTIK',
            'Айғак',
            'Тұран ТВ',
            'Ассамблея ТВ',
            'QADAM TV',
            'HAN-TV',
            'HOME 4K',
            'Museum 4K',
            'MyZen 4K',
            'Инсайт ТВ 4K',
            'Fashion&Style 4K',
            'QUADRO 4К',
            'Travelxp 4K'
        ],
        hd: [
            'Qazaqstan',
            'Хабар',
            '24KZ',
            'Qazsport',
            'Jibek Joly',
            'КТК',
            'Atameken Business',
            'Sport Plus Qazaqstan',
            'Новое телевидение',
            'SHOW KZ',
            'Ключ',
            'Камеди Казахстан',
            'Наше новое кино',
            'Русский Иллюзион',
            'Иллюзион+',
            'Дом кино Премиум',
            'viju TV1000 русское',
            'Bollywood',
            'Кино Сериал',
            'Кино Драма',
            'Киносемья',
            'Кинокомедия',
            'Киномикс',
            'Кинохит',
            'Кинопремьера',
            'Мужское кино',
            'Киносвидание',
            'Киноужас',
            'Киносерия',
            'viju TV1000',
            'viju TV1000 аction',
            'FOX',
            'Flix Snip',
            'Мосфильм. Золотая коллекция',
            'Plan B',
            'КиноБоевик',
            'Премиум Кино',
            'Мировое Кино',
            'Семейное Кино',
            'Amedia 1',
            'Dorama',
            'Amedia Premium',
            'Amedia hit',
            'Start Air',
            'Start World',
            'NickToons',
            'Мульт',
            'Nickelodeon',
            'Ducktv',
            'Мультиландия',
            'History',
            'viju History',
            'viju Explore',
            'Авто 24',
            'Curiosity Stream',
            'National Geographic',
            'Nat Geo WILD',
            'viju Nature',
            'Моя планета',
            'Рыбалка и охота',
            'Кухня',
            'Загородная жизнь',
            'Мужской',
            'Epik!',
            'Travelxp',
            'ЖИВИ!',
            'MMA-TV.COM',
            'Q Arena',
            'Extreme',
            'KHL Prime',
            'Q League',
            'Setanta Qazaqstan',
            'Setanta Sports 1',
            'Setanta Sports 2',
            'INSPORT',
            'Q Football',
            'Mezzo Live',
            '1',
            'Fashion&LifeStyle',
            'TRT World',
            'DW',
            'France 24',
            'Беларусь 24',
            'TRT AVAZ',
            'Жетысу'
        ]
    },

    [CARDS.KEREMET_TV.ID]: {
        tv: [],
        hd: []
    },

    [CARDS.SHANYRAQ_PLUS.ID]: {},

    [CARDS.SHANYRAQ_TV.ID]: {},

    [CARDS.ALEM_PLUS.ID]: {
        hd: [],
        tv: []
    },

    [CARDS.ALEM_TV.ID]: {}
}
channelsTv[CARDS.KEREMET_TV.ID].hd = channelsTv[CARDS.BEREKET.ID].hd
channelsTv[CARDS.KEREMET_TV.ID].tv = channelsTv[CARDS.BEREKET.ID].tv
channelsTv[CARDS.SHANYRAQ_PLUS.ID] = channelsTv[CARDS.BEREKET.ID]
channelsTv[CARDS.SHANYRAQ_TV.ID] = channelsTv[CARDS.KEREMET_TV.ID]
channelsTv[CARDS.ALEM_PLUS.ID] = channelsTv[CARDS.KEREMET_TV.ID]
channelsTv[CARDS.ALEM_TV.ID] = channelsTv[CARDS.ALEM_PLUS.ID]
export { channelsTv }
