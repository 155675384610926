import type { AxiosResponse } from 'axios'

import type {
    TransferRequestId,
    TransferCreateRequest,
    OtpSend,
    TransferRelocationResponse,
    TransferRelocationData,
    TechvAvailability,
    CreateOrderResponse
} from './types'
import type { CustomerSelectedHouse } from '@/shared/modules/customer/types'
import type { DeliveryResponse } from '@/components/payments/InvoiceDeliveryType/types'

import axios from '@/shared/services/api/axios'

export class ServiceTransferApi {
    static createTransferRequests(
        data: TransferCreateRequest
    ): Promise<AxiosResponse<TransferRequestId>> {
        return axios.post(`/api/v1.0/customer/relocation/requests/create`, data)
    }

    static checkTransfer(
        requestId: number
    ): Promise<AxiosResponse<TransferRelocationResponse>> {
        return axios.get('/api/v1.0/customer/relocation/requests/check', {
            params: {
                requestId
            }
        })
    }

    static transferRelocation(
        requestId: number,
        data: TransferRelocationData
    ): Promise<AxiosResponse<DeliveryResponse>> {
        return axios.patch(
            `/api/v1.0/customer/relocation/requests/${requestId}`,
            data
        )
    }

    static orderCreate(
        requestId: TransferRequestId
    ): Promise<AxiosResponse<CreateOrderResponse>> {
        return axios.post(
            '/api/v1.0/customer/relocation/requests/order/create',
            requestId
        )
    }

    static getTechvAvailability(
        data: TechvAvailability
    ): Promise<AxiosResponse<CustomerSelectedHouse[]>> {
        return axios.post(
            '/api/v1.0/customer/relocation/requests/tech-availability-check',
            data
        )
    }

    static sendOtp(data: OtpSend): Promise<AxiosResponse<any>> {
        return axios.post('/api/v1.0/open/otp/sms/send', data)
    }

    static checkOtp(data: OtpSend): Promise<AxiosResponse<any>> {
        return axios.post('/api/v1.0/open/otp/check', data)
    }
}
