import amplitude from 'amplitude-js'
import { useYandexMetrika } from 'yandex-metrika-vue3'
import { AmplitudeCounters, YandexCounters } from './enum'

type MetricInstances = Array<AmplitudeCounters | YandexCounters>
type MetricInstancesMap = {
    [x: number]: () => void
}

export abstract class MetricBase<T extends Record<string, any>> {
    constructor(instances: MetricInstances) {
        this.metricInstances = instances

        this.sendMetric = this.sendMetric.bind(this)
    }

    private metricInstances: MetricInstances = []

    sendMetric<K extends keyof T>(id: K, props?: Partial<T[K]>): void {
        const instancesMap: MetricInstancesMap = {
            [AmplitudeCounters.AmplitudeFirst]: () => {
                amplitude.getInstance().logEvent(String(id), props)
            },
            [AmplitudeCounters.AmplitudeSecond]: () => {
                amplitude.getInstance('second').logEvent(String(id), props)
            },
            [YandexCounters.YandexFirst]: () => {
                const yandexMetrika = useYandexMetrika()

                yandexMetrika.reachGoal(id, props)
            },
            [YandexCounters.YandexTwo]: () => {
                window.ym &&
                    window.ym(YandexCounters.YandexTwo, 'reachGoal', id, props)
            },
            [YandexCounters.YandexThree]: () => {
                window.ym &&
                    window.ym(YandexCounters.YandexThree, 'reachGoal', id, props)
            }
        }

        if (this.metricInstances.length) {
            this.metricInstances.forEach((instance) => {
                try {
                    const callMetric: () => void = instancesMap[instance]

                    callMetric()
                } catch (e) {
                    console.log('Error when sending metric', e)
                }
            })
        } else {
            console.error('No metric instances found')
        }
    }
}
