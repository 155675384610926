<template>
    <Accordion
        v-if="metadata?.agreement && currentCustomerAccount"
        ref="accordion"
        @toggle="onAccordionToggle"
    >
        <template #title>
            <TSpace
                class="customer-account-card__item title"
                vertical
                y-gap="0"
            >
                <h6>
                    {{ $t('personal_account') }}
                    {{ currentCustomerAccount.id }}
                </h6>

                <span>
                    {{ currentCustomerAccount.city }},
                    {{ currentCustomerAccount.street }}
                    {{ currentCustomerAccount.house }}
                </span>
            </TSpace>
        </template>

        <template #content>
            <TSpace vertical>
                <div
                    v-for="(account, idx) in accounts"
                    :key="`account--${idx}`"
                    :class="{
                        active: account.id === currentCustomerAccount.id
                    }"
                    class="customer-account-card__item"
                    @click="onAccountChange(account)"
                >
                    <h6>
                        {{ $t('personal_account') }}
                        {{ account.id }}
                    </h6>

                    <span>
                        {{ account.city }},
                        {{ account.street }}
                        {{ account.house }}
                    </span>
                </div>
            </TSpace>
        </template>
    </Accordion>
</template>

<script setup lang="ts">
import { computed, type ComputedRef, ref, type Ref } from 'vue'
import { storeToRefs } from 'pinia'

import { TSpace } from '@telecom/t-components'
import { useCustomerStore } from '@/shared/modules/customer/store'
import { useServicesStore } from '@/components/services/ServiceList/store'
import { useOffersStore } from '@/shared/modules/offers/store'
import { useChangeArchiveStore } from '@/components/change-archive/store'
import type {
    ParsedCustomerAccount,
    CustomerAccount
} from '@/shared/modules/customer/types'
import { CustomerService } from '@/shared/modules/customer/customerService'
import Accordion from '@/shared/components/UI/Accordion.vue'
import { useDetailsBalanceStore } from '@/components/detalization/DetailsBalance/store'
import { useServiceTransferStore } from '@/components/services/ServiceTransfer/store'
import { useMetric } from '@/shared/hooks/useMetric'
import { useDetalizationStore } from '@/components/detalization/store'
type accordionInstance = InstanceType<typeof Accordion>

const servicesStore = useServicesStore()
const customerStore = useCustomerStore()
const detailsBalanceStore = useDetailsBalanceStore()
const changeArchiveStore = useChangeArchiveStore()
const serviceTransferStore = useServiceTransferStore()
const detalizationStore = useDetalizationStore()
const offersStore = useOffersStore()
const { metadata } = storeToRefs(customerStore)
const { sendMetric } = useMetric()

const agreement = metadata.value?.agreement

const accordion: Ref<accordionInstance | null> = ref(null)

const accounts: ComputedRef<ParsedCustomerAccount[]> = computed(() => {
    if (!agreement) {
        return []
    }

    return CustomerService.getParsedCustomerAccountsList(agreement)
})

const currentCustomerAccount: Ref<ParsedCustomerAccount | undefined> = ref(
    accounts.value.find(
        (account) => account.id === customerStore.currentAccount?.local_id
    )
)

const onAccountChange = async (
    account: ParsedCustomerAccount
): Promise<void> => {
    currentCustomerAccount.value = account

    if (!agreement?.length) {
        return
    }

    const currentAccountFull: CustomerAccount = CustomerService.getAccountByID(
        agreement,
        account.id
    )

    if (accordion.value) {
        accordion.value.onToggle()
    }

    if (currentAccountFull) {
        customerStore.setCurrentAccount(currentAccountFull)
        servicesStore.resetServices()
        detalizationStore.resetInternetDevices()
        await detailsBalanceStore.getBalanceDetails()
        changeArchiveStore.getRecommendations()
        await offersStore.resetOffers()
        serviceTransferStore.resetTransferRequest()
    }

    sendMetric('OPENLISTPERSONALACCOUNT')
}

const onAccordionToggle = (value: boolean): void => {
    if (value) {
        sendMetric('SELECTACCOUNT')
    }
}
</script>

<style scoped lang="scss">
.customer-account-card {
    &__item {
        padding: 6px 10px;
        background-color: color(secondary-hover-focus);
        border-radius: rounded(md);

        &.title {
            background-color: unset;
            padding: 0;
        }

        &.active {
            border: 1px solid color(primary);
            position: relative;
            padding-right: 40px;

            span {
                color: color(dark);
            }

            &::after {
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                border: 6px solid color(primary);
                border-radius: rounded(circle);
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        h6 {
            font-size: text(p4);
            line-height: 128%;
            font-weight: 500;
        }

        span {
            font-size: text(p6);
            line-height: 129%;
            font-weight: 400;
            color: color(gray);
        }
    }
}
</style>
