import type {
    MobileDetail,
    MobileDetailGroup
} from '@/components/detalization/types'
import { useDetalizationStore } from '@/components/detalization/store'
import { useServicesStore } from '@/components/services/ServiceList/store'

export class DetalizationService {
    static getGroupedDetails(
        details: MobileDetail[] | null
    ): MobileDetailGroup | null {
        if (!details) {
            return null
        }

        const items: MobileDetailGroup = {}

        for (const detail of details) {
            if (!detail || !detail.service_date) {
                continue
            }

            const date = detail.service_date.split(' ')[0]

            if (items[date]) {
                items[date].push(detail)
            } else {
                items[date] = [detail]
            }
        }

        return items
    }

    static pushRedirectDetalizationMobile(type: string): void {
        const detalizationStore = useDetalizationStore()
        detalizationStore.setServiceType(type)
    }

    static pushRedirectDetalizationWithPhone(phoneNumb: string): void {
        const serviceStore = useServicesStore()
        const detalizationStore = useDetalizationStore()

        detalizationStore.setPhoneNumber(phoneNumb)
        serviceStore.setServices()
    }
}
