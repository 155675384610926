import { ref, type Ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import type { AxiosError } from 'axios'

import {
    SapaClientErrorCode,
    type PostSapaBlankRequest,
    type PostSapaOrderRequest,
    type RequestForSignDoc,
    type SapaAccount,
    type SapaOrderType,
    type SapaRouterInfo,
    type SegmentCustomerAccount
} from './types'
import { SapaApi } from './api'
import { SapaService } from './service'
import { useCustomerStore } from '@/shared/modules/customer/store'
import { CustomerService } from '@/shared/modules/customer/customerService'
import { PersistanceLocalStorage } from '@/helpers/persistanceStorage'
import { useMetric } from '@/shared/hooks/useMetric'
import { YandexCounters } from '@/shared/services/metric/enum'
import { SentryService } from '@/shared/services/sentry/sentry-service'

export const useSapaStore = defineStore('sapa', () => {
    const { sendMetric } = useMetric()
    const { t } = useI18n()
    const customerStore = useCustomerStore()
    const sapaEnabled: Ref<boolean> = ref(false)
    const accounts: Ref<SapaAccount[] | null> = ref(null)
    const selectedAccount: Ref<SapaAccount | null> = ref(null)
    const router = useRouter()
    const isLoading: Ref<boolean> = ref(false)
    const requestForSignDoc: Ref<RequestForSignDoc> = ref({})
    const tempRequestId: Ref<string> = ref('')
    const tempOrderType: Ref<SapaOrderType | null> = ref(null)
    const sapaBlankFile: Ref<string> = ref('')
    const routerSerialNumber: Ref<string> = ref('')
    const routerInfo: Ref<SapaRouterInfo | null> = ref(null)
    const generatedOtpConfirmation: Ref<string> = ref('')

    const setGeneratedOtpConfirmation = (value: string): void => {
        generatedOtpConfirmation.value = value
    }

    const resetAfterSapaMegalineSuccess = (): void => {
        generatedOtpConfirmation.value = ''
        routerInfo.value = null
        routerSerialNumber.value = ''
        tempOrderType.value = null
    }

    const setIsLoading = (value: boolean) => {
        isLoading.value = value
    }

    const setTempOrderType = (value: SapaOrderType | null): void => {
        tempOrderType.value = value
    }

    const setRouterInfo = (value: SapaRouterInfo): void => {
        routerInfo.value = value
    }

    const createSapaBlank = async (): Promise<void> => {
        if (!selectedAccount.value?.request_id) {
            console.error('`selectedAccount` doesnt exist')
            SentryService.sendEvent({
                message:
                    'SAPA+ | error with `createSapaBlank` = `selectedAccount` doesnt exist',
                tags: {
                    flow: 'sapa+'
                }
            })
            router.push({ name: 'sapa-error' })

            return
        }

        const orderType = selectedAccount.value?.type

        if (!orderType) {
            console.error('`orderType` is not defined')
            SentryService.sendEvent({
                message:
                    'SAPA+ | error with `createSapaBlank` = `orderType` is not defined',
                tags: {
                    flow: 'sapa+'
                }
            })
            router.push({ name: 'sapa-error' })

            return
        }

        const requestId = selectedAccount.value.request_id
        const payloads: Record<SapaOrderType, PostSapaBlankRequest> = {
            installator: {
                request_id: requestId,
                entity_id:
                    requestForSignDoc.value[
                        String(requestId) as keyof RequestForSignDoc
                    ].entityAuthorizationId,
                mobile_phone: customerStore.getPrimaryMobilePhone
            },
            megaliner: {
                request_id: requestId,
                mobile_phone: customerStore.getPrimaryMobilePhone
            }
        }

        const preparedPayload = payloads[orderType]

        if (!preparedPayload) {
            console.error('`preparedPayload` is not defined')
            SentryService.sendEvent({
                message:
                    'SAPA+ | error with `createSapaBlank` = `preparedPayload` is not defined',
                tags: {
                    flow: 'sapa+'
                },
                extra: {
                    requestId,
                    orderType
                }
            })
            router.push({ name: 'sapa-error' })

            return
        }

        isLoading.value = true

        try {
            const { data } = await SapaApi.postSapaBlank(preparedPayload)

            sapaBlankFile.value = data.data
        } catch (error) {
            console.log('error with `createSapaBlank` method ==> ', error)
            const errorData: any = (error as AxiosError).response?.data

            SentryService.sendEvent({
                message: 'SAPA+ | error with `createSapaBlank`',
                tags: {
                    flow: 'sapa+'
                },
                extra: {
                    request: preparedPayload,
                    response: errorData
                }
            })
            router.push({
                name: 'sapa-error',
                query: {
                    error: SapaClientErrorCode.CreateSapaBlank
                }
            })
        } finally {
            isLoading.value = false
        }
    }

    const saveSapaBlank = async (
        requestId: number,
        otp: string
    ): Promise<void> => {
        if (!requestId) {
            console.error('`requestId` doesnt exist')
            return
        }

        const orderType = selectedAccount.value?.type

        if (!orderType) {
            console.error('`orderType` is not defined')
            SentryService.sendEvent({
                message:
                    'SAPA+ | error with `saveSapaBlank` = `orderType` is not defined',
                tags: {
                    flow: 'sapa+'
                }
            })

            return
        }

        const payloads: Record<SapaOrderType, PostSapaBlankRequest> = {
            installator: {
                request_id: requestId,
                entity_id:
                    requestForSignDoc.value[
                        String(requestId) as keyof RequestForSignDoc
                    ].entityAuthorizationId,
                otp,
                mobile_phone: customerStore.getPrimaryMobilePhone
            },
            megaliner: {
                request_id: requestId,
                otp,
                mobile_phone: customerStore.getPrimaryMobilePhone
            }
        }

        const preparedPayload = payloads[orderType]

        if (!preparedPayload) {
            console.error('`preparedPayload` is not defined')
            SentryService.sendEvent({
                message:
                    'SAPA+ | error with `saveSapaBlank` = `preparedPayload` is not defined',
                tags: {
                    flow: 'sapa+'
                },
                extra: {
                    requestId,
                    orderType
                }
            })
            router.push({ name: 'sapa-error' })

            return
        }

        try {
            await SapaApi.postSapaBlank(preparedPayload)
        } catch (error) {
            console.log('error with `saveSapaBlank` method ==> ', error)
            const errorData: any = (error as AxiosError).response?.data

            SentryService.sendEvent({
                message: 'SAPA+ | error with `saveSapaBlank`',
                tags: {
                    flow: 'sapa+'
                },
                extra: {
                    request: preparedPayload,
                    response: errorData
                }
            })
        }
    }

    const setRequestIdFromRedirectParams = (requestId: string): void => {
        if (!requestId) {
            console.error('`requestId` doesnt exist')
            SentryService.sendEvent({
                message:
                    'SAPA+ | `requestId` doesnt exist in `setRequestIdFromRedirectParams`',
                tags: {
                    flow: 'sapa+'
                }
            })
            router.push({ name: 'sapa-error' })

            return
        }

        const existedRequestFromStorage =
            PersistanceLocalStorage.getItem('sapaRequest')

        if (existedRequestFromStorage) {
            setRequestForSign(existedRequestFromStorage)
        }

        tempRequestId.value = requestId
    }

    const setCodeVerifyFromRedirectParams = (codeVerify: string): void => {
        if (!tempRequestId.value) {
            console.error('`requestId` doesnt exist')
            SentryService.sendEvent({
                message:
                    'SAPA+ | `requestId` doesnt exist in `setCodeVerifyFromRedirectParams`',
                tags: {
                    flow: 'sapa+'
                }
            })
            router.push({ name: 'sapa-error' })

            return
        }

        if (!codeVerify) {
            console.error('`codeVerify` doesnt exist')
            SentryService.sendEvent({
                message:
                    'SAPA+ | `codeVerify` doesnt exist in `setCodeVerifyFromRedirectParams`',
                tags: {
                    flow: 'sapa+'
                }
            })
            router.push({ name: 'sapa-error' })

            return
        }

        requestForSignDoc.value[
            tempRequestId.value as keyof RequestForSignDoc
        ] = {
            ...requestForSignDoc.value[
                tempRequestId.value as keyof RequestForSignDoc
            ],
            codeVerify
        }

        PersistanceLocalStorage.setItem('sapaRequest', requestForSignDoc.value)
    }

    const setEntityAuthorizationIdFromRedirectParams = (
        entityAuthorizationId: string
    ): void => {
        if (!tempRequestId.value) {
            console.error('`requestId` doesnt exist')
            SentryService.sendEvent({
                message:
                    'SAPA+ | `requestId` doesnt exist in `setEntityAuthorizationIdFromRedirectParams`',
                tags: {
                    flow: 'sapa+'
                }
            })
            router.push({ name: 'sapa-error' })

            return
        }

        if (!entityAuthorizationId) {
            console.error('`entityAuthorizationId` doesnt exist')
            SentryService.sendEvent({
                message:
                    'SAPA+ | `entityAuthorizationId` doesnt exist `setEntityAuthorizationIdFromRedirectParams`',
                tags: {
                    flow: 'sapa+'
                }
            })
            router.push({ name: 'sapa-error' })

            return
        }

        requestForSignDoc.value[
            tempRequestId.value as keyof RequestForSignDoc
        ] = {
            ...requestForSignDoc.value[
                tempRequestId.value as keyof RequestForSignDoc
            ],
            entityAuthorizationId: Number(entityAuthorizationId)
        }

        PersistanceLocalStorage.setItem('sapaRequest', requestForSignDoc.value)
    }

    const setRequestForSign = (data: RequestForSignDoc): void => {
        requestForSignDoc.value = {
            ...data
        }
    }

    const resetRequestForSignDoc = (requestId: string): void => {
        delete requestForSignDoc.value[requestId]

        PersistanceLocalStorage.setItem('sapaRequest', requestForSignDoc.value)
    }

    const resetSelectedAccount = (): void => {
        selectedAccount.value = null
    }

    const resetTempRequestId = (): void => {
        tempRequestId.value = ''
    }

    const resetSapaBlankFile = (): void => {
        sapaBlankFile.value = ''
    }

    const setCustomerAccounts = (
        customerAccounts: SegmentCustomerAccount[]
    ): void => {
        accounts.value = SapaService.parseSegmentAccountsWithAddress(
            customerStore.customerAccounts,
            customerAccounts
        )
    }

    const setSapaEnabled = (state: boolean): void => {
        sapaEnabled.value = state
    }

    const setSelectedAccount = (account: SapaAccount | null): void => {
        selectedAccount.value = account
    }

    const createSapaOrder = async (): Promise<void> => {
        const payload: PostSapaOrderRequest = {
            type: 'install'
        }

        isLoading.value = true

        try {
            const { data } = await SapaApi.postSapaOrder(payload)

            if (data.id) {
                if (accounts.value) {
                    accounts.value = accounts.value.map((account) => {
                        if (
                            selectedAccount.value?.customer_account_local_id ===
                            account.customer_account_local_id
                        ) {
                            return {
                                ...account,
                                request_id: data.id
                            }
                        }

                        return {
                            ...account
                        }
                    })
                }

                isLoading.value = false

                router.push({
                    name: 'sapa-success',
                    query: {
                        title: t('sapa_order_accepted'),
                        subtitle: t('sapa_order_accepted_subtitle')
                    }
                })

                sendMetric(
                    'screen_view_application_accepted',
                    {},
                    YandexCounters.YandexTwo
                )
            }
        } catch (error) {
            console.log('error with `createSapaOrder` ==> ', error)
            isLoading.value = false
            const errorData: any = (error as AxiosError).response?.data

            SentryService.sendEvent({
                message: 'SAPA+ | error with `createSapaOrder`',
                tags: {
                    flow: 'sapa+'
                },
                extra: {
                    request: payload,
                    response: errorData
                }
            })

            const status = Number(
                (error as AxiosError)?.message.split('code')[1]
            )

            switch (status) {
                case 400:
                case 429:
                    errorCodeStatusHandler(errorData.code)
                    break
                default:
                    router.push({
                        name: 'sapa-error'
                    })

                    break
            }
        }
    }

    const errorCodeStatusHandler = (code: number): void => {
        if (code === 6) {
            router.push({
                name: 'sapa-error',
                query: {
                    title: t('sapa_error_order_already_exist')
                }
            })

            return
        }

        if (code === 2) {
            router.push({
                name: 'sapa-error',
                query: {
                    title: t('sapa_error_has_opened_order'),
                    subtitle: t('sapa_error_has_opened_order_subtitle'),
                    metric: 'screen_view_open_orders'
                }
            })

            return
        }

        if (code === 3) {
            router.push({
                name: 'sapa-error',
                query: {
                    title: t('sapa_error_has_opened_order_by_account'),
                    subtitle: t('sapa_error_has_opened_order_subtitle'),
                    metric: 'screen_view_open_orders_account'
                }
            })

            return
        }

        router.push({
            name: 'sapa-error',
            query: {
                error: code
            }
        })
    }

    const getSapaOrderStatus = async (): Promise<void> => {
        if (!selectedAccount.value?.request_id) {
            console.error('`selectedAccount` doesnt exist')
            SentryService.sendEvent({
                message:
                    'SAPA+ | `selectedAccount` doesnt exist in `getSapaOrderStatus`',
                tags: {
                    flow: 'sapa+'
                }
            })
            router.push({ name: 'sapa-error' })

            return
        }

        isLoading.value = true

        try {
            const { data } = await SapaApi.getSapaOrderById(
                selectedAccount.value.request_id
            )

            selectedAccount.value = {
                ...selectedAccount.value,
                status: data.status,
                type: data.type
            }
        } catch (error) {
            console.log('error with `getSapaOrderStatus ==> ', error)
            const errorData: any = (error as AxiosError).response?.data

            SentryService.sendEvent({
                message: 'SAPA+ | error with `getSapaOrderStatus`',
                tags: {
                    flow: 'sapa+'
                },
                extra: {
                    response: errorData
                }
            })
            router.push({
                name: 'sapa-error'
            })
        } finally {
            isLoading.value = false
        }
    }

    const setGlobalCurrentAccount = (accountLocalId: string): void => {
        if (!accountLocalId) {
            console.error('`accountLocalId` doesnt exist')
            SentryService.sendEvent({
                message:
                    'SAPA+ | `accountLocalId` doesnt exist in `setGlobalCurrentAccount`',
                tags: {
                    flow: 'sapa+'
                }
            })
            router.push({ name: 'sapa-error' })

            return
        }

        const customerStore = useCustomerStore()
        const { getMetadata } = storeToRefs(customerStore)

        if (!getMetadata.value?.agreement) {
            return
        }

        const currentAccount = CustomerService.getAccountByID(
            getMetadata.value.agreement,
            Number(accountLocalId)
        )

        customerStore.setCurrentAccount(currentAccount)
    }

    const setRouterSerialNumber = (data: string): void => {
        routerSerialNumber.value = data
    }

    const checkTerminalEquipment = async (): Promise<void> => {
        setIsLoading(true)

        try {
            const { data } = await SapaApi.getSapaTerminalEquipment({
                serial_number: routerSerialNumber.value
            })

            setRouterInfo(data)

            router.push({ name: 'sapa-router-found' })
        } catch (error) {
            console.log('error with `checkTerminalEquipment` method')
            const errorData: any = (error as AxiosError).response?.data

            SentryService.sendEvent({
                message: 'SAPA+ | error with `checkTerminalEquipment`',
                tags: {
                    flow: 'sapa+'
                },
                extra: {
                    response: errorData
                }
            })
            router.push({ name: 'sapa-router-not-found' })
        } finally {
            setIsLoading(false)
        }
    }

    return {
        isLoading,
        sapaEnabled,
        accounts,
        selectedAccount,
        requestForSignDoc,
        sapaBlankFile,
        tempRequestId,
        routerSerialNumber,
        tempOrderType,
        routerInfo,
        generatedOtpConfirmation,
        setCustomerAccounts,
        setSapaEnabled,
        setSelectedAccount,
        createSapaOrder,
        getSapaOrderStatus,
        setGlobalCurrentAccount,
        setRequestIdFromRedirectParams,
        setCodeVerifyFromRedirectParams,
        setEntityAuthorizationIdFromRedirectParams,
        resetRequestForSignDoc,
        createSapaBlank,
        resetSelectedAccount,
        resetTempRequestId,
        resetSapaBlankFile,
        setRequestForSign,
        saveSapaBlank,
        setRouterSerialNumber,
        setIsLoading,
        setTempOrderType,
        setRouterInfo,
        checkTerminalEquipment,
        setGeneratedOtpConfirmation,
        resetAfterSapaMegalineSuccess
    }
})
