import { computed, type ComputedRef, ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import type { AxiosError } from 'axios'

import type {
    MobileDetail,
    MobileDetailsParams,
    MobileDetalization,
    MonthDetails,
    InternetDetalization,
    InternetDevice,
    InternetDetailParams,
    TelephonyDetailParams,
    TelephonyDetalization
} from '@/components/detalization/types'
import { DetalizationApi } from '@/components/detalization/api'
import type { PhonesListItem } from '@/components/services/ServiceList/types'
import { useTranslationsStore } from '@/shared/modules/translations/store'
import { SentryService } from '@/shared/services/sentry/sentry-service'

export const useDetalizationStore = defineStore('detalization', () => {
    const isLoading: Ref<boolean> = ref(false)

    const mobileDetalization: Ref<MobileDetalization[] | null> = ref(null)
    const mobileDetalizationPhone: Ref<string> = ref('')
    const mobileDetails: Ref<MobileDetail[] | null> = ref(null)
    const mobileDetailsPhone: Ref<string> = ref('')

    const internetDevices: Ref<InternetDevice[] | null> = ref(null)
    const internetDetalization: Ref<InternetDetalization[] | null> = ref(null)

    const telephonyDetalization: Ref<TelephonyDetalization[] | null> = ref(null)

    const currentPhone: Ref<PhonesListItem | null> = ref(null)
    const currentDate: Ref<MonthDetails | null> = ref(null)
    const serviceTypeFromRedirect: Ref<string> = ref('')
    const phoneNumber: Ref<string> = ref('')
    const translationsStore = useTranslationsStore()

    const setServiceType = (type: string): void => {
        serviceTypeFromRedirect.value = type
    }

    const setPhoneNumber = (number: string): void => {
        phoneNumber.value = number
    }

    const mobileDetailParams: ComputedRef<MobileDetailsParams> = computed(
        () => {
            return {
                number: currentPhone.value?.phone,
                service_type: currentPhone.value?.service_type,
                language: translationsStore.language,
                ...currentDate.value
            }
        }
    )

    const internetDetailParams: Ref<InternetDetailParams> = ref({})

    const telephonyDetailParams: Ref<TelephonyDetailParams> = ref({})

    const setCurrentDate = (value: MonthDetails | null): void => {
        currentDate.value = value
    }

    const setMobileDetalization = async (
        phone: PhonesListItem | null
    ): Promise<void> => {
        if (!phone) {
            return
        }

        isLoading.value = true
        mobileDetalization.value = null

        try {
            const { data } = await DetalizationApi.getMobileDetalization(phone)

            mobileDetalization.value = data
            mobileDetalizationPhone.value = phone.phone
        } catch (error) {
            console.log('error with `setMobileDetalization` method ==> ', error)
            const errorData: any = (error as AxiosError)?.response?.data

            SentryService.sendEvent({
                message: 'detalization | error with `setMobileDetalization`',
                tags: {
                    flow: 'detalization'
                },
                extra: {
                    request: phone,
                    response: errorData
                }
            })
        } finally {
            isLoading.value = false
        }
    }

    const setMobileDetails = async (): Promise<void> => {
        isLoading.value = true
        mobileDetails.value = null

        const params: MobileDetailsParams = {
            number: currentPhone.value?.phone,
            service_type: currentPhone.value?.service_type,
            language: translationsStore.language,
            ...currentDate.value
        }

        try {
            const { data } = await DetalizationApi.getMobileDetails(params)

            mobileDetails.value = data.data
            mobileDetailsPhone.value = currentPhone.value?.phone || ''
        } catch (error) {
            console.log('error with `setMobileDetails` method ==> ', error)
            const errorData: any = (error as AxiosError)?.response?.data

            SentryService.sendEvent({
                message: 'detalization | error with `setMobileDetails`',
                tags: {
                    flow: 'detalization'
                },
                extra: {
                    request: params,
                    response: errorData
                }
            })
        } finally {
            isLoading.value = false
        }
    }

    const resetMobileDetalization = (): void => {
        mobileDetalization.value = null
    }

    const downloadDetalization = async (
        type: string,
        format: string,
        params: any
    ): Promise<any> => {
        isLoading.value = true

        try {
            const { data } = await DetalizationApi.downloadDetalization(
                type,
                format,
                params
            )

            return data
        } catch (error) {
            console.log('error with `downloadDetalization` method ==> ', error)
            const errorData: any = (error as AxiosError)?.response?.data

            SentryService.sendEvent({
                message: 'detalization | error with `downloadDetalization`',
                tags: {
                    flow: 'detalization'
                },
                extra: {
                    request: { type, format, params },
                    response: errorData
                }
            })

            throw error
        }
    }

    const setLoading = (value: boolean): void => {
        isLoading.value = value
    }

    const sendXlsToMail = async (
        serviceType: string,
        email: string,
        serviceTypeParams: any
    ): Promise<void> => {
        isLoading.value = true

        const params: MobileDetailsParams = {
            ...serviceTypeParams,
            ...{ email }
        }

        try {
            await DetalizationApi.sendXlsToMail(serviceType, params)
        } catch (error) {
            console.log('error with `sendXlsToMail` method ==> ', error)
            const errorData: any = (error as AxiosError)?.response?.data

            SentryService.sendEvent({
                message: 'detalization | error with `sendXlsToMail`',
                tags: {
                    flow: 'detalization'
                },
                extra: {
                    request: serviceTypeParams,
                    response: errorData
                }
            })

            throw error
        } finally {
            isLoading.value = false
        }
    }

    const sendPdfToMail = async (
        serviceType: string,
        email: string,
        serviceTypeParams: any
    ): Promise<void> => {
        isLoading.value = true

        const params: MobileDetailsParams = {
            ...serviceTypeParams,
            ...{ email }
        }

        try {
            await DetalizationApi.sendPdfToMail(serviceType, params)
        } catch (error) {
            console.log('error with `sendPdfToMail` method ==> ', error)
            const errorData: any = (error as AxiosError)?.response?.data

            SentryService.sendEvent({
                message: 'detalization | error with `sendPdfToMail`',
                tags: {
                    flow: 'detalization'
                },
                extra: {
                    request: serviceTypeParams,
                    response: errorData
                }
            })

            throw error
        } finally {
            isLoading.value = false
        }
    }

    const setInternetDetalization = async (): Promise<void> => {
        isLoading.value = true

        try {
            const { data } = await DetalizationApi.getInternetDetalization(
                internetDetailParams.value
            )
            internetDetalization.value = data.data
        } catch (error) {
            console.log(
                'error with `setInternetDetalization` method ==> ',
                error
            )
            const errorData: any = (error as AxiosError)?.response?.data

            SentryService.sendEvent({
                message: 'detalization | error with `setInternetDetalization`',
                tags: {
                    flow: 'detalization'
                },
                extra: {
                    request: internetDetailParams.value,
                    response: errorData
                }
            })
        } finally {
            isLoading.value = false
        }
    }

    const setInternetDevices = async (): Promise<void> => {
        if (internetDevices.value?.length) {
            return
        }

        isLoading.value = true

        try {
            const { data } = await DetalizationApi.getInternetDevices()
            internetDevices.value = data
        } catch (error) {
            console.log('error with `setInternetDevices` method ==> ', error)
            const errorData: any = (error as AxiosError)?.response?.data

            SentryService.sendEvent({
                message: 'detalization | error with `setInternetDevices`',
                tags: {
                    flow: 'detalization'
                },
                extra: {
                    response: errorData
                }
            })
        } finally {
            isLoading.value = false
        }
    }

    const resetInternetDevices = (): void => {
        internetDevices.value = null
    }

    const setTelephonyDetalization = async (): Promise<void> => {
        isLoading.value = true

        try {
            const { data } = await DetalizationApi.getTelephonyDetalization(
                telephonyDetailParams.value
            )

            telephonyDetalization.value = data.data
        } catch (error) {
            console.log(
                'error with `setTelephonyDetalization` method ==> ',
                error
            )
            const errorData: any = (error as AxiosError)?.response?.data

            SentryService.sendEvent({
                message: 'detalization | error with `setTelephonyDetalization`',
                tags: {
                    flow: 'detalization'
                },
                extra: {
                    request: telephonyDetailParams.value,
                    response: errorData
                }
            })
        } finally {
            isLoading.value = false
        }
    }

    return {
        isLoading,
        mobileDetalization,
        mobileDetalizationPhone,
        mobileDetails,
        mobileDetailsPhone,
        currentPhone,
        currentDate,
        internetDevices,
        internetDetalization,
        telephonyDetalization,
        mobileDetailParams,
        internetDetailParams,
        telephonyDetailParams,
        setMobileDetalization,
        setMobileDetails,
        resetMobileDetalization,
        downloadDetalization,
        sendXlsToMail,
        sendPdfToMail,
        setInternetDevices,
        resetInternetDevices,
        setInternetDetalization,
        setTelephonyDetalization,
        setLoading,
        setCurrentDate,
        setServiceType,
        serviceTypeFromRedirect,
        phoneNumber,
        setPhoneNumber
    }
})
