import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'

export const useMainStore = defineStore('main-page', () => {
    const isAskAuthModal: Ref<boolean> = ref(false)

    const openAskAuthModal = () => {
        isAskAuthModal.value = true
    }

    const closeAskAuthModal = () => {
        isAskAuthModal.value = false
    }

    return {
        isAskAuthModal,
        openAskAuthModal,
        closeAskAuthModal
    }
})
