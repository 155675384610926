<template>
    <Wrapper>
        <ReregistrationDoc />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent, onMounted } from 'vue'

import Preloader from '@/shared/components/UI/Preloader.vue'
import PageError from '@/shared/components/UI/PageError.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import { AituService } from '@/shared/services/aitu/aitu-service'
import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useHeaderIcon } from '@/shared/hooks/useHeaderIcon'

const { setTitle } = useTitle()
const { disableHeaderIcon } = useHeaderIcon()

setTitle('service_transfer_doc')

const ReregistrationDoc = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import(
            '@/components/re-registration/Reregistration/components/ReregistrationDoc.vue'
        )
})

onMounted(() => {
    AituService.disableBackArrow()

    disableHeaderIcon()
})
</script>

<style scoped lang="scss"></style>
