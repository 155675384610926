<template>
    <template v-if="!bonusesStore.isError">
        <h2 v-if="bonusesStore.isActive" class="section-title">
            {{ $t('bonuses') }}
        </h2>

        <Card class="bonuses-card" @click="handleClickBonuses">
            <TSpin v-if="bonusesStore.isLoading" color="black" />

            <TSpace v-else>
                <img
                    class="bonus-logo"
                    src="@/assets/images/currency-bonuses-large.svg"
                    alt="bonuses"
                />

                <TSpace
                    justify="space-between"
                    align="center"
                    style="width: 100%"
                >
                    <TSpace vertical y-gap="4">
                        <TSpace x-gap="4" align="center">
                            <strong>
                                {{
                                    bonusesStore.isActive
                                        ? bonusesStore.balance
                                        : $t('bonuses')
                                }}
                            </strong>

                            <img
                                v-if="bonusesStore.isActive"
                                src="@/assets/images/currency-bonuses.svg"
                                alt="Б"
                            />
                        </TSpace>

                        <small>
                            {{
                                bonusesStore.isActive
                                    ? $t('available')
                                    : $t('loyalty-program')
                            }}
                        </small>
                    </TSpace>

                    <TButton
                        type="gray"
                        rounded
                        no-padding
                        style="padding: 4px"
                    >
                        <TIcon stroke="#5685FE">
                            <ArrowNarrowRightIcon />
                        </TIcon>
                    </TButton>
                </TSpace>
            </TSpace>
        </Card>

        <ActivateBonusesModal
            v-if="showActivateBonusesModal"
            :show="showActivateBonusesModal"
            @continue="handleSuccessActivateBonuses"
            @cancel="cancelActivateBonuses"
        />

        <FreeTVPlusModal
            :show="showFreeTvPlusModal"
            :days-left="freeTvPlusDaysLeft"
            @cancel="cancelFreeTvPlus"
        />
    </template>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, type Ref } from 'vue'
import { useRouter } from 'vue-router'
import {
    ArrowNarrowRightIcon,
    TButton,
    TIcon,
    TSpace,
    TSpin
} from '@telecom/t-components'

import ActivateBonusesModal from './ActivateBonusesModal.vue'
import FreeTVPlusModal from './FreeTvPlus/FreeTvPlusModal.vue'
import Card from '@/shared/components/Card/Card.vue'

import { useBonusesStore } from '@/shared/modules/bonuses/store'
import { useCustomerStore } from '@/shared/modules/customer/store'

const router = useRouter()

const bonusesStore = useBonusesStore()
const customerStore = useCustomerStore()

const showActivateBonusesModal: Ref<boolean> = ref(false)
const showFreeTvPlusModal: Ref<boolean> = ref(false)

// TO-DO: remove hardcode in future
const freeTvPlusStartDate: number = new Date('2024-11-11').getTime()

const customerCreatedAt: number = new Date(
    customerStore.customer.aitu_customer_created_at
        ? customerStore.customer.aitu_customer_created_at.split('T')[0]
        : 0
).getTime()

const freeTvPlusEndDate: number = new Date(
    new Date(customerCreatedAt).setDate(
        new Date(customerCreatedAt).getDate() + 7
    )
).getTime()

const oneDay: number = 24 * 60 * 60 * 1000
const freeTvPlusDaysLeft: number = Math.ceil(
    (new Date(freeTvPlusEndDate).getTime() -
        new Date(new Date().setHours(23, 59, 59)).getTime()) /
        oneDay
)

onBeforeMount(() => {
    if (
        freeTvPlusStartDate <= customerCreatedAt &&
        customerCreatedAt <= freeTvPlusEndDate &&
        freeTvPlusDaysLeft > 0
    ) {
        bonusesStore.toggleMiniBanner()
        showFreeTvPlusModal.value = true
    }

    bonusesStore.getCustomerBonuses().then(() => {
        if (!bonusesStore.isActive && !showFreeTvPlusModal.value) {
            bonusesStore.toggleMiniBanner()
            showActivateBonusesModal.value = true
        }
    })
})

const handleClickBonuses = (): void => {
    if (bonusesStore.isActive) {
        router.push({ name: 'bonuses' })
        return
    }

    showActivateBonusesModal.value = true
}

const handleSuccessActivateBonuses = (): void => {
    router.push({ name: 'bonuses' })
    bonusesStore.toggleMiniBanner()
}

const cancelActivateBonuses = (): void => {
    showActivateBonusesModal.value = false
    bonusesStore.toggleMiniBanner()
}

const cancelFreeTvPlus = (): void => {
    showFreeTvPlusModal.value = false
    bonusesStore.toggleMiniBanner()
}
</script>

<style lang="scss" scoped>
.bonuses-card {
    display: block;
    padding: 16px;
    cursor: pointer;

    .t-spin {
        justify-content: center;
    }

    .bonus-logo {
        width: 48px;
        height: 48px;
    }

    strong {
        font-size: text(p4);
    }

    small {
        color: color(gray);
    }
}
</style>
