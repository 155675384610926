<template>
    <div class="modal">
        <h4 class="modal__title">
            <img :src="modalImg" :alt="title" />
            <span v-html="title"></span>
        </h4>

        <slot name="text">
            <p v-if="text">{{ text }}</p>
        </slot>

        <div class="modal__actions">
            <slot name="actions">
                <TButton full @click="emit('success')">
                    {{ $t('good') }}
                </TButton>
            </slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { TButton } from '@telecom/t-components'
import defaultFailureIcon from '@/assets/images/report-icon-color.svg'

withDefaults(
    defineProps<{
        modalImg?: string
        title?: string
        text?: string
    }>(),
    {
        modalImg: defaultFailureIcon,
        title: 'Ошибка',
        text: ''
    }
)

const emit = defineEmits<{
    (event: 'success'): void
}>()
</script>

<style scoped lang="scss">
.modal {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__title {
        font-weight: 600;
        font-size: text(h6);
        line-height: 125%;
        margin: 0 auto;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 4px;

        img {
            display: block;
            width: 56px;
            height: 56px;
        }
    }

    &:deep(p) {
        color: color(gray);
        line-height: 125%;
        text-align: center;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}
</style>
