import type { AxiosResponse } from 'axios'

import axios from '@/shared/services/api/axios'
import type {
    SingleServiceResponse,
    ServiceFilterResponse,
    ServiceFilterPayload,
    TerminalCheckPayload,
    TerminalCheckResponse,
    TownStatesResponse,
    ReservePayload,
    ReserveResponse
} from '@/components/full-digital/types'

export class FullDigitalApi {
    static getInternetService(): Promise<AxiosResponse<SingleServiceResponse>> {
        return axios.get('/api/v1.0/main/single_services/get_by_slug/internet')
    }

    static getIdNetService(): Promise<AxiosResponse<SingleServiceResponse>> {
        return axios.get('/api/v1.0/main/single_services/get_by_slug/idnet')
    }

    static getWirelessService(): Promise<AxiosResponse<SingleServiceResponse>> {
        return axios.get('/api/v1.0/main/single_services/get_by_slug/wireless')
    }

    static getServiceFilter(
        payload: ServiceFilterPayload
    ): Promise<AxiosResponse<ServiceFilterResponse>> {
        return axios.get('/api/v2.0/open/onboarding/package/filter', {
            params: payload
        })
    }

    static getTerminalCheck(
        payload: TerminalCheckPayload
    ): Promise<AxiosResponse<TerminalCheckResponse>> {
        return axios.get(
            `/api/v1.0/open/terminal/check/${payload.townStateId}`,
            {
                params: payload
            }
        )
    }

    static getTownStates(): Promise<AxiosResponse<TownStatesResponse[]>> {
        return axios.get('/api/v1.0/open/isb/town-states')
    }

    static getReserve(
        data: ReservePayload
    ): Promise<AxiosResponse<ReserveResponse>> {
        return axios.post('/api/v2.0/open/onboarding/reserve', data)
    }
}
