import { computed, type ComputedRef, ref, type Ref } from 'vue'
import { defineStore } from 'pinia'

import type {
    BalanceDetailParams,
    BalanceDetailResponse,
    BalanceDetailMonth
} from '@/components/detalization/DetailsBalance/types'
import { DetailsBalanceApi } from '@/components/detalization/DetailsBalance/api'
import { useCustomerStore } from '@/shared/modules/customer/store'

export const useDetailsBalanceStore = defineStore('detailsBalance', () => {
    const customerStore = useCustomerStore()

    const isLoading: Ref<boolean> = ref(false)

    const balanceDetails: Ref<BalanceDetailResponse | null> = ref(null)

    const nextMonth: Ref<BalanceDetailMonth> = ref({
        name: '',
        declension: ''
    })

    const currentMonth: Ref<BalanceDetailMonth> = ref({
        name: '',
        declension: ''
    })

    const hasAccountsReceivable: ComputedRef<boolean | undefined> = computed(
        () => {
            return (
                balanceDetails.value?.drbDebitor && balanceDetails.value?.isPdz
            )
        }
    )

    const getBalanceDetails = async (): Promise<void> => {
        if (
            !customerStore.getFilialId ||
            !customerStore.getLocalId ||
            !customerStore.getId ||
            typeof customerStore.getCustomerAccountType !== 'number'
        ) {
            return
        }

        isLoading.value = true

        const params: BalanceDetailParams = {
            filialId: customerStore.getFilialId,
            customerAccountId: customerStore.getLocalId,
            customerAccountType: customerStore.getCustomerAccountType,
            accountId: customerStore.getId
        }

        try {
            const { data } = await DetailsBalanceApi.getBalanceDetails(params)

            balanceDetails.value = data.data
        } finally {
            isLoading.value = false
        }
    }

    const setNextMonth = (month: BalanceDetailMonth): void => {
        nextMonth.value = month
    }

    const setCurrentMonth = (month: BalanceDetailMonth): void => {
        currentMonth.value = month
    }

    const getHasOverPayment = (): boolean | undefined => {
        return balanceDetails.value?.hasOverPayment
    }

    return {
        isLoading,
        balanceDetails,
        hasAccountsReceivable,
        nextMonth,
        currentMonth,
        getBalanceDetails,
        setNextMonth,
        setCurrentMonth,
        getHasOverPayment
    }
})
