<template>
    <TSpace v-if="isLoading" class="additional-service-loading">
        <TSpin color="black" />
    </TSpace>

    <TSpace v-else vertical y-gap="12" class="additional-services">
        <div class="additional-services__title">
            {{ $t('additional_services') }}
        </div>

        <!--
        * TODO: После исправления задержки на бекенде - удалить !isHideInfoTvPlus
        /-->
        <TvPlusCard
            v-if="isOffers && !isHideInfoTvPlus"
            @click="onTvPlusClick"
        />

        <TSpace
            v-if="cctvOffers && cctvOffers.length"
            vertical
            class="additional-service"
            @click="$router.push({ name: 'cctv' })"
        >
            <div class="additional-service__head">
                <img
                    src="@/assets/images/card-main-camera.jpg"
                    alt="cctv additional service"
                />
            </div>

            <TSpace
                justify="space-between"
                x-gap="16"
                class="additional-service__body"
            >
                <TSpace vertical y-gap="12" class="additional-service__content">
                    <div class="additional-service__title">
                        {{ $t('additional_services_cc') }}
                    </div>

                    <div class="additional-service__price">
                        {{ $t('additional_services_cc_price') }}
                    </div>
                </TSpace>

                <TSpace align="center" class="additional-service__button">
                    <TButton type="gray" rounded no-padding>
                        <TIcon stroke="#5685FE" width="24" height="24">
                            <ArrowNarrowRightIcon />
                        </TIcon>
                    </TButton>
                </TSpace>
            </TSpace>
        </TSpace>
    </TSpace>
</template>

<script lang="ts" setup>
import { onMounted, computed, type ComputedRef } from 'vue'

import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'

import {
    ArrowNarrowRightIcon,
    TButton,
    TIcon,
    TSpace,
    TSpin
} from '@telecom/t-components'
import TvPlusCard from './TvPlusCard.vue'
import { useOffersStore } from '@/shared/modules/offers/store'
import { useMetric } from '@/shared/hooks/useMetric'

const offersStore = useOffersStore()
/**
 * TODO: После исправления задержки на бекенде - удалить isHideInfoTvPlus
 */
const {
    cctvOffers,
    isLoading,
    tvPlusOffers: offers,
    isHideInfoTvPlus
} = storeToRefs(offersStore)

const router = useRouter()
const { sendMetric } = useMetric()

onMounted(() => {
    offersStore.setOffers()
})

const onTvPlusClick = (): void => {
    router.push({ name: 'tv-plus' })
    sendMetric('HOMEADDSERVICETVPLUS')
}

const isOffers: ComputedRef<boolean> = computed(() =>
    Boolean(offers.value && offers.value.length)
)
</script>

<style scoped lang="scss">
.additional-services {
    &__title {
        font-size: text(p2);
        line-height: 127%;
        font-weight: 600;
    }
}

.additional-service {
    background-color: color(white);
    border-radius: rounded(lg);
    overflow: hidden;
    box-shadow: 0px 12px 32px 0px rgba(42, 70, 94, 0.03);

    &-loading {
        height: 70px;
        margin: auto;
    }

    &__body {
        padding: 16px;
    }

    &__title {
        font-size: text(p4);
        line-height: 128%;
        font-weight: 500;
        color: color(dark);
    }

    &__price {
        font-size: text(p5);
        line-height: 125%;
        letter-spacing: 0.32px;
        color: color(gray);
    }

    &__button {
        .t-button {
            padding: 4px !important;
        }
    }

    &__head {
        height: 160px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
