<template>
    <MainWrapper />
</template>

<script setup lang="ts">
import { onBeforeMount, onUnmounted } from 'vue'

import MainWrapper from '@/components/main/MainWrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import { AituService } from '@/shared/services/aitu/aitu-service'
import { NavigationItemMode } from '@/shared/types/aitu'

const { setTitle } = useTitle()

setTitle('my_telecom')

onBeforeMount(() => {
    initAituMethods()
})

const initAituMethods = async (): Promise<void> => {
    await AituService.enableBackArrow()
    await AituService.showBackArrow(false)
    AituService.setNavigationItemMode(NavigationItemMode.UserProfile)
}

onUnmounted(() => {
    AituService.setNavigationItemMode(NavigationItemMode.SystemBackArrow)
    AituService.showBackArrow()
    AituService.enableBackArrow(false)
})
</script>
