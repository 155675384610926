import amplitude from 'amplitude-js'
import { useYandexMetrika } from 'yandex-metrika-vue3'
import { YandexCounters } from '@/shared/services/metric/enum'

const yandexMetrika = useYandexMetrika()

export const useMetric = () => {
    const isTrackingAllowed = (): boolean => {
        const dnt: string = (navigator.doNotTrack ||
            (window as any).doNotTrack ||
            (navigator as any)['msDoNotTrack']) as string
        const isDntEnabled: boolean = dnt === '1' || dnt === 'yes'

        return !isDntEnabled
    }

    const sendMetric = (
        action: string,
        props: Record<string, any> = {},
        counterId: YandexCounters = YandexCounters.YandexFirst,
        sendToAmplitude = true
    ): void => {
        if (sendToAmplitude) {
            sendAmplitudeMetric(action, props)
        }

        sendYandexMetric(action, counterId, props)
    }

    const sendYandexMetric = (
        action: string,
        counterId: YandexCounters = YandexCounters.YandexFirst,
        props: any = {}
    ): void => {
        if (isTrackingAllowed()) {
            switch (counterId) {
                case YandexCounters.YandexFirst:
                    yandexMetrika.reachGoal(action, props)
                    break
                case YandexCounters.YandexTwo:
                    window.ym!(counterId, 'reachGoal', action, props)
                    break
                case YandexCounters.YandexThree:
                    window.ym!(counterId, 'reachGoal', action, props)
                    break
                default:
                    console.error('Error: Not YandexCounter')
            }
        }
    }

    const sendAmplitudeMetric = (action: string, props: any = {}): void => {
        const allowedEnv: string[] = ['production', 'staging']

        if (!allowedEnv.includes(import.meta.env.VITE_NODE_ENV)) {
            console.warn('amplitude tracking is disabled')
            return
        }

        if (isTrackingAllowed()) {
            amplitude.getInstance().logEvent(action, props)
        }
    }

    return {
        sendMetric,
        sendYandexMetric,
        sendAmplitudeMetric
    }
}
