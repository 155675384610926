<template>
    <div v-if="isLoading || bonusesStore.isLoading" class="loading">
        <TSpin color="black" />
    </div>

    <div v-else class="modal-payment">
        <h5 class="modal-payment__title text-center">
            {{ $t('payments_payment') }}
        </h5>
        <TSpace vertical y-gap="12">
            <div class="modal-payment__form">
                <InputMoney
                    v-model="amount"
                    :label="$t('payment_amount')"
                    :max="999999999"
                />
                <div v-if="amountValidator" class="amount-validator">
                    {{ $t('payments_error_less_100') }}
                </div>
                <div v-if="autoPaymentEnabled || text" class="info-text">
                    <TIcon stroke="#F1B102" width="20" height="20">
                        <AlertCircleIcon />
                    </TIcon>
                    <p>
                        {{ $t('payments_my_autopayment_info') }}
                    </p>
                </div>
            </div>

            <template v-if="!bonusesStore.isError || !bonusesStore.isActive">
                <hr />

                <TSpace align="center" justify="space-between">
                    <TSpace x-gap="12" align="center">
                        <TSwitch
                            v-model="payWithBonuses"
                            :disabled="
                                bonusesStore.balance === 0 || amount === 0
                            "
                        />

                        <TSpace vertical y-gap="2">
                            <p>{{ $t('bonuses_spend') }}</p>
                            <span class="available-bonuses">
                                {{ $t('accumulated') }}
                                {{ bonusesStore.balance }} Б
                            </span>
                        </TSpace>
                    </TSpace>

                    <span v-if="payWithBonuses" class="spend-bonuses">
                        -{{ totalBonusesWillSpend }} Б
                    </span>
                </TSpace>

                <hr />

                <TSpace justify="space-between">
                    <p>{{ $t('payments_total') }}</p>
                    <p>{{ totalPaymentAmount }} ₸</p>
                </TSpace>
            </template>
        </TSpace>

        <TSpace v-if="hasAccountsReceivable" y-gap="24" vertical>
            <TCheckbox v-model="payWithDebt" @click="onPayWithDebt">
                <span>{{ $t('pay_with_current_payment') }}</span>
            </TCheckbox>

            <div class="modal-payment__actions">
                <TButton
                    full
                    :disabled="amountValidator"
                    @click="onManualPayment"
                >
                    {{ $t('continue') }}
                </TButton>
            </div>
        </TSpace>

        <div v-else class="modal-payment__actions">
            <TButton
                type="gray"
                full
                :disabled="amountValidator"
                @click="onManualPayment"
            >
                {{ $t('payments_pay_manually') }}
            </TButton>
            <TButton full @click="onAutoPayment">
                {{
                    $t(
                        autoPaymentEnabled
                            ? 'payments_my_autopayment'
                            : 'auto_payment_create'
                    )
                }}
            </TButton>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    TButton,
    TIcon,
    AlertCircleIcon,
    TSpin,
    TCheckbox,
    TSpace,
    TSwitch,
    useToast
} from '@telecom/t-components'
import { computed, type ComputedRef, onMounted, onUnmounted, ref, type Ref } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

import { useAutoPaymentStore } from '@/components/payments/AutoPayment/store'
import { useDetailsBalanceStore } from '@/components/detalization/DetailsBalance/store'
import { useKTPay } from '@/shared/hooks/useKTPay'
import type { OrderParams } from '@/shared/modules/kt-pay/types'
import { useCustomerStore } from '@/shared/modules/customer/store'
import { useMetric } from '@/shared/hooks/useMetric'
import { YandexCounters } from '@/shared/services/metric/enum'
import { AppRedirectUrls } from '@/components/auth/types'
import { useTranslationsStore } from '@/shared/modules/translations/store'
import InputMoney from '@/shared/components/UI/InputMoney.vue'
import { useBonusesStore } from '@/shared/modules/bonuses/store'

const props = defineProps<{
    amountSum?: number
    text?: boolean
    debtCheckbox?: boolean
}>()

const translationsStore = useTranslationsStore()
const detailsBalanceStore = useDetailsBalanceStore()
const autoPaymentStore = useAutoPaymentStore()
const bonusesStore = useBonusesStore()
const customerStore = useCustomerStore()
const { currentAccount } = storeToRefs(customerStore)
const { createOrder, openWidget } = useKTPay()
const { balanceDetails, hasAccountsReceivable } =
    storeToRefs(detailsBalanceStore)
const { sendMetric } = useMetric()
const { t } = useI18n()

const amount: Ref<number> = ref(0)
const minAmount: Ref<number> = ref(100)
const router = useRouter()
const isLoading: Ref<boolean> = ref(false)
const payWithDebt: Ref<boolean> = ref(false)
const payWithBonuses: Ref<boolean> = ref(false)

const handlers = {
    setAmountFromProps: () => {
        amount.value = Number(props.amountSum)
        payWithDebt.value = props.debtCheckbox
    },
    setAmount: () => {
        amount.value = hasAccountsReceivable.value
            ? balanceDetails.value?.pdz ?? 0
            : balanceDetails.value?.debt ?? 0
    }
}

onMounted(async (): Promise<void> => {
    if (props.amountSum) {
        handlers.setAmountFromProps()
    } else {
        handlers.setAmount()
    }

    await getInfoAutoPayment()

    sendMetric('WINDOWPAYMENT')
})

const autoPaymentEnabled: ComputedRef<boolean> = computed(
    () => autoPaymentStore.isEnabled
)

const amountValidator: ComputedRef<boolean> = computed(
    () =>
        totalPaymentAmount.value < minAmount.value &&
        (payWithBonuses.value ? totalPaymentAmount.value !== 0 : true)
)

const totalBonusesWillSpend: ComputedRef<number> = computed(() => {
    if (bonusesStore.balance >= amount.value) {
        return amount.value
    }

    if (amount.value - bonusesStore.balance < minAmount.value) {
        return amount.value - minAmount.value
    }

    return bonusesStore.balance
})

const totalPaymentAmount: ComputedRef<number> = computed(() => {
    if (!payWithBonuses.value) {
        return amount.value
    }

    const totalAmount =
        Math.round(
            (amount.value - totalBonusesWillSpend.value + Number.EPSILON) * 100
        ) / 100

    if (totalAmount === 0) {
        return totalAmount
    }

    return totalAmount < minAmount.value ? minAmount.value : totalAmount
})

const getInfoAutoPayment = async (): Promise<void> => {
    const obj = autoPaymentStore.autoPayment

    if (
        typeof obj === 'object' &&
        obj !== null &&
        Object.keys(obj).length > 0
    ) {
        return
    }

    isLoading.value = true

    await autoPaymentStore.getInfo()
    await autoPaymentStore.getCardList()

    isLoading.value = false
}

const onAutoPayment = (): void => {
    if (autoPaymentEnabled.value) {
        router.push({ name: 'auto-payment' })
    } else {
        router.push({ name: 'auto-payment.create' })
        sendMetric('CLICKBUTTONCREATEAUTOPAY', {}, YandexCounters.YandexTwo)
    }
}

const onManualPayment = async (): Promise<void> => {
    isLoading.value = true

    if (payWithBonuses.value) {
        try {
            await bonusesStore.addBalance({
                amount: totalBonusesWillSpend.value,
                addition: false,
                branchId: currentAccount.value!.filial_id,
                customerAccountId: currentAccount.value!.id,
                localId: currentAccount.value!.local_id
            })
        } catch (e) {
            useToast({
                message: t('service_unavailable'),
                type: 'error',
                duration: 3000,
                loading: false,
                closable: false,
                closeText: '',
                onClose: () => {}
            })
            isLoading.value = false
            return
        }
    }

    if (totalPaymentAmount.value) {
        const params: OrderParams = {
            order_type: 'guid',
            abonent_id: currentAccount.value?.local_id,
            amount: totalPaymentAmount.value,
            server_id: currentAccount.value?.filial_id,
            description: 'Transaction from KTPAY'
        }

        await createOrder(params)
        const successLink = `${window.location.origin}?redirect=${AppRedirectUrls.PaymentKtPaySuccess}&lang=${translationsStore.language}`
        const failLink = `${window.location.origin}?redirect=${AppRedirectUrls.PaymentKtPayFailure}&lang=${translationsStore.language}`
        await openWidget(successLink, failLink)
    } else {
        router.push({ name: AppRedirectUrls.PaymentKtPaySuccess })
    }

    isLoading.value = false

    if (hasAccountsReceivable.value) {
        if (payWithDebt.value) {
            sendMetric('DEBTCONTINUEWITHTICK', {}, YandexCounters.YandexTwo)
        } else {
            sendMetric('DEBTCONTINUE', {}, YandexCounters.YandexTwo)
        }
    } else {
        sendMetric('TAPPAY')
    }
}

const onPayWithDebt = (): void => {
    if (payWithDebt.value) {
        amount.value = Number(balanceDetails.value?.sumOfDebt)
    } else {
        amount.value = Number(balanceDetails.value?.pdz)
    }
}

onUnmounted(() => {
    bonusesStore.setStatusMiniBanner(true)
})
</script>

<style scoped lang="scss">
.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-payment {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &__title {
        font-weight: 600;
        font-size: text(h5);
        line-height: 125%;
    }

    hr {
        margin: 0;
        height: 1px;
        background: color(divider);
        border: none;
    }

    &__form {
        .info-text {
            margin-top: 4px;
            display: flex;
            align-items: flex-start;
            gap: 8px;
            color: color(gray-light);
            line-height: 125%;
        }
    }

    p {
        font-size: text(p5);
        font-weight: 500;
        line-height: 20px;
    }

    .available-bonuses {
        color: color(gray);
        font-size: text(p6);
        font-weight: 400;
    }

    .spend-bonuses {
        color: color(green);
        font-size: text(p5);
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        @include media(min, md) {
            flex-direction: row;
        }
    }

    .amount-validator {
        color: color(error);
        padding-top: 4px;
    }
}
</style>
