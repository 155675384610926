import type { AxiosResponse } from 'axios'

import type {
    AgreementCheckPayload,
    AgreementCheckResponse,
    SearchByIinParams,
    SearchByIinAndMobileResponse,
    SearchByMobilePhoneParams,
    SearchByLocalIdParams,
    SearchByLocalIdResponse,
    CheckProductsParams,
    CheckProductsResponse,
    AccDebtCheckResponse,
    AccDebtCheckParams,
    ConnectedServicesParams,
    ConnectedServicesResponse,
    GbdflAddressesParams,
    GetAddressParams,
    GetAddressResponse,
    AddressesMatchParams,
    AddressesMatchResponse,
    GbdflAddressesResponse,
    ChangeBillAccountOwnerParams,
    GetBillAccountOwnerParams,
    ChangeBillAccountOwnerResponse,
    GetBillAccountOwnerResponse,
    MakeReregistrationRequestParams,
    MakeReregistrationRequestResponse,
    GetApplicationsResponse,
    MakeRequestReissueParams,
    MakeRequestReissueResponse,
    GenerateReissuePdfParams,
    GenerateReissueDocumentParams,
    CompleteExReregistrationResponse
} from './types'
import axios from '@/shared/services/api/axios'

export class ReregistrationApi {
    static makeReregistrationRequest(
        params: MakeReregistrationRequestParams
    ): Promise<AxiosResponse<MakeReregistrationRequestResponse>> {
        return axios.post('/api/v2.0/customer/reissue/make-request', params)
    }

    static checkForSeveralAgreements(
        params: AgreementCheckPayload
    ): Promise<AxiosResponse<AgreementCheckResponse>> {
        return axios.get('/api/v2.0/customer/reissue/agreement-check', {
            params
        })
    }

    static searchByIin(
        params: SearchByIinParams
    ): Promise<AxiosResponse<SearchByIinAndMobileResponse>> {
        return axios.get(
            '/api/v2.0/customer/reissue/search-by/iin-or-mobile-phone',
            {
                params
            }
        )
    }

    static searchByMobilePhone(
        params: SearchByMobilePhoneParams
    ): Promise<AxiosResponse<SearchByIinAndMobileResponse>> {
        return axios.get(
            '/api/v2.0/customer/reissue/search-by/iin-or-mobile-phone',
            {
                params
            }
        )
    }

    static searchByLocalID(
        params: SearchByLocalIdParams
    ): Promise<AxiosResponse<SearchByLocalIdResponse>> {
        return axios.get('/api/v2.0/customer/reissue/search-by/local-id', {
            params
        })
    }

    static productsCheck(
        params: CheckProductsParams
    ): Promise<AxiosResponse<CheckProductsResponse>> {
        return axios.get('/api/v2.0/customer/reissue/products-check', {
            params
        })
    }

    static accDebtCheck(
        params: AccDebtCheckParams
    ): Promise<AxiosResponse<AccDebtCheckResponse>> {
        return axios.get('/api/v2.0/customer/reissue/debt-status-with-sum', {
            params
        })
    }

    static getConnectedServices(
        params: ConnectedServicesParams
    ): Promise<AxiosResponse<ConnectedServicesResponse[]>> {
        return axios.get('/api/v2.0/customer/reissue/connected-services', {
            params
        })
    }

    static getExConnectedServices(
        params: MakeRequestReissueParams
    ): Promise<AxiosResponse<ConnectedServicesResponse[]>> {
        return axios.get('/api/v2.0/customer/reissue/ex-connected-services', {
            params
        })
    }

    static getConnectedProducts(
        params: ConnectedServicesParams
    ): Promise<AxiosResponse<ConnectedServicesResponse[]>> {
        return axios.get('/api/v1.0/customer/connected-products', {
            params
        })
    }

    static getAddressesGbdfl(
        params: GbdflAddressesParams
    ): Promise<AxiosResponse<GbdflAddressesResponse>> {
        return axios.get('/api/v2.0/customer/reissue/apartments', {
            params
        })
    }

    static getAddress(
        params: GetAddressParams
    ): Promise<AxiosResponse<GetAddressResponse>> {
        return axios.get('/api/v2.0/customer/reissue/apartment', {
            params
        })
    }

    static addressesMatch(
        params: AddressesMatchParams
    ): Promise<AxiosResponse<AddressesMatchResponse>> {
        return axios.get('/api/v2.0/customer/reissue/address-matching', {
            params
        })
    }

    static contractAvailabilityCheck(payload: {
        request_id: number
    }): Promise<AxiosResponse<{ contractNumber: number }>> {
        return axios.post(
            '/api/v2.0/customer/reissue/check-availability-contract',
            payload
        )
    }

    static changeBillAccountOwner(
        payload: ChangeBillAccountOwnerParams
    ): Promise<AxiosResponse<ChangeBillAccountOwnerResponse>> {
        return axios.post(
            '/api/v2.0/customer/reissue/change-bill-account-owner-request',
            payload
        )
    }

    static getBillAccountOwner(
        params: GetBillAccountOwnerParams
    ): Promise<AxiosResponse<GetBillAccountOwnerResponse>> {
        return axios.get(
            '/api/v2.0/customer/reissue/change-bill-account-owner-request',
            {
                params
            }
        )
    }

    static getApplications(params: {
        filter: number
    }): Promise<AxiosResponse<GetApplicationsResponse>> {
        return axios.get('/api/v2.0/customer/reissue/check-statements', {
            params
        })
    }

    static makeRequestReissue(
        params: MakeRequestReissueParams
    ): Promise<AxiosResponse<MakeRequestReissueResponse>> {
        return axios.post('/api/v2.0/customer/reissue/request-reissue', params)
    }

    static rejectReissue(
        params: MakeRequestReissueParams
    ): Promise<AxiosResponse<MakeRequestReissueResponse>> {
        return axios.post('/api/v2.0/customer/reissue/reject-reissue', params)
    }

    static generateReissuePdf(
        params: GenerateReissuePdfParams
    ): Promise<AxiosResponse<MakeRequestReissueResponse>> {
        return axios.post('/api/v2.0/customer/reissue/complete-consent', params)
    }

    static generateReissueDocument(
        params: GenerateReissueDocumentParams
    ): Promise<AxiosResponse<MakeRequestReissueResponse>> {
        return axios.post('/api/v2.0/customer/reissue/document', params)
    }

    static completeExReregistration(
        params: CompleteExReregistrationResponse
    ): Promise<AxiosResponse> {
        return axios.post(
            '/api/v2.0/customer/reissue/ex-complete-reregistration',
            params
        )
    }

    static cancelReissue(
        params: MakeRequestReissueParams
    ): Promise<MakeRequestReissueResponse> {
        return axios.post('/api/v2.0/customer/reissue/cancel-reissue', params)
    }
}
