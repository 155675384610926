import type { AxiosResponse } from 'axios'

import type {
    GetSapaOrderResponse,
    GetSapaTerminalEquipmentQueryParams,
    GetSapaTerminalEquipmentResponse,
    GetSegmentByCustomerResponse,
    PostSapaBlankRequest,
    PostSapaBlankResponse,
    PostSapaOrderAcceptRequest,
    PostSapaOrderAcceptResponse,
    PostSapaOrderRequest,
    PostSapaOrderResponse,
    PostSapaRatingRequest,
    PostSapaRatingResponse
} from './types'
import axios from '@/shared/services/api/axios'

export class SapaApi {
    static getSegmentByCustomer(): Promise<
        AxiosResponse<GetSegmentByCustomerResponse>
    > {
        return axios.get('/api/v1.0/customer/sapa/segment-by-customer')
    }

    static getSapaTerminalEquipment(
        query: GetSapaTerminalEquipmentQueryParams
    ): Promise<AxiosResponse<GetSapaTerminalEquipmentResponse>> {
        return axios.get('/api/v1.0/customer/sapa/equipment/search', {
            params: {
                ...query
            }
        })
    }

    static getSapaOrderById(
        id: number
    ): Promise<AxiosResponse<GetSapaOrderResponse>> {
        return axios.get(`/api/v1.0/customer/sapa/requests/${id}`)
    }

    static postSapaOrder(
        payload: PostSapaOrderRequest
    ): Promise<AxiosResponse<PostSapaOrderResponse>> {
        return axios.post('/api/v1.0/customer/sapa/requests/create', payload)
    }

    static postSapaBlank(
        payload: PostSapaBlankRequest
    ): Promise<AxiosResponse<PostSapaBlankResponse>> {
        return axios.post('/api/v1.0/customer/sapa/blank/create', payload)
    }

    static postSapaOrderAccept(
        requestId: number,
        payload: PostSapaOrderAcceptRequest
    ): Promise<AxiosResponse<PostSapaOrderAcceptResponse>> {
        return axios.post(
            `/api/v1.0/customer/sapa/requests/${requestId}/accept`,
            payload
        )
    }

    static postSapaRating(
        payload: PostSapaRatingRequest
    ): Promise<AxiosResponse<PostSapaRatingResponse>> {
        return axios.post('/api/v1.0/customer/sapa/ratings', payload)
    }
}
