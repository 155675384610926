<template>
    <ServicesListWrapper />
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'

import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const ServicesListWrapper = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import('@/components/services/ServiceList/ServicesListWrapper.vue')
})

const { setTitle } = useTitle()

setTitle('services')
</script>

<style scoped lang="scss"></style>
