<template>
    <div class="layout">
        <div v-if="isAppClosed" class="desktop-alert">
            <h1 class="section-title">Откройте мобильное приложение</h1>
        </div>
        <div v-else :class="['content', layout]">
            <Container>
                <TSpin :show="isLoading" background-color="#fff" full />

                <Navigation
                    v-if="hasBurger"
                    :is-show-menu="showNavbar"
                    @on-route="toggleMenu"
                />

                <TToastProvider>
                    <RouterView />
                </TToastProvider>
            </Container>

            <AskAuthModal />
        </div>

        <TModal v-model="isNeededUpdateIos" :close-icon="false">
            <ModalUpdateIosApp @update="updateAppLink" />
        </TModal>
    </div>
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router'
import {
    computed,
    type ComputedRef,
    onBeforeMount,
    onMounted,
    ref,
    type Ref
} from 'vue'

import { TToastProvider, TSpin, TModal } from '@telecom/t-components'
import { Utils } from './helpers/utils'
import ModalUpdateIosApp from './shared/components/Modal/ModalUpdateIosApp.vue'
import AskAuthModal from './components/auth/components/modals/AskAuthModal.vue'
import { AituService } from './shared/services/aitu/aitu-service'
import { useAuth } from './components/auth/hooks/useAuth'
import { useLayout } from './shared/hooks/useLayout'
import { useNavbar } from '@/shared/hooks/useNavbar'
import { defineAxiosDefaults } from '@/shared/services/api/axios'
import { defineGatewayAxiosDefaults } from '@/shared/services/api/gateway'
import { defineBonusesAxiosDefaults } from '@/shared/services/api/bonuses'
import Container from '@/shared/components/Layout/Container.vue'
import Navigation from '@/shared/components/Layout/Navigation.vue'

const { updateToken, isLoading } = useAuth()
const { layout } = useLayout()
const { showNavbar, hasBurger, toggleMenu } = useNavbar()
const isAppClosed: Ref<boolean> = ref(false)
const isNeededUpdateIos: ComputedRef<boolean> = computed(() => {
    return (
        Utils.compareVersions(Utils.getIosVersion(), '1.127.0') === -1 ||
        Utils.compareVersions(Utils.getIosVersion(), '1.127.0') === 0
    )
})

const updateAppLink = (): void => {
    AituService.openExternalUrl('https://onelink.to/9rewvx')
}

onBeforeMount(() => {
    if (!AituService.isInApp()) {
        isAppClosed.value = true

        return
    }

    AituService.enableNotifications()

    defineAxiosDefaults()
    defineGatewayAxiosDefaults()
    defineBonusesAxiosDefaults()
    updateToken(true)
})

onMounted(() => {
    // addHeadScript()
})

const addHeadScript = (): void => {
    const script = document.createElement('script')
    script.innerHTML = `(function(I,n,f,o,b,i,p){
            I[b]=I[b]||function(){(I[b].q=I[b].q||[]).push(arguments)};
            I[b].t=1*new Date();i=n.createElement(f);i.async=1;i.src=o;
            p=n.getElementsByTagName(f)[0];p.parentNode.insertBefore(i,p)})
            (window,document,'script','https://livechat.infobip.com/widget.js','liveChat');
            liveChat('init', '034167b5-2303-4d73-ab92-ad0050dba396');
            `

    document.head.appendChild(script)
}
</script>

<style lang="scss"></style>
