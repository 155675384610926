<template>
    <Wrapper>
        <TechNotAvailable />
    </Wrapper>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const TechNotAvailable = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import('@/components/services/ServiceTransfer/modals/TechNotAvailable.vue')
})
</script>

<style scoped lang="scss"></style>
