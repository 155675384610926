import type {
    Service,
    Package,
    PhonesListItem
} from '@/components/services/ServiceList/types'

export class ServicesListService {
    static getParsedPhones(data: Service[] | null): PhonesListItem[] {
        if (!data) {
            return []
        }

        const phones: PhonesListItem[] = []
        const operators: number[] = [2000097, 2000170]

        data.forEach((service) => {
            service.PRODUCTS.filter((product: Package) =>
                operators.includes(product.PRODUCT_ID)
            ).forEach((product) =>
                phones.push({
                    phone: product.PRODUCT_NUM,
                    service_type: product.SERVICE_TYPE
                })
            )
        })

        return phones
    }

    static getParsedHomePhones(data: Service[] | null): string[] {
        if (!data) {
            return []
        }

        const phones: string[] = []
        const operators: number[] = [2000000, 2000018]

        data.forEach((service) => {
            service.PRODUCTS.filter((product: Package) =>
                operators.includes(product.PRODUCT_ID)
            ).forEach((product) => {
                if (
                    product.PHONE_NUMBER &&
                    !phones.includes(product.PHONE_NUMBER)
                ) {
                    phones.push(product.PHONE_NUMBER)
                }
            })
        })

        return phones
    }

    static getMainServices(data: Service[] | null): Service[] {
        if (!data) {
            return []
        }

        const notAcceptableTypes: string[] = ['VAS', 'RENT', 'CC_TV']

        return data.filter(
            (service: Service) =>
                ((service.TYPE === 'base' &&
                    !notAcceptableTypes.includes(service.SERVICE_TYPE)) ||
                    service.TYPE === 'package') &&
                service.STATUS === 'connected'
        )
    }

    static getRentServices(data: Service[] | null): Service[] {
        if (!data) {
            return []
        }

        return data.filter(
            (service) =>
                service.TYPE === 'base' &&
                service.SERVICE_TYPE === 'RENT' &&
                service.STATUS === 'connected'
        )
    }

    static getAdditionalServices(data: Service[] | null): Service[] {
        if (!data) {
            return []
        }

        const serviceTypes: string[] = ['VAS', 'CC_TV']

        return data.filter(
            (service) =>
                service.TYPE === 'base' &&
                serviceTypes.includes(service.SERVICE_TYPE) &&
                service.STATUS === 'connected'
        )
    }

    static getFormattedServicePrice(price: string | undefined): string {
        if (!price) {
            return ''
        }

        return price.split(',')[0]
    }

    static getProductsIdFromServices(services: Service[]): number[] {
        return services.flatMap((item) =>
            item.PRODUCTS.map((el) => el.PRODUCT_ID)
        )
    }
}
