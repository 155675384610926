<template>
    <Wrapper>
        <ApplicationSign />

        <FDCloseModal ref="closeModalRef" />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent, onMounted, ref } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'
import PageError from '@/shared/components/UI/PageError.vue'
import { AituService } from '@/shared/services/aitu/aitu-service'
import FDCloseModal from '@/components/full-digital/components/FDCloseModal.vue'
import type { FDCloseModalInterface } from '@/components/full-digital/types'
import { useHeaderClose } from '@/components/full-digital/hooks/useHeaderClose'

const closeModalRef = ref<FDCloseModalInterface | null>(null)
const { enableHeaderClose } = useHeaderClose(closeModalRef)

const ApplicationSign = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/shared/modules/onboarding/ApplicationSign.vue')
})

onMounted((): void => {
    AituService.disableBackArrow()

    enableHeaderClose()
})
</script>

<style scoped lang="scss"></style>
