import type { AxiosResponse } from 'axios'

import type { TranslationsResponse } from './types'
import axios from '@/shared/services/api/axios'

export class TranslationsApi {
    static getList(): Promise<AxiosResponse<TranslationsResponse>> {
        return axios.get('/ru/api/v1.0/open/translations/customer_new')
    }
}
