<template>
    <div class="modal" :class="{ 'no-gap': noGap }">
        <slot name="image"></slot>

        <h4 class="modal__title">
            <span v-html="title"></span>
        </h4>

        <slot name="text">
            <p v-if="text" class="modal__text" v-html="text"></p>
        </slot>

        <slot name="content"></slot>

        <div class="modal__actions">
            <slot name="actions">
                <TButton full @click="emit('success')">
                    {{ $t('good') }}
                </TButton>
            </slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { TButton } from '@telecom/t-components'

withDefaults(
    defineProps<{
        title?: string
        text?: string
        noGap?: boolean
    }>(),
    {
        title: 'Заголовок',
        text: '',
        noGap: false
    }
)

const emit = defineEmits<{
    (event: 'success'): void
}>()
</script>

<style scoped lang="scss">
.modal {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &.no-gap {
        gap: 4px;

        .modal__text {
            margin-bottom: 24px;
        }
    }

    &__title {
        font-weight: 600;
        font-size: text(p2);
        line-height: 125%;
        max-width: 240px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 8px;
    }

    p {
        text-align: center;
        color: color(gray);
        line-height: 125%;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}
</style>
