import type {
    FDServiceModalTabLabels,
    FDTab
} from '@/components/full-digital/types'
import { channelsTv, channelsTvCinema } from '@/components/full-digital/data'
import ServiceContentArray from '@/components/full-digital/components/FDServiceContentArray.vue'
import ServiceContentObject from '@/components/full-digital/components/FDServiceContentObject.vue'

export class FullDigitalService {
    static getMainServiceModalTab(
        serviceId: number,
        itemId: number,
        labels: FDServiceModalTabLabels
    ): FDTab[] {
        const full =
            import.meta.env.VITE_NODE_ENV === 'production'
                ? [407, 408]
                : [411, 412]

        if (full.includes(itemId)) {
            return [
                {
                    id: 'cinema',
                    label: labels.cinemas,
                    component: ServiceContentObject,
                    props: {
                        object: channelsTvCinema[serviceId].cinemaRoom
                    },
                    title: 'online-cinema'
                },
                {
                    id: 'hd',
                    label: labels.hd,
                    component: ServiceContentArray,
                    props: {
                        array: channelsTvCinema[serviceId].hd,
                        hd: true
                    },
                    title: 'egov_list_channels'
                },
                {
                    id: 'tv',
                    label: labels.tv,
                    component: ServiceContentArray,
                    props: {
                        array: channelsTvCinema[serviceId].tv,
                        hd: false
                    },
                    title: 'egov_list_channels'
                }
            ]
        } else {
            return [
                {
                    id: 'hd',
                    label: labels.hd,
                    component: ServiceContentArray,
                    props: {
                        array: channelsTv[serviceId].hd,
                        hd: true
                    },
                    title: 'egov_list_channels'
                },
                {
                    id: 'tv',
                    label: labels.tv,
                    component: ServiceContentArray,
                    props: {
                        array: channelsTv[serviceId].tv,
                        hd: false
                    },
                    title: 'egov_list_channels'
                }
            ]
        }
    }
}
