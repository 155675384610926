import { useSapaStore } from './store'
import type { SapaAccount, SegmentCustomerAccount } from './types'
import type { ParsedCustomerAccount } from '@/shared/modules/customer/types'

export class SapaService {
    static parseSegmentAccountsWithAddress(
        customerAccounts: ParsedCustomerAccount[],
        sapaAccounts: SegmentCustomerAccount[]
    ): SapaAccount[] {
        const parsedAccounts: SapaAccount[] = []

        customerAccounts.forEach((account) => {
            const foundAccount = sapaAccounts.find(
                (item) => item.customer_account_local_id === account.id
            )

            if (foundAccount) {
                parsedAccounts.push({
                    ...foundAccount,
                    address: {
                        city: account.city || '-',
                        street: account.street || '-',
                        house: account.house || '-',
                        flat: account.flat,
                        sub_flat: account.sub_flat
                    }
                })
            }
        })

        return parsedAccounts
    }

    static getSapaAccountByRequestId(
        requestId: number,
        accounts: SapaAccount[]
    ): SapaAccount | undefined {
        return accounts.find((account) => account.request_id === requestId)
    }

    static redirectPushSetRequestId = (requestId: string): void => {
        const sapaStore = useSapaStore()

        sapaStore.setRequestIdFromRedirectParams(requestId)
    }

    static redirectPushSetCodeVerify = (codeVerify: string): void => {
        const sapaStore = useSapaStore()

        sapaStore.setCodeVerifyFromRedirectParams(codeVerify)
    }

    static redirectPushSetEntityAuthorizationId = (
        entityAuthorizationId: string
    ): void => {
        const sapaStore = useSapaStore()

        sapaStore.setEntityAuthorizationIdFromRedirectParams(
            entityAuthorizationId
        )
    }
}
