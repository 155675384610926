import { storeToRefs } from 'pinia'
import { ref, watch, type Ref, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import type { AxiosError } from 'axios'

import { useAppRedirect } from './useAppRedirect'
import { useAuthStore } from '@/components/auth/store'
import { AuthService } from '@/components/auth/authService'
import { useCustomerStore } from '@/shared/modules/customer/store'
import { AituService } from '@/shared/services/aitu/aitu-service'
import { Utils } from '@/helpers/utils'
import { useTranslationsStore } from '@/shared/modules/translations/store'
import type { Language } from '@/shared/modules/translations/types'
import { SentryService } from '@/shared/services/sentry/sentry-service'

export const useAuth = () => {
    const translationsStore = useTranslationsStore()
    const customerStore = useCustomerStore()
    const authStore = useAuthStore()
    const { isAuthenticated, getExpiresAt } = storeToRefs(authStore)
    const interval: Ref<number | undefined> = ref(undefined)
    const refreshInProgress: Ref<boolean> = ref(false)
    const router = useRouter()
    const isLoading: Ref<boolean> = ref(false)
    const { appRedirectHandler, setRedirect } = useAppRedirect()

    onBeforeMount(() => {
        initLanguage()
        setRedirect()

        authStore.setAituPhone()
        authStore.setPincode()
    })

    const initLanguage = () => {
        const language = Utils.getValueByQueryParam('lang')

        if (!language) {
            return
        }

        translationsStore.setLanguage(language as Language)
    }

    watch(
        () => isAuthenticated.value,
        () => {
            if (isAuthenticated.value) {
                interval.value = setInterval(() => {
                    updateToken()
                }, 5000) as unknown as number
            } else {
                clearInterval(interval.value)
            }
        },
        {
            immediate: true
        }
    )

    const loadCustomer = () => {
        customerStore.unsetCustomer()
        customerStore
            .getPortalCustomer()
            .then(() => {
                isLoading.value = false
            })
            .catch((error) => {
                isLoading.value = false

                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message:
                        'AUTH | error with `loadCustomer` method in `useAuth`',
                    tags: {
                        flow: 'auth'
                    },
                    extra: {
                        request: authStore.aituPhone,
                        response: errorData
                    }
                })

                authStore.logout().then(() => {
                    router.push({ name: 'login' })
                })
            })
    }

    const redirectToPincode = () => {
        router.push({
            name: 'pincode-verification',
            query: {
                lang: translationsStore.language
            }
        })
    }

    const updateToken = (isInit = false): void => {
        if (
            isAuthenticated.value &&
            !AuthService.isTokenNeedToBeUpdated(getExpiresAt.value) &&
            !refreshInProgress.value &&
            AituService.isSupported() &&
            isInit
        ) {
            const resolved = appRedirectHandler('redirect')

            if (resolved) {
                return
            }

            redirectToPincode()

            return
        }

        if (
            isAuthenticated.value &&
            AuthService.isTokenNeedToBeUpdated(getExpiresAt.value) &&
            !refreshInProgress.value
        ) {
            refreshInProgress.value = true

            if (isInit) {
                isLoading.value = true

                if (AituService.isSupported()) {
                    const resolved = appRedirectHandler('redirect')

                    if (!resolved) {
                        redirectToPincode()
                    }
                }
            }

            authStore
                .refreshToken()
                .then(() => {
                    refreshInProgress.value = false

                    if (isInit) {
                        if (!AituService.isSupported()) {
                            loadCustomer()

                            return
                        }

                        isLoading.value = false
                    }
                })
                .catch(() => {
                    clearInterval(interval.value)
                    refreshInProgress.value = false
                    isLoading.value = false

                    authStore.logout().then(() => {
                        router.push({ name: 'login' })
                    })
                })
        }
    }

    return {
        refreshInProgress,
        isLoading,
        isAuthenticated,
        updateToken
    }
}
