import { ref, watch, type Ref } from 'vue'
import { useRoute } from 'vue-router'

import { YandexCounters } from '../services/metric/enum'
import { useHeaderIcon } from './useHeaderIcon'
import { HeaderMenuIcon, NavigationItemMode } from '@/shared/types/aitu'
import { AituService } from '@/shared/services/aitu/aitu-service'
import { useMetric } from '@/shared/hooks/useMetric'
import { useAuthStore } from '@/components/auth/store'
import { useAppRedirect } from '@/components/auth/hooks/useAppRedirect'

const { sendMetric } = useMetric()
const showNavbar: Ref<boolean> = ref(false)

export const useNavbar = () => {
    const authStore = useAuthStore()
    const { redirectPushProcessEnabled } = useAppRedirect()
    const route = useRoute()
    const hasBurger: Ref<boolean> = ref(false)

    const toggleMenu = (): void => {
        showNavbar.value = !showNavbar.value

        if (showNavbar.value) {
            metricHandler()
        }
    }

    const { enableHeaderIcon, disableHeaderIcon } = useHeaderIcon(
        'right-navbar',
        HeaderMenuIcon.Menu,
        toggleMenu
    )

    const setMenu = (): void => {
        enableHeaderIcon()

        hasBurger.value = true
    }

    const unsetMenu = (): void => {
        disableHeaderIcon()

        hasBurger.value = false
    }

    const setBackArrow = (): void => {
        AituService.enableBackArrow()
        AituService.showBackArrow()
        AituService.disableSwipeBack()
        AituService.setBackArrowHandler(() => toggleMenu())
    }

    const unsetBackArrow = async (): Promise<void> => {
        if (route.name === 'home') {
            await AituService.enableBackArrow()
            await AituService.showBackArrow(false)
            AituService.setNavigationItemMode(NavigationItemMode.UserProfile)

            return
        }

        AituService.enableBackArrow(false)
    }

    const metricHandler = (): void => {
        if (authStore.isAuthenticated) {
            sendMetric(
                'notautho_main',
                {
                    clicked: 'menu'
                },
                YandexCounters.YandexTwo
            )
        } else {
            sendMetric('NOTAUTHOMENU')
        }
    }

    watch(
        () => route.name,
        () => {
            if (route.query.rPush || redirectPushProcessEnabled.value) {
                return
            }

            if (typeof route.meta.menu === 'boolean' && !route.meta.menu) {
                unsetMenu()
            } else {
                setMenu()
            }
        }
    )

    watch(
        () => showNavbar.value,
        () => {
            if (showNavbar.value) {
                setBackArrow()
            } else {
                unsetBackArrow()
            }
        }
    )

    return {
        showNavbar,
        hasBurger,
        setMenu,
        unsetMenu,
        toggleMenu,
        setBackArrow,
        unsetBackArrow
    }
}
