<template>
    <div class="preloader-mask">
        <div class="preloader">
            <div style="transform: rotate(0deg); animation-delay: -1.1s"></div>
            <div style="transform: rotate(30deg); animation-delay: -1s"></div>
            <div style="transform: rotate(60deg); animation-delay: -0.9s"></div>
            <div style="transform: rotate(90deg); animation-delay: -0.8s"></div>
            <div
                style="transform: rotate(120deg); animation-delay: -0.7s"
            ></div>
            <div
                style="transform: rotate(150deg); animation-delay: -0.6s"
            ></div>
            <div
                style="transform: rotate(180deg); animation-delay: -0.5s"
            ></div>
            <div
                style="transform: rotate(210deg); animation-delay: -0.4s"
            ></div>
            <div
                style="transform: rotate(240deg); animation-delay: -0.3s"
            ></div>
            <div
                style="transform: rotate(270deg); animation-delay: -0.2s"
            ></div>
            <div
                style="transform: rotate(300deg); animation-delay: -0.1s"
            ></div>
            <div style="transform: rotate(330deg); animation-delay: 0s"></div>
        </div>
    </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.preloader-mask {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
}

.preloader {
    color: color(dark);
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 1;

    @keyframes lds-spinner {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    div {
        transform-origin: 12px 12px;
        animation: lds-spinner 1.2s linear infinite;
    }

    div:after {
        content: ' ';
        display: block;
        position: absolute;
        left: 11px;
        width: 2px;
        height: 6px;
        border-radius: 20%;
        background: color(dark);
    }
}
</style>
