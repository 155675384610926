<template>
    <div>
        <Card class="tariffs-card" @click="handleClickIdCard">
            <TSpace>
                <TIcon class="tariff-icon" stroke="var(--color-primary)">
                    <DocumentFaceIcon />
                </TIcon>

                <TSpace
                    justify="space-between"
                    align="center"
                    style="width: 100%"
                >
                    <TSpace vertical y-gap="4">
                        <strong>
                            {{ $t('identification_doc') }}
                        </strong>
                        <div class="badge-wrapper">
                            <small class="badge-blue">
                                {{ $t('novelty') }}
                            </small>
                        </div>
                    </TSpace>
                </TSpace>
            </TSpace>
        </Card>
    </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { DocumentFaceIcon, TIcon, TSpace } from '@telecom/t-components'
import Card from '@/shared/components/Card/Card.vue'
import { useMetric } from '@/shared/hooks/useMetric'
import { YandexCounters } from '@/shared/services/metric/enum'

const router = useRouter()
const { sendMetric } = useMetric()

const handleClickIdCard = (): void => {
    router.push({
        name: 'digital-doc-profile'
    })

    sendMetric('DIGITALDOCUMENTS', {}, YandexCounters.YandexThree)
}
</script>

<style scoped lang="scss">
.badge-wrapper {
    margin-top: 4px;

    .badge-blue {
        font-size: text(p6);
        line-height: 128%;
        color: color(white);
        font-weight: 400;
        background-color: color(primary);
        padding: 2px 6px;
        border-radius: rounded(xxl);
    }
}
</style>
