import { computed, type ComputedRef, ref, type Ref } from 'vue'
import { defineStore } from 'pinia'

import { ServicesMobileApi } from './api'
import { ServicesListService } from './services'
import type {
    AddBoosterParams,
    Booster,
    PhonesListItem,
    Service
} from './types'

export const useServicesStore = defineStore('services', () => {
    const isLoading: Ref<boolean> = ref(false)
    const error: Ref<string | null> = ref(null)

    const services: Ref<Service[] | null> = ref(null)

    const boosters: Ref<Booster[] | null> = ref(null)
    const boosterParams: Ref<AddBoosterParams | null> = ref(null)
    const productsId: Ref<number[] | null> = ref(null)

    const trafficBoosters: ComputedRef<Booster[] | null> = computed(() => {
        if (!boosters.value) {
            return []
        }

        return boosters.value?.filter(
            (booster) => booster.category === 'new_internet'
        )
    })

    const phoneNumbers: ComputedRef<PhonesListItem[]> = computed(() =>
        ServicesListService.getParsedPhones(services.value)
    )

    const homePhoneNumbers: ComputedRef<string[]> = computed(() =>
        ServicesListService.getParsedHomePhones(services.value)
    )

    const setServices = async (): Promise<void> => {
        if (services.value) {
            return
        }

        isLoading.value = true

        try {
            const { data } = await ServicesMobileApi.getServices()

            services.value = data
            productsId.value =
                ServicesListService.getProductsIdFromServices(data)
        } catch (err) {
            if (err instanceof Error) {
                error.value = err.message
            } else {
                error.value = 'An unknown error occurred.'
            }
        } finally {
            isLoading.value = false
        }
    }

    const getBoosters = async (serviceType: string): Promise<void> => {
        isLoading.value = true

        try {
            const { data } = await ServicesMobileApi.getBoosters(serviceType)

            boosters.value = data
        } finally {
            isLoading.value = false
        }
    }

    const addBoostTraffic = async (
        serviceType: string,
        params: AddBoosterParams
    ): Promise<void> => {
        isLoading.value = true

        try {
            await ServicesMobileApi.addBoostTraffic(serviceType, params)
        } finally {
            isLoading.value = false
        }
    }

    const resetServices = (): void => {
        services.value = null
    }

    return {
        isLoading,
        services,
        phoneNumbers,
        homePhoneNumbers,
        boosters,
        trafficBoosters,
        boosterParams,
        productsId,
        setServices,
        resetServices,
        getBoosters,
        addBoostTraffic
    }
})
