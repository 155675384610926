import type { AxiosResponse } from 'axios'

import axios from '@/shared/services/api/axios'
import type { OrderParams } from '@/shared/modules/kt-pay/types'

export class KTPayApi {
    static createOrder(payload: OrderParams): Promise<AxiosResponse<any>> {
        return axios.post('/api/v1.0/open/orders', payload)
    }
}
