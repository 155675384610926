import { ref, type Ref, type ComputedRef, computed } from 'vue'
import { defineStore } from 'pinia'

import { ChangeArchiveApi } from './api'
import type {
    ArchiveRecommendations,
    ProductOfferResponse,
    ArchiveData
} from './types'
import { TariffPlan } from './types'
import { appealsConstants } from '@/components/appeals/constants'

export const useChangeArchiveStore = defineStore('change-archive', () => {
    const isLoading: Ref<boolean> = ref(false)
    const error: Ref<string | null> = ref(null)
    const errorResponse: Ref<any> = ref(null)
    const recommendations: Ref<ArchiveRecommendations[] | null> = ref(null)
    const productOffer1: Ref<ProductOfferResponse | null> = ref(null)
    const productOffer2: Ref<ProductOfferResponse | null> = ref(null)
    const replaceArchiveResp: Ref<any> = ref(null)
    const errorExceededFreq: Ref<any> = ref(null)
    const productOfferIdFirst: Ref<string> = ref('')
    const productOfferIdSecond: Ref<string> = ref('')

    const getRecommendations = async (): Promise<void> => {
        if (recommendations.value) {
            return
        }

        isLoading.value = true

        try {
            const { data } = await ChangeArchiveApi.getRecommendations()

            recommendations.value = data
        } catch (err) {
            if (err instanceof Error) {
                error.value = err.message
            } else {
                error.value = 'An unknown error occurred.'
            }
        } finally {
            isLoading.value = false
        }
    }

    const setProductTarrif1 = async (): Promise<void> => {
        if (productOffer1.value) {
            return
        }

        isLoading.value = true

        try {
            if (
                getProductOfferT1.value &&
                getProductOfferT1.value.productOfferId
            ) {
                productOfferIdFirst.value =
                    getProductOfferT1.value.productOfferId
            } else {
                return
            }

            const { data } = await ChangeArchiveApi.getProductOffers(
                productOfferIdFirst.value
            )
            productOffer1.value = data
        } catch (err) {
            if (err instanceof Error) {
                error.value = err.message
            } else {
                error.value = 'An unknown error occurred.'
            }
        }
    }

    const setProductTarrif2 = async (): Promise<void> => {
        if (productOffer2.value) {
            return
        }

        isLoading.value = true

        try {
            if (
                getProductOfferT2.value &&
                getProductOfferT2.value.productOfferId
            ) {
                productOfferIdSecond.value =
                    getProductOfferT2.value.productOfferId || ''
            } else {
                return
            }

            const { data } = await ChangeArchiveApi.getProductOffers(
                productOfferIdSecond.value
            )
            productOffer2.value = data
        } catch (err) {
            if (err instanceof Error) {
                error.value = err.message
            } else {
                error.value = 'An unknown error occurred.'
            }
        } finally {
            isLoading.value = false
        }
    }

    const sendReplaceArchive = async (): Promise<void> => {
        isLoading.value = true

        if (
            !getProductOfferT1.value ||
            !getProductOfferT1.value.oldproductOfferId ||
            !getProductOfferT1.value.productOfferId
        ) {
            return
        }

        try {
            const archiveData: ArchiveData = {
                category: TariffPlan.TariffFirst,
                product_offer_id: getProductOfferT1.value.productOfferId || '',
                old_product_offer_id:
                    getProductOfferT1.value.oldproductOfferId || '',
                sales_channel_id: appealsConstants.sales_chanel_id
            }

            const { data } = await ChangeArchiveApi.replaceArchive(archiveData)

            replaceArchiveResp.value = data
        } catch (err: any) {
            errorResponse.value = err.response?.data.message
            errorExceededFreq.value = err.response?.data.errors.error[0]
        } finally {
            isLoading.value = false
        }
    }

    const getProductOfferT1: ComputedRef<ArchiveRecommendations | null> =
        computed(() => {
            if (!recommendations.value) {
                return null
            }

            const offer = recommendations.value.find(
                (element) => element.category === TariffPlan.TariffFirst
            )

            return offer || null
        })

    const getProductOfferT2: ComputedRef<ArchiveRecommendations | null> =
        computed(() => {
            if (!recommendations.value) {
                return null
            }

            const offer = recommendations.value.find(
                (element) => element.category === TariffPlan.TariffSecond
            )

            return offer || null
        })

    const clearRecommendation = (): void => {
        recommendations.value = null
    }

    const clearErrorResponse = (): void => {
        errorResponse.value = null
        errorExceededFreq.value = null
    }

    return {
        isLoading,
        error,
        getRecommendations,
        recommendations,
        sendReplaceArchive,
        errorResponse,
        replaceArchiveResp,
        clearRecommendation,
        errorExceededFreq,
        clearErrorResponse,
        setProductTarrif1,
        setProductTarrif2,
        productOffer1,
        productOffer2,
        getProductOfferT1
    }
})
