import type { SelectOption } from '@telecom/t-components/dist/components/TSelect/types'
import type { MonthSelectFormat } from '@/components/detalization/types'
import type { Region, RegionIsb, Street } from '@/shared/types/telecom'
import { useMetric } from '@/shared/hooks/useMetric'
import router from '@/router'
import { useMonth } from '@/shared/hooks/useMonth'
import type { SelectModelOption } from '@/shared/types/types'
import { YandexCounters } from '@/shared/services/metric/enum'

const { sendMetric } = useMetric()
const { getMonth } = useMonth()

interface YandexCounterConfig {
    isYandexTwo: boolean
}

export class TelecomService {
    static formatDateToYYYYMM = (dateStr: string | undefined): string => {
        if (!dateStr) {
            return ''
        }

        const date = new Date(dateStr)
        const year = date.getFullYear()
        const month = date.getMonth() + 1

        return `${year}${String(month).padStart(2, '0')}`
    }

    static formatDateToDDMMYYYY = (dateStr: string | undefined): string => {
        if (!dateStr) {
            return ''
        }

        const date = new Date(dateStr)
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')

        return `${day}.${month}.${year}`
    }

    static formatDateToMonthIndex = (dateStr: string | undefined): number => {
        if (!dateStr) {
            return 0
        }

        const date = new Date(dateStr)

        return date.getMonth() + 1
    }

    static formatDateToYYYYMMHHMMSS = (dateStr: string | undefined): string => {
        if (!dateStr) {
            return ''
        }

        const date = new Date(dateStr)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        const seconds = String(date.getSeconds()).padStart(2, '0')

        return `${year}-${String(month).padStart(
            2,
            '0'
        )}-${day} ${hours}:${minutes}:${seconds}`
    }

    static Base64ToBlobCustom = (fileCode: string): string => {
        const base64 = fileCode.includes(',')
            ? fileCode.split(',')[1]
            : fileCode

        const binary = atob(base64.replace(/\s/g, ''))
        const len = binary.length
        const buffer = new ArrayBuffer(len)
        const view = new Uint8Array(buffer)

        for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i)
        }

        const blob = new Blob([view], { type: 'application/pdf' })
        return URL.createObjectURL(blob)
    }

    static Base64ToBlob = (fileCode: string): string => {
        const binary = atob(fileCode.replace(/\s/g, ''))
        const len = binary.length
        const buffer = new ArrayBuffer(len)
        const view = new Uint8Array(buffer)

        for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i)
        }

        const blob = new Blob([view], { type: 'application/pdf' })
        return URL.createObjectURL(blob)
    }

    static fileToBlob = async (filePath: string): Promise<Blob> => {
        try {
            const response = await fetch(filePath)

            if (!response.ok) {
                throw new Error(`Network response was not ok for ${filePath}`)
            }

            return await response.blob()
        } catch (error) {
            console.error('Ошибка при загрузке файла:', error)
            throw error
        }
    }

    static blobToBase64 = (blob: Blob): Promise<string> => {
        try {
            const reader = new FileReader()

            return new Promise<string>((resolve, reject) => {
                reader.onloadend = () => {
                    if (reader.result) {
                        resolve(reader.result.toString().split(',')[1])
                    } else {
                        reject(new Error('Ошибка при конвертации в base64'))
                    }
                }
                reader.readAsDataURL(blob)
            })
        } catch (error) {
            throw new Error(`Ошибка при загрузке файла: ${error}`)
        }
    }

    static fileToBase64 = async (filePath: string): Promise<string> => {
        try {
            const blob = await TelecomService.fileToBlob(filePath)

            return await TelecomService.blobToBase64(blob)
        } catch (error) {
            console.error('Ошибка при конвертации файла в Base64:', error)
            throw error
        }
    }

    static saveAsPdf = (
        content: string | Uint8Array,
        filename: string
    ): void => {
        const URL = window.URL || window.webkitURL || window

        try {
            const blob = new Blob([content], { type: 'application/pdf' })

            if (!blob) {
                console.error('Blob creation failed.')
                return
            }

            const a = document.createElement('a')
            const url = URL.createObjectURL(blob)

            a.href = url
            a.download = filename
            a.click()

            URL.revokeObjectURL(url)
            a.remove()
        } catch (error) {
            console.error('Error:', error)
        }
    }

    static last12Months = (t: any, dateFrom?: Date): MonthSelectFormat[] => {
        const monthsResult = []
        const currentDate = dateFrom || new Date()

        for (let i = 0; i < 12; i++) {
            const monthIndex = (currentDate.getMonth() - i + 12) % 12
            const year =
                currentDate.getFullYear() - (i > currentDate.getMonth() ? 1 : 0)
            const lastDay = new Date(year, monthIndex + 1, 0).getDate()
            const dateStart = `${year}-${String(monthIndex + 1).padStart(
                2,
                '0'
            )}-01`
            const dateEnd = `${year}-${String(monthIndex + 1).padStart(
                2,
                '0'
            )}-${String(lastDay).padStart(2, '0')}`

            monthsResult.push({
                id: i,
                date_start: dateStart,
                date_end: dateEnd,
                label: `${t(getMonth(monthIndex).name)} ${year}`
            })
        }

        return monthsResult
    }

    static formatAmount = (amount: number | string): string => {
        return `${amount.toLocaleString('ru-RU')} ₸`
    }

    static formatAmountRound = (
        amount: number | string | undefined
    ): string => {
        if (!amount) {
            return '0 ₸'
        }

        return `${amount
            .toLocaleString('ru-RU', { useGrouping: true })
            .replace(',', '.')} ₸`
    }

    static parseDate(dateString: string): Date {
        let day, month, year

        if (dateString.includes('.')) {
            ;[day, month, year] = dateString
                .split('.')
                .map((num) => parseInt(num, 10))
            month -= 1
        } else if (dateString.includes('/')) {
            ;[month, day, year] = dateString
                .split('/')
                .map((num) => parseInt(num, 10))
            month -= 1
        } else {
            throw new Error('Неверный формат даты')
        }

        return new Date(year, month, day)
    }

    static formatReceiptDate(dateString: string): string {
        const date = new Date(dateString)
        const day = date.getDate().toString().padStart(2, '0')
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const year = date.getFullYear()

        return `${day}.${month}.${year}`
    }

    static modifyRegions = (regions: Region[]): SelectOption[] => {
        if (!regions.values) {
            return []
        }

        return regions.map((region: Region) => ({
            id: region.id,
            label: region.name,
            server_id: region.server_id,
            city_id: region.order
        }))
    }

    static modifyRegionsIsb = (regions: RegionIsb[]): SelectModelOption[] => {
        if (!regions.values) {
            return []
        }

        return regions.map((region: RegionIsb) => ({
            id: region.id,
            label: region.text,
            filial_id: region.filial_id,
            town_id: region.town_id,
            value: region.id,
            text: region.text
        }))
    }

    static modifyStreets = (streets: Street[]): SelectModelOption[] => {
        if (!streets.values) {
            return []
        }

        return streets.map((street: Street) => ({
            id: street.id,
            label: street.text,
            street_type_id: street.street_type_id,
            street_type_name: street.street_type_name,
            street_type_short_name: street.street_type_short_name,
            value: street.id,
            text: street.text
        }))
    }

    static handleInputMaxLength = (e: Event, max: number): string => {
        const inputElement = e.target as HTMLInputElement

        if (inputElement.value.length > max) {
            inputElement.value = inputElement.value.slice(0, max)
        }

        return inputElement.value
    }

    static getFormattedPhoneNumber = (phoneNumber: string): string => {
        const formattedNumber = `+7 ${phoneNumber.slice(
            0,
            3
        )} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(
            6,
            8
        )} ${phoneNumber.slice(8)}`

        return formattedNumber
    }

    static onNavigateWithMetric(
        routeName: string,
        metric?: string,
        { isYandexTwo }: YandexCounterConfig = { isYandexTwo: false }
    ): void {
        router
            .push({ name: routeName })
            .then(() => {
                if (metric) {
                    if (isYandexTwo) {
                        sendMetric(metric, {}, YandexCounters.YandexTwo)
                    } else {
                        sendMetric(metric)
                    }
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }

    static cutFio(
        firstName: string,
        middleName: string,
        lastName: string
    ): string {
        const initial = (name: string) =>
            name ? `${name.charAt(0).toUpperCase()}.` : ''

        // Собираем сокращенное ФИО
        return `${lastName} ${initial(firstName)}${initial(middleName)}`
    }

    static anonymizePhoneNumber(phoneNumber: string): string {
        const cleaned = phoneNumber.replace(/[^\d+]/g, '')

        const maskPattern = /^\+?(\d)(\d)(\d{2})(\d{3})(\d{4})$/

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const masked = cleaned.replace(maskPattern, (_, p1, p2, p3, p4, p5) => {
            return `+${p1} ${p2}** *** ${p5}`
        })

        return masked
    }

    static formatPeriodWithDeclension(
        t: any,
        start?: string,
        end?: string
    ): string {
        if (!start || !end) {
            return ''
        }

        const [startY, startM, startD] = start.split('-').map(Number)
        const [endY, endM, endD] = end.split('-').map(Number)
        const currentD = new Date().getUTCDate()
        const currentM = new Date().getUTCMonth() + 1

        const formatDate = (
            day: number,
            month: number,
            year?: number
        ): string => {
            const monthName = t(getMonth(month - 1).declension)
            return year ? `${day} ${monthName} ${year}` : `${day} ${monthName}`
        }

        if (endY > startY) {
            return `${formatDate(startD, startM, startY)} - ${formatDate(
                endD,
                endM,
                endY
            )}`
        } else if (endM > startM) {
            return `${formatDate(startD, startM, startY)} - ${formatDate(
                endD,
                endM,
                endY
            )}`
        } else {
            const finalEndD = endD > currentD ? currentD : endD
            const dayRange = startD === finalEndD ? '' : `${startD} - `

            return `${dayRange}${currentM === endM ? finalEndD : endD} ${t(
                getMonth(startM - 1).declension
            )} ${endY}`
        }
    }

    static formatDateWithDeclension(t: any, date: string): string {
        if (!date) {
            return ''
        }

        const [year, month, day] = date.split('-').map(Number)

        return `${day} ${t(getMonth(month - 1).declension)} ${year}`
    }

    static getLocalISOWithoutTimezone(date: Date) {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        const seconds = String(date.getSeconds()).padStart(2, '0')

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
    }
}
