export interface AuthenticateRequestBody {
    phone: string
    password: string
}

export interface RefreshRequestBody {
    refresh_token: string
}

export interface Token {
    token_type: string
    expires_in: number
    access_token: string
    refresh_token: string
}

export interface AuthResponse extends Token {}

export interface OAuthState extends Token {
    expires_at: number
}

export interface ResetPasswordRequestBody {
    password: string
    password_confirm: string
    phone: string
    token: string
}

export interface SendSmsRequestBody {
    phone: string
}

export interface CheckSmsRequestBody {
    phone: string
    code: string
}

export interface CheckSmsResponse {
    token: string
}

export type AuthenticateAituType = 'authentication' | 'new_verification'

export interface AuthenticateAituParams {
    phone: string
    type: AuthenticateAituType
    state: string
    redesign: 0 | 1
    release_branch: 0 | 1
}

export interface AuthenticateAituResponse {
    link: string
}

export interface CheckAituCodeRequestBody {
    code: string
    type: AuthenticateAituType
    redesign: 0 | 1
    release_branch: 0 | 1
}

export interface AituToken {
    token_type: string
    expires_in: number
    access_token: string
    scope: string
    id_token: string
}

export interface AituUserData {
    firstName: string
    lastName: string
    patronymic: string
    idCardNumber: string
    documentNumber: string
    dateOfBirth: string
    placeOfBirth: string
    issueDate: string
    expireDate: string
    authority: string
    iin: string
    nation: string
}

export interface CheckAituCodeResponse {
    aitu?: AituToken
    telecom: Token
    phone?: string
    customer: boolean
    customer_id: number
    message?: string
    full_name?: string
    iin?: string
    full_document?: AituUserData
}

export interface RateAituVerificationRequestBody {
    rating: number
    comment?: string
}

export type AituRedirectErrorHandler = (
    error: string,
    errorDescription: string,
    authType: AuthenticateAituType
) => void

export interface AituRedirectErrorsRules {
    [error: string]: AituRedirectErrorHandler
}

export enum AituRedirectState {
    Default = 'home',
    FullDigital = 'sign-doc',
    RedirectPush = 'redirectPush'
}

export enum AppRedirectUrls {
    PaymentKtPaySuccess = 'payments-success',
    PaymentKtPayFailure = 'home',
    PaymentReregistrationKtPay = 're-registration-appeal.payment-status'
}

export interface ModalState {
    selectVerification: boolean
}

export type AppQueryRedirectMap = {
    redirect: string | null
    redirectPush: string | null
}

export interface RedirectPushParamsHandlers {
    [param: string]: (value: string) => void
}

export type AppQueryRedirectHandlers = {
    redirect: (redirectValue: string | null) => boolean
    redirectPush: (redirectValue: string | null) => boolean
}

export interface OtpVerificationPayload {
    mobile_phone: number
    code: number
    user: 'MobApp'
}

export interface OtpVerificationResponse {
    telecom: Token
}
