import { createRouter, createWebHistory, type Router } from 'vue-router'

import { useAuthStore } from '@/components/auth/store'
import HomeView from '@/pages/HomePage.vue'
import NotFound from '@/pages/NotFound/NotFound.vue'
import HelpPage from '@/pages/Help/HelpPage.vue'
import LoginPage from '@/pages/Auth/LoginPage.vue'
import UserFoundByIin from '@/pages/Auth/UserFoundByIin.vue'
import AituRedirectPage from '@/pages/Auth/AituRedirectPage.vue'
import AituVerificationPage from '@/pages/Auth/AituVerificationPage.vue'
import AituRatingPage from '@/pages/Auth/AituRatingPage.vue'
import AituNotFoundUserPage from '@/pages/Auth/AituNotFoundUserPage.vue'
import AituErrorPage from '@/pages/Auth/AituErrorPage.vue'
import PincodeVerificationPage from '@/pages/Auth/PincodeVerificationPage.vue'
import DetalizationPage from '@/pages/Detalization/DetalizationPage.vue'
import DetailsBalancePage from '@/pages/Detalization/DetailsBalancePage.vue'
import DetalizationTelephonyPage from '@/pages/Detalization/DetalizationTelephonyPage.vue'
import DetalizationInternetPage from '@/pages/Detalization/DetalizationInternetPage.vue'
import DetalizationMobilePage from '@/pages/Detalization/DetalizationMobilePage.vue'
import DetailsAdditionalInformation from '@/pages/Detalization/DetailsAdditionalInformation.vue'
import DetalizationMobileDetailsPage from '@/pages/Detalization/DetalizationMobileDetailsPage.vue'
import PaymentsPage from '@/pages/Payments/PaymentsPage.vue'
import WrapperPage from '@/pages/Payments/AutoPayment/WrapperPage.vue'
import IndexPage from '@/pages/Payments/AutoPayment/IndexPage.vue'
import CreatePage from '@/pages/Payments/AutoPayment/CreatePage.vue'
import UpdatePage from '@/pages/Payments/AutoPayment/UpdatePage.vue'
import StatusPage from '@/pages/Payments/AutoPayment/StatusPage.vue'
import PaymentsDeliveryTypePage from '@/pages/Payments/InvoiceDelivery/PaymentsDeliveryTypePage.vue'
import PaymentsChangeEmail from '@/pages/Payments/InvoiceDelivery/PaymentsChangeEmail.vue'
import PaymentsAddEmail from '@/pages/Payments/InvoiceDelivery/PaymentsAddEmail.vue'
import PaymentsMailVerification from '@/pages/Payments/InvoiceDelivery/PaymentsMailVerification.vue'
import PaymentsHistoryPage from '@/pages/Payments/PaymentsHistoryPage.vue'
import PaymentsInvoicingPage from '@/pages/Payments/PaymentsInvoicingPage.vue'
import ReceiptPage from '@/pages/Receipt/ReceiptPage.vue'
import PaymentsSuccessPage from '@/pages/Payments/PaymentsSuccessPage.vue'
import PaymentsFailurePage from '@/pages/Payments/PaymentsFailurePage.vue'
import ServicePage from '@/pages/Services/ServicePage.vue'
import ServicePackageSettingsPage from '@/pages/Services/PackageSettings/ServicePackageSettingsPage.vue'
import ServiceProlongationPage from '@/pages/Services/Prolongation/ServiceProlongationPage.vue'
import ServiceChangeSimPage from '@/pages/Services/ChangeSim/ServiceChangeSimPage.vue'
import ServiceChangeEsimPage from '@/pages/Services/ChangeEsim/ServiceChangeEsimPage.vue'
import ServiceChangeEsimImeiPage from '@/pages/Services/ChangeEsim/ServiceChangeEsimImeiPage.vue'
import ServiceChangeEsimQrPage from '@/pages/Services/ChangeEsim/ServiceChangeEsimQrPage.vue'
import ServiceChangeEsimVerificationPage from '@/pages/Services/ChangeEsim/ServiceChangeEsimVerificationPage.vue'
import ServiceChangeEsimSuccessPage from '@/pages/Services/ChangeEsim/ServiceChangeEsimSuccessPage.vue'
import ServiceRoamingPage from '@/pages/Services/Roaming/ServiceRoamingPage.vue'
import ServiceRoamingTermsPage from '@/pages/Services/Roaming/ServiceRoamingTermsPage.vue'
import ServiceRoamingDetailPage from '@/pages/Services/Roaming/ServiceRoamingDetailPage.vue'
import ServiceConditions from '@/pages/Services/Conditions/ServiceConditions.vue'
import AppealsPage from '@/pages/Appeals/AppealsPage.vue'
import AppealDetailPage from '@/pages/Appeals/AppealDetailPage.vue'
import OrderDetailIframePage from '@/pages/Appeals/WFM/OrderDetailIframePage.vue'
import AppealDetailIframePage from '@/pages/Appeals/WFM/AppealDetailIframePage.vue'
import AppealServiceQuality from '@/pages/Appeals/AppealServiceQualityPage.vue'
import OrderDetailPage from '@/pages/Appeals/OrderDetailPage.vue'
import SettingsPage from '@/pages/Settings/SettingsPage.vue'
import SettingsInfo from '@/pages/Settings/SettingsInfo.vue'
import SettingsPassword from '@/pages/Settings/SettingsPassword.vue'
import SettingsProfile from '@/pages/Settings/SettingsProfile.vue'
import SettingsEmail from '@/pages/Settings/SettingsEmail.vue'
import HelpAddressPage from '@/pages/Help/HelpAddressPage.vue'
import HelpFaqPage from '@/pages/Help/HelpFaqPage.vue'
import TvPlusPage from '@/pages/TvPlus/TvPlusPage.vue'
import HelpFaqCategoryPage from '@/pages/Help/HelpFaqCategoryPage.vue'
import CCTVPage from '@/pages/CCTV/CCTVPage.vue'
import CCTVLivePage from '@/pages/CCTV/CCTVLivePage.vue'
import ChangeArchivePage from '@/pages/ChangeArchive/ChangeArchivePage.vue'
import ChangeArchiveVerificationPage from '@/pages/ChangeArchive/ChangeArchiveVerificationPage.vue'
import ChangeArchiveConditionPage from '@/pages/ChangeArchive/ChangeArchiveConditionPage.vue'
import BonusesPage from '@/pages/Bonuses/BonusesPage.vue'
import BonusesDetailsPage from '@/pages/Bonuses/BonusesDetailsPage.vue'
import BonusesSpendPage from '@/pages/Bonuses/BonusesSpendPage.vue'
import BonusesGetPage from '@/pages/Bonuses/BonusesGetPage.vue'
import BonusesSalesPage from '@/pages/Bonuses/BonusesSalesPage.vue'
import BonusesTipsPage from '@/pages/Bonuses/BonusesTipsPage.vue'
import BonusesAboutPage from '@/pages/Bonuses/BonusesAboutPage.vue'
import BonusesGamePage from '@/pages/Bonuses/BonusesGamePage.vue'
import ImportantDocsPage from '@/pages/ImportantDocs/ImportantDocsPage.vue'
import PublicAgreementPage from '@/pages/ImportantDocs/PublicAgreementPage.vue'
import MainPage from '@/pages/FullDigital/MainPage.vue'
import ServicePotentialPage from '@/pages/FullDigital/ServicePotentialPage.vue'
import GbdflPage from '@/pages/Auth/GbdflPage.vue'
import TelecomServiceRulesPage from '@/pages/ImportantDocs/TelecomServiceRulesPage.vue'
import ApplicationSignPage from '@/pages/FullDigital/ApplicationSignPage.vue'
import PhoneNumberVerificationPage from '@/pages/FullDigital/PhoneNumberVerificationPage.vue'
import ApplicationView from '@/pages/FullDigital/ApplicationView.vue'
import ConstructorPage from '@/pages/FullDigital/ConstructorPage.vue'
import CheckPhonePage from '@/pages/FullDigital/CheckPhonePage.vue'
import ConnectServicePage from '@/pages/FullDigital/ConnectServicePage.vue'
import IdentityNavPage from '@/pages/FullDigital/IdentityNavPage.vue'
import TechnicalCheckPage from '@/pages/FullDigital/TechnicalCheckPage.vue'
import FAQPage from '@/pages/FullDigital/FAQPage.vue'
import ReferralInformationPage from '@/pages/FullDigital/ReferralInformationPage.vue'
import { useRedirectPushRoute } from '@/shared/hooks/router/useRedirectPushRoute'
import EmployeeMotivationPage from '@/pages/Help/EmployeeMotivationPage.vue'
import ReregistrationPage from '@/pages/Appeals/Reregistration/ReregistrationPage.vue'
import ReregistrationChooseAddressPage from '@/pages/Appeals/Reregistration/ReregistrationChooseAddressPage.vue'
import ReregistrationChooseEquipmentPage from '@/pages/Appeals/Reregistration/ReregistrationChooseEquipmentPage.vue'
import ReregistrationChooseLocalPage from '@/pages/Appeals/Reregistration/ReregistrationChooseLocalPage.vue'
import ReregistrationDocViewPage from '@/pages/Appeals/Reregistration/ReregistrationDocViewPage.vue'
import ReregistrationEntryPage from '@/pages/Appeals/Reregistration/ReregistrationEntryPage.vue'
import ReregistrationErrorPage from '@/pages/Appeals/Reregistration/ReregistrationErrorPage.vue'
import ReregistrationOtpPage from '@/pages/Appeals/Reregistration/ReregistrationOtpPage.vue'
import ReregistrationPaymentStatusPage from '@/pages/Appeals/Reregistration/ReregistrationPaymentStatusPage.vue'
import ReregistrationSignPage from '@/pages/Appeals/Reregistration/ReregistrationSignPage.vue'
import ReregistrationStep1Page from '@/pages/Appeals/Reregistration/ReregistrationStep1Page.vue'
import ReregistrationStep2Page from '@/pages/Appeals/Reregistration/ReregistrationStep2Page.vue'
import ReregistrationEgovPage from '@/pages/Appeals/Reregistration/ReregistrationEgovPage.vue'
import ReregistrationSuccessPage from '@/pages/Appeals/Reregistration/ReregistrationSuccessPage.vue'
import ReregistrationTariffPlanPage from '@/pages/Appeals/Reregistration/ReregistrationTariffPlanPage.vue'
import ReregistrationApplicationsPage from '@/pages/Appeals/Reregistration/ReregistrationApplicationsPage.vue'
import ReregistrationApplicationDetailPage from '@/pages/Appeals/Reregistration/ReregistrationApplicationDetailPage.vue'
import SapaRouterStartPage from '@/pages/Sapa/SapaRouterStartPage.vue'
import SapaCustomerAccountsPage from '@/pages/Sapa/SapaCustomerAccountsPage.vue'
import SapaInfoAndConditionsPage from '@/pages/Sapa/SapaInfoAndConditionsPage.vue'
import SapaOrderStatusPage from '@/pages/Sapa/SapaOrderStatusPage.vue'
import SapaOrderConfirmPage from '@/pages/Sapa/SapaOrderConfirmPage.vue'
import SapaSignDocPage from '@/pages/Sapa/SapaSignDocPage.vue'
import SapaViewDocPage from '@/pages/Sapa/SapaViewDocPage.vue'
import SapaOtpPage from '@/pages/Sapa/SapaOtpPage.vue'
import SapaSuccessPage from '@/pages/Sapa/SapaSuccessPage.vue'
import SapaErrorPage from '@/pages/Sapa/SapaErrorPage.vue'
import TransferAddress from '@/pages/Services/Transfer/ServiceTransferPage.vue'
import TransferAddressOpenOrder from '@/pages/Services/Transfer/ServiceTransferFail/OpenOrder.vue'
import TransferAddressRefused from '@/pages/Services/Transfer/ServiceTransferFail/Refused.vue'
import TransferAddressUnavailable from '@/pages/Services/Transfer/ServiceTransferFail/Unavailable.vue'
import TransferAddressTechNotAvailable from '@/pages/Services/Transfer/ServiceTransferFail/TechNotAvailable.vue'
import TransferAddressImpossible from '@/pages/Services/Transfer/ServiceTransferFail/TransferImpossible.vue'
import TransferAddressForm from '@/pages/Services/Transfer/ServiceTransferFormPage.vue'
import TransferAddressDoc from '@/pages/Services/Transfer/ServiceTransferDocPage.vue'
import TransferAddressSign from '@/pages/Services/Transfer/ServiceTransferSignPage.vue'
import TransferAddressVerfication from '@/pages/Services/Transfer/ServiceTransferVerificationPage.vue'
import MegaSapaScanGuidePage from '@/pages/Sapa/Megaline/MegaSapaScanGuidePage.vue'
import MegaSapaScanQrPage from '@/pages/Sapa/Megaline/MegaSapaScanQrPage.vue'
import MegaSapaSerialNumberFormPage from '@/pages/Sapa/Megaline/MegaSapaSerialNumberFormPage.vue'
import MegaSapaRouterNotFoundPage from '@/pages/Sapa/Megaline/MegaSapaRouterNotFoundPage.vue'
import MegaSapaRouterFoundPage from '@/pages/Sapa/Megaline/MegaSapaRouterFoundPage.vue'
import MegaSapaGeneratedOtpViewPage from '@/pages/Sapa/Megaline/MegaSapaGeneratedOtpViewPage.vue'
import MegaSapaRouterInstallmentGuidePage from '@/pages/Sapa/Megaline/MegaSapaRouterInstallmentGuidePage.vue'
import SapaRatingPage from '@/pages/Sapa/SapaRatingPage.vue'
import SapaRouterInstallPage from '@/pages/Sapa/Feedback/RouterInstallPage.vue'
import OnlineBookingPage from '@/pages/OnlineBooking/OnlineBookingPage.vue'
import DigitalDocumentsProfile from '@/pages/DigitalDocuments/DigitalDocumentsProfile.vue'
import DigitalDocumentsList from '@/pages/DigitalDocuments/DigitalDocumentsList.vue'
import DigitalDocumentsIdentityCard from '@/pages/DigitalDocuments/DigitalDocumentsIdentityCard.vue'
import DigitalDocumentsOtpVerf from '@/pages/DigitalDocuments/DigitalDocumentsOtpVerf.vue'
import DigitalDocumentsModalRefused from '@/pages/DigitalDocuments/DigitalDocumentsModalRefused.vue'
import DigitalDocumentsServiceUnavailable from '@/pages/DigitalDocuments/DigitalDocumentsServiceUnavailable.vue'
import OntPage from '@/pages/ONT/OntPage.vue'
import ExtraServicesPage from '@/pages/ExtraServices/ExtraServicesPage.vue'

const router: Router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior() {
        return {
            top: 0
        }
    },
    routes: [
        {
            path: '/:pathMatch(.*)*',
            name: 'not-found',
            component: NotFound
        },
        {
            path: '/',
            name: 'home',
            component: HomeView
        },
        {
            path: '/login',
            name: 'login',
            component: LoginPage,
            meta: {
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/auth/user-found-by-iin',
            name: 'auth-user-found-by-iin',
            component: UserFoundByIin,
            meta: {
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/aitu/redirect',
            name: 'aitu-redirect',
            component: AituRedirectPage,
            meta: {
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/aitu/verification',
            name: 'aitu-verification',
            component: AituVerificationPage,
            meta: {
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/aitu/rating',
            name: 'aitu-rating',
            component: AituRatingPage,
            meta: {
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/aitu/not-found-user',
            name: 'aitu-not-found-user',
            component: AituNotFoundUserPage,
            meta: {
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/aitu/error',
            name: 'aitu-error',
            component: AituErrorPage,
            meta: {
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/pincode-verification',
            name: 'pincode-verification',
            component: PincodeVerificationPage,
            meta: {
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/help',
            name: 'help',
            component: HelpPage
        },
        {
            path: '/help/employee-motivation',
            name: 'employee-motivation',
            component: EmployeeMotivationPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/detalization',
            name: 'detalization',
            component: DetalizationPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/detalization/balance',
            name: 'details-balance',
            component: DetailsBalancePage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/detalization/telephony',
            name: 'detalization-telephony',
            component: DetalizationTelephonyPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/detalization/internet',
            name: 'detalization-internet',
            component: DetalizationInternetPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/detalization/mobile',
            name: 'detalization-mobile',
            component: DetalizationMobilePage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/detalization/addition-information',
            name: 'details-addition-information',
            component: DetailsAdditionalInformation,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/detalization/mobile/details',
            name: 'detalization-mobile-details',
            component: DetalizationMobileDetailsPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/payments',
            name: 'payments',
            component: PaymentsPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/payments/auto',
            name: 'auto-payment-wrapper',
            component: WrapperPage,
            props: true,
            meta: {
                layout: 'auth',
                requiredAuth: true
            },
            children: [
                {
                    path: '/payments/auto',
                    name: 'auto-payment',
                    component: IndexPage,
                    props: true
                },
                {
                    path: '/payments/create',
                    name: 'auto-payment.create',
                    component: CreatePage,
                    props: true
                },
                {
                    path: '/payments/update',
                    name: 'auto-payment.update',
                    component: UpdatePage,
                    props: true
                },
                {
                    path: '/payments/status',
                    name: 'auto-payment.status',
                    component: StatusPage,
                    props: true
                }
            ]
        },
        {
            path: '/payments/history',
            name: 'payments-history',
            component: PaymentsHistoryPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/payments/invoicing',
            name: 'payments-invoicing',
            component: PaymentsInvoicingPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/payments/invoicing/receipt',
            name: 'receipt',
            component: ReceiptPage,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/payments/invoice-delivery',
            name: 'invoice-delivery',
            component: PaymentsDeliveryTypePage,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/payments/invoice-delivery/change-email',
            name: 'invoice-delivery-change-email',
            component: PaymentsChangeEmail,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/payments/invoice-delivery/add-email',
            name: 'invoice-delivery-add-email',
            component: PaymentsAddEmail,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/payments/invoice-delivery/verificate-email',
            name: 'invoice-delivery-verificate-email',
            component: PaymentsMailVerification,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/payments/success',
            name: 'payments-success',
            component: PaymentsSuccessPage
        },
        {
            path: '/payments/failure',
            name: 'payments-failure',
            component: PaymentsFailurePage
        },
        {
            path: '/services',
            name: 'services',
            component: ServicePage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/services/package-settings/:id',
            name: 'service-package-settings',
            component: ServicePackageSettingsPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/services/prolongation',
            name: 'service-prolongation',
            component: ServiceProlongationPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/services/transfer-address',
            name: 'transfer-address',
            component: TransferAddress,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/services/transfer-address/open-order',
            name: 'transfer-open-order',
            component: TransferAddressOpenOrder,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/services/transfer-address/refused',
            name: 'transfer-refused',
            component: TransferAddressRefused,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/services/transfer-address/unavailable',
            name: 'transfer-unavailable',
            component: TransferAddressUnavailable,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/services/transfer-address/tech-not-available',
            name: 'transfer-tech-err',
            component: TransferAddressTechNotAvailable,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/services/transfer-address/impossible',
            name: 'transfer-impossible',
            component: TransferAddressImpossible,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/services/transfer-address/form',
            name: 'transfer-address-form',
            component: TransferAddressForm,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/services/transfer-address/sign',
            name: 'transfer-address-sign',
            component: TransferAddressSign,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/services/transfer-address/doc',
            name: 'transfer-address-doc',
            component: TransferAddressDoc,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/services/transfer-address/verification',
            name: 'transfer-address-verificate',
            component: TransferAddressVerfication,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/services/change-sim',
            name: 'service-change-sim',
            component: ServiceChangeSimPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/services/change-esim',
            name: 'service-change-esim',
            component: ServiceChangeEsimPage,
            meta: {
                layout: 'auth',
                requiredAuth: true
            }
        },
        {
            path: '/services/change-esim/imei',
            name: 'change-esim-imei',
            component: ServiceChangeEsimImeiPage,
            meta: {
                layout: 'auth',
                requiredAuth: true
            }
        },
        {
            path: '/services/change-esim/qr',
            name: 'change-esim-qr',
            component: ServiceChangeEsimQrPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/services/change-esim/verification',
            name: 'change-esim-verification',
            component: ServiceChangeEsimVerificationPage,
            meta: {
                layout: 'auth',
                requiredAuth: true
            }
        },
        {
            path: '/services/change-esim/success',
            name: 'change-esim-success',
            component: ServiceChangeEsimSuccessPage,
            meta: {
                layout: 'auth',
                requiredAuth: true
            }
        },
        {
            path: '/services/roaming',
            name: 'service-roaming',
            component: ServiceRoamingPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/services/roaming/terms',
            name: 'service-roaming-terms',
            component: ServiceRoamingTermsPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/services/roaming/:id',
            name: 'service-roaming-detail',
            component: ServiceRoamingDetailPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/services/conditions',
            name: 'service-conditions',
            component: ServiceConditions,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/appeals',
            name: 'appeals',
            component: AppealsPage,
            props: true,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/appeals/appeal/:id',
            name: 'appeals-detail',
            component: AppealDetailPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/appeals/service-quality',
            name: 'appeals-service-quality',
            component: AppealServiceQuality,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/appeals/order/:id',
            name: 'order-detail',
            component: OrderDetailPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/appeals/wfm/appeal/:id',
            name: 'wfm-appeal-detail',
            component: AppealDetailIframePage,
            meta: {
                requiredAuth: true,
                layout: 'full'
            }
        },
        {
            path: '/appeals/wfm/order/:id',
            name: 'wfm-order-detail',
            component: OrderDetailIframePage,
            meta: {
                requiredAuth: true,
                layout: 'full'
            }
        },
        {
            path: '/appeals/re-registration',
            name: 're-registration-appeal',
            component: ReregistrationPage,
            props: true,
            meta: {
                requiredAuth: true
            },
            children: [
                {
                    path: 'error',
                    name: 're-registration-appeal.error',
                    component: ReregistrationErrorPage,
                    props: true,
                    meta: {
                        layout: 'auth'
                    }
                },
                {
                    path: 'entry',
                    name: 're-registration-appeal.entry',
                    component: ReregistrationEntryPage,
                    props: true
                },
                {
                    path: 'step-1',
                    name: 're-registration-appeal.step-1',
                    component: ReregistrationStep1Page,
                    props: true
                },
                {
                    path: 'step-2',
                    name: 're-registration-appeal.step-2',
                    component: ReregistrationStep2Page,
                    props: true
                },
                {
                    path: 'egov',
                    name: 're-registration-appeal.egov',
                    component: ReregistrationEgovPage,
                    props: true,
                    meta: {
                        layout: 'auth'
                    }
                },
                {
                    path: 'tariff-plan',
                    name: 're-registration-appeal.tariff-plan',
                    component: ReregistrationTariffPlanPage,
                    props: true
                },
                {
                    path: 'sign',
                    name: 're-registration-appeal.sign',
                    component: ReregistrationSignPage,
                    props: true,
                    meta: {
                        layout: 'auth'
                    }
                },
                {
                    path: 'choose-local',
                    name: 're-registration-appeal.choose-local',
                    component: ReregistrationChooseLocalPage,
                    props: true
                },
                {
                    path: 'equipment',
                    name: 're-registration-appeal.equipment',
                    component: ReregistrationChooseEquipmentPage,
                    props: true,
                    meta: {
                        layout: 'auth'
                    }
                },
                {
                    path: 'docview',
                    name: 're-registration-appeal.docview',
                    component: ReregistrationDocViewPage,
                    props: true,
                    meta: {
                        layout: 'auth'
                    }
                },
                {
                    path: 'verification',
                    name: 're-registration-appeal.otp',
                    component: ReregistrationOtpPage,
                    props: true,
                    meta: {
                        layout: 'auth'
                    }
                },
                {
                    path: 'choose-address',
                    name: 're-registration-appeal.choose-address',
                    component: ReregistrationChooseAddressPage,
                    props: true,
                    meta: {
                        layout: 'auth'
                    }
                },
                {
                    path: 'payment-status',
                    name: 're-registration-appeal.payment-status',
                    component: ReregistrationPaymentStatusPage,
                    props: true,
                    meta: {
                        layout: 'auth'
                    }
                },
                {
                    path: 'success',
                    name: 're-registration-appeal.success',
                    component: ReregistrationSuccessPage,
                    props: true,
                    meta: {
                        layout: 'auth'
                    }
                },
                {
                    path: 'list',
                    name: 're-registration-appeal.list',
                    component: ReregistrationApplicationsPage,
                    props: true
                },
                {
                    path: 'detail',
                    name: 're-registration-appeal.detail',
                    component: ReregistrationApplicationDetailPage,
                    props: true
                }
            ]
        },
        {
            path: '/settings',
            name: 'settings',
            component: SettingsPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/settings/info',
            name: 'settings-info',
            component: SettingsInfo,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/settings/password',
            name: 'settings-password',
            component: SettingsPassword,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/settings/profile',
            name: 'settings-profile',
            component: SettingsProfile,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/settings/email',
            name: 'settings-email',
            component: SettingsEmail,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/address',
            name: 'address',
            component: HelpAddressPage
        },
        {
            path: '/faq',
            name: 'faq',
            component: HelpFaqPage
        },
        {
            path: '/tv-plus',
            name: 'tv-plus',
            component: TvPlusPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/faq-category/:id',
            name: 'faq-category',
            component: HelpFaqCategoryPage
        },
        {
            path: '/cctv',
            name: 'cctv',
            component: CCTVPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/cctv-live',
            name: 'cctv-live',
            component: CCTVLivePage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/change-archive',
            name: 'change-archive',
            component: ChangeArchivePage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/change-archive/sms-verification',
            name: 'change-archive-sms-verification',
            component: ChangeArchiveVerificationPage,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/change-archive/condition',
            name: 'change-archive-condition',
            component: ChangeArchiveConditionPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/digital-document/profile',
            name: 'digital-doc-profile',
            component: DigitalDocumentsProfile,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/digital-document/list',
            name: 'digital-doc-list',
            component: DigitalDocumentsList,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/digital-document/id-card',
            name: 'digital-doc-id-card',
            component: DigitalDocumentsIdentityCard,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/digital-document/otp',
            name: 'digital-doc-otp',
            component: DigitalDocumentsOtpVerf,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/digital-document/refused',
            name: 'digital-doc-refused',
            component: DigitalDocumentsModalRefused,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/digital-document/unavailable',
            name: 'digital-doc-unavailable',
            component: DigitalDocumentsServiceUnavailable,
            meta: {
                requiredAuth: true,
                layout: 'auth'
            }
        },
        {
            path: '/bonuses',
            name: 'bonuses',
            component: BonusesPage,
            meta: {
                requiredAuth: true
            },
            children: [
                {
                    path: 'details',
                    name: 'bonuses.details',
                    component: BonusesDetailsPage
                },
                {
                    path: 'spend',
                    name: 'bonuses.spend',
                    component: BonusesSpendPage
                },
                {
                    path: 'get/:id?',
                    name: 'bonuses.get',
                    component: BonusesGetPage
                },
                {
                    path: 'sales/:id?',
                    name: 'bonuses.sales',
                    component: BonusesSalesPage
                },
                {
                    path: 'tips',
                    name: 'bonuses.tips',
                    component: BonusesTipsPage
                },
                {
                    path: 'about',
                    name: 'bonuses.about',
                    component: BonusesAboutPage
                },
                {
                    path: 'game',
                    name: 'bonuses.game',
                    component: BonusesGamePage,
                    meta: {
                        layout: 'full'
                    }
                }
            ]
        },
        {
            path: '/important-docs',
            name: 'important-docs',
            component: ImportantDocsPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/public-agreement',
            name: 'public-agreement',
            component: PublicAgreementPage
        },
        {
            path: '/full-digital',
            name: 'main',
            component: MainPage,
            props: true,
            meta: {},
            children: []
        },
        {
            path: '/full-digital/potential',
            name: 'main-service-potential',
            component: ServicePotentialPage,
            props: true,
            meta: {
                menu: false
            }
        },
        {
            path: '/gbdfl',
            name: 'gbdfl',
            component: GbdflPage,
            meta: {
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/telecom-service-rules',
            name: 'telecom-service-rules',
            component: TelecomServiceRulesPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/sapa',
            name: 'sapa',
            component: SapaRouterStartPage,
            meta: {
                requiredAuth: true,
                menu: false
            }
        },
        {
            path: '/sapa/accounts',
            name: 'sapa-accounts',
            component: SapaCustomerAccountsPage,
            meta: {
                requiredAuth: true,
                menu: false
            }
        },
        {
            path: '/sapa/info',
            name: 'sapa-info',
            component: SapaInfoAndConditionsPage,
            meta: {
                requiredAuth: true,
                menu: false
            }
        },
        {
            path: '/sapa/order/status',
            name: 'sapa-order-status',
            component: SapaOrderStatusPage,
            meta: {
                requiredAuth: true,
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/sapa/order/confirm',
            name: 'sapa-order-confirm',
            component: SapaOrderConfirmPage,
            meta: {
                requiredAuth: true,
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/sapa/sign-doc',
            name: 'sapa-sign-doc',
            component: SapaSignDocPage,
            meta: {
                requiredAuth: true,
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/sapa/view-doc',
            name: 'sapa-view-doc',
            component: SapaViewDocPage,
            meta: {
                requiredAuth: true,
                menu: false
            }
        },
        {
            path: '/sapa/otp',
            name: 'sapa-otp',
            component: SapaOtpPage,
            meta: {
                requiredAuth: true,
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/sapa/success',
            name: 'sapa-success',
            component: SapaSuccessPage,
            meta: {
                requiredAuth: true,
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/sapa/error',
            name: 'sapa-error',
            component: SapaErrorPage,
            meta: {
                requiredAuth: true,
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/sapa/scan-guide',
            name: 'sapa-scan-guide',
            component: MegaSapaScanGuidePage,
            meta: {
                requiredAuth: true,
                menu: false
            }
        },
        {
            path: '/sapa/scan-qr',
            name: 'sapa-scan-qr',
            component: MegaSapaScanQrPage,
            meta: {
                requiredAuth: true,
                menu: false
            }
        },
        {
            path: '/sapa/serial-number-form',
            name: 'sapa-serial-number-form',
            component: MegaSapaSerialNumberFormPage,
            meta: {
                requiredAuth: true,
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/sapa/router-not-found',
            name: 'sapa-router-not-found',
            component: MegaSapaRouterNotFoundPage,
            meta: {
                requiredAuth: true,
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/sapa/router-found',
            name: 'sapa-router-found',
            component: MegaSapaRouterFoundPage,
            meta: {
                requiredAuth: true,
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/sapa/generated-otp-view',
            name: 'sapa-generated-otp-view',
            component: MegaSapaGeneratedOtpViewPage,
            meta: {
                requiredAuth: true,
                menu: false
            }
        },
        {
            path: '/sapa/router-installment-guide',
            name: 'sapa-router-installment-guide',
            component: MegaSapaRouterInstallmentGuidePage,
            meta: {
                requiredAuth: true,
                menu: false
            }
        },
        {
            path: '/sapa/rating',
            name: 'sapa-rating',
            component: SapaRatingPage,
            meta: {
                requiredAuth: true,
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/feedback/router-install',
            name: 'feedback-router-install',
            component: SapaRouterInstallPage,
            meta: {
                requiredAuth: true,
                layout: 'auth',
                menu: false
            }
        },
        {
            path: '/full-digital/sign-doc',
            name: 'sign-doc',
            component: ApplicationSignPage,
            meta: {
                layout: 'auth'
            }
        },
        {
            path: '/full-digital/phone-verification',
            name: 'phone-verification',
            component: PhoneNumberVerificationPage,
            meta: {
                layout: 'auth'
            }
        },
        {
            path: '/full-digital/application-view',
            name: 'application-view',
            component: ApplicationView,
            meta: {
                layout: 'auth'
            }
        },
        {
            path: '/full-digital/constructor',
            name: 'service-constructor',
            component: ConstructorPage
        },
        {
            path: '/full-digital/referral-information',
            name: 'fd-referral-information',
            component: ReferralInformationPage
        },
        {
            path: '/full-digital/checked-user',
            name: 'checked-user',
            component: CheckPhonePage,
            meta: {
                layout: 'auth'
            }
        },
        {
            path: '/full-digital/connect-service',
            name: 'connect-service',
            component: ConnectServicePage,
            meta: {
                layout: 'auth'
            }
        },
        {
            path: '/full-digital/proof-of-identity',
            name: 'proof-of-identity',
            component: IdentityNavPage,
            meta: {
                layout: 'auth'
            }
        },
        {
            path: '/full-digital/technical-check',
            name: 'technical-check',
            component: TechnicalCheckPage
        },
        {
            path: '/full-digital/faq',
            name: 'fd-faq',
            component: FAQPage
        },
        {
            path: '/online-booking',
            name: 'online-booking',
            component: OnlineBookingPage,
            meta: {
                requiredAuth: true,
                layout: 'full'
            }
        },
        {
            path: '/ont/:id',
            name: 'modem-ont',
            component: OntPage,
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/extra-services',
            name: 'extra-services',
            component: ExtraServicesPage,
            meta: {
                requiredAuth: true
            }
        }
    ]
})

router.beforeEach((to, _from, next) => {
    const authStore = useAuthStore()

    if (to.meta.requiredAuth) {
        if (!authStore.isAuthenticated) {
            authStore.logout().then(() => {
                next({
                    name: 'login'
                })
            })

            return
        }
    }

    if (to.name === 'login' && authStore.isAuthenticated) {
        next({
            name: 'home'
        })

        return
    }

    next()
})

router.afterEach((to, from) => {
    const { handleRedirectPush } = useRedirectPushRoute()

    handleRedirectPush(to, from)
})

export default router
