<template>
    <div class="how-watch-modal">
        <img
            class="how-watch-modal__banner"
            :src="getImageBanner"
            alt="tv-plus"
        />
        <p class="how-watch-modal__banner-description">
            {{ $t('free_tv_plus_promo_description') }}
        </p>
        <p class="how-watch-modal__modal-title">
            {{ $t('tv_plus_instruction') }}
        </p>
        <h5 class="how-watch-modal__title">
            {{ $t('tv_plus_how_and_where_watch_instruction') }}
        </h5>
        <div class="how-watch-modal__list">
            <div class="how-watch-modal__item">
                <div class="how-watch-modal__item-count">1</div>
                <p class="how-watch-modal__item-text">
                    {{ $t('tv_plus_download_app') }}
                </p>
                <div class="how-watch-modal__item-image">
                    <div class="wrap">
                        <img
                            class="app-store"
                            :src="getImageStepOneAppStore"
                            alt="Шаг 1: App Store"
                            @click="goToLink('appStore')"
                        />
                        <img
                            class="google-play"
                            :src="getImageStepOneGooglePlay"
                            alt="Шаг 1: Google Play"
                            @click="goToLink('googlePlay')"
                        />
                    </div>
                </div>
            </div>

            <div class="how-watch-modal__item">
                <div class="how-watch-modal__item-count">2</div>
                <p class="how-watch-modal__item-text">
                    {{ $t('free_tv_plus_select_promocode_desc') }}
                </p>
                <div class="how-watch-modal__item-image">
                    <img :src="getImageStepTwo" alt="Шаг 2" />
                </div>
            </div>

            <div class="how-watch-modal__item">
                <div class="how-watch-modal__item-count">3</div>
                <p class="how-watch-modal__item-text">
                    {{ $t('free_tv_plus_enter_promocode_desc') }}
                </p>
                <div class="how-watch-modal__item-image">
                    <img :src="getImageStepThree" alt="Шаг 3" />
                </div>
            </div>

            <div class="how-watch-modal__item">
                <div class="how-watch-modal__item-count">4</div>
                <p class="how-watch-modal__item-text">
                    {{ $t('free_tv_plus_enter_phone_desc') }}
                </p>
                <div class="how-watch-modal__item-image">
                    <img :src="getImageStepFour" alt="Шаг 4" />
                </div>
            </div>

            <div class="how-watch-modal__item">
                <div class="how-watch-modal__item-count">5</div>
                <p class="how-watch-modal__item-text">
                    {{ $t('tv_plus_otp_code_desc') }}
                </p>
                <div class="how-watch-modal__item-image">
                    <img :src="getImageStepFive" alt="Шаг 5" />
                </div>
            </div>

            <div class="how-watch-modal__item">
                <div class="how-watch-modal__item-count">6</div>
                <p class="how-watch-modal__item-text">
                    {{ $t('tv_plus_you_ready_use') }}
                </p>
                <div class="how-watch-modal__item-image">
                    <img src="@/assets/images/how-watch-5.jpg" alt="Шаг 6" />
                </div>
            </div>
        </div>

        <div class="how-watch-modal__footer">
            <p>
                {{ $t('free_tv_plus_instruction_footer') }}
            </p>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, type ComputedRef } from 'vue'
import { storeToRefs } from 'pinia'

import { useTranslationsStore } from '@/shared/modules/translations/store'
import { AituService } from '@/shared/services/aitu/aitu-service'
import { LoyaltyMetrics } from '@/shared/services/metric/loyaltyMetrics'

import pathImageBanner from '@/assets/images/free-tv-plus-banner.png'
import pathImageBannerKk from '@/assets/images/free-tv-plus-banner_kk.png'
import pathImageBannerEn from '@/assets/images/free-tv-plus-banner_en.png'
import pathImageStepOneAppStore from '@/assets/images/how-watch-2_AppStore.png'
import pathImageStepOneAppStoreKk from '@/assets/images/how-watch-2_AppStore_kk.png'
import pathImageStepOneAppStoreEn from '@/assets/images/how-watch-2_AppStore_en.png'
import pathImageStepOneGooglePlay from '@/assets/images/how-watch-2_GooglePlay.png'
import pathImageStepOneGooglePlayKk from '@/assets/images/how-watch-2_GooglePlay_kk.png'
import pathImageStepOneGooglePlayEn from '@/assets/images/how-watch-2_GooglePlay_en.png'
import pathImageStepTwo from '@/assets/images/how-to-watch-2-promocode.png'
import pathImageStepTwoKk from '@/assets/images/how-to-watch-2-promocode_kk.png'
import pathImageStepTwoEn from '@/assets/images/how-to-watch-2-promocode_en.png'
import pathImageStepThree from '@/assets/images/how-to-watch-3-promocode.png'
import pathImageStepThreeKk from '@/assets/images/how-to-watch-3-promocode_kk.png'
import pathImageStepThreeEn from '@/assets/images/how-to-watch-3-promocode_en.png'
import pathImageStepFour from '@/assets/images/how-to-watch-4-promocode.png'
import pathImageStepFourKk from '@/assets/images/how-to-watch-4-promocode_kk.png'
import pathImageStepFourEn from '@/assets/images/how-to-watch-4-promocode_en.png'
import pathImageStepFive from '@/assets/images/how-watch-4.jpg'
import pathImageStepFiveKk from '@/assets/images/how-watch-4_kk.png'
import pathImageStepFiveEn from '@/assets/images/how-watch-4_en.png'

const translationsStore = useTranslationsStore()
const { language } = storeToRefs(translationsStore)
const { sendMetric } = new LoyaltyMetrics()

const imagePaths = {
    banner: {
        ru: pathImageBanner,
        kk: pathImageBannerKk,
        en: pathImageBannerEn
    },
    stepOneAppStore: {
        ru: pathImageStepOneAppStore,
        kk: pathImageStepOneAppStoreKk,
        en: pathImageStepOneAppStoreEn
    },
    stepOneGooglePlay: {
        ru: pathImageStepOneGooglePlay,
        kk: pathImageStepOneGooglePlayKk,
        en: pathImageStepOneGooglePlayEn
    },
    steptwo: {
        ru: pathImageStepTwo,
        kk: pathImageStepTwoKk,
        en: pathImageStepTwoEn
    },
    stepThree: {
        ru: pathImageStepThree,
        kk: pathImageStepThreeKk,
        en: pathImageStepThreeEn
    },
    stepFour: {
        ru: pathImageStepFour,
        kk: pathImageStepFourKk,
        en: pathImageStepFourEn
    },
    stepFive: {
        ru: pathImageStepFive,
        kk: pathImageStepFiveKk,
        en: pathImageStepFiveEn
    }
}

const getImageForStep = (
    step: keyof typeof imagePaths
): ComputedRef<string> => {
    return computed(() => {
        const stepImages = imagePaths[step]
        return stepImages[language.value]
    })
}

const getImageBanner = getImageForStep('banner')
const getImageStepOneAppStore = getImageForStep('stepOneAppStore')
const getImageStepOneGooglePlay = getImageForStep('stepOneGooglePlay')
const getImageStepTwo = getImageForStep('steptwo')
const getImageStepThree = getImageForStep('stepThree')
const getImageStepFour = getImageForStep('stepFour')
const getImageStepFive = getImageForStep('stepFive')

const goToLink = (linkName: string): void => {
    const appStoreLink = 'https://itunes.apple.com/ru/app/tv/id1536132045?mt=8'
    const googlePlayLink =
        'https://play.google.com/store/apps/details?id=kz.telecom.tv'

    sendMetric('tvplus_download_icon', {
        clicked: linkName === 'appStore' ? 'apple' : 'android'
    })

    AituService.openExternalUrl(
        linkName === 'appStore' ? appStoreLink : googlePlayLink
    )
}
</script>

<style lang="scss" scoped>
.how-watch-modal {
    position: relative;

    &__banner {
        width: 100%;
    }

    &__modal-title {
        position: absolute;
        top: -41px;
        left: 50%;
        transform: translateX(-50%);
        color: color(dark);
        text-align: center;
        font-size: text(p3);
        font-weight: 500;
        line-height: 120%;
        letter-spacing: 0.4px;
    }

    &__title {
        margin-top: 20px;
        color: color(dark);
        font-size: text(h4);
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -0.32px;
    }

    &__list {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__item {
        position: relative;
        padding-left: 42px;
    }

    &__item-count {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border: 1px solid color(gray);
        border-radius: rounded(circle);
        color: color(dark);
        text-align: center;
        font-size: text(p5);
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0.32px;
    }

    &__item-text {
        color: color(dark);
        font-size: text(p4);
        font-weight: 400;
        line-height: 133%;
        letter-spacing: 0.36px;
    }

    &__item-image {
        .wrap {
            width: 100%;
            display: grid;
            grid-template-columns: 43% 54%;
            gap: 8px;
            pointer-events: none;
        }

        img {
            pointer-events: auto;
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__footer {
        padding-top: 28px;

        p {
            padding-top: 28px;
            border-top: 1px solid color(gray);
            color: color(gray);
            text-align: center;
            font-size: text(p5);
            font-weight: 400;
            line-height: 125%;
            letter-spacing: 0.32px;
        }
    }
}
</style>
