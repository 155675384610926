<template>
    <InformationAboutModem />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'
import { useTitle } from '@/shared/hooks/useTitle'

const InformationAboutModem = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import('@/components/ont/components/InformationAboutModem.vue')
})

const { setTitle } = useTitle()

setTitle('information_about_modem')
</script>
