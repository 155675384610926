import { useI18n } from 'vue-i18n'

import { AituService } from '../services/aitu/aitu-service'

interface SetTitleConfig {
    translate: boolean
}

export const useTitle = () => {
    const { t } = useI18n()

    const setTitle = (
        title: string,
        { translate }: SetTitleConfig = { translate: true }
    ): void => {
        if (!translate) {
            AituService.setTitle(title)

            return
        }

        AituService.setTitle(t(title))
    }

    return {
        setTitle
    }
}
