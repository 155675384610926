import { MetricBase } from './metricBase'
import { AmplitudeCounters } from './enum'

interface LoyaltyMetricsMap {
    Popup_onboarding_showed: {
        clicked: 'terms' | 'join' | 'exit'
    }
    bonus_opened: {
        clicked: string
    }
    detail_bonus_opened: {
        clicked: 'expire_date' | 'story' | 'back'
    }
    pay_bonus_opened: {
        clicked: 'selected_ls' | 'spend' | 'back'
    }
    pay_bonus_modal_opened: {
        clicked: 'exit' | 'typed' | 'confirm'
    }
    pay_bonus_success: {
        clicked: 'continue'
    }
    promo_partners_opened: {
        clicked: string
    }
    [key: `detail_promo_${string}`]: {
        clicked: 'to_site' | 'copy'
    }
    get_bonus_opened: {
        clicked: string
    }
    tv_plus_opened: {
        clicked: 'exit' | 'promo_copied' | 'go_to_partner_site'
    }
    sosed_opened: {
        clicked: 'exit' | 'share' | 'copy'
    }
    faq_opened: {
        clicked: string
    }
    about_opened: undefined
    tvplus_newuser_modal: {
        clicked: 'connect' | 'cancel'
    }
    tvplus_download_icon: {
        clicked: 'apple' | 'android'
    }
    new_year_banner: {
        clicked: 'view_page'
    }
    banner_unauth: {
        clicked: 'sosed'
    }
    banner_auth: {
        clicked: 'sosed'
    }
}

export class LoyaltyMetrics extends MetricBase<LoyaltyMetricsMap> {
    constructor() {
        super([AmplitudeCounters.AmplitudeSecond])
    }
}
