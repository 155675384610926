import { nextTick, type Ref } from 'vue'

import type { FDCloseModalInterface } from '../types'
import { HeaderMenuIcon } from '@/shared/types/aitu'
import { useHeaderIcon } from '@/shared/hooks/useHeaderIcon'

export const useHeaderClose = (
    closeModalRef?: Ref<FDCloseModalInterface | null>
) => {
    const closeModalHandler = async (): Promise<void> => {
        if (!closeModalRef?.value) {
            return
        }

        await nextTick()

        if (closeModalRef.value) {
            closeModalRef.value.toggleModal('exit')
        }
    }

    const { enableHeaderIcon, disableHeaderIcon } = useHeaderIcon(
        'full-digital-close',
        HeaderMenuIcon.Close,
        closeModalHandler
    )

    const enableHeaderClose = (): void => {
        enableHeaderIcon()
    }

    const disableHeaderClose = (): void => {
        disableHeaderIcon()
    }

    return {
        enableHeaderClose,
        disableHeaderClose
    }
}
