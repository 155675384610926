export const useModal = <TypeState>(state: TypeState | any) => {
    const toggleModal = (key: keyof TypeState): void => {
        if (state && key) {
            state[key] = !state[key]
        }
    }

    const setModal = (key: keyof TypeState, status: boolean): void => {
        if (state && key) {
            state[key] = status
        }
    }

    return {
        toggleModal,
        setModal
    }
}
