export interface ModalState {
    success: boolean
    failure: boolean
    hasDebt: boolean
    freqExceeded: boolean
}

export interface ArchiveModalState {
    changeArchiveFail: boolean
}

export enum TariffPlan {
    Tariff = 'Archive TP',
    TariffFirst = 'Archive TP 1',
    TariffSecond = 'Archive TP 2'
}

type ArchiveRecommendationsCategory = TariffPlan

export interface ArchiveRecommendations {
    category: ArchiveRecommendationsCategory
    predictProba: number
    productOfferId: string
    oldproductOfferId: string
    rap: number
}

export interface ProductOfferComponents {
    componentId: string
    description: string
    name: string
    nameForCustomer: string
    productOfferId: string
}

export interface ProductOfferResponse {
    annualContract: object
    components: ProductOfferComponents[]
    price: number
    productOfferName: string
}

export interface ArchiveData {
    category: string
    otp_code?: string
    product_offer_id: string
    old_product_offer_id?: string
    sales_channel_id?: number
}
