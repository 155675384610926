<template>
    <div class="main-service-card">
        <div
            class="main-service-card__image"
            :class="{
                'with-banner': !!serviceBackground,
                bereket: service.id === FDService.bereket,
                'keremet-tv': service.id === FDService.keremetTv,
                'keremet-mobile': service.id === FDService.keremetMobile,
                'shanyrak-plus': service.id === FDService.shanyrakPlus,
                'shanyrak-mobile': service.id === FDService.shanyrakMobile,
                'shanyrak-tv': service.id === FDService.shanyrakTv,
                'alem-plus': service.id === FDService.alemPlus,
                'alem-mobile': service.id === FDService.alemMobile,
                'alem-tv': service.id === FDService.alemTv,
                alem: service.id === FDService.alem
            }"
            :style="{
                '--background-banner': serviceBackground
            }"
        >
            <p class="title">
                {{ service.block_title }}
            </p>
            <p v-if="service.old_price" class="price">
                {{ $t('from_price') }}
                &nbsp;
                <span class="line-through">
                    {{ service.price }}
                </span>
                &nbsp;
                <span>{{ service.old_price }}</span>
                {{ $t('price_per_month') }}
            </p>
            <p v-else class="price">
                {{ $t('from_price') }}
                <span>
                    {{ service.price }}
                </span>
                {{ $t('price_per_month') }}
            </p>

            <TSpace
                v-if="showNewTooltipService.includes(service.id)"
                x-gap="4"
                class="main-service-card__image__novelty"
            >
                <img
                    src="@/assets/images/services/novelty-star.svg"
                    alt="new"
                />
                {{ $t('novelty') }}
            </TSpace>
        </div>
        <div class="main-service-card__content">
            <template
                v-for="(item, index) in service.rate_details"
                :key="index"
            >
                <div
                    v-if="!unVisibleServiceDetail.includes(item.id)"
                    class="item"
                >
                    <TIcon stroke="#267CCC">
                        <MobileInfinityIcon
                            v-if="
                                item.icon === 'kt-icon-device-nlt' ||
                                item.icon === 'kt-icon-speedometer' ||
                                item.icon === 'kt-icon-infinity-small'
                            "
                        />
                        <MobilePhoneIcon
                            v-if="
                                item.icon === 'kt-icon-mobile-nlt' ||
                                item.icon === 'kt-icon-tariff-mobile'
                            "
                        />
                        <TVNewsIcon
                            v-if="
                                item.icon === 'kt-icon-tv-nlt' ||
                                item.icon === 'kt-icon-tariff-tv'
                            "
                        />
                        <Film02Icon
                            v-if="item.icon === 'kt-icon-online-cinema-icon'"
                        />
                        <Gift01Icon v-if="item.icon === 'kt-icon-gift'" />
                        <PhoneCall01Icon
                            v-if="
                                item.icon === 'kt-icon-phone-nlt' ||
                                item.icon === 'kt-icon-phone-receiver'
                            "
                        />
                        <MessageAlertCircleIcon
                            v-if="item.icon === 'kt-icon-message-alert'"
                        />
                        <Globe03Icon v-if="item.icon === 'kt-icon-globe'" />
                        <ShoppingCart02Icon
                            v-if="item.icon === 'kt-icon-shopping-cart'"
                        />
                    </TIcon>
                    <template v-if="item.show_modal">
                        <div>
                            <h5 v-html="item.text_1"></h5>
                            <p
                                class="link"
                                @click="openModal(service.id, item)"
                                v-html="item.text_2"
                            ></p>
                            <TModal
                                v-model="
                                    modalState[
                                        `infoModal-${service.id}-${item.id}`
                                    ]
                                "
                                @close="
                                    switchModal(
                                        `infoModal-${service.id}-${item.id}`
                                    )
                                "
                            >
                                <div class="service-modal">
                                    <template
                                        v-if="
                                            showModalInfoService.includes(
                                                item.id
                                            )
                                        "
                                    >
                                        <div
                                            class="service-modal__title"
                                            v-html="item.header_modal"
                                        ></div>
                                        <div
                                            class="service-modal__content"
                                            v-html="item.text_modal"
                                        ></div>
                                    </template>
                                    <template
                                        v-else-if="
                                            showModalTabsService.includes(
                                                item.id
                                            )
                                        "
                                    >
                                        <div class="service-modal__title">
                                            {{
                                                $t(
                                                    activeModalTab?.title ||
                                                        channels.activeTab.title
                                                )
                                            }}
                                        </div>
                                        <TTabs
                                            :tabs="channels.tab"
                                            :current-item="
                                                activeModalTab ||
                                                channels.activeTab
                                            "
                                            @change-item="changeItem"
                                        >
                                            <Component
                                                :is="
                                                    (
                                                        activeModalTab ||
                                                        channels.activeTab
                                                    )?.component
                                                "
                                                v-bind="
                                                    (
                                                        activeModalTab ||
                                                        channels.activeTab
                                                    )?.props
                                                "
                                            />
                                        </TTabs>
                                    </template>
                                    <template
                                        v-else-if="
                                            showModalBonusService.includes(
                                                item.id
                                            )
                                        "
                                    >
                                        <div
                                            class="service-modal__title"
                                            v-html="item.header_modal"
                                        ></div>
                                        <div class="service-modal__content">
                                            <span>
                                                {{
                                                    $t(
                                                        'subscription_family_modal_text_1'
                                                    )
                                                }}
                                                <span
                                                    class="link"
                                                    @click="goToTelecomShop"
                                                >
                                                    shop.telecom.kz
                                                </span>
                                                {{
                                                    $t(
                                                        'subscription_family_modal_text_2'
                                                    )
                                                }}
                                            </span>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div
                                            class="service-modal__title"
                                            v-html="item.header_modal"
                                        ></div>
                                        <div class="service-modal__content">
                                            <ServiceContentObject
                                                :object="
                                                    channelsTvCinema[service.id]
                                                        .cinemaRoom
                                                "
                                            />
                                        </div>
                                    </template>
                                </div>
                            </TModal>
                        </div>
                    </template>
                    <template v-else>
                        <div>
                            <h5 v-html="item.text_1"></h5>
                            <p v-html="item.text_2"></p>
                        </div>
                    </template>
                </div>
            </template>
        </div>
        <div class="main-service-card__action">
            <!--            <TButton type="outlined" @click="next(service)">
                {{ $t('more_details') }}
            </TButton>-->
            <TButton type="outlined" @click="next(service, true)">
                {{ $t('connect') }}
            </TButton>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, type ComputedRef, type Ref } from 'vue'
import { useRouter } from 'vue-router'

import {
    Film02Icon,
    Gift01Icon,
    Globe03Icon,
    MessageAlertCircleIcon,
    MobileInfinityIcon,
    MobilePhoneIcon,
    PhoneCall01Icon,
    ShoppingCart02Icon,
    TButton,
    TIcon,
    TModal,
    TSpace,
    TTabs,
    TVNewsIcon
} from '@telecom/t-components'
import { useI18n } from 'vue-i18n'
import { useModalBySteps } from '@/shared/hooks/useModalBySteps'
import {
    FDService,
    type ModalState,
    type FDTab
} from '@/components/full-digital/types'
import { FullDigitalService } from '@/components/full-digital/service'
import ServiceContentObject from '@/components/full-digital/components/FDServiceContentObject.vue'
import { CARDS, channelsTvCinema } from '@/components/full-digital/data'
import { useFullDigitalStore } from '@/components/full-digital/store'
import { AituService } from '@/shared/services/aitu/aitu-service'
import { FullDigitalMetrics } from '@/shared/services/metric/fullDigitalMetrics'

const props = defineProps<{
    service: any
}>()

const router = useRouter()
const fullDigitalStore = useFullDigitalStore()
const { t } = useI18n()
const { sendMetric } = new FullDigitalMetrics()

const { modalState, switchModal } = useModalBySteps<ModalState>(
    reactive<ModalState>({
        infoModal: false
    })
)

const serviceDetails: Ref<any> = ref(null)
const activeModalTab: Ref<FDTab | null> = ref(null)

const unVisibleServiceDetail: number[] = [355, 357, 359, 392, 419]
const showModalTabsService: number[] = [
    CARDS.BEREKET.TV_CINEMA_ID,
    CARDS.BEREKET.TV_ID,
    CARDS.KEREMET_MOBILE.TV_CINEMA_ID,
    CARDS.ALEM_PLUS.TV_ID,
    CARDS.ALEM_TV.TV_ID
]
const showModalInfoService: number[] = [
    CARDS.BEREKET.MOBILE_CONNECTION_ID,
    CARDS.KEREMET_MOBILE.MOBILE_CONNECTION_ID,
    CARDS.ALAMAN.MOBILE_CONNECTION_ID,
    CARDS.SHANYRAQ_TV.CITY_CONNECTION_ID,
    CARDS.SHANYRAQ_PLUS.CITY_CONNECTION_ID
]
const showModalBonusService: number[] = [
    CARDS.BEREKET.BONUS,
    CARDS.KEREMET_TV.BONUS,
    CARDS.KEREMET_MOBILE.BONUS
]
const constructorLoad: number[] = [
    CARDS.KEREMET_TV.ID,
    CARDS.ALAMAN.ID,
    CARDS.INTERNET_500.ID,
    CARDS.INTERNET_200.ID,
    CARDS.BEREKET.ID,
    CARDS.KEREMET_MOBILE.ID
]
const showNewTooltipService: number[] = [
    CARDS.BEREKET.ID,
    CARDS.KEREMET_TV.ID,
    CARDS.KEREMET_MOBILE.ID,
    CARDS.SHANYRAQ_PLUS.ID,
    CARDS.SHANYRAQ_TV.ID,
    CARDS.SHANYRAQ_MOBILE.ID
]
const channels: Ref<any> = ref({})

const serviceBackground: ComputedRef<string | undefined> = computed(() => {
    if (!props.service.background) {
        return
    }
    return `url(${
        import.meta.env.VITE_API_BASE_URL + props.service.background
    })`
})

const setChannels = (serviceId: number, itemId: number): any => {
    const service = FullDigitalService.getMainServiceModalTab(
        serviceId,
        itemId,
        {
            cinemas: t('cinemas'),
            hd: t('hd-channels'),
            tv: t('tv-channels')
        }
    )

    channels.value = {
        tab: service,
        activeTab: service[0]
    }
}

const changeItem = (item: FDTab): void => {
    activeModalTab.value = item
}

const openModal = (serviceId: number, item: any): void => {
    serviceDetails.value = item
    switchModal(`infoModal-${serviceId}-${item.id}` as keyof ModalState)
    activeModalTab.value = null
    setChannels(serviceId, item.id)
}

const next = (service: any, potential: boolean = false): void => {
    fullDigitalStore.setSelectedService(service)

    if (potential) {
        router.push({
            name: 'main-service-potential'
        })
        return
    }

    sendMetric('all_packages_opened', {
        connect_package: service.block_title
    })

    if (constructorLoad.includes(service.id)) {
        router.push({ name: 'service-constructor' })
        return
    }

    router.push({
        name: 'main-service-potential'
    })
}

const goToTelecomShop = () => {
    AituService.openExternalUrl(
        'https://shop.telecom.kz/?utm_source=telecom&utm_medium=familybutton'
    )
}
</script>

<style scoped lang="scss">
.main-service-card {
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: rounded(lg);
    background: color(white);
    box-shadow: 0px 12px 32px 0px rgba(42, 70, 94, 0.03);

    .line-through {
        text-decoration: line-through;
    }

    &__image {
        width: 100%;
        position: relative;
        border-radius: 8px;
        background: linear-gradient(118deg, #82a0bc -7.25%, #82a0bc 107.25%);
        color: color(white);
        padding: 1em;

        &.with-banner {
            background: center / 100% 100% no-repeat var(--background-banner) !important;
        }

        &__novelty {
            position: absolute;
            right: 12px;
            top: 12px;
            border-radius: rounded(pill);
            background: color(white);
            padding: 6px;
            padding-right: 8px;
            color: color(dark);
            font-size: text(p5);

            img {
                width: 20px;
                height: 20px;
            }
        }

        .title {
            font-size: text(h6);
            font-style: normal;
            font-weight: 600;
            line-height: 115.385%;
            margin-bottom: 2px;
        }

        .price {
            font-size: text(p5);
            font-style: normal;
            font-weight: 400;
            line-height: 125%;

            span {
                font-size: text(p3);
                font-style: normal;
                font-weight: 600;
                line-height: 125%;
            }
        }

        &.bereket {
            background: linear-gradient(
                118deg,
                #caa352 -7.25%,
                #efcc83 107.25%
            );
        }

        &.keremet-tv {
            background: linear-gradient(
                118deg,
                #57b4e5 -7.25%,
                #57b4e5 107.25%
            );
        }

        &.keremet-mobile {
            background: linear-gradient(
                118deg,
                #4dcc93 -7.25%,
                #4dcc93 107.25%
            );
        }

        &.shanyrak-plus {
            background: linear-gradient(
                118deg,
                #e38585 -7.25%,
                #f55cca 107.25%
            );
        }

        &.shanyrak-mobile {
            background: linear-gradient(
                118deg,
                #09d3af -7.25%,
                #20b79b 107.25%
            );
        }

        &.shanyrak-tv {
            background: linear-gradient(
                118deg,
                #bb95fa -7.25%,
                #a278e7 107.25%
            );
        }

        &.alem-plus {
            background: linear-gradient(
                118deg,
                #b99b5c -7.25%,
                #ddd299 107.25%
            );
        }

        &.alem-mobile {
            background: linear-gradient(
                118deg,
                #63c399 -7.25%,
                #5fb8aa 107.25%
            );
        }

        &.alem-tv {
            background: linear-gradient(
                118deg,
                #85a7dd -7.25%,
                #7abde7 107.25%
            );
        }

        &.alem {
            background: linear-gradient(
                118deg,
                #b0b0b1 -7.25%,
                #a6a8aa 107.25%
            );
        }
    }

    &__content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 8px;

        .item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 12px;
        }

        h5 {
            color: color(dark);
            font-size: text(p5);
            font-style: normal;
            font-weight: 600;
            line-height: 125%;
        }

        p {
            color: color(gray);
            font-size: text(p5);
            font-style: normal;
            font-weight: 400;
            line-height: 125%;

            :deep(a) {
                color: color(primary) !important;
                text-decoration-line: underline !important;
            }
        }
    }

    &__action {
        width: 100%;

        .t-button {
            width: 100%;
            color: color(primary);
            font-size: text(p4);
            font-style: normal;
            font-weight: 500;
            line-height: 127.778%;
        }
    }
}

.service-modal {
    color: color(dark);

    &__title {
        text-align: center;
        font-size: text(p1);
        font-style: normal;
        font-weight: 600;
        line-height: 125%;
        margin-bottom: 24px;
    }

    &__content {
        font-size: text(p3);
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: 0.4px;

        .link {
            color: color(primary);
            text-decoration-line: underline;
        }
    }
}
</style>
