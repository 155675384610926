<template>
    <Wrapper>
        <PincodeVerification />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent, onBeforeMount } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { AituService } from '@/shared/services/aitu/aitu-service'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const PincodeVerification = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/auth/components/PincodeVerification.vue')
})

onBeforeMount(() => {
    AituService.enableBackArrow()
    AituService.showBackArrow(false)
})
</script>
