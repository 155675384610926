import { HeaderMenuIcon, type HeaderMenuItem } from '@/shared/types/aitu'
import { AituService } from '@/shared/services/aitu/aitu-service'

export const useHeaderIcon = (
    id = 'close',
    icon: HeaderMenuIcon = HeaderMenuIcon.Close,
    handler?: () => void | Promise<void>
) => {
    const enableHeaderIcon = (): void => {
        const mainPageHeaderMenu: HeaderMenuItem[] = [
            {
                id,
                icon
            }
        ]

        AituService.setHeaderMenuItems(mainPageHeaderMenu)
        AituService.setHeaderTitleHandler(handler)
    }

    const disableHeaderIcon = (): void => {
        AituService.setHeaderMenuItems([])
    }

    return {
        enableHeaderIcon,
        disableHeaderIcon
    }
}
