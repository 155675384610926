<template>
    <TSpace class="tariff-packages" y-gap="8" vertical>
        <TSpin class="loading-center" :show="isLoading" color="black" />

        <TSpace justify="space-between" align="center">
            <h2 class="section-title">
                {{ $t('package_services') }}
            </h2>

            <TButton
                type="text"
                size="small"
                text-color="primary-text"
                @click="viewAllHandler"
            >
                {{ $t('view_all') }}
            </TButton>
        </TSpace>

        <TSpace
            v-if="fullDigitalStore.internetService"
            justify="center"
            align="start"
            :y-gap="16"
            vertical
        >
            <FDService
                v-for="service in serviceList"
                :key="service.id"
                :service="service"
            />
        </TSpace>
    </TSpace>
</template>

<script setup lang="ts">
import { type Ref, onMounted, ref, computed, type ComputedRef } from 'vue'
import { useRouter } from 'vue-router'

import { TSpace, TSpin, TButton } from '@telecom/t-components'
import { useFullDigitalStore } from '@/components/full-digital/store'
import FDService from '@/components/full-digital/components/FDService.vue'
import type { PackageService } from '@/components/full-digital/types'
import { useMetric } from '@/shared/hooks/useMetric'
import { YandexCounters } from '@/shared/services/metric/enum'
import { FullDigitalMetrics } from '@/shared/services/metric/fullDigitalMetrics'

const router = useRouter()
const { sendMetric } = useMetric()
const fullDigitalMetrics = new FullDigitalMetrics()
const fullDigitalStore = useFullDigitalStore()
const isLoading: Ref<boolean> = ref(false)

onMounted(() => {
    getInternetService()
})

const getInternetService = async (): Promise<void> => {
    isLoading.value = true

    await fullDigitalStore.getInternetService()

    isLoading.value = false
}

const serviceList: ComputedRef<PackageService[] | null> = computed(() => {
    if (!fullDigitalStore.internetService) {
        return null
    }

    return fullDigitalStore.internetService.filter(
        (item: PackageService) => item.tab === 'ftth'
    )
})

const viewAllHandler = (): void => {
    router.push({ name: 'main' })

    sendMetric(
        'notautho_main',
        {
            clicked: 'allpackages'
        },
        YandexCounters.YandexTwo
    )
    fullDigitalMetrics.sendMetric('all_packages_opened', {})
}
</script>

<style scoped lang="scss">
.loading-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
}

.tariff-packages {
    &:deep(.main-service-card) {
        &:nth-child(1) {
            order: 1;
        }
    }
}
</style>
