<template>
    <Wrapper>
        <Constructor />

        <FDCloseModal ref="closeModalRef" />
    </Wrapper>
</template>

<script setup lang="ts">
import {defineAsyncComponent, onMounted, ref} from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import { AituService } from "@/shared/services/aitu/aitu-service";
import FDCloseModal from "@/components/full-digital/components/FDCloseModal.vue";
import type {FDCloseModalInterface} from "@/components/full-digital/types";
import { useHeaderClose } from '@/components/full-digital/hooks/useHeaderClose'

const Constructor = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/full-digital/components/FDConstructor.vue')
})

const closeModalRef = ref<FDCloseModalInterface | null>(null)
const { enableHeaderClose } = useHeaderClose(closeModalRef)

onMounted((): void => {
    AituService.disableBackArrow()

    enableHeaderClose()
})

const { setTitle } = useTitle()

setTitle('Kazakhtelecom')
</script>
