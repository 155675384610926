<template>
    <div class="container">
        <slot />
    </div>
</template>

<style scoped lang="scss">
.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
}
</style>
