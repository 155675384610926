import { TranslationsApi } from './api'
import type { Language, TranslationGroup } from './types'

export class TranslationsService {
    static async getTranslations(): Promise<TranslationGroup> {
        const translations: TranslationGroup = {
            ru: {},
            kk: {},
            en: {}
        }

        try {
            const { data } = await TranslationsApi.getList()

            for (const key in translations) {
                translations[key] = data[key]['customer_new']
            }
        } catch (error) {
            console.error('Translations cannot be loaded => ', error)
        }

        return translations
    }

    static russianPluralizationRule(
        choice: number,
        choicesLength: number
    ): number {
        if (choice === 0) {
            return 0
        }

        const teen = choice > 10 && choice < 20
        const endsWithOne = choice % 10 === 1

        if (!teen && endsWithOne) {
            return 1
        }

        if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
            return 2
        }

        return choicesLength < 4 ? 2 : 3
    }

    static isExistedLanguage(lang: any): lang is Language {
        return lang === 'ru' || lang === 'kk' || lang === 'en'
    }
}
