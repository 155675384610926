<template>
    <div class="wrapper">
        <slot></slot>
    </div>
</template>

<style scoped lang="scss">
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
</style>
