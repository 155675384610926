<template>
    <SettingsEmailWrapper />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import PageError from '@/shared/components/UI/PageError.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import Preloader from '@/shared/components/UI/Preloader.vue'

const SettingsEmailWrapper = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import('@/components/settings/SettingsEmail/SettingsEmailWrapper.vue')
})

const { setTitle } = useTitle()

setTitle('settings_change_email_ru')
</script>

<style scoped lang="scss"></style>
