<template>
    <Wrapper>
        <ServicePotential />

        <FDCloseModal ref="closeModalRef" />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent, onMounted, ref } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'
import FDCloseModal from '@/components/full-digital/components/FDCloseModal.vue'
import type { FDCloseModalInterface } from '@/components/full-digital/types'
import { AituService } from '@/shared/services/aitu/aitu-service'

const closeModalRef = ref<FDCloseModalInterface | null>(null)

const ServicePotential = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import('@/components/full-digital/components/FDServicePotential.vue')
})

const { setTitle } = useTitle()

setTitle('manager_help')

onMounted(() => {
    AituService.enableBackArrow(false)
})
</script>
