<template>
    <TModal v-model="modalState.exit" :close-icon="false">
        <ModalSimple
            :title="$t('really_want_to_exit')"
            :text="$t('return_to_beginning_progress_lost')"
        >
            <template #actions>
                <TSpace x-gap="12">
                    <TButton full type="error" @click="resetFD">{{
                        $t('exit')
                    }}</TButton>
                    <TButton full @click="toggleModal('exit')">{{
                        $t('cancel')
                    }}</TButton>
                </TSpace>
            </template>
        </ModalSimple>
    </TModal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { TButton, TModal, TSpace } from '@telecom/t-components'

import { useModal } from '@/shared/hooks/useModal'
import { useFullDigitalStore } from '@/components/full-digital/store'
import type { FDModalStateExit } from '@/components/full-digital/types'
import ModalSimple from '@/shared/components/Modal/ModalSimple.vue'

const router = useRouter()
const fullDigitalStore = useFullDigitalStore()

const modalState: FDModalStateExit = reactive({
    exit: false
})
const { toggleModal } = useModal<FDModalStateExit>(modalState)

const resetFD = () => {
    fullDigitalStore.reset()
    router.push({ name: 'home' })
}

defineExpose({ toggleModal })
</script>
