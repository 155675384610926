<template>
    <Wrapper>
        <SettingsProfile />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import PageError from '@/shared/components/UI/PageError.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import Preloader from '@/shared/components/UI/Preloader.vue'

const SettingsProfile = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import('@/components/settings/SettingsProfile/SettingsProfile.vue')
})

const { setTitle } = useTitle()

setTitle('personal_info')
</script>

<style scoped lang="scss"></style>
