<template>
    <TSpace
        align="center"
        class="page-error"
        vertical
        y-gap="24"
        justify="center"
    >
        <div class="page-error__image">
            <img src="@/assets/images/rocket.svg" />
        </div>

        <TSpace align="center" vertical y-gap="12" class="page-error__body">
            <h6 class="page-error__title">
                {{ $t('page_error_title') }}
            </h6>

            <span class="page-error__text">
                {{ $t('page_error_text') }}
            </span>

            <div class="page-error__buttons">
                <TButton full @click="updateHandler">
                    {{ $t('update') }}
                </TButton>
            </div>
        </TSpace>
    </TSpace>

    <TSpin :show="isLoading" full background-color="#fff" />
</template>

<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, ref, type Ref } from 'vue'

import { TSpace, TButton, TSpin } from '@telecom/t-components'
import { Utils } from '@/helpers/utils'
import { useTranslationsStore } from '@/shared/modules/translations/store'
import { AituService } from '@/shared/services/aitu/aitu-service'
import { useTitle } from '@/shared/hooks/useTitle'
import { useLayout } from '@/shared/hooks/useLayout'
import { useNavbar } from '@/shared/hooks/useNavbar'

const { setTitle } = useTitle()
const { setLayout } = useLayout()
const { setMenu, unsetMenu } = useNavbar()

const translationsStore = useTranslationsStore()
const isLoading: Ref<boolean> = ref(false)

onBeforeMount(() => {
    setTitle('', {
        translate: false
    })

    setLayout('auth')
    unsetMenu()

    AituService.enableBackArrow()
    AituService.showBackArrow(false)
})

onBeforeUnmount(() => {
    setLayout('')
    setMenu()
})

const updateHandler = async (): Promise<void> => {
    isLoading.value = true

    await new Promise((resolve) => setTimeout(resolve, 1000))

    const updateLink = `${window.location.origin}?lang=${translationsStore.language}`

    Utils.redirectLinkByUrl(updateLink)
}
</script>

<style scoped lang="scss">
.page-error {
    padding: 32px 16px;
    height: 100%;
    min-height: calc(100vh - 92px);

    &__body {
        width: 100%;
    }

    &__title {
        font-size: text(h6);
        font-weight: 500;
    }

    &__text {
        color: color(gray);
        font-size: text(p5);
        text-align: center;
    }

    &__buttons {
        width: 100%;
    }
}
</style>
