import type { AxiosResponse } from 'axios'

import axios from '@/shared/services/api/axios'
import type {
    OffersRequestResponse,
    EnableOfferRequestBody,
    SetTVPlusResponse,
    SetTVPlusRequest
} from '@/shared/modules/offers/types'

export class OffersApi {
    static getOffers(): Promise<
        AxiosResponse<{ orders: OffersRequestResponse }>
    > {
        return axios.get('/api/v1.0/customer/offers')
    }

    static enableOffer(
        payload: EnableOfferRequestBody
    ): Promise<AxiosResponse<any>> {
        return axios.post('/api/v1.0/customer/offers', payload)
    }

    static setTVPlus(
        body: SetTVPlusRequest
    ): Promise<AxiosResponse<SetTVPlusResponse>> {
        return axios.post('/api/v1.0/customer/offers', body)
    }
}
