<template>
    <Wrapper>
        <TechnicalCheck />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

import { useTitle } from '@/shared/hooks/useTitle'

const TechnicalCheck = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import('@/components/full-digital/components/FDTechnicalCheck.vue')
})

const { setTitle } = useTitle()

setTitle('technical-check')
</script>
