import type { AxiosResponse } from 'axios'

import type {
    AituCustomerSaveRequestBody,
    GetCustomerResponse,
    UpdateUserAituRequestBody
} from './types'
import axios from '@/shared/services/api/axios'

export class CustomerApi {
    static getCurrentCustomer(): Promise<AxiosResponse<GetCustomerResponse>> {
        return axios.get('/api/v1.0/customer/customer')
    }

    static aituCustomerSave(
        payload: AituCustomerSaveRequestBody
    ): Promise<AxiosResponse> {
        return axios.post('/api/v3.0/customer/aitu/customer/save', payload)
    }

    static updateUserAitu(
        payload: UpdateUserAituRequestBody
    ): Promise<AxiosResponse> {
        return axios.post('/api/v3.0/customer/aitu/update-client', payload)
    }
}
