import { computed, type ComputedRef, ref, type Ref, reactive } from 'vue'
import { defineStore } from 'pinia'

import type { SelectOption } from '@telecom/t-components/dist/components/TSelect/types'
import type { AxiosError } from 'axios'
import { FullDigitalApi } from '@/components/full-digital/api'
import type {
    BonusesState,
    PackageService,
    ServiceComponent,
    ServiceFilter,
    ServiceFilterPayload,
    TerminalCheckPayload,
    TerminalCheckStocks,
    TownStatesResponse
} from '@/components/full-digital/types'
import type { GbdflUserData } from '@/shared/modules/gbdfl/types'
import { useAuthStore } from '@/components/auth/store'
import { BonusesApi } from '@/shared/modules/bonuses/api'
import { SentryService } from '@/shared/services/sentry/sentry-service'

export const useFullDigitalStore = defineStore(
    'full-digital-store',
    () => {
        const authStore = useAuthStore()

        const internetService: Ref<PackageService[] | null> = ref(null)
        const idNetService: Ref<PackageService[] | null> = ref(null)
        const wirelessService: Ref<PackageService[] | null> = ref(null)
        const selectedService: Ref<PackageService | null> = ref(null)
        const isOntRent: Ref<boolean> = ref(true)
        const isHomePhone: Ref<boolean> = ref(true)
        const homePhoneObject: Ref<ServiceComponent | null> = ref(null)
        const terminalInfo: Ref<any | null> = ref(null)
        const isHideAituVerify: Ref<boolean> = ref(false)
        const defaultUserDate = {
            firstName: '',
            lastName: '',
            middleName: '',
            iin: '',
            number: '',
            issuedBy: '',
            beginDate: '',
            endDate: '',
            townState: {
                id: 0,
                label: '',
                value: 0,
                text: ''
            },
            street: {
                id: 0,
                label: '',
                value: 0,
                text: ''
            },
            house: '',
            flat: '',
            subHouse: '',
            isHomeOwner: false,
            documentType: 0,
            zipCode: '',
            townId: 0,
            captchaToken: '',
            orderId: 0
        }
        const userData: Ref<GbdflUserData> = ref(defaultUserDate)
        const townStates: Ref<TownStatesResponse[] | null> = ref(null)

        const savedFilterPayload: Ref<ServiceFilterPayload | null> = ref(null)

        const bonuses: BonusesState = reactive({
            promocode: '',
            isPromocodeInvalid: false,
            bonusesAmount: 0
        })

        const checkPromocode = async (value: string): Promise<void> => {
            try {
                const { data } = await BonusesApi.checkPromocode(value)

                if (!data.status) {
                    bonuses.isPromocodeInvalid = true
                    bonuses.promocode = ''
                    return
                }

                // TO-DO: get bonuses amount from request
                bonuses.bonusesAmount = 2000
            } catch (error) {
                bonuses.isPromocodeInvalid = true
                bonuses.promocode = ''

                const errorData: any = (error as AxiosError).response?.data

                SentryService.sendEvent({
                    message: 'FD | error with `checkPromocode`',
                    tags: {
                        flow: 'FD'
                    },
                    extra: {
                        response: errorData,
                        request: { promoCode: value }
                    }
                })
            }
        }

        const getInternetService = async (): Promise<void> => {
            try {
                if (internetService.value) {
                    return
                }

                const excludeIds = [388, 389, 390]

                const { data } = await FullDigitalApi.getInternetService()

                const filteredInternetService = data.data['main_services']
                    ?.filter((service) => !excludeIds.includes(service.id))
                    .map((service) => {
                        if (service.block_title?.includes('Bereket')) {
                            return {
                                ...service,
                                block_title: 'Bereket'
                            }
                        }

                        if (service.block_title?.includes('Shanyraq Plus')) {
                            return {
                                ...service,
                                block_title: 'Shanyraq Plus'
                            }
                        }

                        return service
                    })

                internetService.value = filteredInternetService ?? []
            } catch (error) {
                console.error('Error fetching internet services:', error)
                internetService.value = null

                const errorData: any = (error as AxiosError).response?.data

                SentryService.sendEvent({
                    message: 'FD | error with `getInternetService`',
                    tags: {
                        flow: 'FD'
                    },
                    extra: {
                        response: errorData
                    }
                })
            }
        }

        const getIdNetService = async (): Promise<void> => {
            if (idNetService.value) {
                return
            }

            try {
                const { data } = await FullDigitalApi.getIdNetService()

                const idNetServiceIds = [335, 352, 348]

                if (import.meta.env.VITE_NODE_ENV === 'staging') {
                    idNetServiceIds.push(283)
                }

                idNetService.value =
                    data.data['main_services'].map((obj: any) => ({
                        ...obj,
                        tab: idNetServiceIds.includes(obj.id) ? 'ftth' : 'adsl'
                    })) ?? []
            } catch (error) {
                const errorData: any = (error as AxiosError).response?.data

                SentryService.sendEvent({
                    message: 'FD | error with `getIdNetService`',
                    tags: {
                        flow: 'FD'
                    },
                    extra: {
                        response: errorData
                    }
                })
            }
        }

        const getWirelessService = async (): Promise<void> => {
            if (wirelessService.value) {
                return
            }

            try {
                const { data } = await FullDigitalApi.getWirelessService()

                const excludeIds = [376, 375, 374, 377]

                wirelessService.value =
                    data.data['main_services']?.filter(
                        (service: any) => !excludeIds.includes(service.id)
                    ) ?? []
            } catch (error) {
                const errorData: any = (error as AxiosError).response?.data

                SentryService.sendEvent({
                    message: 'FD | error with `getWirelessService`',
                    tags: {
                        flow: 'FD'
                    },
                    extra: {
                        response: errorData
                    }
                })
            }
        }

        const getAllService = async (): Promise<void> => {
            try {
                await getInternetService()
                await getIdNetService()
                await getWirelessService()
            } catch (e) {
                console.error('Error in getting data from services', e)
            }
        }

        const getServiceList = (type: string): PackageService[] | null => {
            if (!internetService.value || !idNetService.value) {
                return []
            }

            if (type === 'wireless') {
                return wirelessService.value
            }

            return [...internetService.value, ...idNetService.value].filter(
                (obj) => obj.tab === type
            )
        }

        const getServiceFilter = async (
            payload: ServiceFilterPayload
        ): Promise<void> => {
            if (!selectedService.value) {
                return
            }

            try {
                const { data } = await FullDigitalApi.getServiceFilter(payload)

                const excludedComponents: string[] = [
                    '1035762',
                    '1035756',
                    '1035813',
                    '1035790',
                    '1035750',
                    '1035743',
                    '1035815',
                    '1035814',
                    '1035813',
                    '1035786',
                    '1035785',
                    '1035786',
                    '1035777',
                    '1035791',
                    '1035795',
                    '1035790',
                    '1035774',
                    '1035773',
                    '1035775',
                    '1036408',
                    '1036472',
                    '1036473',
                    '1036454',
                    '1036455'
                ]

                savedFilterPayload.value = payload

                selectedService.value.filter = {
                    ...data.data,
                    components: data.data?.components?.filter(
                        (component) =>
                            !excludedComponents.includes(component.component_id)
                    )
                }
            } catch (error) {
                const errorData: any = (error as AxiosError).response?.data

                SentryService.sendEvent({
                    message: 'FD | error with `getServiceFilter`',
                    tags: {
                        flow: 'FD'
                    },
                    extra: {
                        response: errorData,
                        request: { selectedService: payload }
                    }
                })
            }
        }

        const getSelectedServiceSettings: ComputedRef<
            ServiceFilter | undefined
        > = computed(() => selectedService.value?.filter)

        const setSelectedService = (service: any): void => {
            selectedService.value = service
        }

        const setIsOntRent = (value: boolean): void => {
            isOntRent.value = value
        }

        const setIsHomePhone = (
            value: boolean,
            obj: ServiceComponent | null
        ): void => {
            isHomePhone.value = value
            if (obj) {
                homePhoneObject.value = obj
            }
        }

        const terminalCheck = async (
            payload: TerminalCheckPayload
        ): Promise<void> => {
            try {
                const { data } = await FullDigitalApi.getTerminalCheck(payload)

                terminalInfo.value = data
            } catch (error) {
                const errorData: any = (error as AxiosError).response?.data

                SentryService.sendEvent({
                    message: 'FD | error with `terminalCheck`',
                    tags: {
                        flow: 'FD'
                    },
                    extra: {
                        response: errorData,
                        request: { townStateId: payload }
                    }
                })
            }
        }

        const isTermEquipmentCount: ComputedRef<boolean> = computed(
            () =>
                terminalInfo.value?.stocks.some(
                    (el: TerminalCheckStocks) => el.termEquipmentCount >= 1
                ) ?? false
        )

        const setUserData = (data: any): void => {
            userData.value = {
                ...userData.value,
                ...data
            }
        }

        const getTownStates = async (): Promise<void> => {
            try {
                const { data } = await FullDigitalApi.getTownStates()

                townStates.value = data
            } catch (error) {
                const errorData: any = (error as AxiosError).response?.data

                SentryService.sendEvent({
                    message: 'FD | error with `getTownStates`',
                    tags: {
                        flow: 'FD'
                    },
                    extra: {
                        response: errorData
                    }
                })
            }
        }

        const getReserve = async (): Promise<boolean> => {
            const payload = {
                town_id: userData.value.townState.city_id,
                phone: authStore.getAituPhone,
                recaptchaToken: userData.value.captchaToken,
                city: {
                    reserve: isHomePhone.value
                },
                mobile: {
                    reserve: false
                }
            }

            try {
                const { data } = await FullDigitalApi.getReserve(payload)

                if (data.success) {
                    setUserData({ orderId: data.data.order_id })
                }

                return data.success
            } catch (error) {
                const errorData: any = (error as AxiosError).response?.data

                SentryService.sendEvent({
                    message: 'FD | error with `getReserve`',
                    tags: {
                        flow: 'FD'
                    },
                    extra: {
                        response: errorData,
                        request: { reservePayload: payload }
                    }
                })

                return false
            }
        }

        const getTownStatesModify: ComputedRef<SelectOption[]> = computed(
            () => {
                if (!townStates.value) return []

                const regionsCode: { [key: number]: string } = {
                    42: '1. astana',
                    170: '2. almaty',
                    1609: '3. kokshetau',
                    1695: '4. koschi',
                    1557: '5. aktobe',
                    135: '6. atyrau',
                    173: '7. uralsk',
                    1360: '8. taraz',
                    1433: '9. karaganda',
                    1434: '10. temirtau',
                    2: '11. kostanay',
                    2110: '12. kyzylorda',
                    164: '13. aktau',
                    203: '14. shymkent',
                    199: '15. turkestan',
                    1602: '16. pavlodar',
                    218: '17. petropavlovsk',
                    1960: '18. ust-kamenogorsk',
                    2165: '19. semey'
                }

                return townStates.value.map((region: TownStatesResponse) => ({
                    id: region.id,
                    label: region.text,
                    server_id: region.filial_id,
                    city_id: region.id,
                    town_id: region.town_id,
                    code: regionsCode[region.town_id]
                }))
            }
        )

        const reset = () => {
            userData.value = defaultUserDate
            selectedService.value = null
            isOntRent.value = false
            isHomePhone.value = false
            homePhoneObject.value = null
            terminalInfo.value = null
        }

        return {
            selectedService,
            getSelectedServiceSettings,
            isOntRent,
            isHomePhone,
            terminalInfo,
            isTermEquipmentCount,
            internetService,
            homePhoneObject,
            userData,
            townStates,
            getTownStatesModify,
            isHideAituVerify,
            bonuses,
            savedFilterPayload,
            getInternetService,
            getIdNetService,
            getWirelessService,
            getAllService,
            getServiceList,
            setSelectedService,
            getServiceFilter,
            setIsOntRent,
            setIsHomePhone,
            terminalCheck,
            setUserData,
            getTownStates,
            getReserve,
            reset,
            checkPromocode
        }
    },
    {
        persist: {
            paths: [
                'selectedService',
                'isOntRent',
                'isHomePhone',
                'homePhoneObject',
                'terminalInfo',
                'isHideAituVerify',
                'userData',
                'townStates',
                'bonuses'
            ]
        }
    }
)
