<template>
    <TModal
        class="free-tv-plus-modal"
        :model-value="show && !showDetailed"
        :close-icon="false"
        @close="emit('cancel')"
    >
        <iframe
            class="youtube-player"
            :src="youtubeUrl"
            title="YouTube video player"
            frameborder="0"
            referrerpolicy="strict-origin-when-cross-origin"
        />

        <TSpace class="content" vertical y-gap="24">
            <TSpace vertical y-gap="8">
                <h3 class="title">
                    {{ $t('free_tv_plus_title_1_line') }}
                    <br />
                    <span class="gradient-text">
                        {{ $t('free_tv_plus_title_2_line') }}
                    </span>
                </h3>

                <p class="description">
                    {{ $t('free_tv_plus_description', daysLeft) }}
                </p>
            </TSpace>

            <TSpace vertical y-gap="16">
                <TButton @click="handleDetail">
                    {{ $t('detail') }}
                </TButton>
                <TButton type="gray" @click="handleCancel">
                    {{ $t('not_this_time') }}
                </TButton>
            </TSpace>
        </TSpace>
    </TModal>

    <TModal v-model="showDetailed" full-width @close="emit('cancel')">
        <HowToWatchTvPlus />
    </TModal>
</template>

<script setup lang="ts">
import { computed, type ComputedRef, type Ref, ref } from 'vue'
import { TButton, TModal, TSpace } from '@telecom/t-components'

import HowToWatchTvPlus from './HowToWatchTvPlus.vue'
import { useTranslationsStore } from '@/shared/modules/translations/store'
import { LoyaltyMetrics } from '@/shared/services/metric/loyaltyMetrics'

defineProps<{
    show: boolean
    daysLeft: number
}>()

const emit = defineEmits<{
    (event: 'cancel'): void
}>()

const translationsStore = useTranslationsStore()
const { sendMetric } = new LoyaltyMetrics()

const youtubeUrl: ComputedRef<string> = computed(() =>
    translationsStore.language === 'kk'
        ? 'https://www.youtube.com/embed/fI7CeraNL60'
        : 'https://www.youtube.com/embed/zlWbna0kIdY'
)

const showDetailed: Ref<boolean> = ref(false)

const handleDetail = (): void => {
    showDetailed.value = true
    sendMetric('tvplus_newuser_modal', { clicked: 'connect' })
}

const handleCancel = (): void => {
    emit('cancel')
    sendMetric('tvplus_newuser_modal', { clicked: 'cancel' })
}
</script>

<style scoped lang="scss">
.free-tv-plus-modal {
    .youtube-player {
        width: 100%;
        height: 100%;
        aspect-ratio: 16 / 9;
    }

    .content {
        padding: 24px 16px;
        text-align: center;

        .title {
            font-size: text(h6);
            font-weight: 600;
            line-height: 30px;
        }

        .gradient-text {
            background: linear-gradient(
                119deg,
                #fff626 1.28%,
                #b82774 25.64%,
                #3cccbb 51.37%,
                #3751cc 73.29%,
                #a660ff 98.72%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    :deep(.t-modal__content) {
        padding: 0;
    }
}
</style>
