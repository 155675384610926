<template>
    <div class="active-bonus-modal">
        <TModal
            :model-value="show && !showSuccessModal && !showWarnModal"
            @close="$emit('cancel')"
        >
            <div class="active-bonus">
                <div class="banner-wrapper">
                    <img
                        class="active-bonus__banner"
                        src="@/assets/images/bonuses/kt-club-banner.png"
                    />
                    <h1 class="active-bonus__title">
                        {{ $t('bonuses_welcome_title') }}
                    </h1>
                </div>

                <TSpace
                    v-for="item in bonuses"
                    :key="item.id"
                    class="active-bonus__content"
                >
                    <img :src="item.icon" alt="" class="active-bonus__img" />
                    <div class="active-bonus__text">
                        <h4 class="active-bonus__subtitle">
                            {{ item.subtitle }}
                        </h4>
                        <p class="active-bonus__desc">{{ item.desc }}</p>
                    </div>
                </TSpace>

                <div
                    ref="activeBonusModalInfo"
                    class="active-bonus__info"
                    v-html="$t('bonuses_welcome_agreement')"
                ></div>

                <TSpace vertical style="width: 100%">
                    <TButton
                        :loading="bonusesStore.isLoading"
                        @click="handleActivateBonuses"
                    >
                        {{ $t('continue') }}
                    </TButton>
                </TSpace>
            </div>
        </TModal>
        <ActivateBonusesSuccessModal
            :show="showSuccessModal"
            @continue="handleContinue"
        />
        <ActivateBonusesWarnModal
            :show="showWarnModal"
            :title="bonusStatus?.message"
            :wait="isWaitStatus"
            @continue="handleContinueWarn"
        />
    </div>
</template>

<script setup lang="ts">
import { ref, type ComputedRef, type Ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { TButton, TModal, TSpace } from '@telecom/t-components'

import ActivateBonusesSuccessModal from './ActivateBonusesSuccessModal.vue'
import ActivateBonusesWarnModal from './ActivateBonusesWarnModal.vue'
import type { ActivateBonus } from './types'
import { useBonusesStore } from '@/shared/modules/bonuses/store'
import giftBoxIcon from '@/assets/images/bonuses/gift-box.png'
import walletIcon from '@/assets/images/bonuses/wallet.png'
import dartBoardIcon from '@/assets/images/bonuses/dart-board.png'
import type { GetCustomerBonusesStatus } from '@/shared/modules/bonuses/types'
import { useMetric } from '@/shared/hooks/useMetric'
import { YandexCounters } from '@/shared/services/metric/enum'
import { AituService } from '@/shared/services/aitu/aitu-service'

defineProps<{
    show: boolean
}>()

const emit = defineEmits<{
    (event: 'continue'): void
    (event: 'cancel'): void
}>()

const { t } = useI18n()
const bonusesStore = useBonusesStore()
const { sendMetric } = useMetric()

const showSuccessModal: Ref<boolean> = ref(false)
const showWarnModal: Ref<boolean> = ref(false)
const bonusStatus: Ref<GetCustomerBonusesStatus | null> = ref(null)
const activeBonusModalInfo: Ref<HTMLElement[] | null> = ref(null)

onMounted(() => {
    if (activeBonusModalInfo.value instanceof HTMLElement) {
        const bonusModalLinkPdf = activeBonusModalInfo.value.querySelector(
            '.loyalty-modal-link'
        )

        if (bonusModalLinkPdf) {
            const href = bonusModalLinkPdf.getAttribute('href') || ''

            bonusModalLinkPdf.addEventListener('click', (e) => {
                e.preventDefault()
                openLink(href)
            })
        }
    }
})

const openLink = (link: string): void => {
    AituService.openExternalUrl(link)
}

const bonuses: ActivateBonus[] = [
    {
        id: 1,
        icon: giftBoxIcon,
        subtitle: t('bonuses_welcome_subtitle_1'),
        desc: t('bonuses_welcome_description_1')
    },
    {
        id: 2,
        icon: walletIcon,
        subtitle: t('bonuses_welcome_subtitle_2'),
        desc: t('bonuses_welcome_description_2')
    },
    {
        id: 3,
        icon: dartBoardIcon,
        subtitle: t('bonuses_welcome_subtitle_3'),
        desc: t('bonuses_welcome_description_3')
    }
]

const isWaitStatus: ComputedRef<boolean> = computed(() => {
    return (
        bonusStatus.value?.status === 'BONUSES_READY_TO_SEND' ||
        bonusStatus.value?.status === 'WAITING_NEW_USER_TO_JOIN'
    )
})

const handleActivateBonuses = async (): Promise<void> => {
    const data = await bonusesStore.activateBonuses()

    bonusStatus.value = data ?? null
    showSuccessModal.value = true

    sendMetric('popup_onboarding_clicked', {}, YandexCounters.YandexTwo)
}

const handleContinue = (): void => {
    showSuccessModal.value = false

    if (bonusStatus.value?.code === 1) {
        showWarnModal.value = true
        return
    }

    emit('continue')

    sendMetric('popup_join_showed', {}, YandexCounters.YandexTwo)
}

const handleContinueWarn = (): void => {
    showWarnModal.value = false
    emit('continue')
}
</script>

<style lang="scss">
.active-bonus {
    .banner-wrapper {
        max-width: 342px;
        min-height: 246px;
    }

    &__banner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 16px 16px 0 0;
        max-height: 250px;
    }

    &__title {
        position: absolute;
        top: 21%;
        left: 28%;
        line-height: 125%;
        color: color(purple);
        font-size: text(p3);
    }

    &__content {
        margin: 14px 0;
    }
    &__subtitle {
        font-size: text(p5);
        color: color(black);
        font-weight: 600;
    }

    &__desc {
        font-size: text(p6);
        font-weight: 400;
        color: color(gray);
        line-height: 100%;
    }

    &__img {
        max-width: 64px;
        height: 64px;
    }

    &__info {
        font-size: text(p7);
        color: color(gray);
        text-align: center;
        line-height: 90%;
        font-weight: 400;
        margin: 0 -20px 14px;

        a {
            color: color(primary);
        }
    }
}

.active-bonus-modal {
    .t-modal-wrap {
        .t-modal {
            &__content {
                position: relative !important;
            }

            .close-icon {
                position: relative;
                z-index: 1;

                path {
                    fill: color(white);
                }
            }
        }
    }
}
</style>
