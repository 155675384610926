<template>
    <Wrapper>
        <LoginForm />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent, onBeforeMount } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'
import { AituService } from '@/shared/services/aitu/aitu-service'

const LoginForm = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/auth/components/LoginForm.vue')
})

const { setTitle } = useTitle()

setTitle('authorization')

onBeforeMount(() => {
    AituService.enableBackArrow()
    AituService.showBackArrow(false)
})
</script>
