<template>
    <Wrapper>
        <AutoPaymentWrapper />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const AutoPaymentWrapper = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import(
            '@/components/payments/AutoPayment/components/AutoPaymentWrapper.vue'
        )
})
</script>
