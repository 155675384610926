<template>
    <div class="user-balance-card">
        <Card>
            <TSpin v-if="isLoading" color="black" />

            <template v-else>
                <div class="user-balance-card__info">
                    <TSpace vertical y-gap="4">
                        <Alert v-if="detailBalanceError" type="warning">
                            <template #icon>
                                <TIcon stroke="#FFBB37">
                                    <AlertTriangleIcon />
                                </TIcon>
                            </template>
                            <template #text>
                                {{ $t('balance_detail_error') }}
                            </template>
                        </Alert>

                        <Alert
                            v-if="hasAccountsReceivable || hasDebtPayed"
                            :type="hasDebtPayed ? 'success' : 'error'"
                            :clickable="hasDebtPayed"
                            @on-click="switchModal('debtInfo')"
                        >
                            <template #text>
                                {{
                                    hasDebtPayed
                                        ? $t('debt_payment_activation_time')
                                        : $t('pay_off_debt_error')
                                }}
                            </template>
                        </Alert>
                        <TSpace
                            v-if="getHasOverPayment"
                            align="center"
                            justify="space-between"
                        >
                            <div class="user-balance-card__key">
                                {{ $t('free_funds') }}
                            </div>
                            <div class="user-balance-card__value">
                                {{
                                    TelecomService.formatAmountRound(
                                        Number(balanceDetails?.availableFunds)
                                    )
                                }}
                            </div>
                        </TSpace>
                        <TSpace
                            v-else-if="hasAccountsReceivable"
                            align="center"
                            justify="space-between"
                        >
                            <div class="user-balance-card__key text-red">
                                {{ $t('debt') }}
                            </div>
                            <div class="user-balance-card__value">
                                {{
                                    TelecomService.formatAmountRound(
                                        balanceDetails?.pdz
                                    )
                                }}
                            </div>
                        </TSpace>
                        <TSpace v-else align="center" justify="space-between">
                            <div
                                :class="balanceDetails?.isPdz ? 'text-red' : ''"
                                class="user-balance-card__key"
                            >
                                {{ $t('debt') }}
                            </div>
                            <div class="user-balance-card__value">
                                {{
                                    TelecomService.formatAmountRound(
                                        Number(balanceDetails?.debt)
                                    )
                                }}
                            </div>
                        </TSpace>

                        <TSpace
                            v-if="!getHasOverPayment && !hasAccountsReceivable"
                            align="center"
                            justify="space-between"
                        >
                            <div class="user-balance-card__key">
                                {{ $t('to_pay') }}
                            </div>
                            <div class="user-balance-card__value min">
                                {{
                                    $t('balance_due_by', {
                                        month: $t(
                                            getMonth(nextMonth).declension
                                        )
                                    })
                                }}
                            </div>
                        </TSpace>
                    </TSpace>
                </div>
                <div class="user-balance-card__actions">
                    <TSpace align="center" justify="center">
                        <TButton
                            :disabled="detailBalanceError"
                            type="outlined"
                            size="small"
                            full
                            @click="onDetails"
                        >
                            {{ $t('details') }}
                        </TButton>
                        <TButton size="small" full @click="onPay">
                            {{ $t('payments_pay') }}
                        </TButton>
                    </TSpace>
                </div>
            </template>
        </Card>
    </div>

    <TModal v-model="modalState.payment" @close="closePayment">
        <ModalPayment />
    </TModal>

    <TModal v-model="modalState.debtInfo" @close="switchModal('debtInfo')">
        <ModalSimple
            :title="$t('debt_fyi')"
            :text="$t('debt_fyi_text')"
            no-gap
            @success="switchModal('debtInfo')"
        >
            <template #image>
                <TSpace justify="center">
                    <TIcon width="56" height="56" stroke="#667a8b">
                        <InfoCircleIcon />
                    </TIcon>
                </TSpace>
            </template>
        </ModalSimple>
    </TModal>
</template>

<script setup lang="ts">
import {
    computed,
    type ComputedRef,
    onMounted,
    reactive,
    ref,
    type Ref
} from 'vue'
import { storeToRefs } from 'pinia'

import {
    AlertTriangleIcon,
    InfoCircleIcon,
    TButton,
    TIcon,
    TModal,
    TSpace,
    TSpin
} from '@telecom/t-components'
import { useRoute, useRouter } from 'vue-router'
import type { ModalState } from './types'
import { TelecomService } from '@/shared/services/telecom/telecom-service'
import { useModalBySteps } from '@/shared/hooks/useModalBySteps'
import ModalPayment from '@/shared/components/Modal/ModalPayment.vue'
import Card from '@/shared/components/Card/Card.vue'
import { useDetailsBalanceStore } from '@/components/detalization/DetailsBalance/store'
import { useMonth } from '@/shared/hooks/useMonth'
import { useMetric } from '@/shared/hooks/useMetric'
import Alert from '@/shared/components/UI/Alert.vue'
import ModalSimple from '@/shared/components/Modal/ModalSimple.vue'
import { useBonusesStore } from '@/shared/modules/bonuses/store'

const bonusesStore = useBonusesStore()
const detailsBalanceStore = useDetailsBalanceStore()
const { balanceDetails, hasAccountsReceivable, isLoading } =
    storeToRefs(detailsBalanceStore)
const { getMonth } = useMonth()
const router = useRouter()
const { sendMetric } = useMetric()
const route = useRoute()

const detailBalanceError: Ref<boolean> = ref(false)
const interval: Ref<number | undefined> = ref(undefined)

onMounted(async (): Promise<void> => {
    await getDetailsBalance()

    if (hasAccountsReceivable?.value && !hasDebtPayed.value) {
        sendMetric('PAYOFFTHEDEBT')
    }
})

const hasDebtPayed: ComputedRef<boolean | undefined> = computed(() => {
    return (
        balanceDetails.value?.drbDebitor &&
        !balanceDetails.value?.isPdz &&
        typeof balanceDetails.value?.isPdz === 'boolean'
    )
})

const getHasOverPayment: ComputedRef<boolean | undefined> = computed(() => {
    return detailsBalanceStore.getHasOverPayment()
})

const currentMonth: ComputedRef<number> = computed(() => {
    const currentDate = new Date()

    detailsBalanceStore.setCurrentMonth(getMonth(currentDate.getMonth()))

    return currentDate.getMonth()
})

const nextMonth: ComputedRef<number> = computed(() => {
    if (currentMonth.value + 1 === 12) {
        return 0
    }

    detailsBalanceStore.setNextMonth(getMonth(currentMonth.value + 1))

    return currentMonth.value + 1
})

const { modalState, switchModal } = useModalBySteps<ModalState>(
    reactive<ModalState>({
        chargesModal: false,
        balanceModal: false,
        payment: false,
        receiptNo: false,
        receiptGenerate: false,
        debtInfo: false
    })
)

const getDetailsBalance = async (): Promise<void> => {
    if (
        typeof balanceDetails.value === 'object' &&
        balanceDetails.value !== null &&
        Object.keys(balanceDetails.value).length > 0 &&
        !route.query.payment
    ) {
        return
    }

    try {
        await detailsBalanceStore.getBalanceDetails()

        if (hasAccountsReceivable.value && route.query.payment) {
            let counter = 0

            interval.value = setInterval(() => {
                if (counter === 3 || hasDebtPayed) {
                    clearInterval(interval.value)
                    return
                }

                detailsBalanceStore.getBalanceDetails()
                counter += 1
            }, 2000) as unknown as number
        } else {
            clearInterval(interval.value)
        }
    } catch (error: any) {
        const { message } = error.response?.data || {}

        if (message) {
            detailBalanceError.value = true
        }
    } finally {
        if (route.query.payment) {
            router.replace({ query: {} })
        }
    }
}

const onPay = (): void => {
    bonusesStore.toggleMiniBanner()
    switchModal('payment')
    sendMetric('PAY')
}

const closePayment = (): void => {
    bonusesStore.toggleMiniBanner()
    switchModal('payment')
}

const onDetails = (): void => {
    router.push({ name: 'details-balance' })
    sendMetric('DETAIL')
}

defineExpose({
    balanceDetails,
    hasAccountsReceivable,
    onDetails
})
</script>

<style scoped lang="scss">
.user-balance-card {
    display: block;

    .t-spin {
        justify-content: center;
    }

    &__key {
        font-weight: 400;
        font-size: text(p5);
        line-height: 125%;
        color: color(gray-light);

        &.text-red {
            color: color(red);
        }
    }

    &__value {
        font-weight: 600;
        font-size: text(p2);
        line-height: 127%;
        color: color(black);

        &.min {
            font-weight: 400;
            font-size: text(p5);
            line-height: 125%;
            color: color(black);
        }
    }
    &__actions {
        margin-top: 17px;
    }
}
</style>
