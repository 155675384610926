<template>
    <Wrapper>
        <ServiceChangeEsim />
    </Wrapper>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const ServiceChangeEsim = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import('@/components/services/ServiceChangeEsim/ServiceChangeEsim.vue')
})

const { setTitle } = useTitle()

setTitle('detalization_mobile_change_e_sim')
</script>

<style scoped lang="scss"></style>
