import { storeToRefs } from 'pinia'
import type { AxiosError } from 'axios'

import type { Language } from '../translations/types'
import { CustomerApi } from './api'
import { useCustomerStore } from './store'
import type {
    AituCustomerSaveRequestBody,
    CustomerAccount,
    CustomerAgreement,
    ParsedCustomerAccount,
    CustomerDeliverySelected
} from './types'
import { AituService } from '@/shared/services/aitu/aitu-service'
import { SentryService } from '@/shared/services/sentry/sentry-service'

export class CustomerService {
    private static getParsedAccounts(
        data: CustomerAgreement[]
    ): CustomerAccount[] {
        const accounts: CustomerAccount[] = []

        for (const agreement of data) {
            for (const account of agreement.customer_account) {
                if (!account.hide_local_id) {
                    accounts.push(account)
                }
            }
        }

        return accounts
    }

    static getDefaultAccount(data: CustomerAgreement[]): CustomerAccount {
        return this.getParsedAccounts(data)[0]
    }

    static getAccountByID(
        data: CustomerAgreement[],
        id: number
    ): CustomerAccount {
        return this.getParsedAccounts(data).find(
            (account) => account.local_id === id
        )!
    }

    static getParsedCustomerAccountsList(
        data: CustomerAgreement[]
    ): ParsedCustomerAccount[] {
        return this.getParsedAccounts(data).reduce(
            (acc: ParsedCustomerAccount[], item: CustomerAccount) => {
                if (!item.hide_local_id) {
                    acc.push({
                        id: item?.local_id,
                        street: item.address.selectedStreet.text,
                        city: item.address.selectedTown.text,
                        house: item.address.selectedHouse.text,
                        flat: item.address.flat,
                        sub_flat: item.address.sub_flat
                    })
                }
                return acc
            },
            []
        )
    }

    static getCustomerDeliveryType(
        agreements: CustomerAgreement[],
        localId: number
    ): CustomerDeliverySelected {
        const data = agreements.filter((item) =>
            item.customer_account.find(
                (customer) => customer.local_id === localId
            )
        )

        return data.map((item) => {
            const customerAccount = item.customer_account[0]
            const deliveryType = customerAccount?.bill_delivery_type_id

            return {
                delivery_type: deliveryType,
                delivery_spec_id:
                    deliveryType === 1
                        ? 0
                        : customerAccount?.digital_bill_delivery_data?.[0]
                              ?.digital_bill_delivery_spec_id,
                address:
                    deliveryType === 1
                        ? ''
                        : customerAccount?.digital_bill_delivery_data?.[0]
                              ?.address
            }
        })[0]
    }

    static async checkCustomerAituSave(
        customerId: number | undefined,
        locale: Language
    ): Promise<void> {
        if (!customerId) {
            console.log('customer id doesnt exist ===')
            return
        }

        const { id } = await AituService.getMe()
        const langs: { [key: string]: 1 | 2 | 3 } = {
            ru: 1,
            kk: 2,
            en: 3
        }

        const body: AituCustomerSaveRequestBody = {
            customerId,
            aituUserId: id,
            status: 1,
            locale: langs[locale as string] || 1
        }

        try {
            await CustomerApi.aituCustomerSave(body)
        } catch (error) {
            console.log('error in `aituCustomerSave` method ==> ', error)
            const errorData: any = (error as AxiosError)?.response?.data

            SentryService.sendEvent({
                message: 'AUTH | error with `aituCustomerSave` method',
                tags: {
                    flow: 'auth'
                },
                extra: {
                    request: {
                        ...body
                    },
                    response: errorData
                }
            })
        }
    }

    static redirectPushSetCurrentAccount(accountLocalId: string): void {
        const customerStore = useCustomerStore()
        const { getMetadata } = storeToRefs(customerStore)

        if (!getMetadata.value?.agreement) {
            return
        }

        const currentAccount = CustomerService.getAccountByID(
            getMetadata.value.agreement,
            Number(accountLocalId)
        )

        customerStore.setCurrentAccount(currentAccount)
    }
}
