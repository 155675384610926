<template>
    <Wrapper>
        <DetalizationInternet />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const DetalizationInternet = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import(
            '@/components/detalization/DetalizationInternet/DetalizationInternet.vue'
        )
})

const { setTitle } = useTitle()

setTitle('services_package_home_internet')
</script>
