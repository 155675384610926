import { defineStore } from 'pinia'
import { ref, type Ref, computed, type ComputedRef } from 'vue'

import { AutoPaymentApi } from './api'
import { AutoPaymentService } from './service'
import type {
    AutoPaymentResponse,
    AutoPaymentResponseData,
    AutoPaymentForm,
    DebitCard,
    AddDebitCardPayload,
    AutoPaymentPayload,
    RemoveDebitCardPayload
} from '@/components/payments/AutoPayment/types'
import { useCustomerStore } from '@/shared/modules/customer/store'
import { AituService } from '@/shared/services/aitu/aitu-service'

export const useAutoPaymentStore = defineStore(
    'autoPayment',
    () => {
        const isLoading: Ref<boolean> = ref(false)

        const customerStore = useCustomerStore()

        const error: Ref<string | null> = ref(null)

        const autoPayment: Ref<AutoPaymentResponse | null> = ref(null)

        const cardList: Ref<DebitCard[]> = ref([])

        const removeCard: Ref<DebitCard> = ref({
            card_id: null,
            card_mask: ''
        })

        const autoPaymentData: ComputedRef<AutoPaymentResponseData> = computed(
            () => {
                if (!autoPayment.value || !autoPayment.value.data) {
                    return {}
                }

                return autoPayment.value.data
            }
        )

        const isEnabled: ComputedRef<boolean> = computed(() =>
            Boolean(!autoPayment.value?.errCode)
        )

        const isActive: ComputedRef<boolean> = computed(() => {
            if (autoPayment.value && autoPayment.value.data) {
                return Boolean(autoPayment.value.data.is_active)
            }

            return false
        })

        const setIsLoading = (state: boolean): void => {
            isLoading.value = state
        }

        const getInfo = async (): Promise<void> => {
            try {
                if (!customerStore.getId) {
                    return
                }

                const { data } = await AutoPaymentApi.getInfo(
                    customerStore.getId
                )

                autoPayment.value = AutoPaymentService.setAutoPayment(data)
            } catch (err) {
                if (err instanceof Error) {
                    error.value = err.message
                } else {
                    error.value = 'An unknown error occurred.'
                }
            }
        }

        const setAutoPaymentForm = (payload: AutoPaymentForm): void => {
            if (!autoPayment.value?.data) {
                return
            }

            autoPayment.value.data = { ...autoPayment.value.data, ...payload }
        }

        const updateAutoPaymentDate = (newDate: Date): void => {
            if (autoPayment.value && autoPayment.value.data) {
                autoPayment.value.data.autopayment_date =
                    newDate.toLocaleDateString('en-US')
                autoPayment.value.data.autopayment_day = newDate.getDate()
            }
        }

        const getCardList = async (): Promise<void> => {
            try {
                if (!customerStore.getId) {
                    return
                }

                const { data } = await AutoPaymentApi.getCardList(
                    customerStore.getId
                )

                if (!data.errCode) {
                    cardList.value = AutoPaymentService.setCardList(
                        data.data,
                        autoPaymentData.value
                    )
                }
            } catch (err) {
                if (err instanceof Error) {
                    error.value = err.message
                } else {
                    error.value = 'An unknown error occurred.'
                }
            }
        }

        const getActiveCard: ComputedRef<DebitCard | undefined> = computed(
            () => {
                return cardList.value.find((card) => card.is_active === true)
            }
        )

        const addCard = async (payload: AddDebitCardPayload): Promise<void> => {
            try {
                const { data } = await AutoPaymentApi.addCard(payload)

                // window.location.href = data.redirect_url
                AituService.openExternalUrl(data.redirect_url)
            } catch (err) {
                if (err instanceof Error) {
                    error.value = err.message
                } else {
                    error.value = 'An unknown error occurred.'
                }
            }
        }

        const removedCard = async (): Promise<void> => {
            try {
                if (!customerStore.getId || !removeCard.value?.card_token) {
                    return
                }

                const payload: RemoveDebitCardPayload = {
                    customer_account_id: customerStore.getId,
                    card_token: removeCard.value.card_token
                }

                await AutoPaymentApi.removeCard(payload)

                await getCardList()
            } catch (err) {
                if (err instanceof Error) {
                    error.value = err.message
                } else {
                    error.value = 'An unknown error occurred.'
                }
            }
        }

        const setRemoveCard = (card: DebitCard): void => {
            removeCard.value = card
        }

        const changeActiveCard = (cardId: string): void => {
            cardList.value = cardList.value.map((card) => {
                setAutoPaymentForm({
                    card_token: card.card_token,
                    card_mask_full: card.card_mask,
                    card_mask: `****${card.card_mask.slice(-4)}`
                })

                return {
                    ...card,
                    is_active: card['is_active']
                        ? false
                        : card['card_id'] === cardId
                }
            })
        }

        const createAutoPayment = async (
            payload: AutoPaymentPayload
        ): Promise<void> => {
            try {
                if (!customerStore.getId) {
                    return
                }

                await AutoPaymentApi.createAutoPayment(payload)

                await getInfo()
            } catch (err) {
                if (err instanceof Error) {
                    error.value = err.message
                } else {
                    error.value = 'An unknown error occurred.'
                }
            }
        }

        const updateAutoPayment = async (
            payload: AutoPaymentPayload
        ): Promise<void> => {
            try {
                if (!customerStore.getId) {
                    return
                }

                await AutoPaymentApi.updateAutoPayment(payload)

                await getInfo()
            } catch (err) {
                if (err instanceof Error) {
                    error.value = err.message
                } else {
                    error.value = 'An unknown error occurred.'
                }
            }
        }

        const deleteAutoPayment = async (): Promise<void> => {
            try {
                if (!customerStore.getId) {
                    return
                }

                await AutoPaymentApi.deleteAutoPayment({
                    customer_account_id: customerStore.getId
                })

                await getInfo()
            } catch (err) {
                if (err instanceof Error) {
                    error.value = err.message
                } else {
                    error.value = 'An unknown error occurred.'
                }
            }
        }

        const clearAutoPayment = (): void => {
            autoPayment.value = null
            cardList.value = []
        }

        return {
            isLoading,
            setIsLoading,
            autoPayment,
            autoPaymentData,
            isEnabled,
            isActive,
            cardList,
            removeCard,
            getActiveCard,
            getInfo,
            updateAutoPaymentDate,
            setAutoPaymentForm,
            getCardList,
            setRemoveCard,
            addCard,
            removedCard,
            changeActiveCard,
            createAutoPayment,
            updateAutoPayment,
            deleteAutoPayment,
            clearAutoPayment
        }
    },
    {
        persist: true
    }
)
