import { ref, type Ref, computed, type ComputedRef } from 'vue'
import { defineStore } from 'pinia'

import type { SelectOption } from '@telecom/t-components/dist/components/TSelect/types'
import { AppealsMobileApi } from './api'
import { AppealService } from './appealService'
import type {
    OrdersCart,
    Appeal,
    AppealsCart,
    CreateAppealForm,
    Channel,
    OrderDetail,
    AppealResponse,
    WFMAppeals,
    WFMOrders
} from './types'
import { useServicesStore } from '@/components/services/ServiceList/store'
import type { Service } from '@/components/services/ServiceList/types'

export const useAppealsStore = defineStore('appeals', () => {
    const serviceStore = useServicesStore()

    const appeals: Ref<AppealsCart[] | null> = ref(null)
    const orders: Ref<AppealsCart[] | null> = ref(null)
    const appealDetail: Ref<Appeal[] | null> = ref(null)
    const orderDetail: Ref<OrdersCart | null> = ref(null)
    const isLoading: Ref<boolean> = ref(false)
    const error: Ref<string | null> = ref(null)
    const services: Ref<Service[] | null> = ref(null)
    const reasons: Ref<SelectOption[] | null> = ref(null)
    const consultationReasons: Ref<SelectOption[] | null> = ref(null)
    const channels: Ref<Channel[] | null> = ref(null)
    const wfmAppealsData: Ref<WFMAppeals> = ref({
        isDYM: false,
        id: 0,
        status: ''
    })
    const wfmOrdersData: Ref<WFMOrders> = ref({
        isDYI: false,
        id: 0,
        status: ''
    })

    const resetOrders = (): void => {
        orders.value = null
    }

    const resetAppeals = (): void => {
        appeals.value = null
    }

    const setIsLoading = (state: boolean): void => {
        isLoading.value = state
    }

    const getAppeals = async (): Promise<void> => {
        if (appeals.value) {
            return
        }

        isLoading.value = true

        try {
            const { data } = await AppealsMobileApi.getAppeals()
            appeals.value = AppealService.modifiedAppeals(data.communications)
        } catch (err) {
            if (err instanceof Error) {
                error.value = err.message
            } else {
                error.value = 'An unknown error occurred.'
            }
        } finally {
            isLoading.value = false
        }
    }

    const getOrders = async (): Promise<void> => {
        if (orders.value) {
            return
        }

        isLoading.value = true

        try {
            const { data } = await AppealsMobileApi.getOrders()
            orders.value = AppealService.modifiedOrders(data)
        } catch (err) {
            if (err instanceof Error) {
                error.value = err.message
            } else {
                error.value = 'An unknown error occurred.'
            }
        }
    }

    const selectDYMData = async (data: AppealResponse): Promise<void> => {
        try {
            const DYMType = 10
            const execudeDYMState = [3, 6, 9, 15]

            const { communications: [fC] = [] } = data || {}

            if (
                fC.COMMUNICATION_TYPE_ID === DYMType &&
                !execudeDYMState.includes(fC.COMMUNICATION_STATE_ID) &&
                fC.SINGLE_DAMAGE_ID
            ) {
                const orderWfmEntitySpecId = 31
                const singleDamageId = fC.SINGLE_DAMAGE_ID

                const DYMData = await AppealsMobileApi.getWFMData(
                    String(singleDamageId),
                    orderWfmEntitySpecId
                )

                wfmAppealsData.value = AppealService.modifiedWFMAppelItem(
                    data.communications,
                    DYMData.data
                )
            }
        } catch (err) {}
    }

    const getAppealDetail = async (id: string): Promise<void> => {
        isLoading.value = true

        try {
            const { data } = await AppealsMobileApi.getAppealItem(id)

            await selectDYMData(data)

            appealDetail.value = AppealService.modifiedAppealItem(
                data.communications
            )
        } catch (err) {
            if (err instanceof Error) {
                error.value = err.message
            } else {
                error.value = 'An unknown error occurred.'
            }
        } finally {
            isLoading.value = false
        }
    }

    const selectDYIData = async (
        id: string,
        data: OrderDetail
    ): Promise<void> => {
        try {
            const DYIType = 3

            const { order } = data.status

            if (order && order.osmOrderTypeId === DYIType) {
                const orderWfmEntitySpecId = 1
                const DYIData = await AppealsMobileApi.getWFMData(
                    id,
                    orderWfmEntitySpecId
                )

                wfmOrdersData.value = AppealService.modifiedWFMOrderItem(
                    id,
                    DYIData.data
                )
            }
        } catch (err) {}
    }

    const getOrderDetail = async (id: string): Promise<void> => {
        isLoading.value = true

        try {
            const { data } = await AppealsMobileApi.getOrderItem(id)

            await selectDYIData(id, data)

            orderDetail.value = AppealService.modifiedOrderItem(data.status)
        } catch (err) {
            if (err instanceof Error) {
                error.value = err.message
            } else {
                error.value = 'An unknown error occurred.'
            }
        } finally {
            isLoading.value = false
        }
    }

    const getServices = async (): Promise<void> => {
        isLoading.value = true

        try {
            await serviceStore.setServices()
            services.value = serviceStore.services
        } catch (err) {
            if (err instanceof Error) {
                error.value = err.message
            } else {
                error.value = 'An unknown error occurred.'
            }
        } finally {
            isLoading.value = false
        }
    }

    const getReasonsArray = async (id: number): Promise<void> => {
        isLoading.value = true

        try {
            const { data } = await AppealsMobileApi.getReasons(id)
            reasons.value = AppealService.modifiedReasons(data)
        } catch (err) {
            if (err instanceof Error) {
                error.value = err.message
            } else {
                error.value = 'An unknown error occurred.'
            }
        } finally {
            isLoading.value = false
        }
    }

    const createAppealeForm = async (data: CreateAppealForm): Promise<void> => {
        isLoading.value = true

        try {
            await AppealsMobileApi.createAppealForm(data)
        } catch (err) {
            if (err instanceof Error) {
                error.value = err.message
            } else {
                error.value = 'An unknown error occurred.'
            }
        } finally {
            isLoading.value = false
        }
    }

    const getConsultReasons = async (): Promise<void> => {
        if (consultationReasons.value) {
            return
        }

        isLoading.value = true

        try {
            const { data } = await AppealsMobileApi.getConsultCauses()
            consultationReasons.value = AppealService.modifiedReasons(data)
        } catch (err) {
            if (err instanceof Error) {
                error.value = err.message
            } else {
                error.value = 'An unknown error occurred.'
            }
        } finally {
            isLoading.value = false
        }
    }

    const historyAppeal: ComputedRef<AppealsCart[] | null> = computed(() => {
        if (!appeals.value) {
            return []
        }

        return appeals.value
            .filter((appeal) =>
                [3, 6, 9, 15, 17, 20].includes(appeal.status_id)
            )
            .sort((a, b) => b.create_date.localeCompare(a.create_date))
    })

    const historyOrder: ComputedRef<AppealsCart[] | null> = computed(() => {
        if (!orders.value) {
            return []
        }

        return orders.value
            .filter((order) => [-1, 3].includes(order.status_id))
            .sort((a, b) => b.create_date.localeCompare(a.create_date))
    })

    const activeAppeal: ComputedRef<AppealsCart[] | null> = computed(() => {
        if (!appeals.value) {
            return []
        }

        return appeals.value.filter(
            (appeal) => ![3, 6, 9, 15, 17, 20].includes(appeal.status_id)
        )
    })

    const activeOrder: ComputedRef<AppealsCart[] | null> = computed(() => {
        if (!orders.value) {
            return []
        }

        return orders.value.filter(
            (order: AppealsCart) => ![-1, 3].includes(order.status_id)
        )
    })

    const filteredServices: ComputedRef<Service[] | null> = computed(() => {
        if (!services.value) {
            return []
        }

        return services.value.filter(
            (service) =>
                (service.TYPE === 'package' || service.TYPE === 'base') &&
                !(
                    service.TYPE === 'base' &&
                    (service.SERVICE_TYPE === 'RENT' ||
                        service.SERVICE_TYPE === 'VAS' ||
                        service.SERVICE_TYPE === 'CC_TV')
                )
        )
    })

    const serviceSubscription: ComputedRef<SelectOption[]> = computed(() => {
        if (!filteredServices.value) {
            return []
        }

        return AppealService.modifyServiceNameSubscribtion(
            filteredServices.value
        )
    })

    const historyMergedArrays: ComputedRef<AppealsCart[]> = computed(() => {
        if (!historyAppeal.value) {
            return []
        }

        if (!historyOrder.value) {
            return []
        }

        return historyAppeal.value
            .concat(historyOrder.value)
            .sort((a, b) => b.create_date.localeCompare(a.create_date))
    })

    const activeMergedArrays: ComputedRef<AppealsCart[]> = computed(() => {
        if (!activeAppeal.value) {
            return []
        }

        if (!activeOrder.value) {
            return []
        }

        return activeAppeal.value
            .concat(activeOrder.value)
            .sort((a, b) => b.create_date.localeCompare(a.create_date))
    })

    const getChannels = async (): Promise<void> => {
        if (channels.value) {
            return
        }

        isLoading.value = true

        try {
            const { data } = await AppealsMobileApi.getChannels()
            channels.value = data
        } catch (err) {
            if (err instanceof Error) {
                error.value = err.message
            } else {
                error.value = 'An unknown error occurred.'
            }
        } finally {
            isLoading.value = false
        }
    }

    return {
        isLoading,
        setIsLoading,
        error,
        appeals,
        getAppeals,
        orders,
        wfmOrdersData,
        wfmAppealsData,
        getOrders,
        historyAppeal,
        historyOrder,
        activeAppeal,
        activeOrder,
        historyMergedArrays,
        activeMergedArrays,
        getAppealDetail,
        appealDetail,
        getOrderDetail,
        orderDetail,
        getServices,
        services,
        filteredServices,
        getReasonsArray,
        reasons,
        createAppealeForm,
        serviceSubscription,
        getConsultReasons,
        consultationReasons,
        getChannels,
        channels,
        resetOrders,
        resetAppeals
    }
})
