import { computed, type ComputedRef, ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import type { AxiosResponse } from 'axios'

import { OffersApi } from './api'
import { OffersService } from './services'
import type {
    Offer,
    OffersRequestResponse,
    SetTVPlusRequest,
    SetTVPlusResponse
} from './types'

export const useOffersStore = defineStore('cctv', () => {
    const isLoading: Ref<boolean> = ref(false)
    const isConnecting: Ref<boolean> = ref(false)

    const offers: Ref<OffersRequestResponse | null> = ref(null)
    const liveLink: Ref<string> = ref('')

    /**
     * TODO: После исправления задержки на бекенде - удалить
     */
    const isHideInfoTvPlus: Ref<boolean> = ref(false)

    const cctvOffers: ComputedRef<Offer[]> = computed(() => {
        if (!offers.value) {
            return []
        }

        return OffersService.getParsedOffers(offers.value)
    })

    const tvPlusOffers: ComputedRef<Offer[]> = computed(() => {
        if (!offers.value) {
            return []
        }

        return offers.value['TV+'] as Offer[]
    })

    const setOffers = async (): Promise<void> => {
        if (offers.value) {
            return
        }

        isLoading.value = true

        try {
            const { data } = await OffersApi.getOffers()

            offers.value = data.orders
        } finally {
            isLoading.value = false
        }
    }

    const setStaticOffers = (): void => {
        offers.value = {
            'TV+': [
                {
                    ID: 33519,
                    RESOURCE_SPECIFICATION_ID: '-1',
                    NAME: 'Prime',
                    DESCRIPTION:
                        'Интерактивное телевидение от Казахтелеком. Управляйте эфиром (пауза, перемотка, архив записей). Просмотр на ТВ и смартфонах. 30+ каналов, 15+ в HD. Бесплатный доступ к фильмам Амедиатека, TV+ и "Сделано в Казахстане". Можно подключить до 5 экранов.',
                    STATUS: 'disabled',
                    TYPE: 'base',
                    PRICE: 1390,
                    REQUIRED_FIELDS: {
                        CUSTOMER_ACCOUNT_ID: true,
                        SALES_CHANNEL_ID: true,
                        PRODUCT_OFFER_ID: true
                    },
                    components: [
                        {
                            ID: '1033505',
                            NAME: '36779_Super TV+'
                        }
                    ]
                },
                {
                    ID: 36779,
                    RESOURCE_SPECIFICATION_ID: '-1',
                    NAME: 'Super New',
                    DESCRIPTION:
                        'Интерактивное телевидение от Казахтелеком. Управляйте эфиром (пауза, перемотка, архив записей). Просмотр на ТВ и смартфонах. 30+ каналов, 15+ в HD. Бесплатный доступ к фильмам Амедиатека, TV+ и "Сделано в Казахстане". Можно подключить до 5 экранов.',
                    STATUS: 'disabled',
                    TYPE: 'base',
                    PRICE: 3990,
                    REQUIRED_FIELDS: {
                        CUSTOMER_ACCOUNT_ID: true,
                        SALES_CHANNEL_ID: true,
                        PRODUCT_OFFER_ID: true
                    },
                    components: [
                        {
                            ID: '1033505',
                            NAME: '36779_Super TV+'
                        }
                    ]
                }
            ]
        }
    }

    const resetOffers = async (): Promise<void> => {
        offers.value = null

        await setOffers()
    }

    const setLiveLink = (link: string): void => {
        liveLink.value = link
    }

    const setTVPlus = (
        body: SetTVPlusRequest
    ): Promise<AxiosResponse<SetTVPlusResponse>> => {
        isConnecting.value = true

        return new Promise((resolve, reject) => {
            OffersApi.setTVPlus(body)
                .then((response) => {
                    isConnecting.value = true
                    resolve(response)
                })
                .catch((error) => {
                    isConnecting.value = true
                    reject(error)
                })
        })
    }

    /**
     * TODO: После исправления задержки на бекенде - удалить
     */
    const toggleHideInfoTvPlus = () => {
        isHideInfoTvPlus.value = !isHideInfoTvPlus.value

        setTimeout(async () => {
            await resetOffers()
            isHideInfoTvPlus.value = !isHideInfoTvPlus.value
        }, 60000)
    }

    return {
        isLoading,
        isConnecting,
        offers,
        cctvOffers,
        tvPlusOffers,
        liveLink,
        isHideInfoTvPlus,
        setOffers,
        resetOffers,
        setLiveLink,
        setTVPlus,
        setStaticOffers,
        toggleHideInfoTvPlus
    }
})
