<template>
    <TSpace class="bonuses-card-static" y-gap="16" vertical>
        <h2 class="section-title">
            {{ $t('bonuses') }}
        </h2>

        <Card class="bonuses-card" @click="emit('on-click')">
            <TSpace>
                <img
                    class="bonus-logo"
                    src="@/assets/images/currency-bonuses-large.svg"
                    alt="bonuses"
                />

                <TSpace
                    justify="space-between"
                    align="center"
                    style="width: 100%"
                >
                    <TSpace vertical y-gap="4">
                        <TSpace x-gap="4" align="center">
                            <strong>
                                {{ $t('bonuses') }}
                            </strong>
                        </TSpace>

                        <small>
                            {{ $t('loyalty-program') }}
                        </small>
                    </TSpace>

                    <TButton
                        type="gray"
                        rounded
                        no-padding
                        style="padding: 4px"
                    >
                        <TIcon stroke="#5685FE">
                            <ArrowNarrowRightIcon />
                        </TIcon>
                    </TButton>
                </TSpace>
            </TSpace>
        </Card>
    </TSpace>
</template>

<script setup lang="ts">
import {
    ArrowNarrowRightIcon,
    TButton,
    TIcon,
    TSpace
} from '@telecom/t-components'

import Card from '@/shared/components/Card/Card.vue'

const emit = defineEmits<{
    (event: 'on-click'): void
}>()
</script>

<style lang="scss" scoped>
.bonuses-card {
    display: block;
    padding: 16px;
    cursor: pointer;

    .bonus-logo {
        width: 48px;
        height: 48px;
    }

    strong {
        font-size: text(p4);
    }

    small {
        color: color(gray);
    }
}
</style>
