<template>
    <div class="banners-slider-wrapper">
        <div v-if="isLoading" class="loading-box">
            <TSpin :show="isLoading" color="#000" />
        </div>
        <Swiper
            class="banners-slider"
            :space-between="30"
            :centered-slides="true"
            :autoplay="{
                delay: 5000,
                disableOnInteraction: false
            }"
            :pagination="customPagination"
            :auto-height="true"
        >
            <SwiperSlide v-if="sapaEnabled" @click="sapaLink">
                <div class="slide-content">
                    <div class="slide-content__image full">
                        <img
                            src="@/assets/images/sapa/sapa-plus.png"
                            alt="images"
                        />
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide v-if="hasRecommendations">
                <div class="slide-content slide-content--archive">
                    <div class="slide-content__text">
                        <h3>{{ $t('dear_subscriber') }}</h3>
                        <p>{{ $t('change_archive_expired_period') }}</p>
                        <TButton
                            type="gray"
                            size="tiny"
                            @click="onRecommendationMore"
                        >
                            {{ $t('detail') }}
                        </TButton>
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide
                v-for="(slide, index) in mainSlider"
                :key="index"
                @click="
                    goToUrl(String(slide.link), Boolean(slide.is_custom_link)),
                        handleSendMetricsBySlideName(slide.slide_name)
                "
            >
                <div class="slide-content">
                    <div class="slide-content__image full">
                        <img :src="slide?.mobile || undefined" alt="images" />
                    </div>
                    <div class="slide-content__text">
                        <h3>{{ slide.name }}</h3>
                        <p>{{ slide.description }}</p>
                    </div>
                </div>
            </SwiperSlide>

            <div ref="paginationRef" class="swiper-pagination"></div>
        </Swiper>
    </div>
</template>

<script setup lang="ts">
import { computed, type ComputedRef, onMounted, ref, type Ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { TButton, TSpin } from '@telecom/t-components'

import 'swiper/css'

import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { useSlidersStore } from '@/shared/modules/sliders/store'
import { useSapaStore } from '@/components/sapa/store'
import { useChangeArchiveStore } from '@/components/change-archive/store'
import type { ArchiveRecommendations } from '@/components/change-archive/types'
import { TariffPlan } from '@/components/change-archive/types'
import { useMetric } from '@/shared/hooks/useMetric'
import { AituService } from '@/shared/services/aitu/aitu-service'
import { useAuthStore } from '@/components/auth/store'
import { LoyaltyMetrics } from '@/shared/services/metric/loyaltyMetrics'

SwiperCore.use([Navigation, Pagination, Autoplay])

const changeArchiveStore = useChangeArchiveStore()
const { isLoading, recommendations } = storeToRefs(changeArchiveStore)
const { sendMetric } = useMetric()
const loyaltyMetrics = new LoyaltyMetrics()
const router = useRouter()
const hasUpgradePrice: Ref<boolean> = ref(false)
const isLoadingBanners: Ref<boolean> = ref(false)
const authStore = useAuthStore()

const slidersStore = useSlidersStore()
const { mainSlider } = storeToRefs(slidersStore)
const { sapaEnabled } = storeToRefs(useSapaStore())

const customPagination = {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index: number, className: string): string {
        return `
            <li class="${className}" tab-index="${index}">
                <div class="autoplay-progress">
                    <svg viewBox="0 0 48 48">
                        <circle
                            cx="24"
                            cy="24"
                            r="20"
                            stroke-linecap="round"
                        ></circle>
                    </svg>
                </div>
            </li>
        `
    }
}

onMounted(() => {
    getRecommendations()
    getMainSlider()
})

const getRecommendations = (): void => {
    if (!authStore.isAuthenticated) {
        return
    }

    changeArchiveStore.getRecommendations()

    if (hasRecommendations.value) {
        sendMetric('HOMEAPPEARANCECONTRACTTERMINATIONBANNER')
    }
}

const hasRecommendations: ComputedRef<boolean | null | string> = computed(
    () =>
        recommendations.value &&
        recommendations.value.length > 0 &&
        recommendations.value.some(
            (item: ArchiveRecommendations) =>
                item.category === TariffPlan.TariffFirst
        )
)

const swiperPaddingBottom: ComputedRef<string> = computed(() => {
    let total = 1

    if (hasRecommendations.value || hasUpgradePrice.value) {
        total++
    }

    if (total > 1) {
        return '30px'
    }

    if (mainSlider.value.length > 1) {
        return '30px'
    }

    return '0px'
})

const sapaLink = (): void => {
    AituService.openExternalUrl('https://www.instagram.com/reel/DBbZ648IC5j/')
}

const onRecommendationMore = (): void => {
    router.push({ name: 'change-archive' })
    sendMetric('HOMEENDCONTRACTBANERNEXT')
}

const getMainSlider = async (): Promise<void> => {
    isLoadingBanners.value = true
    let slugs: string[] = []

    if (authStore.isAuthenticated) {
        slugs = ['auth_main_slider']
    } else {
        slugs = ['redesign_main_slider']
    }

    await slidersStore.getMainSlider(slugs)

    isLoadingBanners.value = false
}

const goToUrl = (url: string, customLink: boolean): void => {
    if (!url || url === '/') {
        return
    }

    if (customLink) {
        router.push({ path: url })
    } else {
        AituService.openExternalUrl(url)
    }

    loyaltyMetrics.sendMetric('banner_auth', {
        clicked: 'sosed'
    })
    loyaltyMetrics.sendMetric('banner_auth', {
        clicked: 'sosed'
    })
}

const handleSendMetricsBySlideName = (name: string | null): void => {
    switch (name) {
        case 'Invite_neighour_for_auth_zone_MP':
            loyaltyMetrics.sendMetric('banner_auth', {
                clicked: 'sosed'
            })
            break
        case 'Invite_neighour_for_non_auth_zone_MP':
            loyaltyMetrics.sendMetric('banner_unauth', {
                clicked: 'sosed'
            })
            break
        default:
            break
    }
}
</script>

<style lang="scss">
.banners-slider {
    &.swiper {
        width: 100%;
        height: 100%;
        padding-bottom: v-bind(swiperPaddingBottom);

        .swiper-pagination {
            bottom: 0;
        }

        .swiper-pagination-bullet {
            background-color: color(gray);
            width: 10px;
            height: 10px;
            position: relative;

            .autoplay-progress {
                display: none;
            }
        }

        .swiper-pagination-bullet-active {
            background-color: color(primary);

            .autoplay-progress {
                display: flex;

                svg {
                    animation: progress 5000ms linear;
                }
            }
        }
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: rounded(xl);

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .slide-content {
        min-height: 140px;
        width: 100%;
        background-color: color(gray);
        color: color(white);
        padding: 18px 16px;
        padding-left: 80px;
        border-radius: rounded(xl);
        position: relative;
        overflow: hidden;

        &__image {
            position: absolute;
            bottom: 0;
            left: 5px;
            height: 105px;
            width: 76px;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;

                &.desktop {
                    display: none;
                }
            }

            &.full {
                width: 100%;
                height: 100%;
                left: 0;
            }
        }

        &--archive {
            background-image: url('@/assets/images/change-archive.png');
            background-size: cover;
            background-position: center center;
        }

        &__text {
            margin-left: 70px;
        }

        .t-button.outlined {
            color: color(white);
        }

        h3 {
            font-weight: 500;
            font-size: text(p4);
            line-height: 127.7%;
            margin-bottom: 2px;
        }

        p {
            font-weight: 400;
            line-height: 125%;
            margin-bottom: 8px;
        }

        .t-button {
            color: color(white);
            background-color: rgba(255, 255, 255, 0.3);

            &:hover {
                background-color: color(white);
                color: color(dark);
            }
        }

        &--upgrade-price {
            background-color: color(white);
            color: color(dark);
            padding-left: 16px;
            padding-right: 55px;

            .slide-content__image {
                position: absolute;
                top: 4px;
                right: 6px;
                left: unset;
                width: 47px;
                height: 50px;
            }

            h3 {
                font-weight: 500;
                font-size: text(p4);
                line-height: 127.7%;
            }

            p {
                font-size: text(p4);
                margin-bottom: 0;

                &.gray {
                    color: color(gray);
                }
            }

            .t-button {
                margin-top: 8px;
                background-color: color(primary);

                &:hover {
                    color: color(white);
                    background-color: color(primary-hover);
                }
            }
        }
    }

    .autoplay-progress {
        position: absolute;
        left: -4px;
        top: -4px;
        z-index: 99;
        width: 18px;
        height: 18px;
        display: none;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: color(primary);

        svg {
            position: absolute;
            left: 0;
            top: 0px;
            z-index: 99;
            width: 18px;
            height: 18px;
            stroke-width: 4px;
            stroke: color(primary);
            fill: none;
            stroke-dashoffset: 125.6;
            stroke-dasharray: 125.6;
            transform: rotate(-90deg);
        }
    }

    @keyframes progress {
        0% {
            stroke-dashoffset: 125.6;
        }

        100% {
            stroke-dashoffset: 0;
        }
    }
}

.loading-box {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
