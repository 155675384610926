import type { OffersRequestResponse, Offer } from './types'

export class OffersService {
    static getParsedOffers(data: OffersRequestResponse | null): Offer[] {
        if (!data) {
            return []
        }

        const offers: Offer[] = []
        const validIdArray: number[] = [34599]

        for (const [key, value] of Object.entries(data)) {
            if (key === 'TV+') {
                continue
            }

            if (Array.isArray(value)) {
                value.forEach((item) => {
                    if (validIdArray.includes(item.ID)) {
                        offers.push(item)
                    }
                })
            } else if (validIdArray.includes(value.ID)) {
                offers.push(value)
            }
        }

        return offers
    }
}
