<template>
    <Wrapper>
        <AppealDetailIframe />
    </Wrapper>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const AppealDetailIframe = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/appeals/WFM/AppealDetailIframe.vue')
})

const { setTitle } = useTitle()

setTitle('appeals_wfm_select_date')
</script>
