<template>
    <ReregistrApplicationDetail />
</template>

<script setup lang="ts">
import { defineAsyncComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import Preloader from '@/shared/components/UI/Preloader.vue'
import PageError from '@/shared/components/UI/PageError.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import { AituService } from '@/shared/services/aitu/aitu-service'

const { setTitle } = useTitle()
const router = useRouter()

setTitle('re_registration_pa')

const ReregistrApplicationDetail = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import(
            '@/components/re-registration/Reregistration/components/ReregistrationApplicationDetail.vue'
        )
})

const backHandler = (): void => {
    router.push({ name: 're-registration-appeal.list' })
}

onMounted(() => {
    AituService.enableBackArrow()
    AituService.showBackArrow()
    AituService.setBackArrowHandler(() => backHandler())
})
</script>

<style scoped lang="scss"></style>
