import type { Language } from '../modules/translations/types'

export class UrlService {
    private static addToUrl(language: Language, url: string): string {
        return `/${language}${url}`
    }

    static getTranslatedUrl(
        url: string | undefined,
        language: Language
    ): string {
        if (!url) {
            return ''
        }

        const modifiedUrl = url.startsWith('http')
            ? url
            : UrlService.addToUrl(language, url)

        return modifiedUrl
    }
}
