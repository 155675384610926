<template>
    <Wrapper>
        <AppealsServiceQuality />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const AppealsServiceQuality = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import('@/components/appeals/Appeals/AppealServiceQuality.vue')
})

const { setTitle } = useTitle()

setTitle('appeals_quality_of_service')
</script>
