export const useModalBySteps = <T = Record<string, boolean>>(modalState: T) => {
    const queueModals = new Set<keyof T>()

    const disableModals = (): void => {
        for (const key in modalState) {
            modalState[key as keyof T] = false as T[keyof T]
        }
    }

    const switchModal = (key: keyof T): void => {
        const lastElement = Array.from(queueModals)[queueModals.size - 1]

        lastElement === key
            ? queueModals.delete(lastElement)
            : queueModals.add(key)

        disableModals()

        if (queueModals.size > 0) {
            modalState[Array.from(queueModals)[queueModals.size - 1]] =
                true as T[keyof T]
        }
    }

    return {
        modalState,
        switchModal
    }
}
