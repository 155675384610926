<template>
    <Wrapper>
        <ReregistrationSuccess />

        <ReregistrationCloseModal ref="closeModalRef" />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent, onMounted, ref } from 'vue'

import Preloader from '@/shared/components/UI/Preloader.vue'
import PageError from '@/shared/components/UI/PageError.vue'
import type { CloseModalInterface } from '@/components/re-registration/Reregistration/types'
import { useHeaderClose } from '@/components/full-digital/hooks/useHeaderClose'
import { AituService } from '@/shared/services/aitu/aitu-service'
import ReregistrationCloseModal from '@/components/re-registration/Reregistration/components/ReregistrationCloseModal.vue'
import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'

const { setTitle } = useTitle()

setTitle('')

const ReregistrationSuccess = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import(
            '@/components/re-registration/Reregistration/components/ReregistrationSuccess.vue'
        )
})

const closeModalRef = ref<CloseModalInterface | null>(null)
const { enableHeaderClose } = useHeaderClose(closeModalRef)

onMounted(() => {
    AituService.disableBackArrow()

    enableHeaderClose()
})
</script>

<style scoped lang="scss"></style>
