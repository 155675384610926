import { defineStore } from 'pinia'
import { computed, ref, type ComputedRef, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { AxiosError } from 'axios'

import { useToast } from '@telecom/t-components'
import {
    AituRedirectState,
    type AuthenticateAituType,
    type AuthenticateRequestBody,
    type OAuthState,
    type ResetPasswordRequestBody,
    type Token
} from './types'
import { AuthService } from './authService'
import { AuthApi } from './api'
import { useAppRedirect } from './hooks/useAppRedirect'
import type {
    AituResponseModifiedType,
    AituResponseType,
    GetPhoneResponse
} from '@/shared/types/aitu'
import { AituService } from '@/shared/services/aitu/aitu-service'
import { useCustomerStore } from '@/shared/modules/customer/store'
import { SentryService } from '@/shared/services/sentry/sentry-service'

export const useAuthStore = defineStore(
    'authorization',
    () => {
        const customerStore = useCustomerStore()
        const { t } = useI18n()
        const { queryRedirectMap } = useAppRedirect()
        const oauth: Ref<OAuthState> = ref({
            token_type: '',
            expires_in: 0,
            access_token: '',
            refresh_token: '',
            expires_at: 0
        })
        const aituPhone: Ref<string> = ref('')
        const currentPincode: Ref<string> = ref('')
        const tempCustomerName: Ref<string> = ref('')
        const isBiometryEnabled: Ref<boolean> = ref(false)
        const tempCustomerId: Ref<number> = ref(0)

        const setOAuth = (data: Token) => {
            oauth.value = {
                ...data,
                expires_at: AuthService.getExpiresAt(data.expires_in)
            }
        }

        const unsetOAuth = () => {
            oauth.value = {
                token_type: '',
                expires_in: 0,
                access_token: '',
                refresh_token: '',
                expires_at: 0
            }
        }

        const setTempCustomerName = (name: string) => {
            tempCustomerName.value = name
        }

        const setIsBiometryEnabled = (state: boolean) => {
            isBiometryEnabled.value = state
        }

        const checkBiometry = async (): Promise<
            AituResponseType | AituResponseModifiedType | void
        > => {
            try {
                const result = await AituService.checkBiometry()

                // @todo: remove this condition
                if (result === 'false') {
                    useToast({
                        message: t('biometry_disabled_or_not_matched'),
                        type: 'warn',
                        duration: 3000,
                        loading: false,
                        closable: false,
                        closeText: '',
                        onClose: () => {}
                    })
                }

                return result
            } catch (error) {
                console.warn('biometry is unavailable ===>', error)
                useToast({
                    message: t('biometry_disabled_or_not_matched'),
                    type: 'warn',
                    duration: 3000,
                    loading: false,
                    closable: false,
                    closeText: '',
                    onClose: () => {}
                })

                return 'failed'
            }
        }

        const setTempCustomerId = (id: number) => {
            tempCustomerId.value = id
        }

        const login = (credentials: AuthenticateRequestBody) => {
            return new Promise((resolve, reject) => {
                AuthApi.authenticate(credentials)
                    .then(({ data }) => {
                        setOAuth(data)
                        resolve(data)
                    })
                    .catch((error) => {
                        console.log('error in `login` request', error)
                        useToast({
                            message: error?.response?.data?.errors || 'Ошибка',
                            type: 'error',
                            duration: 3000,
                            loading: false,
                            closable: false,
                            closeText: '',
                            onClose: () => {}
                        })

                        reject(error)
                    })
            })
        }

        const refreshToken = () => {
            return new Promise((resolve, reject) => {
                AuthApi.refresh({
                    refresh_token: oauth.value.refresh_token
                })
                    .then(({ data }) => {
                        AituService.setItem('auth/token', data)

                        setOAuth(data)
                        resolve(data)
                    })
                    .catch((error) => {
                        console.log('error in `refresh` request', error)
                        const errorData: any = (error as AxiosError)?.response
                            ?.data
                        const status = Number(
                            (error as AxiosError)?.message.split('code')[1]
                        )

                        if (status !== 401) {
                            SentryService.sendEvent({
                                message: 'AUTH | error with `refresh` method',
                                tags: {
                                    flow: 'auth'
                                },
                                extra: {
                                    request: oauth.value.refresh_token,
                                    response: errorData
                                }
                            })
                        }

                        reject(error)
                    })
            })
        }

        const resetPassword = (data: ResetPasswordRequestBody) => {
            return new Promise((resolve, reject) => {
                AuthApi.resetPassword(data)
                    .then(({ data }) => {
                        resolve(data)
                    })
                    .catch((error) => {
                        console.log('error in `resetPassword` request', error)
                        reject(error)
                    })
            })
        }

        const logout = (): Promise<void> => {
            return new Promise((resolve) => {
                unsetOAuth()
                customerStore.unsetCustomer()
                AituService.setItem('auth/token', '')

                resolve()
            })
        }

        const setAituPhone = async () => {
            const result: GetPhoneResponse | null = await AituService.getPhone()

            if (!result) {
                return
            }

            aituPhone.value = result.phone.substring(1)
        }

        const setPincode = async (pin?: string) => {
            if (pin) {
                currentPincode.value = pin

                return
            }

            const result = await AituService.getItem('auth/pin')

            if (!result) {
                return
            }

            currentPincode.value = result
        }

        const generateAituLink = (
            type: AuthenticateAituType = 'authentication',
            state = queryRedirectMap.value.redirectPush
                ? `redirectPush=${queryRedirectMap.value.redirectPush}`
                : AituRedirectState.Default
        ): Promise<string> => {
            return new Promise((resolve, reject) => {
                const releaseBranch =
                    import.meta.env.VITE_RELEASE_ENV === 'release' ? 1 : 0

                AuthApi.authenticateAitu({
                    phone: aituPhone.value,
                    type,
                    state,
                    redesign: 1,
                    release_branch: releaseBranch
                })
                    .then(({ data }) => {
                        AituService.enableBackArrow()
                        AituService.showBackArrow(false)

                        resolve(data.link)
                    })
                    .catch((error) => {
                        console.log('generateAituLink error ====> ', error)
                        const errorData: any = (error as AxiosError)?.response
                            ?.data

                        SentryService.sendEvent({
                            message:
                                'AUTH | error with `generateAituLink` method',
                            tags: {
                                flow: 'auth'
                            },
                            extra: {
                                request: {
                                    phone: aituPhone.value,
                                    authType: type,
                                    state,
                                    redesign: 1,
                                    redirectPush:
                                        queryRedirectMap.value.redirectPush ||
                                        'default'
                                },
                                response: errorData
                            }
                        })

                        reject(error)
                    })
            })
        }

        const isAuthenticated: ComputedRef<boolean> = computed(
            () => !!oauth.value.access_token
        )

        const getExpiresAt: ComputedRef<number> = computed(
            () => oauth.value.expires_at
        )

        const getAccessToken: ComputedRef<string> = computed(
            () => oauth.value.access_token
        )

        const getRefreshToken: ComputedRef<string> = computed(
            () => oauth.value.refresh_token
        )

        const getAituPhone: ComputedRef<string> = computed(
            () => aituPhone.value
        )

        const getCurrentPincode: ComputedRef<string> = computed(
            () => currentPincode.value
        )

        const getTempCustomerName: ComputedRef<string> = computed(
            () => tempCustomerName.value
        )

        const getTempCustomerId: ComputedRef<number> = computed(
            () => tempCustomerId.value
        )

        return {
            aituPhone,
            oauth,
            isAuthenticated,
            getExpiresAt,
            getAccessToken,
            getRefreshToken,
            getAituPhone,
            getCurrentPincode,
            tempCustomerName,
            getTempCustomerName,
            isBiometryEnabled,
            getTempCustomerId,
            tempCustomerId,
            setOAuth,
            unsetOAuth,
            login,
            logout,
            resetPassword,
            refreshToken,
            setAituPhone,
            generateAituLink,
            setPincode,
            setTempCustomerName,
            setIsBiometryEnabled,
            checkBiometry,
            setTempCustomerId
        }
    },
    {
        persist: true
    }
)
