export enum YandexCounters {
    YandexFirst = import.meta.env.VITE_YANDEX_METRIC_FIRST_ID,
    YandexTwo = import.meta.env.VITE_YANDEX_METRIC_TWO_ID,
    YandexThree = import.meta.env.VITE_YANDEX_METRIC_THREE_ID
}

export enum AmplitudeCounters {
    AmplitudeFirst = import.meta.env.VITE_AMPLITUDE_API_KEY,
    AmplitudeSecond = import.meta.env.VITE_AMPLITUDE_SECOND_API_KEY
}
