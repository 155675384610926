<template>
    <Wrapper>
        <ServiceRulesWrapper />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const ServiceRulesWrapper = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/important-docs/ServiceRulesWrapper.vue')
})

const { setTitle } = useTitle()

setTitle('telecom_services_rules')
</script>

<style scoped lang="scss"></style>
