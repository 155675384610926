<template>
    <Wrapper>
        <OnlineBookingWrapper />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'
import { useHeaderClose } from '@/components/online-booking/hooks/useHeaderClose'
import { AituService } from '@/shared/services/aitu/aitu-service'

const OnlineBookingWrapper = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import('@/components/online-booking/components/OnlineBookingStart.vue')
})

const router = useRouter()

const onClose = (): void => {
    router.push({ name: 'home' })
}

const { setTitle } = useTitle()
const { enableHeaderClose } = useHeaderClose(onClose)

onMounted(() => {
    AituService.enableBackArrow()
    AituService.showBackArrow(false)

    enableHeaderClose()
})

setTitle('online_booking')
</script>

<style scoped lang="scss"></style>
