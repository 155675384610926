import type { AxiosResponse } from 'axios'

import type {
    CreateAppealForm,
    Appeals,
    Orders,
    OrderDetail,
    AppealResponse,
    AppealReasons,
    Channel,
    WFMData,
    ResetInternetParams
} from './types'
import axios from '@/shared/services/api/axios'

export class AppealsMobileApi {
    static getAppeals(): Promise<AxiosResponse<Appeals>> {
        return axios.get('/api/v1.0/customer/communications')
    }

    static getOrders(): Promise<AxiosResponse<Orders[]>> {
        return axios.get('/api/v1.0/customer/orders')
    }

    static getAppealItem(
        appealId: string
    ): Promise<AxiosResponse<AppealResponse>> {
        return axios.get(`/api/v1.0/customer/communications/${appealId}`)
    }

    static getOrderItem(orderId: string): Promise<AxiosResponse<OrderDetail>> {
        return axios.get(`/api/v1.0/main/web-hook/status/${orderId}`)
    }

    static getWFMData(
        order: string,
        entitySpecId: number
    ): Promise<AxiosResponse<WFMData>> {
        return axios.get('/api/v1.0/customer/wfm-work-order', {
            params: {
                entityId: order,
                entitySpecId
            }
        })
    }

    static getReasons(
        productId: number
    ): Promise<AxiosResponse<AppealReasons[]>> {
        return axios.get(
            `/api/v1.0/open/potential/reasons/?productId=${productId}`
        )
    }

    static createAppealForm(
        data: CreateAppealForm
    ): Promise<AxiosResponse<AppealResponse>> {
        return axios.post('/api/v1.0/customer/communications/create', data)
    }

    static getConsultCauses(): Promise<AxiosResponse<AppealReasons[]>> {
        return axios.get('/api/v1.0/customer/communications/consult-reasons')
    }

    static getChannels(): Promise<AxiosResponse<Channel[]>> {
        return axios.get('/api/v1.0/customer/communications/channels')
    }

    static resetAvailablitiyCheck(
        customerAccountId: number
    ): Promise<AxiosResponse<void>> {
        return axios.get(
            '/api/v1.0/customer/internet-technology/session-reset-limit',
            { params: { customerAccountId } }
        )
    }

    static resetInternetSession(
        data: ResetInternetParams
    ): Promise<AxiosResponse<void>> {
        return axios.post(
            '/api/v1.0/customer/internet-technology/reset-active-session',
            data
        )
    }
}
