import type { Orders } from '@/components/appeals/types'
import { TransferAddressStatus } from './types'
import type { TownStatesResponse } from '@/components/full-digital/types'
import type { Street } from '@/shared/types/telecom'
import type { SelectModelOption } from '@/shared/types/types'
import type { CustomerSelectedHouse } from '@/shared/modules/customer/types'
import type { Service } from '@/components/services/ServiceList/types'

export class TransferAddressService {
    static findTownById(data: TownStatesResponse[], townId: number): any {
        if (data) {
            const city = data.find((item) => {
                return item.id === townId
            })

            return city
        }
    }

    static modifyStreets = (streets: Street[]): SelectModelOption[] => {
        if (!streets.values) {
            return []
        }

        return streets.map((street: Street) => ({
            id: street.id,
            label: street.street_type_short_name + street.text,
            street_type_id: street.street_type_id,
            street_type_name: street.street_type_name,
            street_type_short_name: street.street_type_short_name,
            value: street.id,
            text: street.street_type_short_name + street.text
        }))
    }

    static modifyHouse = (
        houses: CustomerSelectedHouse[]
    ): SelectModelOption[] => {
        if (!houses.values) {
            return []
        }

        return houses.map((house: CustomerSelectedHouse) => {
            const houseLabel = house.idx
                ? house.num.toString()
                : house.text?.toString() || ''

            return {
                id: Number(house.num),
                label: houseLabel,
                value: house.idx || '',
                text: house.text
            }
        })
    }

    static filterService = (data: Service[] | null): string => {
        if (!data) {
            return ''
        }

        const productOffer = data.filter(
            (service: Service) =>
                service.TYPE != 'RENT' && service.STATUS == 'connected'
        )

        return productOffer[0].NAME
    }

    static checkOrdersStatus(orders: Orders[]): TransferAddressStatus {
        const hasOpenOrder = orders.some(
            (order: Orders) =>
                order.osm_order_status_id !== 3 &&
                order.osm_order_status_id !== -1
        )

        if (hasOpenOrder) {
            return TransferAddressStatus.OpenOrderFailed
        }

        return TransferAddressStatus.OpenOrderChecked
    }
}
