export enum SapaOrderStatus {
    Waiting = 'queue',
    Created = 'created',
    Refinement = 'refinement',
    Assigned = 'assigned',
    Installation = 'installation',
    Completed = 'completed',
    Canceled = 'cancelled'
}

export type SapaOrderType = 'installator' | 'megaliner'

export interface SegmentCustomerAccount {
    customer_account_id: number
    customer_account_local_id: number
    request_id: number | null
    filial_id: number
}

export interface SapaAccountAddress {
    street: string
    city: string
    house: string
    flat?: number | null
    sub_flat?: number | null
}

export interface SapaAccount extends SegmentCustomerAccount {
    address: SapaAccountAddress
    status?: SapaOrderStatus
    type?: SapaOrderType | null
}

export interface GetSegmentByCustomerResponse {
    show: boolean
    customer_accounts: SegmentCustomerAccount[]
}

export interface PostSapaOrderRequest {
    type: 'install'
}

export interface GetSapaOrderResponse {
    id: number
    status: SapaOrderStatus
    type: SapaOrderType | null
    otp_confirmation?: string
}

export interface PostSapaOrderResponse {
    id: number
    message: string
}

export interface RequestForSignDocParams {
    codeVerify: string
    entityAuthorizationId: number
}

export type RequestForSignDoc = Record<string, RequestForSignDocParams>

export interface PostSapaBlankRequest {
    request_id: number
    entity_id?: number
    mobile_phone: string
    otp?: string
}

export interface PostSapaBlankResponse {
    data: string
}

export interface PostSapaOrderAcceptRequest {
    otp: string
    entity_authorization_id?: number
}

export interface PostSapaOrderAcceptResponse {
    message: string
    otp_confirmation: string
}

export interface RatingModalState {
    success: boolean
}

export interface GetSapaTerminalEquipmentQueryParams {
    serial_number: string
}

export interface SapaRouterInfo {
    vendor: string
    model_name: string
    serial_number: string
    price: string
    status: string
}

export interface GetSapaTerminalEquipmentResponse extends SapaRouterInfo {}

export interface PostSapaRatingRequest {
    request_id: number
    rating: number
    message?: string
}

export interface PostSapaRatingResponse {
    message: string
}

export enum SapaClientErrorCode {
    GetOrderStatus = '91',
    OrderTypeNotFound = '92',
    SegmentByAccountNotFound = '93',
    SegmentDisabledByCustomer = '94',
    SegmentFailed = '95',
    CreateSapaBlank = '96',
    OtpConfirmationNotFound = '97'
}
