import axios from 'axios'

import { useTranslationsStore } from '@/shared/modules/translations/store'
import { useAuthStore } from '@/components/auth/store'

const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_LOYALTY_URL
})

export const defineBonusesAxiosDefaults = (): void => {
    const translationsStore = useTranslationsStore()
    const authStore = useAuthStore()

    axiosInstance.interceptors.request.use((request) => {
        request.headers.Authorization = 'Bearer ' + authStore.getAccessToken
        request.headers['Accept-Language'] = translationsStore.language

        return request
    })
}

export default axiosInstance
