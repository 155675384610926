<template>
    <Wrapper>
        <DetalizationWrapper />
    </Wrapper>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const DetalizationWrapper = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/detalization/DetalizationWrapper.vue')
})

const { setTitle } = useTitle()

setTitle('detalization')
</script>

<style scoped lang="scss"></style>
