<template>
    <AutoPaymentInfo />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const AutoPaymentInfo = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import(
            '@/components/payments/AutoPayment/components/AutoPaymentInfo.vue'
        )
})

const { setTitle } = useTitle()

setTitle('payments_my_autopayment')
</script>
