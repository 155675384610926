<template>
    <div class="modal">
        <h4 class="modal__title">
            <img
                src="@/assets/images/bell-notification.svg"
                :alt="$t('needed_update_app')"
            />
            <span>{{ $t('needed_update_app') }}</span>
        </h4>

        <div class="modal__actions">
            <slot name="actions">
                <TButton full @click="emit('update')">
                    {{ $t('update') }}
                </TButton>
            </slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { TButton } from '@telecom/t-components'

const emit = defineEmits<{
    (event: 'update'): void
}>()
</script>

<style scoped lang="scss">
.modal {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__title {
        font-weight: 600;
        font-size: text(p4);
        line-height: 125%;
        margin: 0 auto;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 4px;

        img {
            display: block;
            width: 56px;
            height: 56px;
        }
    }

    &:deep(p) {
        color: color(dark);
        line-height: 125%;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: 12px;

        @include media(min, md) {
            flex-direction: row;
            gap: 16px;
        }
    }

    @include media(min, md) {
        gap: 24px;
    }
}
</style>
