import type { AxiosResponse } from 'axios'

import type {
    ArchiveRecommendations,
    ProductOfferResponse,
    ArchiveData
} from './types'
import axios from '@/shared/services/api/axios'

export class ChangeArchiveApi {
    static getRecommendations(): Promise<
        AxiosResponse<ArchiveRecommendations[]>
    > {
        return axios.get('/api/v1.0/customer/bigdata/recommendations')
    }

    static getProductOffers(
        productOfferid: string
    ): Promise<AxiosResponse<ProductOfferResponse>> {
        return axios.get(
            `/api/v1.0/customer/bigdata/product-offer/${productOfferid}/components`
        )
    }

    static replaceArchive(data: ArchiveData): Promise<AxiosResponse<any>> {
        return axios.post('/api/v1.0/customer/orders/replace', data)
    }
}
