<template>
    <Wrapper>
        <ServiceTransferSign />
    </Wrapper>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'
import { useTitle } from '@/shared/hooks/useTitle'

const ServiceTransferSign = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () =>
        import('@/components/services/ServiceTransfer/components/ServiceTransferSign.vue')
})

const { setTitle } = useTitle()

setTitle('service_transfer')
</script>

<style scoped lang="scss"></style>
