<template>
    <GameWrapper />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'
import { useTitle } from '@/shared/hooks/useTitle'

const GameWrapper = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/bonuses/components/GameWrapper.vue')
})

const { setTitle } = useTitle()

setTitle('nauryz_challenge_sale')
</script>
