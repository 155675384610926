import axios from 'axios'
import { useRouter } from 'vue-router'

import { UrlService } from '../url-service'
import { useTranslationsStore } from '@/shared/modules/translations/store'
import { useAuthStore } from '@/components/auth/store'
import { useCustomerStore } from '@/shared/modules/customer/store'

axios.defaults.baseURL =
    import.meta.env.VITE_API_BASE_URL || 'https://dev-srv-0.telecom.kz'

export const defineAxiosDefaults = () => {
    const translationsStore = useTranslationsStore()
    const authStore = useAuthStore()
    const customerStore = useCustomerStore()
    const router = useRouter()

    axios.interceptors.request.use((request) => {
        request.url = UrlService.getTranslatedUrl(
            request.url,
            translationsStore.language
        )

        if (authStore.isAuthenticated) {
            request.headers.Authorization = 'Bearer ' + authStore.getAccessToken

            if (customerStore.currentAccount) {
                const params =
                    typeof request.params === 'object' ? request.params : {}
                params['filial_id'] =
                    params['filial_id'] ||
                    customerStore.currentAccount.filial_id
                params['account_id'] =
                    params['account_id'] || customerStore.currentAccount.id
                params['account_local_id'] =
                    params['account_local_id'] ||
                    customerStore.currentAccount.local_id

                request.params = params
            }
        }

        return request
    })

    axios.interceptors.response.use(null, (error) => {
        if (error?.response?.status === 401) {
            authStore.logout().then(() => {
                router.push({ name: 'login' })
            })
        }

        throw error
    })
}

export default axios
