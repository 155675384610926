export interface ModalState {
    failure: boolean
    success: boolean
    approve: boolean
    notInList: boolean
}

export interface ModalClose {
    onClose: boolean
}

export interface TransferRequestId {
    requestId: number
}

export interface TransferCreateRequest {
    clientId: number
}

export interface TransferRelocationResponse {
    result: boolean
    townStateId: number
}

export interface TransferRelocationData {
    status: string
}

export interface CreateOrderResponse {
    orderId: string
}

export interface TechvAvailability {
    requestId: number
    townStateId: number
    streetId: number
    house: number | string
    flat: number
    subFlat?: string
    subHouse?: string
    residentialTypeId: number
}

export interface OtpSend {
    phone_number: string
    otp_code?: string
}

export enum TransferAddressStatus {
    Created = 'created',
    Checked = 'сhecked',
    Acquainted = 'acquainted',
    OpenOrderFailed = 'openOrderFailed',
    OpenOrderChecked = 'openOrderChecked',
    AutoBlankSigned = 'autoBlankSigned',
    TechNotAvailable = 'techNotAvailable',
    NoAddress = 'noAddress'
}

export interface CreateAutoblankForm {
    BlankID: number // 18 in this case
    CID: number // order_id from api/v1.0/open/onboarding/reserve
    DocumentParams: {
        user?: string
        code?: number | string | null // should be empty
        mobNum?: number | string | null // should be empty
        isRent?: number // 0 || 1
        isArenda?: number // 0 || 1
    }
    dataJson: {
        localID: number  // - cause no 'ls'
        paymentFormID: number // 1 - кредитная 2 - авансовая
        isDemandInstaller: number // 0 || 1
        firstName?: string
        middleName?: string
        lastName?: string
        annualContractId: string
        blankAbonentName: string
        newProductOffer: string // product_offer_name from api/v1.0/open/onboarding/package/filter
        docNumber: string
        docDateFrom: string
        docIssuedBy: string
        email: string | null
        IIN: string
        townStateName: string
        streetName: string
        house: number
        subHouse?: number | null
        flat: number | null
        adrStreet: string
        townStateNameNew: string
        streetNameNew: string
        houseNew: number
        subHouseNew?: string | null
        flatNew: number | null
        adrStrNew: string
        oldProductOffer: string | null
    }
}
