import { computed, type ComputedRef, ref, type Ref } from 'vue'
import { defineStore } from 'pinia'

import type { AxiosError } from 'axios'
import { ReregistrationApi } from './api'
import type {
    SearchByIinParams,
    SearchByIinAndMobileResponse,
    SearchByMobilePhoneParams,
    SearchByLocalIdParams,
    SearchByLocalIdResponse,
    CheckProductsParams,
    CheckProductsResponse,
    ConnectedServicesParams,
    PreviousOwnerPersonalAccountData,
    ConnectedServicesResponse,
    GbdflAddressesParams,
    GbdflAddressesResponse,
    GbdflAddress,
    GetAddressParams,
    GetAddressResponse,
    AddressesMatchParams,
    AddressesMatchResponse,
    ChangeBillAccountOwnerParams,
    GetBillAccountOwnerParams,
    MakeReregistrationRequestParams,
    Application,
    GenerateReissuePdfParams,
    ReregistrationError,
    ReregistrationSuccess,
    GenerateReissueDocumentParams,
    CompleteExReregistrationResponse, MakeRequestReissueParams
} from './types'
import { useCustomerStore } from '@/shared/modules/customer/store'
import { OnboardingApi } from '@/shared/modules/onboarding/api'
import { SentryService } from '@/shared/services/sentry/sentry-service'
import { TelecomService } from '@/shared/services/telecom/telecom-service'

export const useReregistrationStore = defineStore(
    'reregistration',
    () => {
        const customerStore = useCustomerStore()

        const isLoading: Ref<boolean> = ref(false)

        const errorState: Ref<ReregistrationError | null> = ref(null)
        const successState: Ref<ReregistrationSuccess | null> = ref(null)

        const requestId: Ref<number | null> = ref(null)
        const searchType: Ref<string | null> = ref(null)
        const foundLocalIds: Ref<SearchByIinAndMobileResponse | null> =
            ref(null)
        const foundOwner: Ref<SearchByLocalIdResponse | null> = ref(null)
        const exOwnerProducts: Ref<CheckProductsResponse | null> = ref(null)
        const selectedLocal: Ref<CheckProductsParams | null> = ref(null)
        const previousOwnerPersonalAccountData: Ref<PreviousOwnerPersonalAccountData | null> =
            ref(null)
        const previousOwnerPhone: Ref<number | null> = ref(null)
        const previousOwnerIin: Ref<string | null> = ref(null)
        const connectedServices: Ref<ConnectedServicesResponse[] | null> =
            ref(null)
        const gbdflAddresses: Ref<GbdflAddressesResponse | null> = ref(null)
        const gbdflAddress: Ref<GetAddressResponse | null> = ref(null)
        const selectedAddress: Ref<GbdflAddress | null> = ref(null)
        const addressesMatchResponse: Ref<AddressesMatchResponse | null> =
            ref(null)

        const newContractNumber: Ref<number | null> = ref(null)
        const newRequestId: Ref<number | null> = ref(null)
        const newRequestStatus: Ref<number | null> = ref(null)

        const applicationsActive: Ref<Application[]> = ref([])
        const applicationsHistory: Ref<Application[]> = ref([])
        const applicationSelected: Ref<Application | null> = ref(null)

        const applicationFile: Ref<any> = ref('')

        const flow: Ref<string | null> = ref(null)

        const rentServices: ComputedRef<ConnectedServicesResponse[]> = computed(
            () => {
                if (!connectedServices.value) {
                    return []
                }

                return connectedServices.value.filter(
                    (service) =>
                        service.type === 'base' &&
                        service.service_type === 'RENT'
                )
            }
        )

        const autoblankForm: ComputedRef<any> = computed(() => {
            const {
                iin,
                first_name: firstName,
                middle_name: middleName,
                last_name: lastName
            } = customerStore.getIndividual!

            const {
                doc_number: docNumber,
                doc_date_from: docDateFrom,
                doc_issued_by: docIssuedBy
            } = customerStore.getCustomerDoc!

            const mainProductOffer = (connectedServices.value || []).reduce(
                (acc, cur) => acc + cur.name + ', ',
                ''
            )

            const contractIds = (connectedServices.value || []).map((product) =>
                Number(product.annual_contract_id)
            )
            const annualContractId = Math.max(...contractIds)

            const adrStreet = `${addressesMatchResponse.value?.data?.town_name}, ${addressesMatchResponse.value?.data?.street_name}, ${addressesMatchResponse.value?.data?.house}`

            const CID =
                foundOwner.value?.global_id ||
                selectedLocal.value?.old_account_id

            const firstNameInitial = firstName ? `${firstName[0]}.` : ''
            const middleNameInitial = middleName ? `${middleName[0]}.` : ''

            const blankAbonentName =
                `${lastName} ${firstNameInitial}${middleNameInitial}`.trim()

            const form = {
                BlankID: 23,
                CID,
                DocumentParams: {
                    mobnum: Number(customerStore.getPrimaryMobilePhone),
                    user: 'MobApp'
                },
                dataJson: {
                    localID: null,
                    IIN: iin,
                    firstName,
                    middleName,
                    lastName,
                    docNumber,
                    docDateFrom,
                    docIssuedBy,
                    email: customerStore.getPrimaryEmail,
                    mainProductOffer,
                    paymentFormID: 1,
                    adrStreet,
                    annualContractId,
                    blankAbonentName,
                    mobnum: Number(customerStore.getPrimaryMobilePhone)
                }
            }

            if (rentServices.value.length) {
                rentServices.value.forEach((service, i) => {
                    // @ts-ignore
                    form.dataJson[`typeDevice${i + 1}`] = service.name
                    // @ts-ignore
                    form.dataJson[`priceDevice${i + 1}`] =
                        service.price.toString()
                })
            }

            return form
        })

        const setIsLoading = (state: boolean): void => {
            isLoading.value = state
        }

        const setSearchType = (value: string | null): void => {
            searchType.value = value
        }

        const setSelectedLocal = (value: CheckProductsParams): void => {
            selectedLocal.value = value
        }

        const setPreviousOwnerData = (
            value: PreviousOwnerPersonalAccountData | null
        ): void => {
            previousOwnerPersonalAccountData.value = value
        }

        const setSelectedAddresses = (value: GbdflAddress): void => {
            selectedAddress.value = value
        }

        const setGbdflAddress = (value: GetAddressResponse | null): void => {
            gbdflAddress.value = value
        }

        const setPreviousOwnerPhone = (value: number | null): void => {
            previousOwnerPhone.value = value
        }

        const setPreviousOwnerIin = (value: string | null): void => {
            previousOwnerIin.value = value
        }

        const setApplicationSelected = (value: Application): void => {
            applicationSelected.value = value
        }

        const setErrorState = (value: ReregistrationError | null): void => {
            errorState.value = value
        }

        const setSuccessState = (value: ReregistrationError | null): void => {
            successState.value = value
        }

        const setFlow = (value: string | null): void => {
            flow.value = value
        }

        const resetReregistration = (): void => {
            selectedLocal.value = null
            exOwnerProducts.value = null
            newRequestId.value = null
            applicationFile.value = null
            requestId.value = null
            foundLocalIds.value = null
            foundOwner.value = null
            previousOwnerPersonalAccountData.value = null
            previousOwnerPhone.value = null
            selectedLocal.value = null
            applicationSelected.value = null
            flow.value = null
        }

        const makeReregistrationRequest = async (
            params: MakeReregistrationRequestParams
        ): Promise<void> => {
            isLoading.value = true

            try {
                const { data } =
                    await ReregistrationApi.makeReregistrationRequest(params)

                requestId.value = data.request_id
            } catch (error) {
                console.log(
                    'error with `makeReregistrationRequest` method ==> ',
                    error
                )
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message:
                        're-registration | error with `makeReregistrationRequest`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            } finally {
                isLoading.value = false
            }
        }

        const searchOwnerByIin = async (
            params: SearchByIinParams
        ): Promise<void> => {
            foundLocalIds.value = null
            foundOwner.value = null
            isLoading.value = true

            try {
                const { data } = await ReregistrationApi.searchByIin(params)
                foundLocalIds.value = data
            } catch (error) {
                console.log('error with `searchOwnerByIin` method ==> ', error)
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message: 're-registration | error with `searchOwnerByIin`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            } finally {
                isLoading.value = false
            }
        }

        const searchOwnerByPhone = async (
            params: SearchByMobilePhoneParams
        ): Promise<void> => {
            foundLocalIds.value = null
            foundOwner.value = null
            isLoading.value = true

            try {
                const { data } = await ReregistrationApi.searchByMobilePhone(
                    params
                )
                foundLocalIds.value = data
            } catch (error) {
                console.log(
                    'error with `searchOwnerByPhone` method ==> ',
                    error
                )
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message:
                        're-registration | error with `searchOwnerByPhone`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            } finally {
                isLoading.value = false
            }
        }

        const searchOwnerByLocalId = async (
            params: SearchByLocalIdParams
        ): Promise<void> => {
            foundOwner.value = null
            foundLocalIds.value = null
            isLoading.value = true

            try {
                const { data } = await ReregistrationApi.searchByLocalID(params)
                foundOwner.value = data
            } catch (error) {
                console.log(
                    'error with `searchOwnerByLocalId` method ==> ',
                    error
                )
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message:
                        're-registration | error with `searchOwnerByLocalId`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            } finally {
                isLoading.value = false
            }
        }

        const productsCheck = async (): Promise<void> => {
            exOwnerProducts.value = null

            if (!selectedLocal.value) {
                return
            }

            isLoading.value = true

            try {
                const { data } = await ReregistrationApi.productsCheck({
                    ...selectedLocal.value,
                    ...{ request_id: requestId.value! }
                })
                exOwnerProducts.value = data
            } catch (error) {
                console.log('error with `productsCheck` method ==> ', error)
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message: 're-registration | error with `productsCheck`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: {
                            ...selectedLocal.value,
                            ...{ request_id: requestId.value! }
                        },
                        response: errorData
                    }
                })

                throw error
            } finally {
                isLoading.value = false
            }
        }

        const getConnectedServices = async (
            params: ConnectedServicesParams
        ): Promise<void> => {
            isLoading.value = true
            connectedServices.value = null

            try {
                const { data } = await ReregistrationApi.getConnectedServices(
                    params
                )

                connectedServices.value = data
            } catch (error) {
                console.log(
                    'error with `getConnectedServices` method ==> ',
                    error
                )
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message:
                        're-registration | error with `getConnectedServices`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            } finally {
                isLoading.value = false
            }
        }

        const getExConnectedServices = async (): Promise<void> => {
            isLoading.value = true
            connectedServices.value = null

            const params: MakeRequestReissueParams = {
                request_id: applicationSelected.value!.id!
            }

            try {
                const { data } = await ReregistrationApi.getExConnectedServices(
                    params
                )

                connectedServices.value = data
            } catch (error) {
                console.log(
                    'error with `getExConnectedServices` method ==> ',
                    error
                )
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message:
                        're-registration | error with `getExConnectedServices`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            } finally {
                isLoading.value = false
            }
        }

        const getAddressesGbdfl = async (
            params: GbdflAddressesParams
        ): Promise<void> => {
            gbdflAddresses.value = null

            try {
                const { data } = await ReregistrationApi.getAddressesGbdfl(
                    params
                )
                gbdflAddresses.value = data
            } catch (error) {
                console.log('error with `getAddressesGbdfl` method ==> ', error)
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message: 're-registration | error with `getAddressesGbdfl`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            }
        }

        const confirmAddress = async (
            params: GetAddressParams
        ): Promise<void> => {
            gbdflAddress.value = null
            isLoading.value = true

            try {
                const { data } = await ReregistrationApi.getAddress(params)

                gbdflAddress.value = data
            } catch (error) {
                console.log('error with `confirmAddress` method ==> ', error)
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message: 're-registration | error with `confirmAddress`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            } finally {
                isLoading.value = false
            }
        }

        const matchAddresses = async (
            params: AddressesMatchParams
        ): Promise<void> => {
            addressesMatchResponse.value = null
            isLoading.value = true

            try {
                const { data } = await ReregistrationApi.addressesMatch(params)
                addressesMatchResponse.value = data
            } catch (error) {
                console.log('error with `matchAddresses` method ==> ', error)
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message: 're-registration | error with `matchAddresses`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            } finally {
                isLoading.value = false
            }
        }

        const createAutoblank = async (code?: string): Promise<void> => {
            isLoading.value = true
            const params = autoblankForm.value

            if (code) {
                params.DocumentParams.code = Number(code)
            }

            try {
                const { data } = await OnboardingApi.createAutoblank(params)
                applicationFile.value = data
            } catch (error) {
                console.log('error with `createAutoblank` method ==> ', error)
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message: 're-registration | error with `createAutoblank`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            } finally {
                isLoading.value = false
            }
        }

        const generateReissuePdf = async (code?: string): Promise<void> => {
            isLoading.value = true
            const params: GenerateReissuePdfParams = {
                request_id: applicationSelected.value!.id
            }

            if (code) {
                params.code = code
                params.code_date = TelecomService.getLocalISOWithoutTimezone(
                    new Date()
                )
            }

            try {
                const { data } = await ReregistrationApi.generateReissuePdf(
                    params
                )
                applicationFile.value = data
            } catch (error) {
                console.log(
                    'error with `generateReissuePdf` method ==> ',
                    error
                )
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message:
                        're-registration | error with `generateReissuePdf`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            } finally {
                isLoading.value = false
            }
        }

        const generateReissueDocument = async (
            params: GenerateReissueDocumentParams
        ): Promise<void> => {
            isLoading.value = true

            try {
                const { data } =
                    await ReregistrationApi.generateReissueDocument(params)
                applicationFile.value = data
            } catch (error) {
                console.log(
                    'error with `generateReissueDocument` method ==> ',
                    error
                )
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message:
                        're-registration | error with `generateReissueDocument`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            } finally {
                isLoading.value = false
            }
        }

        const contractAvailabilityCheck = async (): Promise<void> => {
            newContractNumber.value = null

            try {
                const { data } =
                    await ReregistrationApi.contractAvailabilityCheck({
                        request_id:
                            requestId.value! || applicationSelected.value!.id
                    })

                newContractNumber.value = data.contractNumber
            } catch (error) {
                console.log(
                    'error with `contractAvailabilityCheck` method ==> ',
                    error
                )
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message:
                        're-registration | error with `contractAvailabilityCheck`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: {
                            request_id: requestId.value!
                        },
                        response: errorData
                    }
                })

                throw error
            }
        }

        const changeBillAccountOwner = async (
            params: ChangeBillAccountOwnerParams
        ): Promise<void> => {
            newRequestId.value = null

            try {
                const { data } = await ReregistrationApi.changeBillAccountOwner(
                    params
                )

                newRequestId.value = data.data.id
            } catch (error) {
                console.log(
                    'error with `changeBillAccountOwner` method ==> ',
                    error
                )
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message:
                        're-registration | error with `changeBillAccountOwner`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            }
        }

        const completeExReregistration = async (
            params: CompleteExReregistrationResponse
        ): Promise<void> => {
            newRequestId.value = null

            try {
                const { data } =
                    await ReregistrationApi.completeExReregistration(params)

                newRequestId.value = data.data.id
            } catch (error) {
                console.log(
                    'error with `completeExReregistration` method ==> ',
                    error
                )
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message:
                        're-registration | error with `completeExReregistration`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            }
        }

        const getBillAccountOwner = async (): Promise<void> => {
            newRequestStatus.value = null

            const params: GetBillAccountOwnerParams = {
                crm_request_id: newRequestId.value!,
                request_id: requestId.value! || applicationSelected.value!.id
            }

            try {
                const { data } = await ReregistrationApi.getBillAccountOwner(
                    params
                )

                newRequestStatus.value = data.state_data.id
            } catch (error) {
                console.log(
                    'error with `getBillAccountOwner` method ==> ',
                    error
                )
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message:
                        're-registration | error with `getBillAccountOwner`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: params,
                        response: errorData
                    }
                })

                throw error
            }
        }

        const getApplications = async (filter: number): Promise<void> => {
            isLoading.value = true

            try {
                const { data } = await ReregistrationApi.getApplications({
                    filter
                })

                if (filter === 1) {
                    applicationsActive.value = data.active
                } else {
                    applicationsHistory.value = data.history
                }
            } catch (error) {
                console.log('error with `getApplications` method ==> ', error)
                const errorData: any = (error as AxiosError)?.response?.data

                SentryService.sendEvent({
                    message: 're-registration | error with `getApplications`',
                    tags: {
                        flow: 're-registration'
                    },
                    extra: {
                        request: 'params',
                        response: errorData
                    }
                })

                throw error
            } finally {
                isLoading.value = false
            }
        }

        return {
            isLoading,
            setIsLoading,
            searchOwnerByIin,
            searchOwnerByPhone,
            foundLocalIds,
            searchOwnerByLocalId,
            foundOwner,
            productsCheck,
            selectedLocal,
            setSelectedLocal,
            exOwnerProducts,
            previousOwnerPersonalAccountData,
            setPreviousOwnerData,
            getConnectedServices,
            connectedServices,
            getAddressesGbdfl,
            gbdflAddresses,
            selectedAddress,
            setSelectedAddresses,
            confirmAddress,
            setPreviousOwnerPhone,
            previousOwnerPhone,
            setPreviousOwnerIin,
            previousOwnerIin,
            matchAddresses,
            gbdflAddress,
            setGbdflAddress,
            addressesMatchResponse,
            searchType,
            setSearchType,
            createAutoblank,
            applicationFile,
            rentServices,
            contractAvailabilityCheck,
            newContractNumber,
            changeBillAccountOwner,
            getBillAccountOwner,
            newRequestId,
            newRequestStatus,
            makeReregistrationRequest,
            requestId,
            getApplications,
            setApplicationSelected,
            applicationsActive,
            applicationsHistory,
            applicationSelected,
            generateReissuePdf,
            errorState,
            setErrorState,
            successState,
            setSuccessState,
            getExConnectedServices,
            generateReissueDocument,
            completeExReregistration,
            resetReregistration,
            flow,
            setFlow
        }
    },
    {
        persist: true
    }
)
