import type { AxiosResponse } from 'axios'

import axios from '@/shared/services/api/axios'
import type {
    InternetDetailParams,
    InternetDetalization,
    InternetDevice,
    MobileDetailsParams,
    TelephonyDetailParams
} from '@/components/detalization/types'
import type { PhonesListItem } from '@/components/services/ServiceList/types'

export class DetalizationApi {
    static getInternetDetalization(
        params: InternetDetailParams
    ): Promise<AxiosResponse<{ data: InternetDetalization[] }>> {
        return axios.get('/api/v1.0/customer/export/internet', { params })
    }

    static getInternetDevices(): Promise<AxiosResponse<InternetDevice[]>> {
        return axios.get('/api/v1.0/customer/devices/internet-devices')
    }

    static downloadDetalization(
        type: string,
        format: string,
        params: any
    ): Promise<AxiosResponse<any>> {
        const responseType: 'blob' | 'json' = format === 'pdf' ? 'blob' : 'json'

        return axios.get(
            `/api/v1.0/customer/export/${type}/sign-export-${format}`,
            { responseType, params }
        )
    }

    static sendXlsToMail(
        type: string,
        params: MobileDetailsParams
    ): Promise<AxiosResponse<any>> {
        return axios.get(`/api/v1.0/customer/export/${type}/send-to-email`, {
            params
        })
    }

    static sendPdfToMail(
        type: string,
        params: MobileDetailsParams
    ): Promise<AxiosResponse<any>> {
        return axios.get(
            `/api/v1.0/customer/export/${type}/send-pdf-to-email`,
            {
                params
            }
        )
    }

    static sendEmailInternetDetalization(): Promise<AxiosResponse<any>> {
        return axios.get(`/api/v1.0/customer/export/internet/sign-export-type`)
    }

    static getMobileDetalization(
        phone: PhonesListItem
    ): Promise<AxiosResponse<any>> {
        return axios.get(
            `/api/v1.0/customer/resources_new/${phone.service_type}/${phone.phone}`
        )
    }

    static getMobileDetails(
        params: MobileDetailsParams
    ): Promise<AxiosResponse<any>> {
        return axios.get('/api/v1.0/customer/export/mobile', { params })
    }

    static getTelephonyDetalization(
        params: TelephonyDetailParams
    ): Promise<AxiosResponse<any>> {
        return axios.get('/api/v1.0/customer/export/telephony', { params })
    }
}
