<template>
    <Wrapper>
        <AppealsDetail />
    </Wrapper>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { computed, defineAsyncComponent, type ComputedRef } from 'vue'

import Wrapper from '@/shared/components/Layout/Wrapper.vue'
import { useTitle } from '@/shared/hooks/useTitle'
import PageError from '@/shared/components/UI/PageError.vue'
import Preloader from '@/shared/components/UI/Preloader.vue'

const AppealsDetail = defineAsyncComponent({
    loadingComponent: Preloader,
    errorComponent: PageError,
    loader: () => import('@/components/appeals/Appeals/AppealsDetail.vue')
})

const { t } = useI18n()
const { setTitle } = useTitle()
const route = useRoute()

const orderId: ComputedRef<string> = computed(() => route.params.id as string)

setTitle(`${t('appeals_appeal')} №${orderId.value}`, { translate: false })
</script>
